import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { IUser } from '../../core/services/user.service';
import { REFRESH_TOKEN_NAME, TOKEN_NAME } from '../../shared/constants/config';

@Injectable({
    providedIn: 'root'
})
export class JwtService {
    getToken(): string {
        return localStorage.getItem(TOKEN_NAME);
    }

    getRefreshToken(): string {
        return localStorage.getItem(REFRESH_TOKEN_NAME);
    }

    setToken(token: string): void {
        localStorage.setItem(TOKEN_NAME, token);
    }

    setRefreshToken(refreshToken: string): void {
        localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
    }

    getTokenExpirationDate(token: string): Date {
        const decoded = this._decodeToken(token);
        if (!decoded) return null;
        if (decoded.exp === undefined) return null;
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    getUser(token?: string): IUser {
        if (!token) token = this.getToken();
        if (!token) return null;
        const user = this._decodeToken(token);
        return user;
    }

    isTokenExpired(token?: string): boolean {
        if (!token) token = this.getToken();
        if (!token) return true;
        const date = this.getTokenExpirationDate(token);
        if (!date) return false;
        return !(date.valueOf() > new Date().valueOf());
    }

    deleteToken() {
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem(REFRESH_TOKEN_NAME);
    }

    _decodeToken(token): IUser {
        try {
            return jwt_decode(token);
        } catch (e) {
            // if token decoding failed then we delete the tokens from localstorage
            this.deleteToken();
            return null;
        }
    }

    setUserImage(image: string | null): void {
        if (image) localStorage.setItem('image', image);
        else localStorage.removeItem('image');
    }

    getUserImage(): string | null {
        return localStorage.getItem('image') || null;
    }
}
