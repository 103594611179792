<ng-container *ngIf="(data.type === BillingStatusModalType.Subscribe || data.type === BillingStatusModalType.Pause); else normalModal">
    <div class="dialog-content-wrapper">
        <div class="price-section">
            <div class="price-wrapper">
                <span class="prefix">$</span>
                <span class="value">{{ price | number : '1.2-2' }}</span>
            </div>
            <div class="unit-selector">
                <div class="unit-item" [class.active]="currency === Currency.xDai" (click)="changeUnit(Currency.xDai)">
                    <span>xDAI</span>
                </div>
                <div class="unit-item" [class.active]="currency === Currency.XGT" (click)="changeUnit(Currency.XGT)">
                    <span>XGT</span>
                </div>
            </div>
        </div>
        <p>{{data?.description}}</p>
        <div class="btn-wrapper">
            <button class="confirm" (click)="confirm()">Approve</button>
            <button class="cancel" (click)="closeModal()">Cancel</button>
        </div>
    </div>
</ng-container>

<ng-template #normalModal>
    <fieldset class="fieldset fieldset__custom">
        <legend class="fieldset__title">{{data?.title}} CONFIRMATION</legend>
        <div class="fieldset__body">
            <div class="fieldset__body__item">
                <button class="fieldset__body__button" (click)="confirm()">CONFIRM</button>
            </div>

            <div class="fieldset__body__item">
                <button class="fieldset__body__button fieldset__body__button_cancel" (click)="closeModal()">CANCEL</button>
            </div>
        </div>
        <span class="fieldset__span">{{data?.description}}</span>
    </fieldset>
</ng-template>
