<div class="wrapper">
    <div class="main__column">
        <header class="header">
            <div class="link-holder">
                <a class="inline-link" class="main-link" [routerLink]="''" >
                    <svg class="svg-icon icon-logo"><use xlink:href="#logo"></use></svg>
                </a>
            </div>
        </header>
        <main class="main">
            <div class="row_middle-content">
                <section class="main__section">
                    <h1 class="title_main">
                        TERMS AND CONDITIONS
                    </h1>
                    <h2>1. Introductions</h2>

                    <p>
                        Thanks for choosing Spotify
                        <strong
                            >(&ldquo;Spotify&rdquo;, &ldquo;we&rdquo;,
                            &ldquo;us&rdquo;, &ldquo;our&rdquo;).</strong
                        >
                        By using the Spotify service, websites, or software
                        applications (together, the &ldquo;Spotify
                        Service&rdquo; or &ldquo;Service&rdquo;), including by
                        purchasing or receiving
                        <a class="inline-link" href="#s3-3">Codes or Limited Offers</a>, you are
                        entering into a binding contract with our local company
                        in your country of residence (your &ldquo;Local
                        Country&rdquo;) if applicable or the company listed in
                        this <a class="inline-link" href="#s19">chart</a>. Your agreement with us
                        includes these Terms and Conditions of Use
                        (<strong>&ldquo;Terms&rdquo;</strong>) and our
                        <a class="inline-link"
                            href="/int/legal/privacy-policy/plain"
                            target="_blank"
                            >Privacy Policy</a
                        >
                        (together with the
                        <a class="inline-link"
                            href="/int/legal/mobile-terms-and-conditions/plain"
                            target="_blank"
                            >Mobile Terms</a
                        >
                        where applicable, the
                        <strong>&ldquo;Agreements&rdquo;</strong>). If you
                        don&rsquo;t agree with these Terms, then please
                        don&rsquo;t use the Service.
                    </p>

                    <p>
                        We&rsquo;re pleased to make our Service available to
                        you. The Spotify Service includes social and interactive
                        features.
                    </p>

                    <p>
                        In order to use the Spotify Service, you need to (a) be
                        18 or older, or be 13 or older and have your parent or
                        guardian&rsquo;s consent to these Terms, (b) have the
                        power to enter a binding contract with us and are not
                        barred from doing so under any applicable laws, and (c)
                        be resident in the Local Country. You also warrant that
                        any registration information that you submit to Spotify
                        is true, accurate and complete, and you agree to keep it
                        that way at all times.
                    </p>

                    <h2>2. Changes to the Agreements</h2>

                    <p>
                        Occasionally we may, in our discretion, make changes to
                        the Spotify Service and Agreements. When we make changes
                        to the Agreements that we consider material, we&rsquo;ll
                        notify you through the Service. By continuing to use the
                        Service after those changes are made, you are expressing
                        and acknowledging your acceptance of the changes.
                    </p>

                    <h2>3.Enjoying Spotify</h2>

                    <p>
                        Here&rsquo;s some information about all the ways you can
                        enjoy Spotify.
                    </p>

                    <h3>3.1 Subscriptions</h3>

                    <p>
                        Spotify account holders may access the Spotify Service
                        by any of our several Subscriptions:
                    </p>

                    <ul>
                        <li>
                            <strong>Free Service:</strong> an ad-based,
                            free-of-charge service;
                        </li>
                        <li>
                            <strong>Unlimited Service:</strong> a subscription
                            fee-based service;
                        </li>
                        <li>
                            <strong>Premium Service:</strong> an ad-free,
                            subscription fee-based service which enables you to
                            listen to music while not connected to the internet,
                            among other features; or
                        </li>
                        <li>
                            <strong>Mobile Service:</strong> via a supported
                            mobile handset.
                        </li>
                    </ul>

                    <p>
                        The Premium Service permits you to store cached content
                        on up to three (3) personal computers, mobile handsets
                        and/or other relevant devices (collectively
                        &ldquo;Devices&rdquo;) while your Premium Service
                        subscription is active. You may not transfer copies of
                        cached content to any other device via any means. By
                        using the Mobile Service, you agree to accept the Mobile
                        Terms and to register for the Service as a mobile
                        subscriber. The Premium Service and the Unlimited
                        Service are hereinafter referred to as “Paid
                        Subscriptions”.
                    </p>

                    <h3>
                        3.2 Codes and other Limited Offers
                    </h3>

                    <p>
                        If you have purchased a code sold by or on behalf of
                        Spotify for access to the Unlimited Service or Premium
                        Service for the time period indicated on your email,
                        card or paper receipt (&ldquo;Code&rdquo;), or if you
                        are accessing the service through some other limited
                        offer which you received or purchased from Spotify
                        (&ldquo;Limited Offer&rdquo;), these Agreements apply to
                        your access to the Service. Any separate sets of terms
                        and conditions presented to you along with the Code or
                        other Limited Offer also apply to your access to the
                        Service using the Code or Limited Offer.
                    </p>

                    <p>
                        Subject to any rights you may have under Section 12,
                        Codes are only redeemable in the country where you
                        purchased such Code, not redeemable for cash and may not
                        be returned for a cash refund, exchanged, resold or used
                        to purchase other Codes.
                    </p>

                    <h3>3.3 Trials</h3>

                    <p>
                        From time to time, we may offer trials of the Premium
                        Service and/or Mobile Service for a specified period
                        without payment (a &ldquo;Trial&rdquo;). Spotify
                        reserves the right, in its absolute discretion, to
                        determine your eligibility for a Trial, and to withdraw
                        or to modify a Trial at any time without prior notice
                        and with no liability.
                    </p>

                    <p>
                        For some Trials, we&rsquo;ll require you to provide your
                        payment details to start the Trial. At the end of such
                        Trials, we may automatically start to charge you for the
                        Premium Service on the first day following the end of
                        the Trial, on a recurring monthly basis. By providing
                        your payment details in conjunction with the Trial, you
                        agree to this charge. If you do not want this charge,
                        you must change your Subscription to the Free Service
                        through your Spotify account&rsquo;s settings before the
                        end of the Trial.
                    </p>

                    <h2>4. Licence and assignment</h2>

                    <p>
                        The Spotify Service and the content provided through it
                        are the property of Spotify or Spotify's licensors, and
                        we grant you a limited, non-exclusive, revocable licence
                        to make personal, non-commercial use of the Spotify
                        Service and to receive the media content made available
                        through the Spotify Service in your Local Country, based
                        on the Subscription or Trial you have selected (the
                        &ldquo;Licence&rdquo;). This Licence shall remain in
                        effect for a period of 20 years unless terminated by you
                        or Spotify.
                    </p>

                    <p>
                        The Spotify software applications are licensed, not
                        sold, to you, and Spotify retains ownership of all
                        copies of the Spotify software applications even after
                        installation on your Devices. Spotify may assign these
                        Agreements or any part of them without restrictions. You
                        may not assign these Agreements or any part of them, nor
                        transfer or sub-licence your rights under this Licence,
                        to any third party.
                    </p>

                    <p>
                        All Spotify trademarks, service marks, trade names,
                        logos, domain names, and any other features of the
                        Spotify brand are the sole property of Spotify. This
                        Licence does not grant you any rights to use the Spotify
                        trademarks, service marks, trade names, logos, domain
                        names, or any other features of the Spotify brand,
                        whether for commercial or non-commercial use.
                    </p>

                    <p>
                        You agree to abide by our
                        <a class="inline-link"
                            href="/int/legal/end-user-agreement/plain#s8"
                            target="_blank"
                            >User Guidelines</a
                        >
                        and not to use the Spotify Service (including but not
                        limited to its content) in any manner not expressly
                        permitted by the Terms.
                    </p>

                    <p>
                        Third party software libraries included in the Spotify
                        Service are licensed to you either under these Terms, or
                        under the relevant third party software library&rsquo;s
                        licence terms as published in the help or settings
                        section of our desktop and mobile client and on our
                        website.
                    </p>

                    <h2>5. Third Party Applications</h2>

                    <p>
                        The Spotify Service provides a platform for third party
                        applications, websites and services to make available
                        products and services to you (<strong
                            >&ldquo;Third Party Applications&rdquo;</strong
                        >), and your use of these Third Party Applications is
                        subject to their terms of use. You understand and agree
                        that Spotify is not responsible or liable for the
                        behavior, features, or content of any Third Party
                        Application.
                    </p>

                    <h2>6. User generated content</h2>

                    <p>
                        Spotify users may post, upload and/or contribute
                        (&ldquo;post&rdquo;) content to the Service, including
                        pictures, text and playlist compilations (<strong
                            >&ldquo;User Content&rdquo;</strong
                        >). You represent that you have the right to post any
                        User Content which you post to the Service, and that
                        such User Content, or its use by us as contemplated by
                        this Agreement, does not violate these Agreements,
                        applicable law, or the intellectual property rights of
                        others. You grant us a non-exclusive, transferable,
                        sub-licensable, royalty-free, worldwide license to use
                        any User Content that you post on or in connection with
                        Spotify. This license lasts until you terminate your
                        Spotify account, except in the case of User Content that
                        you have published, made public and/or share with
                        others. Aside from the rights specifically granted
                        herein, you retain ownership of all rights, including
                        intellectual property rights, in the User Content that
                        you post to the Spotify Service, except that, where
                        applicable under Local Country law, you agree to waive
                        your right to be identified as the author of any User
                        Content on the Spotify Service and your right to object
                        to derogatory treatment of such User Content.
                    </p>

                    <p>
                        Spotify does not monitor, review, or edit User Content,
                        but reserves the right to remove or disable access to
                        any User Content for any or no reason, including but not
                        limited to, User Content that, in Spotify&rsquo;s sole
                        discretion, violates these Agreements. Spotify may take
                        these actions without prior notification to you. Removal
                        or disabling of access to User Content shall be at our
                        sole discretion, and we do not promise to remove or
                        disable access to any specific User Content.
                    </p>

                    <p>
                        Spotify is not responsible for User Content nor does it
                        endorse any opinion contained in User Content. If you
                        believe that any User Content infringes your
                        intellectual property rights, please click
                        <a class="inline-link"
                            href="/int/legal/copyright-policy/plain"
                            target="_blank"
                            >here</a
                        >, or if you believe that any User Content does not
                        comply with the User Guidelines (Section 8 below) or
                        that your rights under applicable law have been
                        otherwise infringed by any User Content, please contact
                        us at
                        <a class="inline-link" href="mailto:legal-feedback@spotify.com"
                            >legal-feedback@spotify.com</a
                        >.
                    </p>

                    <h2>7. Consideration</h2>

                    <p>
                        In consideration for the rights granted to you under
                        these Terms, you grant us the right (a) to allow the
                        Spotify Service to use the processor, bandwidth and
                        storage hardware on your Device in order to facilitate
                        the operation of the Service, (b) to provide advertising
                        and other information to you, if you subscribe to the
                        Free Service, and (c) to allow our business partners to
                        do the same.
                    </p>

                    <p>
                        You grant Spotify a non-exclusive, transferable,
                        sub-licensable, royalty-free, perpetual, worldwide
                        licence to use, reproduce, make available to the public,
                        publish, translate and distribute any User Content that
                        you post on or otherwise provide through the Spotify
                        Service.
                    </p>

                    <p>
                        These Terms are not intended to grant rights to anyone
                        except you and Spotify, and in no event shall these
                        Terms create any third party beneficiary rights. Any
                        failure by Spotify to enforce these Terms or any
                        provision thereof shall not waive Spotify’s right to do
                        so.
                    </p>

                    <h2>8. User Guidelines</h2>

                    <p>
                        We’ve established a few ground rules for you to follow
                        when using the Service, to make sure Spotify stays
                        enjoyable for everyone. Please follow these rules and
                        encourage other users to do the same.
                    </p>

                    <p>
                        Spotify respects intellectual property rights and
                        expects you to do the same. This means, for example,
                        that the following is not permitted: (a) Copying,
                        reproducing, “ripping”, recording, or making available
                        to the public any part of the Spotify Services or
                        content delivered to you via the Spotify Services, or
                        otherwise any making use of the Spotify Service which is
                        not expressly permitted under these Terms; (b) using the
                        Spotify service to import or copy any local files you do
                        not have the legal right to import or copy in this way;
                        (c) reverse-engineering, decompiling, disassembling,
                        modification or creating derivative works based on the
                        Spotify Services or any part thereof; (d) circumventing
                        any technology used by Spotify, its licensors, or any
                        third party to protect content accessible through the
                        Service; (e) renting or leasing of any part of the
                        Services; (f) circumventing of any territorial
                        restrictions applied by Spotify; (g) artificially
                        increasing play count or otherwise manipulating the
                        Services by using a script or other automated process;
                        (h) removing or altering any copyright, trademark or
                        other intellectual property notices contained on or
                        provided through the Spotify Service; (i) providing your
                        password to any other person or using any other person’s
                        user name and password.
                    </p>

                    <p>
                        Please respect Spotify and other users of the Spotify
                        Service. Don’t engage in any activity on the Service or
                        upload User Content, including registering and/or using
                        a username, which is or includes material that (a) is
                        offensive, abusive, defamatory, pornographic or obscene;
                        (b) is illegal, or intended to promote or commit an
                        illegal act of any kind, including but not limited to,
                        violations of intellectual property rights, privacy
                        rights or proprietary rights of Spotify or a third
                        party; (c) includes personal data of third parties or is
                        intended to solicit such personal data, (d) includes
                        malicious content such as malware, trojan horses or
                        viruses, or otherwise interferes with any user’s access
                        to the Service; (e) is intended or does harass or bully
                        other users; (f) impersonates or misrepresents your
                        affiliation with another user, person or entity, or is
                        otherwise fraudulent, false, deceptive, or misleading;
                        (g) uses automated means to artificially promote
                        content; (h) involves the transmission of unsolicited
                        mass mailing (“spam”), junk mail, chain letter, or
                        similar, including through the Spotify inbox; (i)
                        involves commercial or sales activities, such as
                        advertising, contests, sweepstakes, or pyramid schemes;
                        (j) promotes commercial products or services; (k)
                        interferes with the Spotify Service, tampers with or
                        attempts to probe, scan, or test for vulnerabilities in
                        the Service or Spotify’s computer systems or network, or
                        breaches any of Spotify’s security or authentication
                        measures, or (l) conflicts with the Agreement, as
                        determined by Spotify. You agree that Spotify may also
                        reclaim your username for any reason.
                    </p>

                    <p>
                        Please be thoughtful about what you make public on
                        Spotify. The Spotify Service includes social and
                        interactive features, including the ability to post User
                        Content, share content, and make certain information
                        about you public, as further described in your account
                        settings. Remember that shared or publicly available
                        information may be used and re-shared by other users on
                        Spotify or across the web, so please use Spotify
                        carefully and manage your account settings regularly.
                        Spotify has no responsibility for your choices to make
                        any actions or material public on the Service.
                    </p>

                    <p>
                        Your password protects your user account, and you are
                        solely responsible for keeping your password
                        confidential and secure. You understand that you are
                        responsible for all use of your username and password on
                        the Service. If your username or password is lost or
                        stolen, or if you believe there has been unauthorized
                        access to your account by third parties, please notify
                        us immediately and change your password as soon as
                        possible.
                    </p>

                    <h2>9. Copyright infringement</h2>

                    <p>
                        Spotify respects the rights of intellectual property
                        owners. For details on Spotify&rsquo;s copyright policy,
                        please
                        <a class="inline-link"
                            href="/int/legal/copyright-policy/plain"
                            target="_blank"
                            >click here</a
                        >.
                    </p>

                    <h2>
                        10. Technology limitations and
                        modifications
                    </h2>

                    <p>
                        Spotify will make reasonable efforts to keep the Spotify
                        Service operational. However, certain technical
                        difficulties or maintenance may, from time to time,
                        result in temporary interruptions. Spotify reserves the
                        right, periodically and at any time, to modify or
                        discontinue, temporarily or permanently, functions and
                        features of the Spotify Service, with or without notice,
                        all without liability to you for any interruption,
                        modification, or discontinuation of the Spotify Service
                        or any function or feature thereof. You understand and
                        agree that Spotify has no obligation to maintain,
                        support, upgrade, or update the Service, or to provide
                        all or any specific content through the Service.
                    </p>

                    <h2>11. Export control</h2>

                    <p>
                        Spotify&rsquo;s products may be subject to U.S. export
                        and reexport control laws and regulations, including the
                        Export Administration Regulations (&ldquo;EAR&rdquo;)
                        maintained by the U.S. Department of Commerce, trade and
                        economic sanctions maintained by the Treasury
                        Department&rsquo;s Office of Foreign Assets Control
                        (&ldquo;OFAC&rdquo;), and the International Traffic in
                        Arms Regulations (&ldquo;ITAR&rdquo;) maintained by the
                        Department of State. You warrant that you are (1) not
                        located in Cuba, Iran, North Korea, Sudan, or Syria, and
                        (2) are not a denied party as specified in the
                        regulations listed above.
                    </p>

                    <p>
                        You agree to comply with all applicable export and
                        reexport control laws and regulations, including the
                        EAR, trade and economic sanctions maintained by OFAC,
                        and the ITAR. Specifically, you agree that you shall not
                        – directly or indirectly – sell, export, reexport,
                        transfer, divert, or otherwise dispose of any products,
                        software, or technology (including products derived from
                        or based on such technology) received from Spotify under
                        these Agreements to any destination, entity, or person
                        prohibited by the laws or regulations of the United
                        States, without obtaining prior authorization from the
                        competent government authorities as required by those
                        laws and regulations. This export control clause shall
                        survive termination or cancellation of these Agreements.
                    </p>

                    <h2>
                        12. Payments, cancellations and
                        cooling off
                    </h2>

                    <p>
                        If you reside in a Local Country which is part of the
                        European Union and have purchased a Paid Subscription or
                        Code online, you have the right to change your mind and
                        receive a full refund within fourteen (14) days of
                        purchase (the &ldquo;Cooling-off Period&rdquo;), but
                        only if you have not logged in or otherwise redeemed or
                        started to consume them.
                    </p>

                    <p>
                        If you have a Paid Subscription, your payment to Spotify
                        will automatically renew at the end of the subscription
                        period, unless you cancel your Paid Subscription through
                        your subscription page before the end of the current
                        subscription period. The cancellation will take effect
                        the day after the last day of the current subscription
                        period, and you will be downgraded to the Free Service.
                        However, if you cancel your payment and/or terminate the
                        Terms after the Cooling-off Period is over (where
                        applicable), and/or before the end of the subscription
                        period, we will not refund any subscription fees already
                        paid to us.
                    </p>

                    <p>
                        Spotify may change the price for the Paid Subscriptions
                        from time to time, and will communicate any price
                        changes to you. Price changes for Paid Subscriptions
                        will take effect at the start of the next subscription
                        period following the date of the price change. By
                        continuing to use the Spotify Service after the price
                        change takes effect, you accept the new price.
                    </p>

                    <h2>13. Term and termination</h2>

                    <p>
                        These Terms will continue to apply to you until
                        terminated by either you or Spotify. Spotify may
                        terminate the Terms or suspend your access to the
                        Spotify Service at any time, including in the event of
                        your actual or suspected unauthorised use of the Spotify
                        Service or non-compliance with the Terms. If you or
                        Spotify terminate the Terms, or if Spotify suspends your
                        access to the Spotify Service, you agree that Spotify
                        shall have no liability or responsibility to you and
                        Spotify will not refund any amounts that you have
                        already paid, to the fullest extent permitted under
                        applicable law. To learn how to terminate your Spotify
                        account, please contact us through the Customer Service
                        contact form.
                    </p>

                    <h2>14. Warranty</h2>

                    <p>
                        We endeavor to provide the best service we can, but you
                        understand and agree that THE SPOTIFY SERVICE IS
                        PROVIDED &ldquo;AS IS&rdquo;, WITHOUT EXPRESS OR IMPLIED
                        WARRANTY OR CONDITION OF ANY KIND. YOU USE THE SPOTIFY
                        SERVICE AT YOUR OWN RISK. SPOTIFY DISCLAIMS ANY
                        WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR
                        A PARTICULAR PURPOSE OR NON-INFRINGEMENT. In addition,
                        Spotify does not warrant, endorse, guarantee or assume
                        responsibility for any Third Party Applications, Third
                        Party Application content, User Content, or any other
                        product or service advertised or offered by a third
                        party on or through the Spotify Service or any
                        hyperlinked website, or featured in any banner or other
                        advertising. You understand and agree that Spotify is
                        not responsible or liable for any transaction between
                        you and third-party providers of Third Party
                        Applications or products or services advertised on or
                        through the Spotify Service. As with any purchase of a
                        product or service through any medium or in any
                        environment, you should use your judgment and exercise
                        caution where appropriate. No advice or information
                        whether oral or in writing obtained by you from Spotify
                        shall create any warranty on behalf of Spotify in this
                        regard. Some aspects of this section may not apply in
                        some jurisdictions.
                    </p>

                    <h2>15. Limitation</h2>

                    <p>
                        You agree that, to the extent permitted by applicable
                        law, your sole and exclusive remedy for any problems or
                        dissatisfaction with the Spotify Service, the Third
                        Party Applications or the Third Party Application
                        content is to uninstall any Spotify software and to stop
                        using the Spotify Service, the Third Party Applications
                        or the Third Party Application content.
                    </p>

                    <p>
                        TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL
                        SPOTIFY, ITS OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS,
                        DIRECTORS, SUBSIDIARIES, AFFILIATES, SUCCESSORS,
                        ASSIGNS, SUPPLIERS OR LICENSORS BE LIABLE FOR (i) ANY
                        INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR
                        CONSEQUENTIAL (INCLUDING LOSS OF USE, DATA, BUSINESS, OR
                        PROFITS) DAMAGES, ARISING OUT OF THE USE OR INABILITY TO
                        USE THE SPOTIFY SERVICE, THIRD PARTY APPLICATIONS OR
                        THIRD PARTY APPLICATION CONTENT, REGARDLESS OF LEGAL
                        THEORY, WITHOUT REGARD TO WHETHER SPOTIFY HAS BEEN
                        WARNED OF THE POSSIBILITY OF THOSE DAMAGES, AND EVEN IF
                        A REMEDY FAILS OF ITS ESSENTIAL PURPOSE; (ii) AGGREGATE
                        LIABILITY FOR ALL CLAIMS RELATING TO THE SPOTIFY
                        SERVICE, THIRD PARTY APPLICATIONS OR THIRD PARTY
                        APPLICATION CONTENT MORE THAN THE AMOUNTS PAID BY YOU TO
                        SPOTIFY DURING THE PRIOR THREE MONTHS IN QUESTION.
                    </p>

                    <p>
                        Nothing in these Agreements removes or limits
                        Spotify&rsquo;s liability for fraud, fraudulent
                        misrepresentation, death or personal injury caused by
                        its negligence. Some aspects of this section may not
                        apply in some jurisdictions.
                    </p>

                    <h2>16. Entire agreement</h2>

                    <p>
                        These Agreements constitute all the terms and conditions
                        agreed upon between you and Spotify and supersede any
                        prior agreements in relation to the subject matter of
                        these Agreements, whether written or oral. Any
                        additional or different terms or conditions in relation
                        to the subject matter of the Agreements in any written
                        or oral communication from you to Spotify are void. You
                        represent that you have not accepted the Agreements in
                        reliance on any oral or written representations made by
                        Spotify that are not contained in the Agreements.
                    </p>

                    <p>
                        Please note, however, that other aspects of your use of
                        the Spotify Service may be governed by additional
                        agreements. That could include, for example, access to
                        the Spotify Community for customer support, access to
                        the Spotify Service as a result of a gift card, or free
                        or discounted Trials. You will agree to separate terms
                        and conditions in those circumstances, which are listed
                        in full
                        <a class="inline-link" href="/int/legal/links/plain" target="_blank">here</a
                        >. Those terms and conditions shall govern only with
                        regard to the aspect of the Service to which they apply,
                        and are distinct from and supplemental to these
                        Agreements, and do not supersede these Agreements. To
                        the extent that there is any conflict between those
                        agreements and these Agreements, these Agreements shall
                        control, except as otherwise provided in these Terms
                    </p>

                    <p>
                        Occasionally we may offer you the chance to participate
                        in sweepstakes, contests, and surveys (&ldquo;Special
                        Promotions&rdquo;) through the Service. Special
                        Promotions may be governed by terms and conditions that
                        are separate from these Terms. If the provisions of a
                        Special Promotion&rsquo;s terms and conditions conflict
                        with these Terms, those separate terms and conditions
                        shall prevail.
                    </p>

                    <h2>17. Severability</h2>

                    <p>
                        Should any provision of the Terms be held invalid or
                        unenforceable for any reason or to any extent, such
                        invalidity or enforceability shall not in any manner
                        affect or render invalid or unenforceable the remaining
                        provisions of the Terms, and the application of that
                        provision shall be enforced to the extent permitted by
                        law.
                    </p>

                    <h2>
                        18. Choice of law, mandatory
                        arbitration and venue
                    </h2>

                    <p>
                        Unless otherwise required by a mandatory law of a member
                        state of the European Union or any other jurisdiction,
                        these Agreements are subject to the laws of Sweden,
                        without regard to choice or conflicts of law principles.
                        Further, you and Spotify agree to the exclusive
                        jurisdiction of the courts of Sweden to resolve any
                        dispute, claim or controversy that arises in connection
                        with these Agreements.
                    </p>
                </section>
            </div>
        </main>
        <footer class="footer">
            <nav class="footer__nav">
                <ul class="top-list">
                    <li class="top-list__item ">
                        <a href="../../../../assets/Terms-of-Use.pdf" class="aside-link__bottom">
                            T&CS
                            <div class="top-list__icon-holder">
                                <svg class="svg-icon icon-terms"><use xlink:href="#terms"></use></svg>
                            </div>
                        </a>
                    </li>
                </ul>
            </nav>
        </footer>
    </div>
</div>

