import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { IUser } from '../../../../core/services/user.service';

@Component({
    selector: 'xion-community-list',
    templateUrl: './community-list.component.html',
    styleUrls: ['./community-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunityListComponent implements OnChanges {
    @ViewChild('usefulSwiper') usefulSwiper: SwiperComponent;
    @Input() name: string;
    @Input() query: string;
    @Input() users: IUser[];

    config: SwiperConfigInterface = {
        direction: 'vertical',
        slidesPerView: 'auto',
        // loop: true,
        keyboard: true,
        freeMode: true,
        mousewheel: true,
        scrollbar: false,
        pagination: false,
        observer: true,
        navigation: {
            nextEl: '.next-user',
            prevEl: '.prev-user',
            disabledClass: 'user-disable'
        },
        breakpoints: {
            640: {
                direction: 'horizontal'
            }
        }
    };

    constructor(private cd: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.cd.detectChanges();
    }
}
