<div class="top-row" *ngIf="post">
    <div class="tabs__media" [class.tabs__media_video]="activeTopTab == 'video'"
         [class.tabs__media_fullscreen]="fullscreen" [class.tabs__media_smallscreen]="!fullscreen">
        <button class="fullscreen" (click)="fullscreen = !fullscreen">
            <svg class="svg-icon icon-fullscreen">
                <use xlink:href="#icon-fullscreen"></use>
            </svg>
        </button>
        <button class="tab__item link-active" [class.link-active]="activeTopTab == 'photo'" *ngIf="post.photo"
                (click)="activeTopTab = 'photo'">
            Photo
        </button>
        <button class="tab__item" [class.link-active]="activeTopTab == 'video'" *ngIf="post.video"
                (click)="activeTopTab = 'video'">
            Video
        </button>
        <div class="img-holder" *ngIf="activeTopTab=='photo' && post.photo">
            <!-- <img [src]="img" alt="" class="image"> -->
            <img [src]="post.photo.url" [alt]="post.photo.filename" class="image">
        </div>
        <xion-video-player class="player-component"
                           *ngIf="activeTopTab=='video' && post.video"
                           [video]="post.video.url"></xion-video-player>
    </div>
    <div class="details">
        <div class="aciton-row">
            <a class="post__actions notification-msg__holder" (click)="sharePost()">
                <svg class="svg-icon icon-share">
                    <use xlink:href="#icon-share"></use>
                </svg>
                <div class="notification-msg">Share</div>
            </a>

            <a class="post__actions post__actions_like notification-msg__holder" *ngIf="user.idxion!=post.user.idxion" (click)="likePost()">
                <svg class="svg-icon icon-like">
                    <use xlink:href="#icon-like"></use>
                </svg>
                <div class="notification-msg">Like</div>
            </a>
            <a  lass="post__actions notification-msg__holder" *ngIf="post.document" [href]="post.document.url">
                <svg class="svg-icon icon-download">
                    <use xlink:href="#icon-download"></use>
                </svg>
                <div class="notification-msg">Download Doc Name</div>
            </a>
        </div>
        <div class="details__row">
            <div class="post__picture post__picture-empty">
                <svg class="svg-icon icon__no-retailer">
                    <use xlink:href="#icon-retailer"></use>
                </svg>
            </div>
            <!-- <div class="post__picture">
                <img [src]="" [alt]="">
            </div> -->
            <h3 class="retailer__name">{{post.user.fullname}}</h3>
        </div>
        <div class="description">
            {{post.text}}
        </div>
        <xion-link-preview class="link__holder"></xion-link-preview>
        <div class="button-holder" *ngIf="user.idxion == post.user.idxion">
            <button class="button button_small button_blue">
                Edit
                <span class="button__arrow"><svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-edit"></use></svg></span>
            </button>
        </div>
    </div>
</div>

<!--
<h2 class="title__sub">
    Top Posts
</h2>
<div class="cards__holder">
    <div class="swiper-container" [swiper]="configProd">
        <div class="swiper-wrapper">
            <a href="" class="card__item swiper-slide">
                <div class="card__picture-holder">
                    <img src="https://picsum.photos/200/300" alt="Title" class="absolute-img__full-image">
                    <svg class="svg-icon icon_no-image">
                        <use xlink:href="#no-image"></use>
                    </svg>
                    <button class="download card__picture_item">
                        <div class="notification-msg__holder">
                            <svg class="svg-icon icon-download">
                                <use xlink:href="#icon-download"></use>
                            </svg>
                            <div class="notification-msg">Download document</div>
                        </div>
                    </button>
                    <div class="card__description card__picture_item"
                         [innerHTML]="listPostsDescription|truncate:300"></div>
                </div>
            </a>
            <a href="" class="card__item swiper-slide">
                <div class="card__picture-holder">
                    <img src="https://picsum.photos/300/300" alt="Title" class="absolute-img__full-image">
                    <button class="download card__picture_item">
                        <div class="notification-msg__holder">
                            <svg class="svg-icon icon-download">
                                <use xlink:href="#icon-download"></use>
                            </svg>
                            <div class="notification-msg">Download document</div>
                        </div>
                    </button>
                </div>
            </a>
            <a href="" class="card__item swiper-slide">
                <div class="card__picture-holder">
                    <img src="https://picsum.photos/400/200" alt="Title" class="absolute-img__full-image">
                </div>
            </a>
            <a href="" class="card__item swiper-slide">
                <div class="card__picture-holder">
                    <img src="https://picsum.photos/400/300" alt="Title" class="absolute-img__full-image">
                </div>
            </a>
        </div>
    </div>
    <button class="next-sub slider-next">
        <svg class="svg-icon slide-arrow_next">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
    <button class="prev-sub slider-prev">
        <svg class="svg-icon slide-arrow">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
</div> -->

