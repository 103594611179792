import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
@Component({
    selector: 'xion-discount-billing-calculator-modal',
    templateUrl: './discount-billing-calculator-modal.component.html',
    styleUrls: ['./discount-billing-calculator-modal.component.scss']
})
export class DiscountBillingCalculatorModalComponent implements OnInit {

    isOneTimePayment: boolean;
    discountForm: FormGroup;
    today = new Date();
    duration = 'days';
    tokenPrice = 0.02;
    rows = [];

    constructor(@Inject(MAT_DIALOG_DATA) public discountLevels: any, private dialogRef: MatDialogRef<DiscountBillingCalculatorModalComponent>,
        private builder: FormBuilder,
        private dialog: MatDialog,) {
        this.setdiscountForm();
    }

    get form() {
        return this.discountForm.controls;
    }

    // TODO:change when we have live API

    ngOnInit() {
        this.calculateProjections();
    }

    setdiscountForm(): void {
        this.discountForm = this.builder.group({
            name: [],
            start_date: [this.today],
            end_date: [],
            password: [''],
            plan: ['daily'],
            description: [''],
            type: ['single'],
            duration: [10],
            price: [10],
            discountLevel: [this.discountLevels[0].rate],
            discount: [true],
            numberOfSubscriptions: [1]
        });
    }

    onProductTypeChange() {
        const plan = this.discountForm.get('plan').value;
        this.isOneTimePayment = false;
        switch (plan) {
            case 'daily':
                this.duration = 'days';
                break;
            case 'weekly':
                this.duration = 'weeks';
                break;
            case 'monthly':
                this.duration = 'months';
                break;
            case 'yearly':
                this.duration = 'years';
                break;
            default:
                this.duration = 'days';
        }
    }

    calculateDuration() {
        const startDate = moment(this.discountForm.get('start_date').value);
        const endDate = moment(this.discountForm.get('end_date').value);
        const precise = this.duration != 'days';
        // @ts-ignore
        const diff = endDate.diff(startDate, this.duration, precise);
        this.discountForm.get('duration').setValue(diff);
        this.calculateProjections();
    }

    calculateProjections() {
        if(this.discountForm.get('duration').value>10000){
            this.openConfirmUnsubscriptionModal()
            
        }else{
            const startDate = moment(this.discountForm.get('start_date').value);
            const duration = this.discountForm.get('duration').value + 1;
            // @ts-ignore
            const date = startDate.add(duration, this.duration).toDate();
            this.discountForm.get('end_date').setValue(date);
            const price = this.discountForm.get('price').value;
            const discountLevel = this.discountForm.get('discountLevel').value;
            const numberOfSubscriptions = this.discountForm.get('numberOfSubscriptions').value;
            // let rows_;
            this.rows = Array(duration).fill({ payment: 0 });
            this.rows.slice(duration-20, duration)
            this.rows = this.rows.map((item, index) => {
                const day = index;
                const cost = price * numberOfSubscriptions;
    
                const payment = cost / Math.pow(Number(1 + discountLevel), Number(day));
    
    
                const savings = cost - payment;
                return {
                    day,
                    cost,
                    payment,
                    savings
                };
    
            });
            let cumPayment = 0;
            this.rows = this.rows.map((item, index) => {
                return {
                    ...item,
                    cumPayment: this.rows.slice(0, index).map(i => i.payment).reduce((a, b) => a + b, 0)
                };
            });
    
    
            this.rows = this.rows.map((item, index) => {
                const cost = price * numberOfSubscriptions * item.day;
                return {
                    ...item,
                    cumSavings: cost - item.cumPayment
                };
            });
    
            this.rows = this.rows.map((item, index) => {
                return {
                    ...item,
                    cumSavings: this.rows[index + 1]?.cumSavings || 0
                };
            });
            this.rows =this.rows;
            this.rows.pop()
        }
       
        
    }

    onDiscountLevelChanged(level) {
    }

    closeDialog() {
        this.dialogRef.close();
    }

    private openConfirmUnsubscriptionModal() {
        return this.dialog
            .open(ConfirmModalComponent, {
                panelClass: 'popup-blue__holder',
                data: {
                    title: 'Discount calculator',
                    description: "Can't calculate discount.",
                    cancel: 'Cancel'
                }
            })
    }
}
