import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../../../auth/services/auth.service';
import { Observable } from 'rxjs';
import { IUser } from '../../../../../core/services/user.service';
import { ISubscription } from '../../../../interfaces/subscription.interface';
import { IAttachment } from '../../../../models/models';

@Component({
    selector: 'xion-query-subscription-modal',
    templateUrl: './query-subscription-modal.component.html',
    styleUrls: ['./query-subscription-modal.component.scss']
})
export class QuerySubscriptionModalComponent implements OnInit {

    user$: Observable<IUser>;
    selected = null;
    issues = [
        'Incorrect Item in my Subscription box',
        'Overcharged',
        'Broken Items',
        'My subscription has not arrived/been delivered',
        'items do not match offer',
        'wrong shipping address',
        'can i get a refund',
        'will my subscription box still be shipped if it\'s paused',
        'will i still have access to my subscription service if it\'s paused',
        'other'
    ];
    queryForm: FormGroup;
    files: FileList = null;
    attachemnts : IAttachment[]
    showMessageBox: boolean = false;


    constructor(@Inject(MAT_DIALOG_DATA) public subscription: ISubscription, private builder: FormBuilder, private dialogRef: MatDialogRef<QuerySubscriptionModalComponent>, private auth: AuthService) {
        this.user$ = this.auth.currentUser;
    }

    ngOnInit() {
        this.queryForm = this.createQueryForm();
    }

    submitForm() {
        const values = this.queryForm.value;
        const data = {
            ...values,
            subscription: this.subscription,
            files: this.files,
        };
    }

    createQueryForm() {
        return this.builder.group({
            issue: [null, Validators.required],
            message: [null],
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

    get form() {
        return this.queryForm.controls;
    }

    selectedFile(files: FileList) {
        this.files = files;
    }

    selectedIssue(issue: string) {
        if (issue === 'other') {
            this.showMessageBox = true;
            this.queryForm.get('message').setValidators([Validators.required])
        } else {
            this.showMessageBox = false;
            this.queryForm.get('message').clearValidators()
        }
    }

    clearedIssueSelection($event: {}) {
        this.files = null;
        this.queryForm.reset();
    }
}
