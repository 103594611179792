<div class="popup-blue__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title">Swap</h3>
        <form [formGroup]="swapForm">
            <div class="form__row select-white__border">
                <div class="form-label__holder">
                    <mat-radio-group (change)="onTokenChanged()" formControlName="method">
                        <mat-radio-button [value]="tokens.ISX">ISX</mat-radio-button>
                        <mat-radio-button [value]="tokens.XSC">XSC</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form__row" [class.error__field]="isFieldInvalid('amount')">
                <div class="form-label__holder">
                    <label class="form-label text-overflow" for="amount">Amount of {{form.method.value}}</label>
                    <input class="textfield textfield__white-border"
                           id="amount"
                           name="amount"
                           type="number"
                           min="0"
                           (input)="onTokenChanged()"
                           formControlName="amount">
                    <div class="amount-equal">{{buyWith}} {{tokenValue}}</div>
                    <span class="error__text" *ngIf="isFieldInvalid('amount')">
                          <p *ngIf="form.amount.errors.required">amount is required</p>
                    </span>
                </div>
            </div>
            <div class="form__row" [class.error__field]="isFieldInvalid('key')">
                <div class="form-label__holder">
                    <label class="form-label text-overflow"
                           for="amount">{{user.usepwd ? 'Password' : 'Private Key'}}</label>
                    <input class="textfield textfield__white-border"
                           id="key"
                           placeholder="{{user.usepwd? 'Password': 'Private Key'}}"
                           name="key"
                           [type]="user.usepwd?'password':'text'"
                           formControlName="key">
                </div>
                <span class="error__text" *ngIf="isFieldInvalid('key')">
                          <p *ngIf="form.key.errors.required">{{user.usepwd ? 'Password' : 'Private Key'}}
                              is required</p>
                          <p *ngIf="form.key.errors.invalid">{{error}}</p>
                    </span>
            </div>
            <div class="row__buttons">
                <button class="button button_small button_white-border" (click)="onSubmit()">
                    swap
                </button>
            </div>
        </form>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>
