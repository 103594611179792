import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { IUser } from 'src/app/core/services/user.service';

@Component({
    selector: 'xion-user-chat-details',
    templateUrl: './user-chat-details.component.html',
    styleUrls: ['./user-chat-details.component.scss']
})
export class UserChatDetailsComponent implements OnInit {

    @Input() user: IUser;
    @Output() showUserDetails = new EventEmitter(false);

    constructor(
        private clipboard: ClipboardService,
        private notifications: NotificationsService) {}

    ngOnInit() {}

    toggleUserDetails() {
        this.showUserDetails.emit(false)
    }
    copyAddress(address) {
        this.clipboard.copyFromContent(address);
        this.notifications.showMessage('address copied to clipboard !', 'hide');
    }
}
