import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { HttpService } from 'src/app/retailers/services/http.service';

@Component({
    selector: 'xion-create-account-modal',
    templateUrl: './create-account-modal.component.html',
    styleUrls: ['./create-account-modal.component.scss']
})
export class CreateAccountModalComponent implements OnInit {
    merchantList = [];

    selectedMerchant;
    walletAddress = '';

    formStep = 'step_1';

    constructor(
        public httpService: HttpService,
        private notifications: NotificationsService,
        public authService: AuthService
    ) {}

    ngOnInit(): void {
        switch (this.authService.currentUserPlanSubject.value) {
            case 'xion': {
                this.merchantList = [
                    {
                        value: 'super_merchant',
                        text: 'Super Merchant'
                    },
                    {
                        value: 'master_merchant',
                        text: 'Master Merchant'
                    },
                    {
                        value: 'sub_merchant',
                        text: 'Sub-Merchant'
                    }
                ];
                break;
            }
            case 'super_merchant': {
                this.merchantList = [
                    {
                        value: 'master_merchant',
                        text: 'Master Merchant'
                    },
                    {
                        value: 'sub_merchant',
                        text: 'Sub-Merchant'
                    }
                ];
                break;
            }
            case 'master_merchant': {
                this.merchantList = [
                    {
                        value: 'sub_merchant',
                        text: 'Sub-Merchant'
                    }
                ];
                break;
            }
            default:
                this.merchantList = [];
                break;
        }
    }

    creatAccount(): void {
        if (!this.selectedMerchant || !this.walletAddress.length) return;
        this.httpService.postCreateAccount(this.selectedMerchant, this.walletAddress).subscribe(
            () => {
                this.formStep = 'step_2';
            },
            (error) => {
                this.notifications.showMessage(error.error.error);
            }
        );
    }
}
