import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IUser } from '../../core/services/user.service';
import { IApiResponse } from '../../shared/models/models';
import { IPost } from '../../shared/pages/social/social.component';

export interface ICommunityList {
    followers: IUser[],
    following: IUser[]
}

export interface IPaginatedPosts {
    total: number,
    posts: IPost[]
}

const cacheBuster$ = new Subject<void>();

@Injectable({
    providedIn: 'root'
})
export class PostsService {

    public isEditingPost: Observable<boolean>;

    private isEditingPostSubject = new BehaviorSubject<boolean>(false);

    private CHANNEL_POSTS = `${environment.api_url}/channel/posts`;
    private COMMUNITY_LIST = `${environment.api_url}/community`;

    constructor(private http: HttpClient) {
        this.isEditingPost = this.isEditingPostSubject.asObservable();
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public createPost(post: IPost): Observable<IPost> {
        return this.http.post(this.CHANNEL_POSTS, post).pipe(pluck('data'));
        // return this.http.post(this.CHANNEL_POSTS, post).pipe(map((response: IApiResponse) => response.data));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public updatePost(postId: string, post: IPost): Observable<IPost> {
        const URL = `${this.CHANNEL_POSTS}/${postId}`;
        return this.http.put(URL, post).pipe(pluck('data'));
        // return this.http.put(URL, post).pipe(map((response: IApiResponse) => response.data));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public deletePost(postId: string): Observable<IPost> {
        const URL = `${this.CHANNEL_POSTS}/${postId}`;
        return this.http.delete(URL).pipe(pluck('data'));
        // return this.http.delete(URL).pipe(map((response: IApiResponse) => response.data));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public likePost(postId: string) {
        const URL = `${this.CHANNEL_POSTS}/${postId}/like`;
        return this.http.post(URL, {});
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge : environment.MAX_CACHE_TIME
    })
    public getPosts(
        start?: number,
        limit: number = 5,
        areasOfInterest: string[] = [],
        username: string = null, merchant: boolean = null): Observable<IPaginatedPosts> {
        const params = {
            start,
            limit,
            username,
            merchant,
            interest: areasOfInterest
        };
        const URL = `${this.CHANNEL_POSTS}/list`;
        return this.http.post(URL, params)
            .pipe(pluck('data'));
            // .pipe(map((response: IApiResponse) => response.data));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge : environment.MAX_CACHE_TIME
    })
    public getCommunityList(): Observable<ICommunityList> {
        return this.http.get(this.COMMUNITY_LIST).pipe(pluck('data'));
        // return this.http.get(this.COMMUNITY_LIST).pipe(map((response: IApiResponse) => response.data));

    }
    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge : environment.MAX_CACHE_TIME
    })
    public getPost(postId: string): Observable<IPost> {
        const URL = `${this.CHANNEL_POSTS}/${postId}`;
        return this.http.get(URL).pipe(pluck('data'));
        // return this.http.get(URL).pipe(map((response: IApiResponse): IPost => response.data));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge : environment.MAX_CACHE_TIME
    })
    public getPostsByUsername(username): Observable<IPaginatedPosts> {
        const URL = `${this.CHANNEL_POSTS}/list`;
        return this.http.post(URL, { username })
            .pipe(pluck('data'));
            // .pipe(map((response: IApiResponse) => response.data));
    }

    public setIsEditingPost(editing: boolean) {
        this.isEditingPostSubject.next(editing);
    }

}
