import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { Observable, Subject } from 'rxjs';
import { map, pluck, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { JwtService } from '../../auth/services/jwt.service';
import { mapResponseToData, updateTokens } from '../../shared/constants/helpers';
import { IApiResponse } from '../../shared/models/models';

export enum KYCStatus {
    ABSENT = 0,
    PENDING = 1,
    APPROVED = 2
}

export type IUserRole = 'seller' | 'buyer';

export interface IUser {
    id: number;
    role: IUserRole;
    walletAddress: string;
    idxion: string;
    fullname: string;
    email: string;
    companyname: string;
    merchant: boolean;
    kycstatus: KYCStatus;
    crn: string;
    pan: string;
    iec: string;
    address: string;
    country: string;
    state: number;
    city: number;
    zip: string;
    image: string;
    xsc: string;
    isx: string;
    website: string;
    notif: boolean;
    currency: string;
    gender: boolean;
    charity: boolean;
    rating: number | null;
    exp: number;
    followed?: boolean;
    interestareas: string[];
    roomId?: string;
    created?: number;
    usepwd: boolean;
    room?: IRoom;
    isKycVerified: boolean
}
export interface IRoom {
    updatedAt: Date;
    unreadCount: number;
}

// export class Merchant implements IUser {
//     address: string;
//     charity: boolean;
//     city: number;
//     companyname: string;
//     country: string;
//     crn: string;
//     currency: string;
//     email: string;
//     exp: number;
//     fullname: string;
//     gender: boolean;
//     id: number;
//     idxion: string;
//     iec: string;
//     image: string;
//     interestareas: string[];
//     isx: string;
//     kycstatus: number;
//     merchant: boolean;
//     notif: boolean;
//     pan: string;
//     rating: number | null;
//     state: number;
//     website: string;
//     xsc: string;
//     zip: string;
//     usepwd: boolean;
//     created?: number;
//     role: IUserRole;
//     walletAddress: string;
// }

// export class User implements IUser {
//     address: string;
//     charity: boolean;
//     city: number;
//     companyname: string;
//     country: string;
//     crn: string;
//     currency: string;
//     email: string;
//     fullname: string;
//     gender: boolean;
//     id: number;
//     idxion: string;
//     iec: string;
//     image: string;
//     interestareas: string[];
//     isx: string;
//     kycstatus: number;
//     merchant: boolean;
//     notif: boolean;
//     pan: string;
//     state: number;
//     website: string;
//     xsc: string;
//     zip: string;
//     rating: number | null;
//     created?: number;
//     usepwd: boolean;
//     role: IUserRole;
//     walletAddress: string;
//     exp: number;
// }
export interface ICredentials {
    xionid: string;
    fullname: string;
    priv_isx: string;
    priv_xsc: string;
    pub_isx: string;
    pub_xsc: string;
}
export interface ISignupResponse extends IApiResponse {
    data: ICredentials;
    error: string | null;
}

const cacheBuster$ = new Subject<void>();
const MAX_CACHE_TIME = environment.MAX_CACHE_TIME;

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private USERS = `${environment.api_url}/users`;
    private UPDATE_PROFILE_URL = `${environment.api_url}/users/updateuserdata`;
    private GET_USER_DATA_URL = `${environment.api_url}/users/getuserdata`;
    private UPDATE_CURRENCY = `${environment.api_url}/users/currency`;
    private USER_LIST = `${environment.api_url}/users/getuserlist`;
    private CONTACTS = `${environment.api_url}/users/contacts`;

    constructor(private http: HttpClient, private jwt: JwtService) {}

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    public getUsersList(): Observable<IUser[]> {
        return this.http.get<IApiResponse>(this.USER_LIST).pipe(pluck('data'));
        // return this.http.get<IApiResponse>(this.USER_LIST).pipe(map(mapResponseToData));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    public getContacts(): Observable<IUser[]> {
        return this.http.get<IApiResponse>(this.CONTACTS).pipe(pluck('data'));
        // return this.http.get<IApiResponse>(this.CONTACTS).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public addContact(userId: string) {
        const params = new HttpParams().set('contact', userId);
        return this.http
            .post<IApiResponse>(this.CONTACTS, {}, { params })
            .pipe(pluck('data'));
            // .pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public deleteContact(userId: string) {
        const URL = `${this.CONTACTS}/${userId}`;
        return this.http.delete<IApiResponse>(URL).pipe(pluck('data'));
        // return this.http.delete<IApiResponse>(URL).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public updateUser(user: IUser): Observable<any> {
        return this.http.post<IApiResponse>(this.UPDATE_PROFILE_URL, user).pipe(
            pluck('data'),
            // map(mapResponseToData),
            tap(updateTokens)
        );
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public updateUserCurrency(currency: string) {
        return this.http.post<IApiResponse>(this.UPDATE_CURRENCY, { currency }).pipe(
            pluck('data'),
            // map(mapResponseToData),
            tap(updateTokens)
        );
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    public getUser(xionId?: string): Observable<IUser> {
        let params = new HttpParams();
        if (!!xionId) params = params.set('xionId', xionId);
        return this.http.get(this.GET_USER_DATA_URL, { params }).pipe(
            take(1),
            pluck('data')
            // map((res: IApiResponse) => res.data)
        );
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public followUser(userId: string) {
        const URL = `${this.USERS}/${userId}/follow`;
        return this.http.post<IApiResponse>(URL, {}).pipe(pluck('data'));
        // return this.http.post<IApiResponse>(URL, {}).pipe(map((response) => response.data));
    }

    public updateUserToken({ token, refreshToken }) {
        this.jwt.setToken(token);
        this.jwt.setRefreshToken(refreshToken);
    }
}
