import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'xion-invalid-chain-warning-modal',
    templateUrl: './invalid-chain-warning-modal.component.html',
    styleUrls: ['./invalid-chain-warning-modal.component.scss']
})
export class InvalidChainWarningModalComponent implements OnInit {

    chainId: number = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public currentChainInfo: any,
    ) {
    }

    ngOnInit(): void {
    }

}
