import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable } from 'ngx-cacheable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IMetadataResponse {
    id: number;
    title: string;
    description: string;
    image: string;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class LinksMetadataService {
    API_KEY = '5cfa61d4608ca8f50b7fdf58e5665147f9dd736502359';
    METADATA_SERVICE_URL = 'https://api.linkpreview.net/';

    constructor(private http: HttpClient) {}

    @Cacheable()
    preview(url: string): Observable<IMetadataResponse> {
        const params = new HttpParams().set('q', url).set('key', this.API_KEY);
        return this.http
            .get(this.METADATA_SERVICE_URL, { params })
            .pipe(map((response) => response as IMetadataResponse));
    }
}
