<div class="popup__content">
  <div class="popup">
    <div class="popup__charts subscription__popup  ">

        <xion-subscriptions-chart
            *ngIf="showCHart"
            [xAxis]="true"
            [yAxis]="true"
            [subscriptions]="subscriptions"
        >
        </xion-subscriptions-chart>

    </div>
    <span _ngcontent-c29="" class="popup__title">TOP PERFORMING SUBSCRIPTIONS</span>
  </div>
  <button (click)="closeDialog()" class="popup__close">
    <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
  </button>
</div>

