<div class="swiper-container " [class.disable]="data.length === 1" [swiper]="config">
    <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let wallet of data">
            <div class="donut donut_xsc">
                <div class="donut__amount">{{wallet.value}}</div>
                <span class="donut__title">{{wallet.name}}</span>
            </div>
            <dl class="donut__currency">
                <dt class="currency__title">
                    Total:
                </dt>
                <dd class="currency__amount">
                    {{wallet.value |  currencyConverter |async}}
                </dd>
            </dl>
        </div>
    </div>
    <button class="next" *ngIf="data.length>1">
        <svg class="svg-icon">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
    <button class="prev" *ngIf="data.length>1">
        <svg class="svg-icon">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
</div>
