<div class="popup-blue__content">

    <div class="popup-blue">

         <xion-view-subscription *ngIf="!editFormActive" [subscription]="subscription"></xion-view-subscription>

        <div class="row__buttons">
            <!-- <button class="button button_small button_white-border" *ngIf="!editFormActive" (click)="toggleEditForm()">Edit</button> -->
            <button class="button button_small button_white-border" *ngIf="editFormActive" (click)="saveSubscription()">Save</button>
            <button class="button button_small button_white-border" (click)="pauseSubscription()">Pause</button>
            <button class="button button_small button_white-border" (click)="archiveSubcription()">Archive</button>
        </div>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>

</div>
