import { ISubscriptionBase } from './subscription.model';

export type ProductType = 'digital' | 'physical' | 'service';
export type BillingType = 'single' | 'recurring' | 'unknown';
export type BillingIntervalType = 'daily' | 'weekly' | 'monthly' | '28th' | '4th' | 'yearly';
export type BillingCyclesStatusType = 'ongoing' | 'custom';
export type BillingProductStatus = 'active' | 'pause' | 'archive' | 'unsubscribe';
export type SubscriptionStatus = 'active' | 'pause' | 'subscribe' | 'unsubscribe' | 'pending';
export type ProductReview = 'pending' | 'approved' | 'declined';

export interface IBillingProductIdBase {
    productID?: number;
}
export interface IBillingProductDetails extends ISubscriptionBase, IBillingStatusBase {
    parentProductID?: number;
    productUrl: string;
    productImageUrl: string;
    review?: ProductReview;
    previousStatus: BillingProductStatus;
    shippingPrice?: number;
    isFreeShipping?: { name: string; id: string };
    createdAt: string;
    paymentLink?: boolean;
    checkoutButton?: boolean;
    refProductId?: number;
    fiatCurrency: string;
    merchantType?: string;
    salesType?: string;
    maxSales: number;
}

export interface IBillingProduct {
    product: IBillingProductDetails;
    billing: IBilling;
    button: IButtonPreview;
    followUpProducts?: IBillingProduct[];
}

export interface IButtonPreview {
    isActivated: boolean;
    buttonColor: string;
    buttonColorText: string;
    buttonName: string;
    buttonShape: string;
    buttonTagLineSettings: IButtonTagLineSetting;
    buttonOutlineSettings: IButtonOutlineSetting;
    buttonSizeSettings: IButtonSizeSetting;
}

export interface IBillingCycleBase {
    billingCycle: BillingCyclesStatusType | null;
}

export interface IBillingStatusBase {
    status: BillingProductStatus;
}

export interface ISubscriptionStatusBase {
    status: SubscriptionStatus;
}

export interface IBillingTypeBase {
    billingType: BillingType;
}

export interface IBilling extends IBillingTypeBase, IBillingCycleBase {
    isLoyalty: boolean;
    billingInterval: BillingIntervalType;
    billingCycleValue: number;
    billingCycleWording: string;
    maxDiscountAllowed: number;
}

export class BillingProductModel {
    product: IBillingProductDetails | any = {};
    billing: IBilling | any = {};
    button: IButtonPreview;
    followUpProducts: IBillingProduct[] = [];

    constructor(data: IBillingProduct) {
        if (data) {
            if (data.product) {
                this.product.productID = data.product.productID || null;
                this.product.parentProductID = data.product.parentProductID || null;
                this.product.productName = data.product.productName || '';
                this.product.productPrice = data.product.productPrice || 0;
                this.product.productUrl = data.product.productUrl || '';
                this.product.productImageUrl = data.product.productImageUrl || '';
                this.product.category = data.product.category || null;
                this.product.productType = data.product.productType || BillingProductType.digital;
                this.product.previousStatus = data.product.previousStatus || StatusEnum.active;
                this.product.status = data.product.status || StatusEnum.active;
                this.product.shippingPrice = data.product.shippingPrice || 0;
                this.product.review = data.product.review || 'pending';
                this.product.createdAt = data.product.createdAt || new Date().toISOString();
                this.product.checkoutButton = data.product.checkoutButton || false;
                this.product.refProductId = data.product.refProductId || 0;
                this.product.fiatCurrency = data.product.fiatCurrency || 'USD';
                this.product.merchantType = data.product.merchantType;
                (this.product.salesType = data.product.maxSales === -1 ? 'Unlimited' : 'Custom'),
                    (this.product.maxSales = data.product.maxSales || 0);
            }
            if (data.billing) {
                this.billing.billingType = data.billing.billingType || BillingTypeEnum.single;
                this.billing.isLoyalty =
                    data.billing.billingType === BillingTypeEnum.single
                        ? false
                        : data.billing.isLoyalty;
                this.billing.billingInterval = data.billing.billingInterval || null;
                this.billing.billingCycle = data.billing.billingCycle || null;
                this.billing.billingCycleWording = this.getBillingCycleWording(data);
                this.billing.billingCycleValue = data.billing.billingCycleValue || null;
                this.billing.maxDiscountAllowed = data.billing.maxDiscountAllowed || 0;
            }

            if (data.button) {
                this.button = data.button || null;
                this.button.isActivated = false;
                this.button.buttonColor =
                    data.button.buttonColor || defaultModelData.button.buttonColor;
                this.button.buttonColorText =
                    data.button.buttonColorText || defaultModelData.button.buttonColorText;
                this.button.buttonShape = data.button.buttonShape || null;
                this.button.buttonTagLineSettings =
                    data.button.buttonTagLineSettings ||
                    defaultModelData.button.buttonTagLineSettings;
                this.button.buttonOutlineSettings =
                    data.button.buttonOutlineSettings ||
                    defaultModelData.button.buttonOutlineSettings;
                this.button.buttonSizeSettings =
                    data.button.buttonSizeSettings || defaultModelData.button.buttonSizeSettings;
            }

            if (data.followUpProducts && data.followUpProducts.length) {
                data.followUpProducts.forEach((item: IBillingProduct) => {
                    const model = new BillingProductModel(item);
                    this.followUpProducts.unshift(model);
                });
            }

            if (data.product.parentProductID) {
                delete this.followUpProducts;
            }
        }
    }

    private getBillingCycleWording(data: IBillingProduct): string {
        let wording = 'N/A';
        const onGoing = 'ON GOING';
        if (data.billing.billingType === BillingTypeEnum.single) return wording;

        if (data.billing.billingCycle === BillingCycles.ongoing) return (wording = onGoing);

        if (data.billing.billingCycleValue) {
            const paymentWording = data.billing.billingCycleValue === 1 ? 'PAYMENT' : 'PAYMENTS';
            wording = data.billing.billingCycleValue + ' ' + paymentWording;
        } else {
            wording = onGoing;
        }
        return wording;
    }
}

export enum BillingCycles {
    ongoing = 'ongoing',
    custom = 'custom'
}

export enum BillingTypeEnum {
    single = 'single',
    recurring = 'recurring',
    loyalty = 'loyalty'
}

export enum BillingProductType {
    digital = 'digital',
    physical = 'physical',
    service = 'service'
}

export enum BillingInterval {
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
    I28th = '28th',
    I4th = '4th',
    yearly = 'yearly'
}

export interface IHttpResponse {
    status: string;
}

export enum IEnumResponseStatus {
    created = 'created',
    updated = 'updated',
    failed = 'failed'
}

export type TypeResponseStatus = 'created' | 'updated' | 'failed';

export interface IResponseStatus {
    id?: number;
    status: TypeResponseStatus;
}

export enum StatusEnum {
    active = 'active',
    pause = 'pause',
    archive = 'archive',
    unsubscribe = 'unsubscribe',
    subscribe = 'subscribe',
    pending = 'pending'
}

export const Currencies = ['USD', 'GBP', 'ZAR', 'AUD', 'CAD', 'EUR'];

export enum BillingStatusModalType {
    Subscribe = 'SUBSCRIBE',
    Unsubscribe = 'UNSUBSCRIBE',
    Pause = 'PAUSE',
    Active = 'ACTIVE'
}

export interface IBillingStatusChangeParam {
    title: string;
    description: string;
    status: string;
    price?: number;
    type?: BillingStatusModalType;
}

export interface IButtonShape {
    shapeName: string;
    borderRadius: string;
}

export const SubscribeButtonShape: IButtonShape[] = [
    {
        shapeName: 'Rectangle Shape',
        borderRadius: '4px'
    },
    {
        shapeName: 'Pill Shape',
        borderRadius: '18px'
    }
];

export interface IButtonOutlineSetting {
    isButtonOutlineCustom: boolean;
    buttonOutlineColor: string;
}

export interface IButtonTagLineSetting {
    tagName: string;
    tagAvatars: string[];
    isTagHidden: boolean;
}

export const SubscribeButtonTagline: IButtonTagLineSetting[] = [
    {
        tagName: 'NONE',
        tagAvatars: [],
        isTagHidden: true
    },
    {
        tagName: 'Includes Lifetime Discounts',
        tagAvatars: [],
        isTagHidden: false
    },
    // {
    //     tagName: '',
    //     tagAvatars: [
    //         'https://xion-static-logos-checkoout-button.s3.amazonaws.com/visa.jpg',
    //         'https://xion-static-logos-checkoout-button.s3.amazonaws.com/mastercard.jpg',
    //         'https://xion-static-logos-checkoout-button.s3.amazonaws.com/dai_img.jpg'
    //     ],
    //     isTagHidden: false
    // },
    {
        tagName: 'Decentralized Checkout',
        tagAvatars: [],
        isTagHidden: false
    },
    {
        tagName: 'Powered by Xion',
        tagAvatars: [],
        isTagHidden: false
    },
    {
        tagName: 'Automatically Renews',
        tagAvatars: [],
        isTagHidden: false
    },
    {
        tagName: 'Fast, Safe & Secure Checkout',
        tagAvatars: [],
        isTagHidden: false
    }
];

export interface IButtonSizeSetting {
    sizeName: string;
    sizeDescription: string;
    buttonWidth: string;
    buttonHeight: string;
    buttonFontSize: string;
    buttonMaxWidth: string;
    buttonMinWidth: string;
    maximumCharacters: number;
}

export const SubscribeButtonSize: IButtonSizeSetting[] = [
    {
        sizeName: 'recommended',
        sizeDescription: '(220 pixels by 40 pixels)',
        buttonWidth: '220px',
        buttonHeight: '40px',
        buttonFontSize: '18px',
        buttonMaxWidth: '220px',
        buttonMinWidth: '220px',
        maximumCharacters: 15
    },
    {
        sizeName: 'small',
        sizeDescription: '(150 pixels by 25 pixels)',
        buttonWidth: '150px',
        buttonHeight: '25px',
        buttonFontSize: '14px',
        buttonMaxWidth: '150px',
        buttonMinWidth: '150px',
        maximumCharacters: 11
    },
    {
        sizeName: 'medium',
        sizeDescription: '(250 pixels by 35 pixels)',
        buttonWidth: '250px',
        buttonHeight: '35px',
        buttonFontSize: '16px',
        buttonMaxWidth: '250px',
        buttonMinWidth: '250px',
        maximumCharacters: 18
    },
    {
        sizeName: 'large',
        sizeDescription: '(350 pixels by 40 pixels)',
        buttonWidth: '350px',
        buttonHeight: '40px',
        buttonFontSize: '18px',
        buttonMaxWidth: '350px',
        buttonMinWidth: '350px',
        maximumCharacters: 20
    },
    {
        sizeName: 'responsive',
        sizeDescription: '(Min width is 100px, max width is 500px)',
        buttonWidth: '100%',
        buttonHeight: '40px',
        buttonFontSize: '17px',
        buttonMaxWidth: '500px',
        buttonMinWidth: '100px',
        maximumCharacters: 30
    }
];

export const defaultModelData: IBillingProduct = {
    product: {
        productID: null,
        parentProductID: null,
        productName: '',
        productPrice: '',
        productUrl: '',
        productImageUrl: '',
        category: null,
        productType: '',
        previousStatus: null,
        status: StatusEnum.active,
        createdAt: '',
        checkoutButton: false,
        refProductId: 0,
        fiatCurrency: 'USD',
        salesType: 'Unlimited',
        maxSales: -1
    },
    billing: {
        billingType: BillingTypeEnum.single,
        isLoyalty: false,
        billingInterval: BillingInterval.daily,
        billingCycle: BillingCycles.ongoing,
        billingCycleValue: null,
        billingCycleWording: 'ON GOING',
        maxDiscountAllowed: 0
    },
    button: {
        isActivated: false,
        buttonColor: '#33a8dc',
        buttonColorText: '#FFFFFF',
        buttonName: 'subscribe',
        buttonShape: null,
        buttonTagLineSettings: {
            tagName: null,
            tagAvatars: [],
            isTagHidden: true
        },
        buttonOutlineSettings: {
            isButtonOutlineCustom: false,
            buttonOutlineColor: '#F6C768'
        },
        buttonSizeSettings: {
            sizeName: null,
            sizeDescription: '',
            buttonWidth: '',
            buttonHeight: '',
            buttonFontSize: '',
            buttonMaxWidth: '',
            buttonMinWidth: '',
            maximumCharacters: 11
        }
    },
    followUpProducts: []
};
