import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import { AffiliateService, IAffiliateStats } from '../../../core/services/affiliate.service';
import { IUser } from '../../../core/services/user.service';


@Component({
    selector: 'xion-affiliate',
    templateUrl: './affiliate.component.html',
    styleUrls: ['./affiliate.component.scss']
})
export class AffiliateComponent implements OnInit {

    statistics$: Observable<IAffiliateStats>;
    today: Date = new Date();
    user$: Observable<IUser>;

    constructor(private affiliateService: AffiliateService, private authService: AuthService) {
    }

    ngOnInit() {
        this.statistics$ = this.affiliateService.getAffiliateSats();
        this.user$ = this.authService.currentUser;
    }

}
