import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmailValidator } from 'src/app/shared/constants/validators';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadPhotoModalComponent } from '../upload-photo-modal/upload-photo-modal.component';

@Component({
  selector: 'xion-support',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss']
})
export class SupportModalComponent implements OnInit {

  supportForm: FormGroup;
  submitted: boolean;

  constructor(private builder: FormBuilder, private dialogRef: MatDialogRef<SupportModalComponent>) {}

  ngOnInit() {
    this.supportForm = this.builder.group(
      {
        name: ['', Validators.required],
        email: ['', [Validators.required, EmailValidator.email]],
        subject: ['', Validators.required],
        message: ['', Validators.required],
      },
    );
  }

  submitForm() {
    this.submitted = true;

    if (this.supportForm.valid) {
      const values = this.supportForm.value;
  }
  }

  get form() {
    return this.supportForm.controls;
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
