import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {LocationService} from 'src/app/core/services/location.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotificationsService} from '../../../../../core/services/notifications.service';

@Component({
    selector: 'xion-retailer-subscription-modal',
    templateUrl: './view-subscription-modal..component.html',
    styleUrls: ['./view-subscription-modal..component.scss']
})
export class ViewRetailerSubscriptionModalComponent implements OnInit {

    subscriptionForm: FormGroup;
    submitted = false;
    editFormActive= true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public subscription: any,
        private builder: FormBuilder,
        private dialogRef: MatDialogRef<ViewRetailerSubscriptionModalComponent>,
        private notifications: NotificationsService
    ) {}


    ngOnInit() {
        this.subscriptionForm = this.builder.group({
            name: [this.subscription.name, Validators.required],
            type: [this.subscription.type, [Validators.required]],
            plan: [this.subscription.type, [Validators.required]],
            duration: [this.subscription.duration, [Validators.required]],
            price: [this.subscription.price, [Validators.required]],
            date: [this.subscription.date, [Validators.required]],
            discount: [this.subscription.discount, [Validators.required]],
            activeCustomers: [this.subscription.activeSubject, [Validators.required]],
            canceledCustomers: [this.subscription.canceledCustomers, [Validators.required]],
            mrr: [this.subscription.mrr, [Validators.required]],
            netRevenue: [this.subscription.netRevenue, [Validators.required]],
        });
    }

    saveSubscription() {
        // this.editFormActive=!this.editFormActive;
        this.submitted = true;

        if(this.subscriptionForm.valid) {
            this.notifications.showMessage('Subscription was updated successfully!','Ok');
            this.closeDialog();
            this.subscription = this.subscriptionForm.value
        }

    }

    toggleEditForm() {
        this.editFormActive = !this.editFormActive
    }

    pauseSubscription() {
        this.notifications.showMessage('Subscription was paused','Ok');
        this.closeDialog();
    }

    archiveSubcription() {
        this.notifications.showMessage('Subscription was archived','Ok');
        this.closeDialog();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    get form() {
        return this.subscriptionForm.controls;
    }
}
