import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
    selector: 'xion-pay-out-modal',
    templateUrl: './pay-out-modal.component.html',
    styleUrls: ['./pay-out-modal.component.scss']
})
export class PayOutModalComponent implements OnInit {
    safeSrc: any;

    constructor(
        private readonly sanitizer: DomSanitizer,
        private authService: AuthService,
        @Inject(MAT_DIALOG_DATA) public target: any
    ) {}

    ngOnInit(): void {
        this.loadIframe();
    }

    loadIframe(): void {
        const { walletAddress } = this.authService.loggedInUser;
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
            `https://widget.mtpelerin.com/?lang=en?addr=${walletAddress}&${this.target}=XDAI`
        );
    }
}
