import { Pipe, PipeTransform } from '@angular/core';
import { ISubscription } from '../interfaces/subscription.interface';

enum Status {
    ACTIVE = 'active',
    ONGOING = 'active',
    PAUSED = 'paused',
    ARCHIVED = 'archived',
    UNSUBSCRIBED = 'unsubscribed',
    PREORDER = 'preorder',
}

@Pipe({
    name: 'subscriptionStatus'
})
export class SubscriptionStatusPipe implements PipeTransform {

    transform(subscription: ISubscription, args?: any): Status {
        if (!subscription.paused && subscription.subscribed) return Status.ACTIVE;
        if (subscription.paused && subscription.subscribed) return Status.PAUSED;
        if (!subscription.subscribed && !subscription.paused) return Status.UNSUBSCRIBED;
        if (subscription.preorder) return Status.PREORDER;
        if (!subscription.active) return Status.ARCHIVED;
        if (subscription.ongoing) return Status.ONGOING;
        return null;
    }

}
