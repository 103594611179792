export interface IAuthResponse {
    access_token: string;
    expires_at: number
}

export type AuthErrorType = 'Internal_server_error' | 'not_found';

export enum AuthErrorTypeEnum {
    InternalServerError = 'Internal_server_error',
    NotFound = 'not_found',
}

export interface IGetAuthTokenError {
    message: string;
    status: number;
    error_type: AuthErrorType
}
