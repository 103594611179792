<fieldset class="fieldset__custom fieldset__custom-table">
    <div class="fieldset__title">TRANSACTIONS</div>

        <div class="responsive-table">
            <table class="table transaction-table">
                <!-- <perfect-scrollbar style="max-width: 700px;width: 100%; max-height: 400px;" > -->
                <thead class="transaction-modal">
                    <th>DATE</th>
                    <th>Amount</th>
                    <!-- <th>Company name</th> -->
                    <th>PAYMENT STATUS</th>
                    <th>ORDER CODE</th>
                    <th>CURRENCY</th>
                    <th>TYPE</th>
                </thead>
                <tbody>
                    <tr *ngIf="!(transactions | async)?.length">
                        <td colspan="7"> No transactions</td>

                </tr>
                <tr (click)="clickedTransaction(trn)" *ngFor="let trn of (transactions | async)?.slice().reverse()"
                    [ngClass]="{'selectedItem': trn.transaction_info.id==selectedTransactionId}">
                    <td>{{trn.transaction_info.date}}</td>
                    <td>${{trn.transaction_info.price | number : '1.2-2'}}</td>
                    <!-- <td>{{trn.transaction_info.companyName}}</td> -->
                    <td>{{trn.transaction_info.status}}</td>
                    <td>{{trn.transaction_info.orderCode}}</td>
                    <td *ngIf="trn.transaction_info.status === 'pending'" class="glow">loading</td>
                    <td *ngIf="trn.transaction_info.status !== 'pending'">{{((trn.transaction_info.currency === 'xgt' ||
                        trn.transaction_info.currency === 'xdai') ? (trn.transaction_info.cryptoAmount | number :
                        '1.2-2') : trn.transaction_info.cryptoAmount) || 0}} {{trn.transaction_info.currency}}</td>
                    <td>{{trn.transaction_info.txType}}</td>
                </tr>
            </tbody>
            <!-- </perfect-scrollbar> -->
        </table>
        <!-- <div *ngIf="!(transactions | async)?.length" style="width: 100%;text-align: center;">No transactions</div> -->

    </div>
    <div class="transaction__content transaction__content_info">

        <div class="transaction__holder">
            <label class="transaction__label" for="name">WEBSITE</label>
            <input readonly class="transaction__input" id="name" type="text"
                [value]="selectedTransaction?.transaction_info?.companyWebsiteURL"
                [class.responsive]="selectedTransaction?.transaction_info?.companyWebsiteURL">
            <div *ngIf="selectedTransaction?.transaction_info?.companyWebsiteURL">
                <img src="assets/images/content_copy_black_24dp.svg"
                    [cdkCopyToClipboard]="selectedTransaction?.transaction_info?.companyWebsiteURL"
                    (click)="copyAddress('name')" *ngIf="copied !== 'name'" alt="Copy" width="20">
                <img src="assets/images/done_black_24dp (1).svg" alt="Done" *ngIf="copied === 'name'" class="active"
                    width="20">
            </div>
        </div>

        <!-- <div class="transaction__holder">
                <label class="transaction__label" for="wallet">WALLET ID</label>
                <input class="transaction__input" id="wallet" type="text">
            </div> -->

        <div class="transaction__holder">
            <label class="transaction__label" for="email">E-mail</label>
            <input readonly class="transaction__input" id="email" type="text"
                [value]="selectedTransaction?.transaction_info?.email"
                [class.responsive]="selectedTransaction?.transaction_info?.email">
            <div *ngIf="selectedTransaction?.transaction_info?.email" class="">
                <img src="assets/images/content_copy_black_24dp.svg"
                    [cdkCopyToClipboard]="selectedTransaction?.transaction_info?.email" (click)="copyAddress('email')"
                    *ngIf="copied !== 'email'" alt="Copy" width="20">
                <img src="assets/images/done_black_24dp (1).svg" alt="Done" *ngIf="copied === 'email'" class="active"
                    width="20">
            </div>
        </div>

        <div class="transaction__holder">
            <label class="transaction__label" for="address">ADDRESS</label>
            <input readonly class="transaction__input" id="address" type="text"
                [value]="selectedTransaction?.transaction_info?.goalAddress"
                [class.responsive]="selectedTransaction?.transaction_info?.goalAddress">
            <div *ngIf="selectedTransaction?.transaction_info?.goalAddress">
                <img src="assets/images/content_copy_black_24dp.svg"
                    [cdkCopyToClipboard]="selectedTransaction?.transaction_info?.goalAddress"
                    (click)="copyAddress('address')" *ngIf="copied !== 'address'" alt="Copy" width="20">
                <img src="assets/images/done_black_24dp (1).svg" alt="Done" *ngIf="copied === 'address'" class="active"
                    width="20">
            </div>
        </div>
        <div class="transaction__holder">
            <label class="transaction__label" for="phone">Phone Number</label>
            <input readonly class="transaction__input" id="phone" type="text"
                [value]="selectedTransaction?.transaction_info?.phoneNumber"
                [class.responsive]="selectedTransaction?.transaction_info?.phoneNumber">
            <div *ngIf="selectedTransaction?.transaction_info?.phoneNumber">
                <img src="assets/images/content_copy_black_24dp.svg"
                    [cdkCopyToClipboard]="selectedTransaction?.transaction_info?.phoneNumber"
                    (click)="copyAddress('phone')" *ngIf="copied !== 'phone'" alt="Copy" width="20">
                <img src="assets/images/done_black_24dp (1).svg" alt="Done" *ngIf="copied === 'phone'" class="active"
                    width="20">
            </div>
        </div>

        <!-- <div class="transaction__holder">
                <label class="transaction__label" for="sing">Sign up date</label>
                <input readonly class="transaction__input" id="sing" type="text">
            </div> -->
    </div>


    <span class="fieldset__close" (click)="close()"></span>
</fieldset>
