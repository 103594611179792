<div class="popup-blue__content">
        <div class="popup-blue">
            <h3 class="popup-blue__title">
                Create {{modalType}}
            </h3>
            <form [formGroup]="providerForm">
                <div class="form__row" [class.error__field]="isFieldInvalid('name')">
                <div class="form-label__holder">
                        <label class="form-label text-overflow" for="name">Name</label>
                        <input class="textfield textfield__white-border" type="text" name="name" id="name" formControlName="name" >
                        <span class="error__text" *ngIf="isFieldInvalid('name')">
                            <p *ngIf="form.name.errors.required" >Name is required</p>
                        </span>
                    </div>
                </div>
                <div class="form__row">
                    <div class="form-label__holder select-white__border" [class.error__field]="isFieldInvalid('interestareas')">
                        <label class="form-label text-overflow">Areas Of Interest</label>
                        <ng-select
                            [items]="interests | async"
                            [multiple]="true"
                            formControlName="interestareas"
                            placeholder="Select Areas Of Interest">
                        </ng-select>
                        <span class="error__text" *ngIf="isFieldInvalid('interestareas')">
                            <p *ngIf="form.interestareas.errors.required">areas of interest is required</p>
                        </span>
                    </div>
                </div>
                <div class="form__row">
                    <div class="form-label__holder select-white__border" [class.error__field]="isFieldInvalid('country')">
                        <label class="form-label text-overflow">Country</label>
                        <ng-select
                            [items]="countries$ | async"
                            formControlName="country"
                            bindValue="id"
                            bindLabel="name"
                            placeholder="Select Country"
                            (change)="selectCountry($event)"
                        >
                        </ng-select>
                        <span class="error__text" *ngIf="isFieldInvalid('country')">
                            <p *ngIf="form.country.errors.required">country is required</p>
                        </span>
                    </div>
                    <div class="form-label__holder select-white__border">
                    <!-- <div class="form-label__holder select-white__border" [class.error__field]="isFieldInvalid('state')"> -->
                        <label class="form-label text-overflow">State</label>
                        <ng-select
                            [items]="states$ | async"
                            formControlName="state"
                            (change)="selectState($event)"
                            bindValue="id"
                            bindLabel="name"
                            [class.disable]="form.state.disabled"
                            placeholder="Select State">
                        </ng-select>
                        <!-- <span class="error__text" *ngIf="isFieldInvalid('state')">
                            <p *ngIf="form.state.errors.required">state is required</p>
                        </span> -->
                    </div>
                </div>
                <div class="form__row">
                    <div class="form-label__holder select-white__border">
                    <!-- <div class="form-label__holder select-white__border" [class.error__field]="isFieldInvalid('city')"> -->
                        <label class="form-label text-overflow">City</label>
                        <ng-select
                            [items]="cities$ | async"
                            formControlName="city"
                            bindValue="id"
                            bindLabel="name"
                            placeholder="Select City">
                            [class.disable]="form.city.disabled"
                        </ng-select>
                        <!-- <span class="error__text" *ngIf="isFieldInvalid('city')">
                            <p *ngIf="form.city.errors.required">city is required</p>
                        </span> -->
                    </div>
                    <div class="form-label__holder" [class.error__field]="isFieldInvalid('zip')">
                        <label class="form-label text-overflow">Zip Code</label>
                        <input class="textfield textfield__white-border" type="text" name="zip-code" id="zip-code" placeholder=""
                            formControlName="zip">
                        <span class="error__text" *ngIf="isFieldInvalid('zip')">
                            <p *ngIf="form.zip.errors.required">zip code is required</p>
                            <p *ngIf="form.zip.errors.pattern">zip pattern is invalid</p>
                        </span>
                    </div>
                </div>
                <div class="form__row" [class.error__field]="isFieldInvalid('address')">
                    <div class="form-label__holder">
                        <label class="form-label text-overflow">Address</label>
                        <input class="textfield textfield__white-border" type="text" name="address" id="address" placeholder=""
                                formControlName="address">
                        <span class="error__text" *ngIf="isFieldInvalid('address')">
                            <p *ngIf="form.address.errors.required">address is required</p>
                        </span>
                    </div>
                </div>
                <div class="form__row"  [class.error__field]="isFieldInvalid('phone')">
                    <div class="form-label__holder">
                    <label class="form-label text-overflow" for="phone">Phone Number</label>
                        <input class="textfield textfield__white-border" type="text" name="phone" id="phone" formControlName="phone" >
                        <span class="error__text" *ngIf="isFieldInvalid('phone')">
                            <p>phone number is required</p>
                        </span>
                    </div>
                </div>
                <div class="form__row">
                    <div class="form-label__holder" [class.error__field]="isFieldInvalid('website')">
                    <label class="form-label text-overflow" for="website">Website</label>
                        <input class="textfield textfield__white-border" type="text" name="website" id="website" formControlName="website" >
                        <span class="error__text" *ngIf="isFieldInvalid('website')">
                            <p *ngIf="form.website.errors.required">website url is required</p>
                            <p *ngIf="form.website.errors.url && !form.website.errors.required">please enter a valid url</p>
                        </span>
                    </div>
                </div>
                <div class="form__row">
                    <div class="form-label__holder" [class.error__field]="isFieldInvalid('email')">
                        <label class="form-label text-overflow" for="email">Email</label>
                        <input class="textfield textfield__white-border" type="text" name="email" id="email" formControlName="email" >
                        <span class="error__text" *ngIf="isFieldInvalid('email')" >
                             <p *ngIf="form.email.errors.required">email is required</p>
                             <p *ngIf="form.email.errors.email && !form.email.errors.required">please enter a valid email</p>
                        </span>
                    </div>
                </div>
            </form>
            <div class="row__buttons">
                <button class="button button_small button_white-border" (click)="onSubmit()">Save</button>
            </div>
        </div>
        <button (click)="closeDialog()" class="close">
            <svg class="svg-icon">
                <use xlink:href="#close-simple"></use>
            </svg>
        </button>
    </div>
