import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xion-auth-user-not-found-modal',
  templateUrl: './auth-user-not-found-modal.component.html',
  styleUrls: ['./auth-user-not-found-modal.component.scss']
})
export class AuthUserNotFoundModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
