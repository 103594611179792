import { Injectable } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ISubscription } from '../../shared/interfaces/subscription.interface';
import { IPost } from '../../shared/pages/social/social.component';
import { IAffiliateSubscription } from './affiliate.service';
import { NotificationsService } from './notifications.service';
import { IUser } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class ShareService {
    private BASE_URL = `http://${window.location.host}`;

    constructor(private notifications: NotificationsService, private clipboard: ClipboardService) {}

    public sharePost(post: IPost) {
        const URL = `${this.BASE_URL}/merchant/social/${post.id}`;
        this.clipboard.copyFromContent(URL);
        this.notifications.showMessage('Post Link copied to your clipboard');
    }
    public shareSubscription(
        subscription: ISubscription | IAffiliateSubscription,
        affiliate?: IUser
    ) {
        let URL = `${this.BASE_URL}/subscription/${subscription.id}`;
        if (affiliate)
            URL = `http://${window.location.host}/subscription/${subscription.id}?aff=${affiliate.idxion}`;
        this.clipboard.copyFromContent(URL);
        this.notifications.showMessage('Subscription Link copied to your clipboard');
    }
    public getSubscriptionLink(subscription) {
        return `${this.BASE_URL}/subscription/${subscription.id}`;
    }
}
