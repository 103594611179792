import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, skip, take, throttleTime } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import { CurrencyService } from '../../../core/services/currency.service';
import { DummyDataService } from '../../../core/services/dummy.service';
import {
    ISubscriptionsFilters,
    SubscriptionsService
} from '../../../core/services/subscriptions.service';
import { IUser } from '../../../core/services/user.service';
import { InterestsService } from '../../../core/shared/interests.service';
import { ISubscription } from '../../interfaces/subscription.interface';
// import { AffiliateService } from '../../../core/services/affiliate.service';

export enum Tabs {
    FEED = 0,
    DISCOVER = 1,
    TRENDING = 2,
    OFFERS = 3,
    PRIVATE = 4
}

@Component({
    selector: 'xion-channel-user',
    templateUrl: './marketplace.component.html',
    styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit, OnDestroy {
    tabs = Tabs;
    activeTab = Tabs.DISCOVER;
    query: Observable<string>;
    interests$: Observable<string[]>;
    user$: Observable<IUser>;
    user: IUser = null;

    filters: ISubscriptionsFilters = {
        preorder: false,
        nonprofit: false,
        physical: true,
        digital: true,
        interestareas: [],
        limit: 100,
        start: 0,
        tab: Tabs.FEED,
        username: null
    };

    feed$: Observable<ISubscription[]>;
    trending$: Observable<ISubscription[]>;
    discover$: Observable<ISubscription[]>;
    private$: Observable<ISubscription[]>;
    onetime$: Observable<ISubscription[]>;

    querySubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private querySub: Subscription;

    constructor(
        private currencyService: CurrencyService,
        private auth: AuthService,
        private dummy: DummyDataService,
        private interestsService: InterestsService,
        private subscriptionsService: SubscriptionsService
    ) {
        this.query = this.querySubject.asObservable();
    }

    ngOnInit() {
        this.interests$ = this.interestsService.getAreasOfInterest();
        this.user$ = this.auth.currentUser;
        this.user$.pipe(take(1)).subscribe((user) => (this.user = user));
        this.loadSubscriptions();

        this.querySub = this.query
            .pipe(
                skip(1),
                throttleTime(500),
                distinctUntilChanged()
            )
            .subscribe(this.loadSubscriptions.bind(this));
    }

    filterSubscriptions() {
        this.loadSubscriptions();
    }

    ngOnDestroy(): void {
        this.querySub.unsubscribe();
    }

    setActiveTab(tab: Tabs) {
        this.activeTab = tab;
    }

    private loadSubscriptions() {
        this.feed$ = this.subscriptionsService.getSubscriptions({
            ...this.filters,
            tab: Tabs.FEED
        });
        this.trending$ = this.subscriptionsService.getSubscriptions({
            ...this.filters,
            tab: Tabs.TRENDING
        });
        this.discover$ = this.subscriptionsService.getSubscriptions({
            ...this.filters,
            tab: Tabs.DISCOVER
        });
        this.private$ = this.subscriptionsService.getSubscriptions({
            ...this.filters,
            tab: Tabs.PRIVATE
        });
        this.onetime$ = this.subscriptionsService.getSubscriptions({
            ...this.filters,
            tab: Tabs.OFFERS
        });
    }
}
