import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BillingStatusModalType, IBillingStatusChangeParam } from '../../models/billing-product.model';

enum Currency {
    xDai = 'xdai',
    XGT = 'xgt'
}

@Component({
    selector: 'xion-billing-change-status-modal',
    templateUrl: './billing-change-status-modal.component.html',
    styleUrls: ['./billing-change-status-modal.component.scss']
})
export class BillingChangeStatusModalComponent implements OnInit {

    Currency = Currency;
    BillingStatusModalType = BillingStatusModalType;
    currency: Currency = Currency.xDai;
    price = 0;

    constructor(@Inject(MAT_DIALOG_DATA) public data: IBillingStatusChangeParam,
                private dialogRef: MatDialogRef<BillingChangeStatusModalComponent>) {
    }

    ngOnInit() {
        this.price = this.data.price;
        if (this.data.type === BillingStatusModalType.Pause) {
            this.price = this.price * 12.5 / 100;
        }
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

    public confirm(): void {
        this.dialogRef.close({
            status: this.data.status,
            currency: this.currency
        });
    }

    changeUnit(unit: Currency) {
        this.currency = unit;
    }

}
