<div class="give-feedback">
    <a href="https://discord.gg/egYTBHS7hQ" target="_blank"><img src="assets/images/header.svg" alt="Header"></a>
</div>
<div class="join_chat home-chat" (click)="navigateToHelp()">
    <img src="assets/images/join_chat-min.png" alt="Chat">
</div>
<div class="wrapper grey-part-bg">
    <main class="main">
        <div class="main__logo">
            <img src="assets/images/new_logo.svg" height="70">
        </div>
        <div class="main_middle">
            <div class="main_middle-item">
                <h1 class="title_main">
                    <div>
                        MAKIN' <b>PAYMENTS</b>
                    </div>
                    <div>
                        <b>& REWARDS </b> EASY
                    </div>
                </h1>
                <div class="main_middle-item__divider"></div>

                <div class="main_middle--content">
                    <div class="main_middle--content-wallet">
                        <button (click)="login('web3modal')"> CONNECT WALLET</button>
                        <div>
                            <img src="assets/images/metamask.svg" alt="Metamask" height="30">
                            <img src="assets/images/torus.svg" alt="Torus" height="30">
                            <img src="assets/images/walletconnect.svg" alt="WalletConnect" height="30">
                        </div>
                    </div>

                    <div class="main_middle--content-or">
                        <span>OR </span>
                    </div>

                    <div class="main_middle--content-form" [formGroup]="emailForm" *ngIf="!isSubmit">
                        <label for="homeEmail" class="mobile-hide">
                            ENTER YOUR EMAIL TO LOGIN / CREATE ACCOUNT
                        </label>
                        <svg class="svg-icon svg-icon-email">
                            <use xlink:href="#mailInput"></use>
                        </svg>
                        <small *ngIf="emailError" for="email" class="error-text">{{emailError}}</small>
                        <input type="text" class="main_middle--content-input" formControlName="email" placeholder="Enter your email address to login / create account">
                        <button [class.disabled]="!isEmailValid" (click)="onSendCode()">SUBMIT</button>
                    </div>

                    <div class="main_middle--content-form" [formGroup]="codeForm" *ngIf="isSubmit">
                        <label for="homeEmail" >AN <i>EMAIL</i> WITH THE <i>CODE</i> HAS BEEN SENT TO
                            <i>{{email}}</i></label>
                        <svg class="svg-icon svg-icon-email">
                            <use xlink:href="#mailInput"></use>
                        </svg>
                        <small *ngIf="codeError" for="email" class="error-text">{{codeError}}</small>
                        <input type="text" class="main_middle--content-input" type="text" minlength="6" maxlength="6"
                            formControlName="code" placeholder="Enter your code">
                        <button [class.disabled]="!isCodeValid" (click)="onAuthenticate()">Authenticate</button>
                    </div>

                    <div class="main_middle--content-or mb-10">
                        <span>OR</span>
                    </div>

                    <div class="app-body-item" (click)="login('google')">
                        <img src="./../assets/images/google-icon.svg" alt="">
                        <span>Login / Create Account with Google</span>
                    </div>

                    <div class="main_middle--content-footer">
                        <img src="assets/images/power.svg">
                    </div>
                </div>
            </div>
            <div class="main_middle-item mobile-responsive">

                <svg class="svg-icon svg-icon-dai">
                    <use xlink:href="#dai-home"></use>
                </svg>

                <div class="main_middle-item-video">
                    <div class="main_middle-item-video-iframe">
                        <div class="main_middle-item-video-iframe__container">
                            <img src="assets/images/boom.svg" alt="" width="480">
                        </div>
                        <div class="main_middle-item-meta">
                            <img src="assets/images/meta.svg" alt="">
                        </div>
                        <span class="free-icon">
                            <i>FREE</i>SETUP
                        </span>

                        <div class="main_middle-item-dai">
                            <img src="assets/images/dai.svg" alt="">
                        </div>
                    </div>

                    <div class="main_middle-item-text">
                        <span>ACCEPT PAYMENTS GLOBALLY</span>
                        <p>NO MIDDLEMEN OR CHARGEBACKS</p>
                        <span>NO BANK ACCOUNT REQUIRED</span>
                        <p> MANAGEMENT & ANALYTICS </p>
                        <span>CREATE LOYALTY PROGRAMS</span>
                        <p>0% TRANSACTION FEES</p>
                    </div>

                    <img class="secret-home" src="assets/images/secret-home.png" alt="">

                </div>
            </div>

        </div>
    </main>
    <footer class="footer">
        <nav class="footer__nav">
            <ul class="top-list-partner">
                <li class="top-list-partner__item">
                    <img src="assets/images/payments/xdai_1.svg" alt="XDAI">
                </li>
                <li class="top-list-partner__item">
                    <img src="assets/images/payments/xgt.svg" alt="XGT" style="height: 27px; margin-top: 4px;">
                </li>
                <li class="top-list-partner__item">
                    <img src="assets/images/payments/eth.svg" alt="ETH">
                </li>
                <li class="top-list-partner__item">
                    <img src="assets/images/payments/btc.svg" alt="BTC">
                </li>
                <li class="top-list-partner__item">
                    <img src="assets/images/payments/ltc.svg" alt="LTC">
                </li>
                <li class="top-list-partner__item">
                    <img src="assets/images/payments/xrp.svg" alt="XGP" style="height: 34px;">
                </li>
            </ul>
            <ul class="top-list">
                <li class="top-list__item">
                    <a target="_blank" href="assets/Terms-of-Use.pdf" class="top-list__link">Terms of
                        Use</a>
                </li>
                <li class="top-list__item list-style">
                    <a target="_blank" href="assets/Privacy_Policy.pdf" class="top-list__link">Privacy
                        Policy</a>
                </li>
                <li class="top-list__item list-style">
                    <a target="_blank" href="https://xionwiki.gitbook.io/xion-global" class="top-list__link">LEARN MORE</a>
                </li>
            </ul>
        </nav>
    </footer>
</div>
