import { Injectable } from '@angular/core';
import Big from 'big.js';
import { HttpService } from '../../retailers/services/http.service';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    subscriptionContractInstance;
    walletSubscriptionContractInstance;

    constructor(
        private http: HttpService,
        private authService: AuthService
    ) {
    }

    async initSubscriptionContract(web3Instance): Promise<void> {
        try {
            const abiRouter = await this.http.getSubscriptionContractAbi().toPromise();
            this.subscriptionContractInstance = new web3Instance.eth.Contract(abiRouter, environment.subscriptionsContractAddress, null);
        } catch (e) {
            console.log(e);
        }
    }

    getSubscriptionContractBalance(): Promise<number> {
        return new Promise(async (resolve) => {
            try {
                // This will return a big int with the XGT amount times 10^18
                const balanceSubscriptionContract = await this.subscriptionContractInstance.methods.customerBalancesBase(this.authService.loggedInUser.walletAddress).call();
                // if you want it to be a normal number, you can use this:
                resolve(parseFloat(new Big(balanceSubscriptionContract.toString()).div(new Big(10 ** 18)).toFixed(18).toString()));
            } catch (e) {
                resolve(0);
            }
        });
    }

    async walletInitSubscriptionContract(web3Instance): Promise<void> {
        try {
            const abiRouter = await this.http.getSubscriptionContractAbi().toPromise();
            this.walletSubscriptionContractInstance = new web3Instance.eth.Contract(abiRouter, environment.subscriptionsContractAddress, null);
        } catch (e) {
            console.log(e);
        }
    }

    getWalletSubscriptionContractBalance(): Promise<number> {
        return new Promise(async (resolve) => {
            try {
                // This will return a big int with the XGT amount times 10^18
                const balanceSubscriptionContract = await this.walletSubscriptionContractInstance.methods.customerBalancesBase(this.authService.loggedInUser.walletAddress).call();
                // if you want it to be a normal number, you can use this:
                resolve(parseFloat(new Big(balanceSubscriptionContract.toString()).div(new Big(10 ** 18)).toFixed(18).toString()));
            } catch (e) {
                resolve(0);
            }
        });
    }
}
