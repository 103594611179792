import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITransaction, ITransactionInfo } from '../../interfaces/transaction.interface';
import { Observable, of } from 'rxjs';
import { TransactionModel } from '../../models/transaction.model';
import { NotificationsService } from 'src/app/core/services/notifications.service';
@Component({
    selector: 'xion-subscription-transactions-modal',
    templateUrl: './subscription-transactions-modal.component.html',
    styleUrls: ['./subscription-transactions-modal.component.scss']
})
export class SubscriptionTransactionsModalComponent implements OnInit {
    transactions: Observable<ITransaction[]>;
    selectedTransactionId: string = 'null';
    selectedTransaction: TransactionModel;

    retryStrings = ['renew', '1st Retry', '2nd Retry', '3rd Retry', '4th Retry'];
    count = 0;

    copied: string = null;

    constructor(
        private dialogRef: MatDialogRef<SubscriptionTransactionsModalComponent>,
        private notifications: NotificationsService,
        @Inject(MAT_DIALOG_DATA) public data: ITransactionInfo[]
    ) {}

    ngOnInit() {
        if (this.data) {
            this.transactions = of(
                this.data.reverse().map((item) => {
                    if (item.txType === 'renew' && item.status === 'failed') {
                        if (this.count < 5) {
                            item.txType = this.retryStrings[this.count];
                            this.count++;
                        }
                    } else {
                        this.count = 0;
                    }
                    this.selectedTransaction = new TransactionModel({ transaction_info: item });
                    return new TransactionModel({ transaction_info: item });
                })
            );
        }
    }

    public close(): void {
        this.dialogRef.close();
    }
    clickedTransaction(tr) {
        this.selectedTransactionId = tr.transaction_info.id;
        this.selectedTransaction = tr;
    }

    copyAddress(params: string): void {
        this.notifications.showMessage('Copied!');
        this.copied = params;
        setTimeout(() => {
            this.copied = null;
        }, 1000);
    }
}
