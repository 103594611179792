<div class="popup-blue__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title">
            Add XSC Wallet
        </h3>
        <form [formGroup]="addWalletForm">
            <div class="form__row">
                <div class="form-label__holder">
                    <label for="form-label text-overflow">XSC wallet ID, created outside of the XION platform</label>
                    <input class="textfield textfield__white-border" type="text" name="xsc"
                            id="xsc" formControlName="xsc">
                </div>
            </div>
            <div class="row__buttons">
                <button class="button button_small button_white-border">
                    Verify
                </button>
            </div>
        </form>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>