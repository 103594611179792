import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AsideService {
    constructor(public authServie: AuthService) {
        // this.authServie.currentUserPlan.subscribe((plan) => {
        //     if (this.allowedPlans.includes(plan)) {
        //         const isAdded = this.merchantLinks.filter(
        //             (menuItem) => menuItem.class === 'connect'
        //         );
        //         if(!isAdded?.length) {
        //             this.merchantLinks.push({
        //                 name: 'CONNECT',
        //                 class: 'connect',
        //                 link: ['/merchant', 'connect'],
        //                 isActive: true
        //             });
        //         }
        //     }
        // });
    }
    allowedPlans = ['master_merchant', 'super_merchant', 'xion'];
    public isOpen: boolean = false;
    public merchantLinks: IMenu[] = [
        {
            name: 'DASHBOARD',
            class: 'dashboard',
            link: ['/merchant', 'dashboard'],
            isActive: true
        },
        {
            name: 'CREATE',
            class: 'create',
            link: ['/merchant', 'create'],
            children: [
                {
                    name: 'Products',
                    link: ['/merchant', 'create', 'products']
                },
                {
                    name: 'Checkout Buttons',
                    link: ['/merchant', 'create', 'checkout']
                },
                {
                    name: 'Payment Links',
                    link: ['/merchant', 'create', 'payment']
                }
            ],
            isActive: true
        },
        {
            name: 'MANAGEMENT',
            class: 'management',
            link: ['/merchant', 'management'],
            children: [
                {
                    name: 'Products',
                    link: ['/merchant', 'management', 'products']
                },
                {
                    name: 'Customers',
                    link: ['/merchant', 'management', 'customers']
                }
            ],
            isActive: true
        },
        // {
        //     name: 'ANALYTICS',
        //     link: ['/merchant', 'analytics'],
        //     class: 'anaytics',
        //     isActive: true
        // },
        {
            name: 'MY ORDERS',
            link: ['/merchant', 'my-orders'],
            class: 'orders',
            isActive: true
        },
        {
            name: 'WALLET',
            class: 'wallet',
            link: ['/merchant', 'wallet'],
            // children: [
            //     {
            //         name: 'Balance',
            //         link: ['/merchant', 'wallet', 'balance'],
            //     },
            //     {
            //         name: 'Buy / Swap',
            //         link: ['/merchant', 'wallet', 'buy'],
            //     }
            // ],
            isActive: true
        },
        // {
        //     name: 'ACTIVITY',
        //     link: ['/merchant', 'activity'],
        //     class: 'activity',
        //     isActive: true
        // },
        // {
        //     name: 'TOP UP / WITHDRAW',
        //     link: ['/merchant', 'topup'],
        //     class: 'topup',
        //     isActive: true
        // },
        {
            name: 'LOYALTY PROGRAM',
            link: ['/merchant', 'loyalty'],
            class: 'loyalty',
            isActive: true
        }
    ];

    toggleAside(open: boolean = !this.isOpen) {
        this.isOpen = open;
    }
}

export interface IMenu {
    name: string;
    link?: string[];
    class: string;
    isActive: boolean;
    children?: Object[];
}
