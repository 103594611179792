<div class="add-post__row retiler-post__item"
    [class.retiler-post__item_disabled]="user.idxion !== post.user.idxion"
    [class.retailer-post__item_edit]="user.idxion === post.user.idxion"
     *ngIf="!isEditingPost;else editPostView">
    <div class="border-right" *ngIf="user.idxion === post.user.idxion"></div>
    <div class="border-left" *ngIf="user.idxion === post.user.idxion"></div>
    <div class="white-background">
        <div class="post">
            <div class="post__picture" *ngIf="post.user.image">
                <img [src]="post.user.image" [alt]="post.user.fullname" [title]="post.user.fullname">
            </div>

            <div class="post__picture post__picture-empty" *ngIf="!post.user.image" [title]="post.user.fullname">
                <svg class="svg-icon icon__no-retailer">
                    <use xlink:href="#icon-retailer"></use>
                </svg>
            </div>

            <div class="form__row">
                <div class="retiler-post__name">
                    {{post.user.fullname}}
                </div>
                <p class="retiler-post__text ">
                    <span *ngIf="showFull">
                        <span [innerHTML]="post.text | linky:{newWindow: true}"></span>
                    </span>
                    <span *ngIf="!post.photo || post.text.length<300 ;else truncateText">
                        <span [innerHTML]="post.text | linky:{newWindow: true}"></span>
                    </span>
                    <ng-template #truncateText>
                        <span [innerHTML]="post.text| linky:{newWindow: true}|truncate:300 "></span>
                        <a (click)="toggleShowMore()"
                           class="retiler-post__more-text">{{showFull ? 'Show Less' : 'Show More'}}</a>
                    </ng-template>
                </p>
            </div>
        </div>

        <xion-link-preview *ngIf="post.metadata"
            [metadata]="post.metadata"
            [showFullPreview]="showFullLinkPreview"
            class="link__holder">
        </xion-link-preview>

        <div class="tabs" *ngIf="post.photo && post.video">
            <button
                class="tab__item link-active"
                [class.link-active]="activeTabItem === 'photo'"
                (click)="activeTabItem = 'photo'">
                    Photo
            </button>
            <button
                class="tab__item"
                [class.link-active]="activeTabItem === 'video'"
                (click)="activeTabItem = 'video'">
                    Video
            </button>
        </div>
        <xion-video-player class="player-component"
                           [video]="post.video.url"
                           *ngIf="post.video && activeTabItem === 'video'">
        </xion-video-player>

        <div class="retiler-post__picture-holder" *ngIf="post.photo && activeTabItem === 'photo'">
            <img [src]="post.photo.url" [alt]="post.photo.filename" class="retiler-post__picture">
        </div>
        <div class="post__actions-holder" *ngIf="user.idxion !== post.user.idxion; else docUpload">
            <a class="post__actions notification-msg__holder"  (click)="followUser(post.user)" *ngIf="!post.user.followed;else unfollowRetailer">
                <svg class="svg-icon icon-follow" >
                    <use xlink:href="#icon-follow"></use>
                </svg>
                <div class="notification-msg">Follow</div>
            </a>
            <ng-template #unfollowRetailer>
                <a class="post__actions notification-msg__holder"  (click)="followUser(post.user)">
                    <svg class="svg-icon icon-unfollow">
                        <use xlink:href="#icon-unfollow"></use>
                    </svg>
                    <div class="notification-msg">Unfollow</div>
                </a>
            </ng-template>
            <a class="post__actions notification-msg__holder" (click)="sharePost()">
                <svg class="svg-icon icon-share">
                    <use xlink:href="#icon-share"></use>
                </svg>
                <div class="notification-msg">Share</div>
            </a>

            <a class="post__actions post__actions_like notification-msg__holder"  [class.un-like]="post.liked" (click)="likePost()">
                <svg class="svg-icon icon-like">
                    <use xlink:href="#icon-like"></use>
                </svg>
                <div class="notification-msg">Like</div>
            </a>
            <ng-container *ngIf="post.document">
                <a [href]="post.document.url" class="post__actions notification-msg__holder">
                    <svg class="svg-icon icon-download">
                        <use xlink:href="#icon-download"></use>
                    </svg>
                    <div class="notification-msg">{{post.document.filename}}</div>
                </a>
            </ng-container>
        </div>
        <ng-template #docUpload>
            <ng-container *ngIf="post.document">
                <div class="post__actions-holder">
                    <a [href]="post.document.url" class="post__actions post__actions_download" [title]="post.document.filename">
                        <svg class="svg-icon icon-download">
                            <use xlink:href="#icon-download"></use>
                        </svg>
                        {{post.document.filename}}
                    </a>
                </div>
            </ng-container>
        </ng-template>
    </div>
    <button class="popup__close" *ngIf="user.idxion === post.user.idxion" (click)="toggleEditPost()">
        <span class="button-text">Edit</span>
    </button>
</div>


<ng-template #editPostView>
    <xion-edit-channel-post
        (closeEditForm)="toggleEditPost()"
        [tab]="tab"
        [post]="post"></xion-edit-channel-post>
</ng-template>
