<button mat-button [matMenuTriggerFor]="notificationMenu" class="top-list__link top-list__link_member">
  <div class="top-list__icon-notification">
      <!-- <svg class="svg-icon icon-lamp"><use xlink:href="#icon-lamp"></use></svg> -->
      <svg class="svg-icon icon-lamp_light"><use xlink:href="#icon-lamp-light"></use></svg>
  </div>
  <div class="text-overflow notification-text">
    Notifications
  </div>
  <svg class="svg-icon icon-arrow__menu"><use xlink:href="#icon-arrow-play"></use></svg>
</button>
<mat-menu #notificationMenu="matMenu" class="submenu submenu__notification" xPosition="before" overlapTrigger="true" backdropClass="drop-menu__wrapper" >

  <ng-container *ngIf="notifications | async as notifs" >
  <div class="submenu__list" *ngFor="let notification of notifs">
    <a  href="#"  class="submenu__link text-overflow notification-msg__holder">
      {{notification.name}}
      <div class="notification-msg">{{notification.name}}</div>
    </a>
  </div>
  </ng-container>

</mat-menu>
