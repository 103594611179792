import * as moment from 'moment';
import { IApiResponse, IAttachment } from '../models/models';
import { IUploadResponse } from '../../core/services/file-upload.service';
import { colorSchemes, REFRESH_TOKEN_NAME, TOKEN_NAME } from './config';
import { HttpErrorResponse } from '@angular/common/http';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { ICity, ICountry, IState } from '../../core/services/location.service';
import { ISubscription, SubscriptionPlan } from '../interfaces/subscription.interface';

export const getColorScheme = (name) => {
    const colorSet = colorSchemes.find(set => set.name === name);
    if (colorSet) {
        return colorSet;
    }
    return colorSchemes.find(set => set.name === 'default');
};

export const passwordGenerator = (length = 15) => {
    const pass_regex = new RegExp('^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$');
    const dict = '!@#$%^&*-_=+\\|:;\',.\<>/?~0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let password = '';
    do {
        password = Array(length).fill(dict).map(x => x[Math.floor(Math.random() * x.length)]).join('');
    } while (pass_regex.test(password));
    return password;
};


export const getUnixTime = (date: Date | string): number => {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return Math.floor(date.getTime() / 1000);
};

export const unixToDate = (unix: number): Date => {
    return new Date(unix * 1000);
};

export const dateToUnix = (date: Date): number => {
    return moment(date).utc().endOf('day').hours(0).minutes(0).seconds(0).milliseconds(0).unix();
};

export const mapResponseToData = (response: IApiResponse) => {
    return response.data;
};

export const filterFileUploadFinished = (response: IUploadResponse): boolean => {
    return response.status === 'success';
};

export const mapToAttachment = (response: IUploadResponse): IAttachment => {
    return response.data ? response.data[0] : null;
};
export const mapToErrorMessage = (response: HttpErrorResponse): string => {
    return response.error.error;
};

export const updateTokens = (data) => {
    localStorage.setItem(TOKEN_NAME, data.token);
    localStorage.setItem(REFRESH_TOKEN_NAME, data.refreshToken);
};

export const setCustomerDetail = (info) => localStorage.setItem('user_info', JSON.stringify(info));
export const getCustomerDetail = () => JSON.parse(localStorage.getItem('user_info'))


export const getSubscriptionDuration = (plan: number) => {
    switch (plan) {
        case SubscriptionPlan.daily :
            return 'days';
        case SubscriptionPlan.weekly :
            return 'weeks';
        case SubscriptionPlan.monthly :
            return 'months';
        case SubscriptionPlan.yearly:
            return 'years';
        default :
            return 'days';
    }
};


export const calculateSubscriptionDuration = (subscription: ISubscription) => {
    const start = moment.unix(subscription.start).toDate();
    const end = moment.unix(subscription.end).toDate();
    const st = moment(start);
    const en = moment(end);
    const duration = getSubscriptionDuration(subscription.periodicity);
    const diff = en.diff(st, duration);
    return `${diff} ${duration}`;
};
