import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
// import { take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrencyService } from '../../../core/services/currency.service';
import { DummyDataService } from '../../../core/services/dummy.service';
import { UserService } from '../../../core/services/user.service';

@Component({
    selector: 'xion-currency-selector',
    templateUrl: './currency-selector.component.html',
    styleUrls: ['./currency-selector.component.scss']
})
export class CurrencySelectorComponent implements OnInit, OnDestroy {

    @Output() selectedCurrency = new EventEmitter();
    currencies: any[];

    config: SwiperConfigInterface = null;
    private destroyedSubject = new Subject();
    private destroyed$: Observable<any> = this.destroyedSubject.asObservable()

    constructor(
        private dummy: DummyDataService,
        private userService: UserService,
        private currencyService: CurrencyService) {
        this.config = {
            direction: 'vertical',
            slidesPerView: 1,
            keyboard: true,
            mousewheel: true,
            initialSlide: this.currencyService.defaultCurrency.index,
            scrollbar: false,
            pagination: false,
            navigation: {
                nextEl: '.next-currency',
                prevEl: '.prev-currency',
                disabledClass: 'currency-disable'
            }
        };
    }

    ngOnInit() {
        this.currencies = this.currencyService.getCurrencies();

        this.currencyService.updateCurrency()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(update => update);
    }

    onCurrencyChange(index) {
        const currency = this.currencies[index];
        this.currencyService.selectedCurrencySubject.next(currency);
    }


    ngOnDestroy(): void {
        this.destroyedSubject.next();
        this.destroyedSubject.complete();
    }

}
