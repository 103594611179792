import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DummyDataService } from 'src/app/core/services/dummy.service';
import { ISubscription } from '../../../../interfaces/subscription.interface';

@Component({
  selector: 'xion-view-subscription-modal',
  templateUrl: './view-transactions-modal.component.html',
  styleUrls: ['./view-transactions-modal.component.scss']
})
export class ViewTransactionsModalComponent implements OnInit {
    transactions$: any;
    activeTab = 'subscription';
    constructor(
        @Inject(MAT_DIALOG_DATA) public subscription: ISubscription,
        private dialogRef: MatDialogRef<ViewTransactionsModalComponent>,
        private dummy: DummyDataService) {}

    ngOnInit() {
        this.transactions$ = this.dummy.getTransactions()
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
