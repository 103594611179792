import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'xion-user-rating',
    templateUrl: './user-rating.component.html',
    styleUrls: ['./user-rating.component.scss']
})
export class UserRatingComponent implements OnInit {

    @Input() rating: number;
    fullstars: any[];
    emptystars: any[];

    constructor() {
    }

    ngOnInit() {
        this.rating = this.rating  || 0;
        this.fullstars = Array(this.rating).fill(null);
        this.emptystars = Array(5 - this.rating).fill(null);
    }

}
