<div class="popup__content">
    <div class="popup">
        <form [formGroup]="supportForm" class="form">
            <div class="form__row" [ngClass]="submitted && form.name.errors ? 'error__field' : ''">
                <input class="textfield" type="text" name="name" id="name" placeholder="NAME" formControlName="name">
                <span class="error__text" *ngIf="submitted && form.name.errors">
                        <p *ngIf="form.name.errors.required">name is required</p> 
                </span>  
            </div>
            <div class="form__row" [ngClass]="submitted && form.email.errors ? 'error__field' : ''">
                <input class="textfield" type="email" name="login-email" id="login-email" placeholder="EMAIL ADDRESS" formControlName="email">
                <span class="error__text" *ngIf="submitted && form.email.errors">
                    <p *ngIf="form.email.errors.required">email is required</p> 
                    <p *ngIf="form.email.errors.email && !form.email.errors.required">please enter a valid email</p>
                </span>   
            </div>
            <div class="form__row" [ngClass]="submitted && form.subject.errors ? 'error__field' : ''">
                <input class="textfield" type="text" name="subject" id="subject" placeholder="SUBJECT" formControlName="subject">
                <span class="error__text" *ngIf="submitted && form.subject.errors">
                    <p *ngIf="form.subject.errors.required">subject is required</p> 
                </span>   
            </div>
            <div class="form__row" [ngClass]="submitted && form.message.errors ? 'error__field' : ''">
                <input class="textfield" type="text" name="message" id="message" placeholder="MESSAGE" formControlName="message">
                <span class="error__text" *ngIf="submitted && form.message.errors">
                    <p *ngIf="form.message.errors.required">message is required</p> 
                </span>   
            </div>
            <div class="form__row">
                <textarea name="" class="textfield" id="" cols="30" rows="5" placeholder="Message"></textarea>
            </div>
            <div class="form__row">
                <button class="button button_blue button_big" (click)="submitForm()">
                    SEND
                    <span class="button__arrow">
                        <svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-arrow-play"></use></svg>
                    </span>
                </button>
            </div> 
        </form>
        <span class="popup__title">
            Contact Support
        </span>
    </div>
    <button (click)="closeDialog()" class="popup__close">
        <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
    </button>
</div>