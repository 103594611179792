export const environment = {
    production: false,
    api_url: 'https://dev-api.xion.app/api',
    chromeExtensionLink:
        'https://chrome.google.com/webstore/detail/xion-wallet/dacfkmlnegfmibbeabdomhahgjoipoln',
    staging: 'http://176.9.85.154:21240/api',
    local: 'http://192.168.101.250:21240/api',
    wallets: {
        ETH: '0x15B0feDa0ff231E5bCC955f965857d45f366d35E',
        BTC: 'bc1q0n5gvuhn82v9w7386y8cmevquxa96mnfj9870y'
    },
    chatKit: {
        instanceLocator: 'v1:us1:5d30431c-58ce-4e2b-982a-49c5d397e414'
    },
    MAX_CACHE_TIME: 120000,
    MAX_CACHE_COUNT: 10,
    social: {
        telegram: 'https://t.me/xionprotocol',
        twitter: 'https://twitter.com/XionProtocol',
        facebook: 'https://www.facebook.com/xionglobal',
        website: 'https://xion.global'
    },
    subscriptionsContractAddress: '0xff4cE7394bC1B868553841b5F69044D2A3b0dE41',
    xgtClaimContractAddress: '0xC2F128d188d095A47d5459C75de8185060df5E2f',
    tokenContractAddress: '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa',
    applozicAppID: 'xion2f35ae1c538ec1b9153fc3bd9d71e137c',
    applozicEmail: 'aeryn@xion.global',
    applozicPass: 'Xionzendesk',
    pusherKey: 'a106e64ce6c53b3d7263',
    chainId: '77',
    chainRpcURL: "https://sokol.poa.network",
    pusherConfig: {
        cluster: 'eu',
        authEndpoint: 'https://dev-api.xion.app/api/auth/pusher'
    },
    checkoutButtonScript: 'https://xion-checkout-button-script-staging.s3.amazonaws.com/bundle.js',

    salt: '730461434089308330',
    facebookID: '582996352609190',
    redditID: 'rsxqflS40z5zug',
    discordID: '730461434089308330',
    twitchID: 'eypexoaquyy5c295klyla3zy8j16z3',
    googleID: '360309746148-ss8js271melbb5jsbvnuo7va4h2inqe0.apps.googleusercontent.com',
    proxyContractAddress: '0x4023d2a0D330bF11426B12C6144Cfb96B7fa6183',
    url: 'https://dev.xion.app/serviceworker',
    emailConfig: {
        verifier: 'xion-auth0-email-password',
        id: '1yTNTG5LEl18aDcXKIUkD4p53q7h6KL7'
    },
    phoneConfig: {
        id: 'Gu2cg6lAm61G1edw4YCT4AEB3s9DIQcf',
        verifier: 'xion-auth0-sms-passwordless'
    },
    auth: {
        ID: '7U7wtu2jKcDGztoqhkwyYQfePJmPIGNa',
        domain: 'https://xion.us.auth0.com',
        customDomain: 'login.checkout.xion.app'
    },
    ramp: {
        hostAppName: 'Xion Finance',
        hostLogoUrl: 'https://xion.finance/images/logo.svg',
        swapAsset: 'xDai',
        hostApiKey: "wuzhxcpush76oxtbwrsx78fxfuv4czocy27ovkfh",
        selectedCountryCode: "GB",
    },
    mainChainId: 'sokol',
    tokenAddress: '0xC25AF3123d2420054c8fcd144c21113aa2853F39', // sokol DMT
    checkoutUrl: 'https://dev-checkout.xion.app',
    loyaltyProductURL: 'https://dev-checkout.xion.app?token=0a3f3d63-b2b4-4ef9-9fc2-d265e9aa0e49',
    followProductURL: 'https://dev-checkout.xion.app?token=1925c8fe-6df4-40c0-9824-77773f5e8cec',
    cookieDomain: 'dev.xion.app',
    infraID: '04adc6075a7047459c5d2a0202b691e9',
    blockExplorerURL: 'https://blockscout.com/poa/sokol/tx/',
};
