<fieldset class="transaction__row">
    <div class="fieldset__title">TRANSACTIONS
        <div class="fieldset__title_search">
            <input class="search-input" type="text" placeholder="SEARCH BY ORDER NO. OR CUSTOMER NAME"  (keyup)="onSearch($event)">
            <img  class="top-list__item_icon"  src="assets/images/header/search.svg" width="20px" alt="Search">
        </div>
    </div>
    <div class="transaction__content">
        <div class="responsive-table">
            <table class="table transaction-table">
                <thead>
                    <th>DATE</th>
                    <th>CUSTOMER</th>
                    <th>AMOUNT</th>
                    <th>ORDER CODE</th>
                    <th>PAYMENT STATUS</th>
                    <th>CURRENCY</th>
                    <th>TYPE</th>
                </thead>
                <tbody>
                    <tr class="no-transactions" *ngIf="!(filteredTransactions)?.length">
                        <td colspan="7">No transactions</td>
                    </tr>
                    <tr #transaction class="transaction__content__body_item"
                        *ngFor="let trns of (filteredTransactions)?.slice().reverse()"
                        (click)="transactionSelected(transaction, trns)">
                        <td>{{trns.transaction_info.date}}</td>
                        <td>{{trns.user_info.name || 'Anonymous'}}</td>
                        <td>${{(trns.transaction_info.currency === 'xgt' ? trns.transaction_info.price * 1 :
                            trns.transaction_info.price) | number : '1.2-2'}}</td>
                        <td>{{trns.transaction_info.orderCode}}</td>
                        <td>{{trns.transaction_info.status}}</td>
                        <td *ngIf="trns.transaction_info.status === 'pending'" class="glow">loading</td>
                        <td *ngIf="trns.transaction_info.status !== 'pending'">
                            {{((trns.transaction_info.currency === 'xgt' || trns.transaction_info.currency === 'xdai') ?
                            ((trns.transaction_info.currency === 'xgt' ? trns.transaction_info.cryptoAmount * 1 :
                            trns.transaction_info.cryptoAmount) | number : '1.2-2') :
                            trns.transaction_info.cryptoAmount) || 0}}
                            {{trns.transaction_info.currency}}</td>
                        <td>{{trns.transaction_info.txType}}</td>
                    </tr>
                </tbody>
            </table>
            <!-- <div *ngIf="!(transactions | async)?.length" style="width: 100%;text-align: center;">No transactions</div> -->

        </div>

        <div class="transaction__content transaction__content_info">

            <div class="transaction__holder">
                <label class="transaction__label" for="name">Name</label>
                <input readonly class="transaction__input" id="name" type="text"
                    [value]="selectedTransaction?.user_info?.name"
                    [class.responsive]="selectedTransaction?.user_info?.name">
                <div *ngIf="selectedTransaction?.user_info?.name">
                    <img src="assets/images/content_copy_black_24dp.svg"
                        [cdkCopyToClipboard]="selectedTransaction?.user_info?.name" (click)="copyAddress('name')"
                        *ngIf="copied !== 'name'" alt="Copy" width="20">
                    <img src="assets/images/done_black_24dp (1).svg" alt="Done" *ngIf="copied === 'name'" class="active"
                        width="20">
                </div>
            </div>

            <!-- <div class="transaction__holder">
                <label class="transaction__label" for="wallet">WALLET ID</label>
                <input class="transaction__input" id="wallet" type="text">
            </div> -->

            <div class="transaction__holder">
                <label class="transaction__label" for="email">E-mail</label>
                <input readonly class="transaction__input" id="email" type="text"
                    [value]="selectedTransaction?.user_info?.email"
                    [class.responsive]="selectedTransaction?.user_info?.email">
                <div *ngIf="selectedTransaction?.user_info?.email" class="">
                    <img src="assets/images/content_copy_black_24dp.svg"
                        [cdkCopyToClipboard]="selectedTransaction?.user_info?.email" (click)="copyAddress('email')"
                        *ngIf="copied !== 'email'" alt="Copy" width="20">
                    <img src="assets/images/done_black_24dp (1).svg" alt="Done" *ngIf="copied === 'email'"
                        class="active" width="20">
                </div>
            </div>

            <div class="transaction__holder">
                <label class="transaction__label" for="address">ADDRESS</label>
                <input readonly class="transaction__input" id="address" type="text"
                    [value]="selectedTransaction?.user_info?.deliveryAddress"
                    [class.responsive]="selectedTransaction?.user_info?.deliveryAddress">
                <div *ngIf="selectedTransaction?.user_info?.deliveryAddress">
                    <img src="assets/images/content_copy_black_24dp.svg"
                        [cdkCopyToClipboard]="selectedTransaction?.user_info?.deliveryAddress"
                        (click)="copyAddress('address')" *ngIf="copied !== 'address'" alt="Copy" width="20">
                    <img src="assets/images/done_black_24dp (1).svg" alt="Done" *ngIf="copied === 'address'"
                        class="active" width="20">
                </div>
            </div>
            <div class="transaction__holder">
                <label class="transaction__label" for="phone">Phone Number</label>
                <input readonly class="transaction__input" id="phone" type="text"
                    [value]="selectedTransaction?.user_info?.phoneNumber"
                    [class.responsive]="selectedTransaction?.user_info?.phoneNumber">
                <div *ngIf="selectedTransaction?.user_info?.phoneNumber">
                    <img src="assets/images/content_copy_black_24dp.svg"
                        [cdkCopyToClipboard]="selectedTransaction?.user_info?.phoneNumber"
                        (click)="copyAddress('phone')" *ngIf="copied !== 'phone'" alt="Copy" width="20">
                    <img src="assets/images/done_black_24dp (1).svg" alt="Done" *ngIf="copied === 'phone'"
                        class="active" width="20">
                </div>
            </div>

            <!-- <div class="transaction__holder">
                <label class="transaction__label" for="sing">Sign up date</label>
                <input readonly class="transaction__input" id="sing" type="text">
            </div> -->
        </div>
    </div>
    <span class="transaction__close" (click)="close()"></span>
</fieldset>
