import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';

@Component({
  selector: 'xion-churn-chart-modal',
  templateUrl: './churn-chart-modal.component.html',
  styleUrls: ['./churn-chart-modal.component.scss']
})
export class ChurnChartModalComponent implements OnInit {

    // showCHart -> to fix conflict between modal plugin and
    // chart plugin. Issue with width of chart
    showCHart = false;
    revenue: Observable<any>;
    users: Observable<any>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ChurnChartModalComponent>) { }

    ngOnInit() {
        this.users = this.data.users;
        this.revenue = this.data.revenue;
        setTimeout(() => {
            this.showCHart = true;
        }, 100);
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
