<div class="custom-popup">
    <h3 class="custom-popup-title">Main Code</h3>
    <p>
        Copy and Paste this Main Code into your header or footer of your website (only once):
    </p>

    <div class="code-box-copy">
        <button class="code-box-copy__btn" (click)="copyScriptCode(srcScriptCode)" title="Copy"></button>
        <pre><code class="language-html" #srcScriptCode>&lt;script type=&quot;text/javascript&quot; src=&quot;{{scriptUrl}}&quot;&gt;&lt;/script&gt;</code></pre>
        <div *ngIf="showCopyResultScript" [@appearAnimation]>
            <span>Copied</span>
        </div>
    </div>

    <h3 class="custom-popup-title custom-popup-title-checkout">Checkout Button Code</h3>
    <p>
        Copy and Paste this Checkout Button code into your website’s source HTML:
    </p>
    <div class="code-box-copy">
        <button class="code-box-copy__btn" (click)="copyButtonCode(productCode)" title="Copy"></button>
        <pre><code class="language-html" #productCode>&lt;div productid=&quot;{{productId}}&quot; class=&quot;xion-subscription-button-wrapper&quot;&gt;&lt;/div&gt;</code></pre>
        <div *ngIf="showCopyResultProduct" [@appearAnimation]>
            <span>Copied</span>
        </div>
    </div>


    <a class="custom-popup-link" target="_blank" href="https://xionwiki.gitbook.io/xion-global/create-checkout-buttons">>> Click here for
        instructions <<</a> </div>
