import { Pipe, PipeTransform } from '@angular/core';
import { calculateSubscriptionDuration } from '../constants/helpers';
import { ISubscription } from '../interfaces/subscription.interface';

@Pipe({
  name: 'subscriptionDuration'
})
export class SubscriptionDurationPipe implements PipeTransform {
  transform(subscription:ISubscription): string {
    return  calculateSubscriptionDuration(subscription)
  }
}
