import {SwiperConfigInterface} from 'ngx-swiper-wrapper';

// List of currencies that we support converting from/to in our app
export const supportedCurrencies = ['USD', 'EUR',
    'NZD', 'RUB', 'CAD', 'PHP', 'ZAR', 'AUD', 'JPY',
    'TRY', 'HKD', 'THB', 'CZK',
    'GBP', 'MXN', 'KRW', 'ISK',
    'SGD', 'BRL', 'PLN', 'INR', 'CNY'];


export enum Currencies {
    USD = 'USD',
    EUR = 'EUR',
    NZD= 'NZD' ,
    RUB='RUB',
    CAD='CAD',
    PHP='PHP',
    ZAR='ZAR',
    AUD='AUD',
    JPY='JPY',
    TRY='TRY', HKD='HKD', THB='THB', CZK='CZK',
    GBP='GBP', MXN='MXN', KRW='KRW', ISK='ISK',
    SGD='SGD',BRL= 'BRL', PLN='PLN', INR='INR',
    CNY='CNY'
}

export const passwordStrengthColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];

export const colorSchemes = [
    {
        name: 'default',
        domain: ['#c9c9c9', '#9bc4ca', '#566f85', '#31d3d6', '#60c0ea']
    },
    {
        name: 'all',
        domain: ['#60c0ea', '#31d3d6', '#566f85', '#9bc4ca', '#0265fe']
    },
    {
        name: 'light',
        domain: ['#9bc4ca', '#566f85', '#31d3d6', '#60c0ea']
    },
    {
        name: 'highlight',
        selectable: true,
        domain: ['#60c0ea', '#31d3d6']
    },
    {
        name: 'primary',
        selectable: true,
        domain: ['#31d3d6']
    },
    {
        name: 'blue',
        selectable: true,
        domain: ['#31d3d6']
    },
    {
        name: 'light_blue',
        selectable: true,
        domain: ['#0265fe']
    },

];

export const ColorSchemes = {
    lightBlue: {
        name: 'light_blue',
        selectable: true,
        domain: ['#0265fe']
    },
    blue: {
        name: 'blue',
        selectable: true,
        domain: ['#31d3d6']
    },
    dark: {
        name: 'dark',
        domain: ['#566f85']

    }
};
export const TOKEN_NAME: string = 'xion_token';
export const REFRESH_TOKEN_NAME: string = 'xion_refresh_token';

// localstorage key for saving connector id, provider of current wallet address
export const CONNECTOR_ID: string = 'xion_connector_id';
export enum ConnectorID {
    MetaMask = 'META_MASK',
    Torus = 'TORUS',
    Authereum = 'AUTHEREUM',
    WalletConnect = 'WALLET_CONNECT',
    CustomAuth = 'CUSTOM_AUTH'
}

export const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'vertical',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
};


