<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <ng-container *ngIf="dataSource.data.length ===0">You don't have any current payments</ng-container>
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Subscription ID</th>
        <td mat-cell *matCellDef="let subscription">
                <span class="subscription-name">
                    {{subscription.id}}
                </span>
            <button class="copy-button" (click)="getAffiliateLink(subscription)">
                <svg class="svg-icon icon-copy">
                    <use xlink:href="#icon-copy"></use>
                </svg>
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef> Total </td>
    </ng-container>
    <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Subscription</th>
        <td mat-cell *matCellDef="let subscription">
                <span class="subscription-name">
                    {{subscription.title}}
                </span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
        <td mat-cell *matCellDef="let payment"> {{payment.start | unixDate}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="percentage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Percentage </th>
        <td mat-cell *matCellDef="let payment"> {{payment.percentage}} %</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Cost Column -->
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Amount of Earnings</th>
        <td mat-cell *matCellDef="let payment"> {{payment.amount | currencyConverter | async}}</td>
        <td mat-footer-cell *matFooterCellDef> {{getTotal('amount') | currencyConverter | async}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
</table>
