import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shuffle } from 'lodash';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';
import { JwtService } from '../../auth/services/jwt.service';
import { supportedCurrencies } from '../../shared/constants/config';
import {
    areasOfInterest,
    countryNames,
    currencies,
    images,
    issues,
    merchantChannelPosts,
    months,
    multiCountries,
    names,
    plot,
    products,
    retailers,
    revenue,
    single
} from '../../shared/constants/data';
import {
    Dispute,
    Distrubutor,
    Message,
    oneTimeOffer,
    Product,
    Provider,
    Retailer,
    RetailerSubscription,
    SubscriptionPost,
    Supplier,
    UserSubscription,
    WalletTransaction
} from '../../shared/models/models';

@Injectable({
    providedIn: 'root'
})
export class DummyDataService {

    constructor(private http: HttpClient, private auth: AuthService, private jwt: JwtService) {
    }

    getAllCountries() {
        return multiCountries;
    }

    getUsersFromCountries() {
        return [['Country', 'Subscriptions'], ...countryNames.map(country => [country, this.getRandomNumber(0, 12000)])];
    }

    getRetailerSubscriptions(size: number = 20) {
        const active = this.generateRetailerSubscriptions();
        const archived = this.generateRetailerSubscriptions();
        const paused = this.generateRetailerSubscriptions(2);
        const subs = { archived, paused, active };
        return of(subs);
    }

    getUserSubscriptions(size: number = 20) {
        const types = ['Daily', 'Monthly', 'Yearly'];
        const durations = ['d', 'm'];
        const stat = ['active', 'paused', 'archived'];
        const productTypes = ['single', 'multiple', 'digital'];

        const subs = Array(size).fill(null).map(() => {
            const start = this.getRandomNumber(0, areasOfInterest.length);
            const end = this.getRandomNumber(start, areasOfInterest.length);
            const interests = areasOfInterest.slice(start, end);
            return {
                id: this.generateRandomID(),
                name: `Subscription ${this.getRandomNumber(1, 500)}`,
                plan: types[Math.floor(Math.random() * types.length)],
                duration: this.getRandomNumber(2, 200) + durations[this.getRandomNumber(0, 2)],
                price: this.getRandomNumber(50, 1200),
                status: stat[this.getRandomNumber(0, 2)],
                type: this.getRandomNumber(0, 2),
                retailerInterests: interests
            };
        });
        // subs = subs.sort((a,b)=>{ if(a.>)})
        return of(subs.sort());
    }

    getPlotData() {
        return plot;
    }

    getRevenue() {
        return revenue;
    }

    getRevenuePlot() {
        return of(plot);
    }

    getPieChartData() {
        return single;
    }

    getNotifications() {
        const index = this.getRandomNumber(0, 10);
        const notifications = new Array(index).fill(null).map((i, index) => ({
            name: `Notification ${++index}`,
            value: '_' + Math.random().toString(36).substr(2, 9)
        }));
        return of(notifications);

    }

    getUsersData() {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const customers = {
            name: 'Customers',
            series: months.map(month => ({ name: month, value: this.getRandomNumber(500, 1000) }))
        };
        return [customers];
    }

    getWalletData() {
        const xsc = this.getRandomNumber(500, 10000);
        const isx = this.getRandomNumber(500, 10000);
        const total = xsc + isx;
        const result = [{ name: 'isx', value: isx }, { name: 'xsc', value: xsc }];
        return of(result);
    }

    getUserChannelSubscriptions(category: string, size = 12, retailer: boolean = false): Observable<SubscriptionPost[]> {
        const plan = ['Daily', 'Monthly', 'Weekly', 'Yearly'];
        size = retailer ? merchantChannelPosts.length : images.length;
        const subscriptions = new Array(size).fill(null).map((i, index) => {
            let product = this.getRandomSubscriptionProduct(index);
            if (retailer) product = this.getRandomRetailerProduct(index);
            return {
                name: `Subscription ${++index}`,
                id: this.generateRandomID(),
                image: product.image,
                video: this.getRandomVideo(),
                description: this.getRandomText().slice(0, 150),
                price: this.getRandomNumber(50, 900),
                discount: this.getRandomNumber(0, 2),
                type: plan[Math.floor(Math.random() * plan.length)],
                subscriptionType: product.type,
                retailer: this.getRandomRetailer(),
                oneTime: Math.random() > 0.5
            };
        });
        return of(shuffle(subscriptions));
    }

    getOffers(size = 12): Observable<oneTimeOffer[]> {
        const images = [
            'https://i.ibb.co/2MZzTBh/smoked-salmon.jpg',
            'https://i.ibb.co/Fzdw4xT/r-BVa-EVdb2d6-Acqi9-AAYc71n-T-00521.jpg',
            'https://i.ibb.co/WDTGXCP/HCO-CICVD2018-MTH12.jpg',
            'https://i.ibb.co/ky2Gywh/6385-20478-z.jpg',
            '\https://i.ibb.co/YBs1h0B/1-Litre-Bottle-Mockup.jpg',
            'https://i.ibb.co/M6pKKxc/pr079.jpg'
        ];
        const offers = new Array(images.length).fill(null).map((i, index) => {
            return {
                id: this.generateRandomID(),
                image: images[index],
                title: `One Time Offer ${++index}`,
                description: this.getRandomText().slice(0, 150),
                price: this.getRandomNumber(50, 900)
            };
        });
        return of(offers);
    }

    getDisputedSubscriptionCharts() {

        const top = new Array(3).fill(null).map((i, index) => ({
            name: `Subscription ${this.getRandomNumber(1, 20)}`,
            value: this.getRandomNumber(70, 100)
        }));

        const worst = new Array(3).fill(null).map((i, index) => ({
            name: `Subscription ${this.getRandomNumber(1, 20)}`,
            value: this.getRandomNumber(5, 50)
        }));
        return of({ top, worst });
    }

    getWallets() {
        const exchange = {
            yen: 11.1,
            eur: 15.4,
            usd: 12.3
        };
        const xscAmt = this.getRandomNumber(500, 10000);
        const isxAmt = this.getRandomNumber(500, 10000);
        const wallets = [
            [
                {
                    amount: xscAmt,
                    value: Math.floor(exchange.usd * xscAmt) + 100,
                    name: 'xsc',
                    address: '1ld3VAbWFpbGluYXRvci5uZXQiLCJ1c2VybmFtZSI6IkFwaHJvZGl0Z'
                },
                {
                    amount: xscAmt,
                    value: Math.floor(exchange.usd * xscAmt),
                    name: 'xsc1',
                    address: '1ld3VAbWFpbGluYXRvci5uZXQiLCJ1c2VybmFtZSI6IkFwaHJvZGl0Z'
                }
            ],
            {
                amount: isxAmt,
                name: 'isx',
                value: Math.floor(exchange.usd * isxAmt),
                address: '3NURGZWU4ZjgyMzY2MTgxNmZhMEZDNUY5YyIsImV4cCI6MTU1NjAwNT'
            }
        ];
        return of(wallets);
        // return of({
        //     xsc: {
        //         amount: xscAmt,
        //         value: Math.floor(exchange.usd * xscAmt),
        //         name: 'xsc',
        //         address: '1ld3VAbWFpbGluYXRvci5uZXQiLCJ1c2VybmFtZSI6IkFwaHJvZGl0Z'
        //     },
        //     isx: {
        //         amount: isxAmt,
        //         name: 'isx',
        //         value: Math.floor(exchange.usd * isxAmt),
        //         address: '1ld3VAbWFpbGluYXRvci5uZXQiLCJ1c2VybmFtZSI6IkFwaHJvZGl0Z'
        //     }
        // });
    }

    getDisputesData() {
        return revenue;
    }

    getSales() {
        const revenue = {
            name: 'Revenue',
            series: months.map(month => ({ name: month, value: this.getRandomNumber(500, 12000) }))
        };
        const sales = {
            name: 'Sales',
            series: months.map(month => ({ name: month, value: this.getRandomNumber(500, 12000) }))
        };
        return [revenue, sales];
        // return of(data);
    }


    getAreasOfInterest() {
        return of(areasOfInterest);
    }

    getCurrencies() {
        return of(currencies);
    }

    getWallet(name: string) {
        const xsc = this.getRandomNumber(500, 10000);
        const isx = this.getRandomNumber(500, 10000);
        const total = xsc + isx;
        return of({ xsc, isx, total });
    }

    getRandomNumber(min = 1, max = 100) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    getRandomStats() {
        const xsc = this.getRandomNumber(1, 10000);
        const isx = this.getRandomNumber(1, 10000);
        const total = xsc + isx;
        const stats = {
            activeSubs: this.getRandomNumber(1, 20000),
            subsSold: this.getRandomNumber(1, 20000),
            revenue: this.getRandomNumber(1, 20000),
            profit: this.getRandomNumber(1, 20000),
            profitMargin: this.getRandomNumber(),
            disputes: this.getRandomNumber(1, 200),
            lostRevenue: this.getRandomNumber(1, 500),
            recoveredRevenue: this.getRandomNumber(1, 500),
            winRate: this.getRandomNumber(),
            disputesRate: this.getRandomNumber(),
            netProfit: this.getRandomNumber(1, 200),
            totalRevenue: this.getRandomNumber(700, 5000),
            wallet: { xsc, isx, total }
        };
        return stats;
    }

    getSubscriptions(id: string) {
        const ids = ['0577748094', '5115924146', '4007156803', '0044761060', '0582575445', '2309776651', '6730054332', '4957823995', '6278362264', '3774889436'];
        const subs = ids.map((id, index) => ({ name: `Subscription ${++index}`, id }));
        if (id) {
            return subs.filter(item => item.id === id);
        }
        return subs;
    }

    getAnalytictsPageStats() {
        const pieChartData = this.getPieChartData();
        const subscriptionsData = new Array(5).fill(null).map((i, index) => ({
            name: `Subscription ${index}`,
            value: this.getRandomNumber(50, 6000)
        }));
        const wallets = this.getWalletData();
        const multiCountries = this.getAllCountries();
        const users = this.getUsersFromCountries();
        const plotData = this.getPlotData();
        const newUsers = this.getUsersData();
        const disputesData = this.getDisputesData();
        const revenueData = this.getSales();
        const revenue = this.getRevenue();
        const stats = this.getRandomStats();

        return {
            pieChartData,
            wallets,
            multiCountries,
            users,
            plotData,
            newUsers,
            disputesData,
            revenueData,
            revenue,
            stats,
            subscriptionsData
        };
    }


    getTransactions(): Observable<WalletTransaction[]> {
        const tokens = ['ISX', 'XSC'];
        const types = ['sent', 'received', 'swapped'];
        const transactions = Array(20).fill(null).map((item, index) => {
            return {
                type: types[this.getRandomNumber(0, types.length)],
                id: this.generateRandomID(),
                token: tokens[this.getRandomNumber(0, tokens.length)],
                price: this.getRandomNumber(30, 1000),
                date: this.getRandomDate(),
                currency: this.getRandomCurrency(),
                value: this.getRandomNumber(25, 2000),
                retailer: this.getRandomRetailer()

            };
        });

        return of(transactions);
    }

    getRetailers(): Observable<Array<{
        name: string,
        logo: string;
        domain: string
        interests: string[]
    }>> {
        const re = retailers.map(retailer => {
            const start = this.getRandomNumber(0, areasOfInterest.length);
            const end = this.getRandomNumber(start, areasOfInterest.length);
            const interests = areasOfInterest.slice(start, end);
            return {
                ...retailer,
                interests
            };
        });
        return of(re);
    }

    getDisputes(type: string, size: number = 30) {
        const disputes = Array(size).fill(null).map(item => this.getRandomDispute());
        return of(disputes);
    }

    getDisputedSubscriptions(type: string = 'pending', size: number = 10) {
        const disputes = Array(this.getRandomNumber(1, 20)).fill(null).map(item => {
            return {
                subscription: this.getRandomSubscription(),
                dispute: Array(this.getRandomNumber(1, 20)).fill(null).map(i => this.getRandomDispute()),
                pending: Array(this.getRandomNumber(1, 20)).fill(null).map(i => this.getRandomDispute()),
                resolved: Array(this.getRandomNumber(1, 20)).fill(null).map(i => this.getRandomDispute())
            };
        });
        return of(disputes);
    }

    getRetailerPosts(category?: string): Observable<any[]> {

        const posts = Array(30).fill(null).map((item, index) => {
            const start = this.getRandomNumber(0, areasOfInterest.length);
            const end = this.getRandomNumber(start, areasOfInterest.length);
            const interests = areasOfInterest.slice(start, end);
            const retailerId = this.generateRandomID();
            const postId = this.generateRandomID();
            return {
                id: postId,
                image: this.getRandomRetailerProduct(index).image,
                video: this.getRandomVideo(),
                text: this.getRandomText(),
                retailer: { id: 39, interests, ...retailers[this.getRandomNumber(0, retailers.length)] }
            };
        });
        return of(posts);
    }

    getUsersList(max = 5): Observable<Retailer[]> {
        const users = Array(max).fill(null).map(i => this.getRandomRetailer()).map(i => {
            return {
                ...i,
                id: 39
            };
        });
        return of(users);
    }


    public getSuppliers(): Observable<Supplier[]> {
        const suppliers = Array(10).fill(null).map((room, index) => {
            return {
                id: this.generateRandomID(),
                name: `Supplier ${++index}`,
                website: 'http://google.com',
                email: 'email@gmail.com',
                phone: '+380000000000',
                address: '221B Baker street'
            };
        });
        return of(suppliers);
    }

    public getDistrubutors(): Observable<Distrubutor[]> {
        const suppliers = Array(10).fill(null).map((room, index) => {
            return {
                id: this.generateRandomID(),
                name: `Distributor ${++index}`,
                website: 'http://google.com',
                email: 'email@gmail.com',
                phone: '+380000000000',
                address: '221B Baker street'
            };
        });
        return of(suppliers);
    }

    public getChatUsers() {
        const rooms = Array(10).fill(null).map(room => {
            return {
                id: this.generateRandomID(),
                retailer: this.getRandomRetailer(),
                user: this.getRandomRetailer()
            };
        });
        return of(rooms);
    }

    public getProducts(name = 'Product'): Observable<Product[]> {
        const images = this.getSubscriptionPreviewImages();
        const products = Array(images.length).fill(null).map((room, index) => {
            return {
                image: images[index],
                title: `${name}${++index}`,
                price: this.getRandomNumber(50, 1200),
                file: 'string'
            };
        });
        return of(products);
    }

    public getProviders(name): Observable<Provider[]> {
        const providers = Array(10).fill(null).map((room, index) => {
            return {
                id: this.generateRandomID(),
                name: `${name}${++index}`,
                website: 'http://google.com',
                email: 'email@gmail.com',
                phone: '+380000000000',
                address: '221B Baker street',
                areasOfInterst: ['Interest'],
                state: 'string',
                city: 'string',
                zip: 'string'
            };
        });
        return of(providers);
    }

    getMessages(): Observable<Message[]> {
        const messages = Array(10).fill(null).map(item => {
            return {
                text: this.getRandomText(),
                createdAt: this.getRandomDate()
            };
        });
        return of(messages);
    }

    getOneSubscription(id: string) {
        return null;
    }

    getSubscriptionPreviewImages() {
        return ['https://i.ibb.co/mt9VjDY/basil.jpg'
            , 'https://i.ibb.co/y6t2YBV/66792011-0-640x640.jpg'
            , 'https://i.ibb.co/3yCThCy/6000191271747.jpg'
            , 'https://i.ibb.co/nP6srj5/74-2.jpg'
            , 'https://i.ibb.co/JkYfzCf/raw-chicken-breast-fillet-with-spices-wooden-board-side-view-copy-space-73872-503.jpg'];
    }

    private generateRetailerSubscriptions(size: number = 20): RetailerSubscription[] {
        const types = ['Daily', 'Monthly', 'Yearly'];
        const durations = ['d', 'm'];
        return Array(size).fill(null).map(() => (
            {
                id: 31,
                name: `Subscription ${this.getRandomNumber(1, 500)}`,
                type: types[Math.floor(Math.random() * types.length)],
                plan: types[Math.floor(Math.random() * types.length)],
                duration: this.getRandomNumber(2, 200) + durations[this.getRandomNumber(0, 2)],
                price: this.getRandomNumber(50, 1200),
                date: this.getRandomDate(),
                discount: this.getRandomNumber(1, 25),
                activeCustomers: this.getRandomNumber(1, 1500),
                canceledCustomers: this.getRandomNumber(1, 1500),
                mrr: this.getRandomNumber(1, 1500),
                netRevenue: this.getRandomNumber(1, 1500)
            }
        ));
    }

    private generateRandomID(): string {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    private getRandomDate(): Date {
        return new Date(+(new Date()) - Math.floor(Math.random() * 10000000000));
    }

    private getRandomText(): string {
        return `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                 eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                 quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi
                  tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem
                  ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea
                  voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur`;
    }

    private getRandomRetailer(): Retailer {
        const start = this.getRandomNumber(0, areasOfInterest.length);
        const end = this.getRandomNumber(start, areasOfInterest.length);
        const retailerId = this.getRandomNumber(1, 92);
        const walletId = this.generateRandomID();
        const interests = areasOfInterest.slice(start, end);
        const userType = Math.random() > 0.5 ? 1 : 0;
        const retailer = {
            id: retailerId,
            walletId,
            interests,
            type: userType,
            followed: Math.random() > 0.5,
            ...retailers[this.getRandomNumber(0, retailers.length)]
        };
        return retailer;
    }

    private getRandomImage(height = 450, width = 500): string {
        return `https://source.unsplash.com/${this.getRandomNumber(height, width)}x${this.getRandomNumber(height, width)}/?nature,water`;
    }

    private getRandomName() {
        const index = this.getRandomNumber(0, names.length);
        return names[index];
    }

    private getRandomDispute(): Dispute {
        const issue = issues[this.getRandomNumber(0, issues.length)];
        const problem = { issue, message: null };
        if (issue === 'other') {
            problem.message = this.getRandomText();
        }
        return {
            id: this.generateRandomID(),
            user: {
                id: this.generateRandomID(),
                walletId: this.generateRandomID(),
                name: this.getRandomName()
            },
            happy: Math.random() > 0.5,
            problem,
            date: this.getRandomDate()
        };
    }

    private getRandomSubscription(): UserSubscription {
        const types = ['Daily', 'Monthly', 'Yearly'];
        const durations = ['d', 'm'];
        const stat = ['active', 'paused', 'archived'];
        const productTypes = ['single', 'multiple', 'digital'];
        return {
            id: this.generateRandomID(),
            name: `Subscription ${this.getRandomNumber(10, 2000)}`,
            plan: types[Math.floor(Math.random() * types.length)],
            duration: this.getRandomNumber(2, 200) + durations[this.getRandomNumber(0, 2)],
            type: productTypes[this.getRandomNumber(0, productTypes.length)],
            price: this.getRandomNumber(50, 1200),
            status: stat[this.getRandomNumber(0, 2)]
        };
    }

    private getRandomCurrency() {
        const index = this.getRandomNumber(0, supportedCurrencies.length);
        return supportedCurrencies[index];
    }

    private getRandomSubscriptionProduct(index) {
        if (index < products.length) return products [index];
        index = this.getRandomNumber(0, products.length);
        return products[index];
    }

    private getRandomVideo() {
        return Math.random() > 0.5 ? 'https://dl5.webmfiles.org/big-buck-bunny_trailer.webm' : null;
    }

    private getRandomRetailerProduct(index: number) {
        if (index < merchantChannelPosts.length) return merchantChannelPosts [index];
        index = this.getRandomNumber(0, merchantChannelPosts.length);
        return merchantChannelPosts[index];
    }
}
