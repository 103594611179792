import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'retailerFilter'
})
export class RetailerFilterPipe implements PipeTransform {

    transform(list: any[], query: string): any {
        return list ? list.filter(item => item.retailer.name.search(new RegExp(query, 'i')) > -1) : [];
    }
}
