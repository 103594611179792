<div class="balance__slider_holder notification-msg__holder">
    <div  class="swiper-container" [swiper]="config" (indexChange)="onCurrencyChange($event)">
        <div class="swiper-wrapper">
            <div *ngFor="let currency of currencies" class="swiper-slide">
                {{currency}}
            </div>
        </div>
    </div>
    <button class="next-currency">
        <svg class="svg-icon slide-arrow_bottom">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
    <button class="prev-currency">
        <svg class="svg-icon slide-arrow">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
    <div class="notification-msg">
        Rates Updated Every Hour
    </div>
</div>
