import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xion-discount-info-modal',
  templateUrl: './discount-info-modal.component.html',
  styleUrls: ['./discount-info-modal.component.scss']
})
export class DiscountInfoModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private dialogRef: MatDialogRef<DiscountInfoModalComponent>) { }
  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
