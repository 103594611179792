import { Component, Input } from '@angular/core';

@Component({
    selector: 'xion-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent {
    @Input() video: string;
}
