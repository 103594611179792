import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../../core/services/user.service';

@Pipe({
  name: 'userFilter'
})
export class UserFilterPipe implements PipeTransform {

    transform(list: IUser[], query: string): IUser[] {
        return list ? list.filter(item => item.fullname.search(new RegExp(query, 'i')) > -1) : [];
    }

}
