import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { IOffer } from '../../interfaces/offer.interface';
import { PrivateKeyModalComponent } from '../../modals/private-key-modal/private-key-modal.component';
import { IUser } from 'src/app/core/services/user.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { LoginModalComponent } from '../../modals/login-modal/login-modal.component';
import { ISubscription } from '../../interfaces/subscription.interface';

@Component({
    selector: 'xion-one-time-offer-list',
    templateUrl: './one-time-offer-list.component.html',
    styleUrls: ['./one-time-offer-list.component.scss']
})
export class OneTimeOfferListComponent implements OnInit {
    @Input() canBuy: boolean;
    @Input() user: IUser;
    @Input() subscription: ISubscription;

    configProd: SwiperConfigInterface = {
        slidesPerView: 'auto',
        keyboard: true,
        freeMode: true,
        mousewheel: true,
        observer: true,
        scrollbar: false,
        pagination: false,
        direction: 'horizontal',
        navigation: {
            nextEl: '.next-sub',
            prevEl: '.prev-sub'
        }
    };

    constructor(
        private dialog: MatDialog,
        private authService: AuthService,
        private route: ActivatedRoute,
        ) {
    }

    ngOnInit() {}

    openPrivateKeyModal(event: any) {
        event.stopPropagation();
        this.dialog
            .open(PrivateKeyModalComponent, {
                panelClass: 'popup__holder',
                data: this.user
            })
            .afterClosed()
            .subscribe(key => key);
    }
    openLoginModal() {
        this.authService.redirectUrl= this.route.snapshot.url;
        this.dialog
        .open(LoginModalComponent, {
            panelClass: 'popup__holder',
            data : {
                subscription : this.subscription,
            }
        });
    }
}
