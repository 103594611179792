import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { Observable, pipe, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AffiliateService } from '../../../core/services/affiliate.service';
import { CustomersService, ICustomer } from '../../../core/services/customers.service';
import { MetadataService } from '../../../core/services/metadata.service';
import { NotificationsService } from '../../../core/services/notifications.service';
import { ShareService } from '../../../core/services/share.service';
import { SubscriptionsService } from '../../../core/services/subscriptions.service';
import { IUser } from '../../../core/services/user.service';
import { IWallets, WalletService } from '../../../core/services/wallet.service';
import { subscriptions } from '../../constants/data';
import { mapToErrorMessage } from '../../constants/helpers';
import { ISubscription } from '../../interfaces/subscription.interface';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { ConnectModalComponent } from '../../modals/connect-modal/connect-modal.component';
import { DiscountCalculatorModalComponent } from '../../modals/discount-calculator-modal/discount-calculator-modal.component';
import { DiscountInfoModalComponent } from '../../modals/discount-info-modal/discount-info-modal.component';
import { LoginModalComponent } from '../../modals/login-modal/login-modal.component';
import { PrivateKeyModalComponent } from '../../modals/private-key-modal/private-key-modal.component';
import { Provider } from '../../models/models';

enum MediaTabs {
    PHOTO = 'photo',
    VIDEO = 'video'
}

@Component({
    selector: 'xion-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, OnDestroy {

    tabs = MediaTabs;
    activeTopTab = MediaTabs.PHOTO;
    activeTab = 'products';

    subscription$: Observable<ISubscription>;
    user$: Observable<IUser>;
    suppliers: Observable<Provider[]>;
    distributors: Observable<Provider[]>;
    wallet$: Observable<IWallets>;
    customers$: Observable<ICustomer[]>;
    customers: ICustomer[];

    subscription: ISubscription;
    user: IUser;

    activeCustomers: number = null;
    cancelledCustomers: number = null;
    pausedCustomers: number = null;
    fullscreen = false;
    hasOnetimeOffers: boolean = false;
    isOwnerOfSubscription: boolean = false;
    asideMenuOpen = false;

    private destroyedSubject = new Subject();
    private destroyed$: Observable<any> = this.destroyedSubject.asObservable();

    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private affiliateService: AffiliateService,
        private customersService: CustomersService,
        private subscriptionService: SubscriptionsService,
        private walletService: WalletService,
        private clipboard: ClipboardService,
        private notifications: NotificationsService,
        private metadata: MetadataService,
        private share: ShareService
    ) {
    }

    ngOnInit() {

        this.user$ = this.authService.currentUser;
        this.wallet$ = this.walletService.wallet$;

        this.user$.subscribe((user: IUser) => (this.user = user));


        this.subscription$ = this.loadSubscription();
        this.customers$ = this.loadMyCustomers();
        this.filterCustomers();


        this.subscription$.pipe(takeUntil(this.destroyed$)).subscribe(
            (subscription) => {
                this.subscription = subscription;
                console.log("sub-->>", subscription)
                this.updateMetadataTags(subscription);
                this.isOwnerOfSubscription = this.user && (subscription.user.idxion === this.user.idxion);
                this.hasOnetimeOffers = subscription.offers.length > 0;
            },
            (err: HttpErrorResponse) => {
                if (err.status === 403) {
                    return this.router.navigate(['subscription']);
                }
            }
        );
    }

    public closeSideMenu() {
        this.asideMenuOpen = !this.asideMenuOpen;
    }

    public openDiscountInfoModal() {
        this.dialog.open(DiscountInfoModalComponent, {
            panelClass: 'popup-blue__holder',
            data: {
                isMerchant: this.isOwnerOfSubscription
            }
        });
    }


    public openDiscountCalculatorModal() {
        this.dialog.open(DiscountCalculatorModalComponent, { panelClass: 'popup-blue__holder' });
    }

    public openConnectModal() {
        this.dialog.open(ConnectModalComponent, {
            panelClass: 'popup-blue__holder',
            data: this.subscription
        });
    }

    public shareSubscription() {
        this.clipboard.copyFromContent(document.URL);
        this.notifications.showMessage('Link copied to your clipboard !', 'Ok');
    }

    public openLoginModal() {
        this.authService.redirectUrl = this.route.snapshot.url;
        this.dialog.open(LoginModalComponent, {
            panelClass: 'popup__holder',
            data: {
                subscription: this.subscription
            }
        });
    }

    public affiliate() {
        this.clipboard.copyFromContent(`${document.URL}?aff=${this.user.idxion}`);
        this.openConfirmAffiliateModal()
            .pipe(
                switchMap(() => this.affiliateService.affiliate(this.subscription)),
                pipe(take(1))
            ).subscribe(
            () => this.notifications.showMessage('affiliate link copied to the clipboard'),
            (err) => this.notifications.showMessage(mapToErrorMessage(err))
        );
    }

    public likeSubscription() {
        this.subscription.liked = !this.subscription.liked;
        // this.subscriptionService.likeSubscription(this.subscription)
    }

    public rateSubscription({ rating }) {
        this.notifications.showMessage('Subscription rated successfully!');
        // this.subscriptionService.likeSubscription(this.subscription,+rating)
    }

    public subscribe() {
        if (!this.user) {
            this.openLoginModal();
        } else {
            this.openKeyModal()
                .pipe(switchMap((key) => this.subscriptionService.subscribe(this.subscription, key)))
                .subscribe(
                    () => {
                        this.notifications.showMessage('You successfully subscribed to this subscription');
                        this.subscription.subscribed = !this.subscription.subscribed;
                    },
                    (err) => this.handleError(err));
        }
    }

    public unsubscribe() {
        this.openKeyModal()
            .pipe(switchMap((key) => this.subscriptionService.unsubscribe(this.subscription, key)))
            .subscribe(() => {
                this.notifications.showMessage('You unsubscribed from this subscription');
                this.subscription.subscribed = !this.subscription.subscribed;
            }, (error) => this.handleError(error));
    }

    public activateSubscription() {
        this.openKeyModal()
            .pipe(switchMap((key) => this.subscriptionService.activateSubscription(this.subscription, key)))
            .subscribe(() => {
                this.notifications.showMessage('You unsubscribed from this subscription');
                this.subscription.subscribed = !this.subscription.subscribed;
            }, (error) => this.handleError(error));
    }

    public activateMerchantSubscription() {
        this.openKeyModal()
            .pipe(switchMap((key) => this.subscriptionService.activateMerchantSubscription(this.subscription, key)))
            .subscribe(() => {
                this.subscription.active = true;
                this.subscription.paused = false;
                this.notifications.showMessage('subscription successfully activated');
            }, (error) => this.handleError(error));
    }

    public pauseMerchantSubscription() {
        this.openKeyModal()
            .pipe(switchMap((key) => this.subscriptionService.pauseMerchantSubscription(this.subscription, key)))
            .subscribe(() => {
                this.subscription.active = false;
                this.subscription.paused = true;
                this.notifications.showMessage('subscription successfully paused');
            }, (error) => this.handleError(error));
    }

    public archiveMerchantSubscription() {
        this.openKeyModal()
            .pipe(switchMap((key) => this.subscriptionService.archiveMerchantSubscription(this.subscription, key)))
            .subscribe(() => {
                this.subscription.active = false;
                this.subscription.paused = false;
                this.notifications.showMessage('subscription successfully archived');
            }, (error) => this.handleError(error));
    }

    public pauseSubscription() {
        this.openKeyModal()
            .pipe(switchMap((key) => this.subscriptionService.pauseSubscription(this.subscription, key)))
            .subscribe(() => {
                this.notifications.showMessage('subscription successfully paused');
                this.subscription.paused = true;
            }, (error) => this.handleError(error));
    }

    ngOnDestroy(): void {
        this.destroyedSubject.next();
        this.destroyedSubject.complete();
    }

    private updateMetadataTags(subscription: ISubscription) {
        const metadata = {
            site: this.share.getSubscriptionLink(subscription),
            description: subscription.description,
            title: subscription.title,
            image: subscription.image
        };
        this.metadata.updateTags(metadata);
    }

    private openKeyModal() {
        return this.dialog
            .open(PrivateKeyModalComponent, { panelClass: 'popup__holder', data: this.user })
            .afterClosed()
            .pipe(take(1), filter((key) => !!key));
    }

    private loadSubscription(): Observable<ISubscription> {
        return this.route.paramMap
            .pipe(take(1))
            .pipe(
                switchMap((params) => this.subscriptionService.getSubscription(params.get('id')))
            );
    }

    private openConfirmAffiliateModal() {
        return this.dialog
            .open(ConfirmModalComponent, {
                panelClass: 'popup-blue__holder',
                data: {
                    title: 'Confirmation',
                    description: 'Add To My Affiliate Dashboard?',
                    confirm: 'Yes',
                    cancel: 'No'
                }
            })
            .afterClosed()
            .pipe(filter((confirm) => confirm));
    }


    private handleError(error: HttpErrorResponse) {
        this.notifications.showMessage(mapToErrorMessage(error));
    }


    private loadMyCustomers() {
        return this.subscription$.pipe(
            take(1),
            filter((subscription) => this.user.idxion === subscription.user.idxion),
            switchMap((subscription) => this.customersService.getSubscribers(subscription))
        );
    }

    private filterCustomers() {
        this.customers$.pipe(takeUntil(this.destroyed$))
            .subscribe(
                (customers) => {
                    this.customers = customers;
                    this.activeCustomers = this.customers.filter(customer => customer.subscribed).length;
                    this.pausedCustomers = this.customers.filter((customer) => customer.paused).length;
                    this.cancelledCustomers = this.customers.filter(customer => !customer.subscribed).length;
                },
                () => {}
            );

    }
}
