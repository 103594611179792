import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

    transform(list: any[], query: string): any {
        return list ? list.filter(item => item.name.search(new RegExp(query, 'i')) > -1) : [];
    }
}
