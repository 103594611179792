import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationsService } from '../../../core/services/notifications.service';
import { ISubscription } from '../../interfaces/subscription.interface';
import { environment } from '../../../../environments/environment';


@Component({
    selector: 'xion-connect-modal',
    templateUrl: './connect-modal.component.html',
    styleUrls: ['./connect-modal.component.scss']
})
export class ConnectModalComponent implements OnInit {

    connectForm: FormGroup;

    selectedColor = '#29a7de';
    activeTab = 'copy';

    showCodeArea = false;
    showColorPicker = false;

    styles = {
        'background-color': '#29a7de',
        color: '#ffffff'
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public subscription: ISubscription,
        private dialogRef: MatDialogRef<ConnectModalComponent>,
        private builder: FormBuilder,
        private clipboard: ClipboardService,
        private notifications: NotificationsService
    ) {
    }

    ngOnInit() {
        const url = `http://${window.location.host}/subscription/${this.subscription.id}`;
        this.connectForm = this.builder.group({
            url: [url],
            title: ['Checkout with XION'],
            logo: [true],
            codeField: [null]
        });
    }

    copySubscriptionLink() {
        const url = this.connectForm.get('url').value;
        this.clipboard.copyFromContent(url);
        this.notifications.showMessage('Link Copied to your clipboard !', 'Ok');
    }

    setColor(color) {
        this.styles[this.selectedColor] = color;
    }

    closeDialog() {
        this.dialogRef.close();
    }

    generateButtonCode() {
        const url = this.connectForm.get('url').value;
        const title = this.connectForm.get('title').value;
        const codeField = this.connectForm.get('codeField');
        const code = `<script crossorigin src="https://link.to.our.js"></script>
                    <link rel="stylesheet" href="https://link.to.default.css">
            <a data-backround=${this.styles['background-color']} data-font="${this.styles.color}" id="xion-checkout-button" style="background-color:${this.styles['background-color']};color:${this.styles['color']}" href="${url}">${title}</a>`;
        codeField.setValue(code);
        this.showCodeArea = !this.showCodeArea;
    }

    copyScript() {
        const code = this.connectForm.get('codeField').value;
        this.clipboard.copyFromContent(code);
        this.notifications.showMessage('The script has been copied to your clipboard', 'Ok');
    }

    get form() {
        return this.connectForm.controls;
    }
}
