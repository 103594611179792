import { Injectable } from '@angular/core';
// import { from } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    private XSC_PRICE = 0.02;

    // tslint:disable-next-line: no-empty
    constructor() {}

    public getXSCValue() {
        return this.XSC_PRICE;
    }

    public convert(fromToken: string = 'XSC', to: string = 'ISX', amount: number) {
        if (fromToken === 'XSC' && to === 'ISX') return amount / this.XSC_PRICE;
        switch (fromToken) {
            case 'ISX':
                return amount / this.XSC_PRICE;
            case 'XSC':
                return amount * this.XSC_PRICE;
            default:
                return amount;
        }
    }
}
