<div class="popup-blue__content discount-details">
    <div class="popup-blue">
        <div *ngIf="data.isMerchant; else elseTempl">

            <div class="details-loyalty">

                <h3 class="popup-blue__title">LOYALTY PROGRAM</h3>
                <p class="details">
                    Are you tired of using the same old discount strategies to keep customers coming back for more purchases?
                </p>
                <p class="details">
                    It’s time to <strong>ACTIVATE </strong> this brand new <strong>Loyalty Program</strong> on your “Recurring Billing” product to get LOYAL CUSTOMERS!
                </p>

                <!-- <p class="details">
                    The Discount Calculator shows the amount of money customers will save over time and what they will pay each day, week, month or year for the subscription.
                </p> -->

                <a target="_blank" href="https://xionwiki.gitbook.io/xion-global/loyalty-cashback-and-discounts/loyalty-program">>> Click here to check out how the loyalty works <<</a>
            </div>

        </div>
        <ng-template #elseTempl>
            <h3 class="popup-blue__title">LOYALTY PROGRAM</h3>
            <p class="details">
                Are you tired of using the same old discount strategies to keep customers coming back for more purchases?
            </p>
            <p class="details">
                It’s time to <strong>ACTIVATE </strong> this brand new <strong>Loyalty Program</strong> on your “Recurring Billing” product to get LOYAL CUSTOMERS!
            </p>

            <!-- <p class="details">
                The Discount Calculator shows the amount of money customers will save over time and what they will pay each day, week, month or year for the subscription.
            </p> -->

            <a target="_blank" href="https://xionwiki.gitbook.io/xion-global/loyalty-cashback-and-discounts/loyalty-program">>> Click here to check out how the loyalty works <<</a>

        </ng-template>


    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>
