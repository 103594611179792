import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { getColorScheme } from 'src/app/shared/constants/helpers';

@Component({
  selector: 'xion-dispute-chargebacks-chart',
  templateUrl: './dispute-chargebacks-chart.component.html',
  styleUrls: ['./dispute-chargebacks-chart.component.scss']
})
export class DisputeChargebacksChartComponent implements OnChanges {

  constructor() { }

  @Input() disputes :any;
  @Input() colorScheme: string = "default";
  @Input() width :number = 250;
  @Input() height :number = 250;
  @Input() xAxis : boolean;
  @Input() yAxis : boolean;
  @Input() tooltipDisabled: boolean;
  scheme = null;

  ngOnChanges() {
    this.scheme = getColorScheme(this.colorScheme)
  }
}
