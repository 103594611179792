import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { getColorScheme } from 'src/app/shared/constants/helpers';
import { IWallets, WalletService } from '../../../../core/services/wallet.service';

@Component({
    selector: 'xion-wallets-chart',
    templateUrl: './wallets-chart.component.html',
    styleUrls: ['./wallets-chart.component.scss']
})
export class WalletsChartComponent implements OnChanges, OnInit, OnDestroy {

    @Input() designatedTotal: number;
    @Input() colorScheme = 'default';
    @Input() view: number[];
    @Input() styles = '';
    @Output() selected = new EventEmitter();

    walletSub: Subscription;

    schemes = [
        {
            name: 'blue',
            selectable: true,
            domain: ['#31d3d6']
        },
        {
            name: 'light_blue',
            selectable: true,
            domain: ['#0265fe']
        }
    ];

    scheme = null;
    total = 0;
    wallets$: Observable<IWallets>;
    wallets: Array<{ name: string, value: number }>;
    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private walletSerivce: WalletService) {
        this.wallets$ = this.walletSerivce.wallet$;
    }

    ngOnInit(): void {
        // REMOVE dummy data
            this.total = 100;
            this.wallets = [
                {name: 'ISX', value: 45},
                {name: 'XSC', value: 55},
            ]
        // end
        this.wallets$.pipe(
            take(1),
            takeUntil(this.destroy$),
            catchError(err => {
                return of(err)
            })
        ).subscribe(resWallets => {
            if (resWallets.status !== 500) {
                this.wallets = [
                    { name: resWallets.ISX.name, value: resWallets.ISX.balance },
                    { name: resWallets.XSC.name, value: resWallets.XSC.balance }
                ];
                this.total = resWallets.XSC.balance + resWallets.ISX.balance;
            }
        }, err =>{
        });
    }

    ngOnChanges() {
        this.scheme = getColorScheme(this.colorScheme);
        // this.walletSub = this.wallets.subscribe(wallets=> this.total = wallets.reduce((acc, curr) => acc.value + curr.value ));
    }
    select($event) {
        this.selected.emit($event);
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
