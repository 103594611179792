import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, finalize, retryWhen, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/services/auth.service';
import { JwtService } from '../../auth/services/jwt.service';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    isRefreshingToken = false;

    constructor(
        private authService: AuthService,
        private jwtService: JwtService,
        private router: Router,
        public loadingService: LoadingService,
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<
        | HttpSentEvent
        | HttpHeaderResponse
        | HttpProgressEvent
        | HttpResponse<any>
        | HttpUserEvent<any>
        | any
    > {
        if (!request.url.includes(environment.api_url)) {
            return next.handle(request);
        }
        if (request.url.includes('authenticate')) {
            return next.handle(request);
        }
        return next.handle(this.addTokenToRequest(request, this.jwtService.getToken())).pipe(
            retryWhen((errors) =>
                errors.pipe(
                    concatMap((error, count) => {
                        if (count < 3 && (error.status === 401 || error.status === 0)) {
                            return of(error.status);
                        }
                        return throwError(error);
                    }),
                    delay(1000)
                )
            ),
            catchError((err) => this.handleError(err, request, next))
        );
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        if (token) {
            return request.clone({ setHeaders: { Authorization: `${token}` } });
        } else {
            return request.clone();
        }
    }

    private handleError(err, request, next) {
        if (err instanceof HttpErrorResponse) {
            switch ((err as HttpErrorResponse).status) {
                case 401:
                    // return this.handle401Error(request, next);
                case 404:
                    this.loadingService.hide();
                    return this.authService.logout() as any;
                    // return this.router.navigate(['404']);
                default:
                    return throwError(err);
            }
        } else {
            return throwError('Error Refreshing Token');
        }
    }
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            return this.authService.refreshToken().pipe(
                switchMap((response: any) => {
                    if (response.data) {
                        const { token, refreshToken } = response.data;
                        // this.tokenSubject.next(response.data.refreshToken);
                        this.jwtService.setToken(token);
                        this.jwtService.setRefreshToken(refreshToken);
                        return next.handle(this.addTokenToRequest(request, token));
                    }
                    return this.authService.logout() as any;
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        return this.authService.logout() as any;
                    }
                    return throwError(error);
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
        } else {
            this.isRefreshingToken = false;
            return next.handle(this.addTokenToRequest(request, this.jwtService.getToken()));
        }
    }
}
