import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChurnChartModalComponent } from '../churn-chart-modal/churn-chart-modal.component';

declare var google: any;

@Component({
  selector: 'xion-users-map-modal',
  templateUrl: './users-map-modal.component.html',
  styleUrls: ['./users-map-modal.component.scss']
})
export class UsersMapModalComponent implements OnInit, AfterViewInit {


  users: any[]

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ChurnChartModalComponent>) { }

  ngOnInit() {
    this.users = this.data.users;
  }


  ngAfterViewInit() {
      const users = this.getUsers()
      google.charts.load('current', {
        packages: ['geochart'],
        // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
        mapsApiKey: 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY'
      });
      google.charts.setOnLoadCallback(drawRegionsMap.bind(this));

      function drawRegionsMap() {
        const data = google.visualization.arrayToDataTable(users);

        const options = {
          colorAxis : {colors: ['#f7fdfd', '#86e5e7', '#94e8ea', '#31d3d6']},
          backgroundColor: '#f7f7f7',
          datalessRegionColor: '#e6e6e6',
        };

        const chart = new google.visualization.GeoChart(document.getElementById('geocharts'));
        chart.draw(data, options);
    }

  }
  closeDialog() {
    this.dialogRef.close();
  }
    private getUsers(){
        return  [['Country', 'Subscriptions'],...this.users.map(item=>[item.country,item.subscriptions])];
    }
}
