import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { Observable } from "rxjs";

import { getColorScheme } from 'src/app/shared/constants/helpers';

@Component({
  selector: "xion-churn-chart",
  templateUrl: "./churn-chart.component.html",
  styleUrls: ["./churn-chart.component.scss"]
})
export class ChurnChartComponent implements OnChanges{

  @Input() users: Observable<any>;
  @Input() revenue: Observable<any>;
  @Input() designatedTotal: number = 100;
  @Input() colorScheme: string = "default";
  @Input() height: number = 350;
  @Input() width: number = 350;
  @Input() styles: string = "";
  @Input() xAxis: boolean;
  @Input() yAxis: boolean;
  @Input() showXAxisLabel: boolean = true;
  @Input() showYAxisLabel: boolean = true;
  @Input() showGridLines: boolean = true;
  @Input() roundEdges: boolean = true;
  @Input() showDataLabel: boolean = false;
  @Input() chartTitle: boolean = false;

  @Output() selected = new EventEmitter();

  scheme = {
    name: "default",
    domain: ["#9bc4ca", "#566f85", "#31d3d6", "#60c0ea"]
  };

  select($event) {
    this.selected.emit($event);
  }

  ngOnChanges(changes: SimpleChanges) {

    this.scheme = getColorScheme(this.colorScheme)
  }
}
