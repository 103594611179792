import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/retailers/services/http.service';
import { AuthService, UserRoleEnum } from '../../auth/services/auth.service';

@Injectable({ providedIn: 'root' })
export class HomeGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private httpService: HttpService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!!this.authService.isLoggedIn) {
            if (this.authService.loggedInUser) {
                this.router.navigateByUrl(this.authService.getAuthRedirectLink());
            }
        }
        return true;
    }
}
