import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Torus from '@toruslabs/torus-embed';
import { Subject, timer } from 'rxjs';
import { Router } from '@angular/router';
import Web3 from 'web3';
import { takeUntil, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ConnectorID } from '../../shared/constants/config';
import { InvalidChainWarningModalComponent } from '../../shared/modals/invalid-chain-warning-modal/invalid-chain-warning-modal.component';
import { Web3modalService } from './web3modal.service';
import { AuthService } from './auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';

declare global {
    interface Window {
        web3;
        ethereum;
    }
}

@Injectable({
    providedIn: 'root'
})
export class Web3InstanceService {
    web3Instance: any = null;

    public torus: any = null;

    constructor(
        private dialog: MatDialog,
        private authService: AuthService,
        private router: Router,
        private loadingService: LoadingService,
        private web3ModalService: Web3modalService
    ) {}

    async init(): Promise<void> {
        if (this.web3Instance) {
            return;
        } else {
            const provider = await this.getValidProvider();
            this.web3Instance = new Web3(provider);
        }
    }

    checkChain(force = true): Promise<boolean> {
        return new Promise(async (resolve) => {
            const chainId = await this.web3Instance.eth.getChainId();
            const stopChainChecker: Subject<any> = new Subject<any>();
            const expectedChainId = this.getMainChainInformation().chainId;
            if (chainId !== expectedChainId) {
                if (force) {
                    const dialogRef = this.dialog.open(InvalidChainWarningModalComponent, {
                        disableClose: true,
                        data: this.getMainChainInformation()
                    });
                    dialogRef.componentInstance.chainId = chainId;
                    window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [this.getMainChainInformation().providerNetworkOption]
                    });
                    timer(0, 1000)
                        .pipe(
                            takeUntil(stopChainChecker),
                            tap(async () => {
                                this.loadingService.setShowLoading(false);
                                const chainId = await this.web3Instance.eth.getChainId();
                                if (dialogRef && dialogRef.componentInstance) {
                                    dialogRef.componentInstance.chainId = chainId;
                                    if (chainId === expectedChainId) {
                                        dialogRef.close();
                                        stopChainChecker.next();
                                        stopChainChecker.complete();
                                        resolve(true);
                                    }
                                } else {
                                    // dialog closed unexpectedly
                                    stopChainChecker.next();
                                    stopChainChecker.complete();
                                    resolve(false);
                                }
                            })
                        )
                        .subscribe();
                } else {
                    resolve(false);
                }
            } else {
                resolve(true);
            }
        });
    }

    clearInstance() {
        this.web3Instance = null;
        this.torus = null;
    }

    observeMetaMaskEvents() {
        window.ethereum.on('accountsChanged', (accounts) => {
            this.authService.logout();
            this.router.navigate(['/home']);
            window.location.reload();
        });
    }

    getMainChainInformation() {
        if (environment.mainChainId === 'xdai') {
            return {
                chainId: 100,
                providerNetworkOption: {
                    chainId: '0x64',
                    chainName: 'xDAI Chain',
                    nativeCurrency: {
                        name: 'xDAI',
                        symbol: 'xDAI',
                        decimals: 18
                    },
                    rpcUrls: ['https://rpc.gnosischain.com/'],
                    blockExplorerUrls: ['https://blockscout.com/poa/xdai/']
                }
            };
        } else if (environment.mainChainId === 'sokol') {
            return {
                chainId: 77,
                providerNetworkOption: {
                    chainId: '0x4d',
                    chainName: 'Sokol',
                    nativeCurrency: {
                        name: 'xDAI',
                        symbol: 'xDAI',
                        decimals: 18
                    },
                    rpcUrls: ['https://sokol.poa.network/'],
                    blockExplorerUrls: ['https://blockscout.com/poa/xdai/']
                }
            };
        } else {
            return {
                chainId: 100,
                providerNetworkOption: {
                    chainId: '0x64',
                    chainName: 'xDAI Chain',
                    nativeCurrency: {
                        name: 'xDAI',
                        symbol: 'xDAI',
                        decimals: 18
                    },
                    rpcUrls: ['https://rpc.gnosischain.com/'],
                    blockExplorerUrls: ['https://blockscout.com/poa/xdai/']
                }
            };
        }
    }

    public async getValidProvider(): Promise<any> {
        const connectorId: ConnectorID = this.web3ModalService.getConnectorId();
        if (connectorId === ConnectorID.MetaMask) {
            return window.ethereum;
        } else if (connectorId === ConnectorID.Torus) {
            await this.torusInit();
            return this.torus.provider;
        } else if (connectorId === ConnectorID.WalletConnect) {
            return await this.web3ModalService.getProvier();
            // TODO: return valid provider
            // return window.ethereum;
        } else if (connectorId === ConnectorID.Authereum) {
            // TODO: return valid provider
            return window.ethereum;
        } else if (connectorId === ConnectorID.CustomAuth) {
            await this.torusInit();
            return this.torus.provider;
        } else {
            return window.ethereum;
        }
    }

    public async torusInit() {
        this.torus = new Torus({ buttonPosition: 'top-right' as any });
        await this.torus.init({
            showTorusButton: false,
            network: {
                host: this.getMainChainInformation().providerNetworkOption.rpcUrls,
                chainId: this.getMainChainInformation().providerNetworkOption.chainId,
                networkName: 'xDai'
            }
        });
    }
}
