import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../retailers/services/http.service';

declare global {
    interface Window {$applozic}
}

@Injectable({
    providedIn: 'root'
})

export class ApplozicService {
    constructor(private auth: AuthService,
                private http: HttpService) {}

    public async init() {
        if (this.auth.loggedInUser.walletAddress) {
            const userData = await this.http.getUserByWallet(this.auth.loggedInUser.walletAddress).toPromise();
            window.$applozic.fn.applozic({
                baseUrl : 'https://apps.applozic.com',
                appId: environment.applozicAppID, //Get your application key from https://www.applozic.com
                userId: this.auth.loggedInUser.walletAddress, //Logged in user's id, a unique identifier for user
                userName: (userData.firstName && userData.lastName) ? userData.firstName + ' ' + userData.lastName : '', //User's display name
                imageLink: '', //User's profile picture url
                email: '', //optional
                contactNumber: '', //optional, pass with internationl code eg: +13109097458
                desktopNotification: true,
                source: '1', // optional, WEB(1),DESKTOP_BROWSER(5), MOBILE_BROWSER(6)
                customFileUrl:'https://googleupload.applozic.com', //optional,  google cloud file upload url
                genereateCloudFileUrl: "https://googleupload.applozic.com/files/url?key={key}", //optional,  generate viewable link for a file incase of file upload on google cloud
                notificationIconLink: 'https://www.applozic.com/favicon.ico', //Icon to show in desktop notification, replace with your icon
                authenticationTypeId: 1, //1 for password verification from Applozic server and 0 for access Token verification from your server
                notificationSoundLink : "../audio/notification_tone.mp3",
                accessToken: environment.applozicPass, //optional, leave it blank for testing purpose, read this if you want to add additional security by verifying password from your server https://www.applozic.com/docs/configuration.html#access-token-url
                locShare: true,
                googleApiKey: "AIzaSyDKfWHzu9X7Z2hByeW4RRFJrD9SizOzZt4", // your project google api key
                googleMapScriptLoaded: false, // true if your app already loaded google maps script
                //   mapStaticAPIkey: "AIzaSyCWRScTDtbt8tlXDr6hiceCsU83aS2UuZw",
                // video: true,
                //   emojilibrary: true, // true if you want to load emoticons in chat
                fileupload : "googleServer", // awsS3Server  or googleCloud or googleServer or customStorage,
                autoTypeSearchEnabled: true, // set to false if you don't want to allow sending message to user who is not in the contact list
                loadOwnContacts: false, //set to true if you want to populate your own contact list (see Step 4 for reference)
                olStatus: true, //set to true for displaying a green dot in chat screen for users who are online
                onInit: (response, data) => {
                    if (response === "success") {
                        window.$applozic('loadTab', '');
                        // login successful, perform your actions if any, for example: load contacts, getting unread message count, etc
                    } else {
                        // error in user login/register (you can hide chat button or refresh page)
                    }
                },
                contactDisplayName: (otherUserId) => {
                    //return the display name of the user from your application code based on userId.
                    return "";
                },
                contactDisplayImage: (otherUserId) => {
                    //return the display image url of the user from your application code based on userId.
                    return "";
                },
                onTabClicked: (response) => {
                    // write your logic to execute task on tab load
                    //   object response =  {
                    //    tabId : userId or groupId,
                    //    isGroup : 'tab is group or not'
                    //  }
                }
            });
        }
    }
}
