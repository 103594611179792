import { Pipe, PipeTransform } from '@angular/core';
import { chains } from './chains';

@Pipe({
    name: 'chainName'
})
export class ChainNamePipe implements PipeTransform {

    transform(chainId: number): string {
        const chain = chains.find(x => x.chainId === chainId);
        return chain ? chain.name : 'Current Network';
    }

}
