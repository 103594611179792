import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { MerchantGuard } from './core/guards/merchant-guard.service';
import { UserGuard } from './core/guards/user.guard';
import { HomePageComponent } from './shared/pages/home/home-page.component';
import { NotFoundComponent } from './shared/pages/notfound/notfound.component';
import { OfferPreviewComponent } from './shared/pages/one-time-offer-preview/offer-preview.component';
import { SubscriptionComponent } from './shared/pages/subscription/subscription.component';
import { TermsComponent } from './shared/pages/terms/terms.component';
import { HomeGuard } from './core/guards/home.guard';
// import { CredentialsComponent } from './shared/pages/credentials/credentials.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    {
        path: '',
        loadChildren: '../app/auth/auth.module#AuthModule'
    },
    {
        path: 'wallet',
        canActivate: [AuthGuard],
        loadChildren: '../app/wallets/wallets.module#WalletsModule'
    },
    {
        path: 'user',
        canActivate: [AuthGuard, UserGuard],
        loadChildren: '../app/users/users.module#UsersModule'
    },
    {
        path: 'merchant',
        canActivate: [AuthGuard],
        loadChildren: '../app/retailers/retailers.module#RetailersModule'
    },
    {
        path: 'home',
        canActivate: [HomeGuard],
        component: HomePageComponent
    },
    {
        path: 'subscription/:id',
        component: SubscriptionComponent
    },
    {
        path: 'one-time-offer/:id',
        component: OfferPreviewComponent
    },
    {
        path: 'terms',
        component: TermsComponent
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
