

<!--<div class="notification">-->
<!--Select user to chat -->
<!--</div>-->
<ul class="message-list">
    <!-- <li class="message-list__item">
        <div class="user__picture_holder" (click)="toggleUserDetails()">
            <svg class="svg-icon no-picture">
                <use xlink:href="#icon-user"></use>
            </svg>
        </div>
        <p class="message-list__description">
            <strong class="message-list__title">Subscription</strong>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor sit ipsa officiis fugit, nihil
            cum distinctio quae nemo eius esse quia fugiat ullam odit autem iusto vitae corrupti consectetur
            tenetur!</p>
        <div class="message-list__date">December 25th</div>
    </li> -->

    <ng-container *ngFor="let message of messages">
        <li class="message-list__item" [class.message-list__item_right]="message.senderId==user.idxion">
            <div class="user__picture_holder"  *ngIf="user.idxion !== message.senderId" (click)="toggleUserDetails()">
                <svg class="svg-icon no-picture">
                    <use xlink:href="#icon-user"></use>
                </svg>
            </div>
            <div class="user__picture_holder"  *ngIf="user.idxion == message.senderId">
                <svg class="svg-icon no-picture">
                    <use xlink:href="#icon-user"></use>
                </svg>
            </div>
            <ng-container *ngFor="let part of message.parts">
                <p class="message-list__description" *ngIf="part.partType === 'inline'; else mediaPart">
                    {{part.payload.content}}
                </p>
                <ng-template #mediaPart>
                    <div class="download__holder media__holder" *ngIf="part.payload.type.indexOf('image') === -1 && part.payload.type.indexOf('video') === -1; else media">
                        <a [href]="part.payload._downloadURL" class="post__actions"
                            *ngIf="part.payload._downloadURL">
                            {{part.payload.name}}
                            <svg class="svg-icon icon-download">
                                <use xlink:href="#icon-download"></use>
                            </svg>
                        </a>
                    </div>
                    <ng-template #media>
                        <div class="media__holder" *ngIf="part.payload._downloadURL">
                            <img class="image"
                                [src]="part.payload._downloadURL"
                                [alt]="part.payload.name"
                                *ngIf="part.payload.type.indexOf('image') !== -1">
                            <xion-video-player class="player-component"
                                *ngIf="part.payload.type.indexOf('video') !== -1"
                                [video]="part.payload._downloadURL"></xion-video-player>
                        </div>
                    </ng-template>
                </ng-template>
            </ng-container>

            <div class="message-list__date">{{message.createdAt | date}}</div>
        </li>
    </ng-container>
</ul>
