import { Injectable } from '@angular/core';
import Torus from '@toruslabs/torus-embed';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Authereum from 'authereum';
import { CONNECTOR_ID, ConnectorID } from '../../shared/constants/config';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class Web3modalService {
    provider:any;
    getConnectorId(): ConnectorID {
        return localStorage.getItem(CONNECTOR_ID) as ConnectorID;
    }
    async getProvier():Promise<any> {
        if(!this.provider){
          await this.login();
        }
       return this.provider
    } 
    saveConnectorId(provider?: any): void {       
        this.provider = provider;
        if (!provider) {
            localStorage.setItem(CONNECTOR_ID, ConnectorID.CustomAuth);
        } else if (provider.isMetaMask) {
            localStorage.setItem(CONNECTOR_ID, ConnectorID.MetaMask);
        } else if (provider.isTorus) {
            localStorage.setItem(CONNECTOR_ID, ConnectorID.Torus);
        } else if(provider.isWalletConnect){
            localStorage.setItem(CONNECTOR_ID, ConnectorID.WalletConnect);
        } else {
            return;
        }
    }

    login(): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            const customWindow = window as any;
            const Web3Modal = customWindow.Web3Modal.default;
            const providerOptions = {              
                walletconnect: {
                  package: WalletConnectProvider,
                  options: { 
                    infuraId: environment.infraID,
                    rpc: {
                      [environment.chainId]: environment.chainRpcURL,
                    },         
                    chainId: environment.chainId,            
                  }, // TODO: add chainId - required
                  
                }                
            };           
            let web3Modal;
            web3Modal = new Web3Modal({ cacheProvider: true, providerOptions });
            if (!$(".web3modal-provider-container:contains('RECOMMENDED')").length) {
                $(".web3modal-provider-name:contains('Torus')")
                    .parent()
                    .prepend("<strong class='recommand-wallet'>RECOMMENDED WALLET</strong><br>");
            }
            let that = this;

           web3Modal
                .connect()
                .then((provider) => {                   
                    that.saveConnectorId(provider);
                    if (provider.selectedAddress) {
                        resolve(provider.selectedAddress);
                    } else if (provider.accounts.length) {
                        resolve(provider.accounts[0]);
                    } else {
                        provider.on('accountsChanged', (accounts: string[]) => {
                            resolve(accounts[0]);
                        });

                        provider.on('chainChanged', (chainId: number) => {
                            console.log(chainId);
                        });

                        provider.on('connect', (info: { chainId: number }) => {
                            console.log(info);
                        });

                        provider.on('disconnect', (error: { code: number; message: string }) => {
                            console.log(error);
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                    reject(e);
                });
        });
    }
}
