import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { Observable, Subject } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { mapResponseToData } from '../../shared/constants/helpers';
import { ISubscription } from '../../shared/interfaces/subscription.interface';

export interface IAffiliateSubscription {
    id: number;
    title: string;
    start: number;
    percentage: number;
    amount: number;
}

interface IAffiliatePayment {
    amount: number;
    date: number;
}

export interface IAffiliateStats {
    lifetime: number;
    today: number;
    seven: number;
    thirty: number;
    lastdays: IAffiliatePayment[];
    total: number;
    subscriptions: IAffiliateSubscription[];
}

const cacheBuster$ = new Subject<void>();
const { MAX_CACHE_TIME } = environment;

@Injectable({
    providedIn: 'root'
})
export class AffiliateService {
    private AFFILIATE = `${environment.api_url}/users/affiliate`;

    constructor(private http: HttpClient) {}

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    affiliate(subscription: ISubscription) {
        const URL = `${this.AFFILIATE}/${subscription.id}`;
        return this.http.post(URL, subscription).pipe(pluck('data'));
        // return this.http.post(URL, subscription).pipe(map(mapResponseToData));
    }

    @Cacheable({
        maxAge: MAX_CACHE_TIME
    })
    getAffiliateSats(): Observable<IAffiliateStats> {
        return this.http.get(this.AFFILIATE).pipe(pluck('data'));
        // return this.http.get(this.AFFILIATE).pipe(map(mapResponseToData));
    }
}
