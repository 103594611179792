import { Inject, Injectable } from '@angular/core';
import { WEB3 } from './web3.injector';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { subscriptionsContractABI, tokenContractABI } from './ABI';
import { environment } from '../../../environments/environment';
import { AbiItem } from 'web3-utils';
import { Irsv } from '../interfaces/subscription.interface';

@Injectable({
    providedIn: 'root'
})

export class Web3Service {
    public subscriptionsContract: Contract | null = null;
    public tokenContract: Contract | null = null;
    constructor(@Inject(WEB3) public web3: Web3) {}

    public _init(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (this.web3.currentProvider && this.web3.currentProvider['isMetaMask']) {
                if ('enable' in (this.web3.currentProvider as object)) {
                    const accounts = await this.web3.currentProvider['enable']();
                    if (accounts && accounts[0]) {
                        this.web3.eth.defaultAccount = accounts[0];
                        if (!this.tokenContract) {
                            this.tokenContract = this.getContract(tokenContractABI, environment.tokenContractAddress);
                        }
                        resolve(this.web3.eth.defaultAccount);
                    } else {
                        reject();
                    }
                }
            }
        });
    }

    public pauseSubscription(options: any[], cb: (err: Error, txHash: string)=>void): Promise<any> {
        return this._request(contractMethods.PAUSE_SUBSCRIPTION, options, true, cb);
    }

    public balanceOf(walletAddress: string): Promise<any> {
        if (!this.tokenContract) {
            this.tokenContract = this.getContract(tokenContractABI, environment.tokenContractAddress);
        }
        const method = 'balanceOf';
        return this.tokenContract.methods[method](walletAddress).call();
    }

    public getMessageForSign(allowed: boolean): Promise<Irsv> {
        return new Promise<Irsv>(async (resolve, reject) => {
            const currentAccount = this.web3.eth.defaultAccount;
            const nonce = await this.tokenContract.methods['nonces'](currentAccount).call();
            const expiry = 0;
            const currency = 'Dai Stablecoin';
            const chain_id = environment.chainId;

            const message = {
                holder: currentAccount,
                spender: environment.subscriptionsContractAddress,
                nonce,
                expiry,
                allowed
            };
            const typedData = JSON.stringify({
                types: {
                    EIP712Domain: [{
                        name: 'name',
                        type: 'string'
                    },
                        {
                            name: 'version',
                            type: 'string'
                        },
                        {
                            name: 'chainId',
                            type: 'uint256'
                        },
                        {
                            name: 'verifyingContract',
                            type: 'address'
                        },
                    ],
                    Permit: [{
                        name: 'holder',
                        type: 'address'
                    },
                        {
                            name: 'spender',
                            type: 'address'
                        },
                        {
                            name: 'nonce',
                            type: 'uint256'
                        },
                        {
                            name: 'expiry',
                            type: 'uint256'
                        },
                        {
                            name: 'allowed',
                            type: 'bool'
                        },
                    ],
                },
                primaryType: 'Permit',
                domain: {
                    name: currency,
                    version: '1',
                    chainId: chain_id,
                    verifyingContract: environment.tokenContractAddress
                },
                message
            });
            this.web3.currentProvider['sendAsync'](
                {
                    method: "eth_signTypedData_v3",
                    params: [currentAccount, typedData],
                    from: currentAccount
                }, (err, result) => {
                    if (err) reject(err);
                    if (result && result.result) {
                        const signature = result.result.substring(2);
                        const r = "0x" + signature.substring(0, 64);
                        const s = "0x" + signature.substring(64, 128);
                        const v = parseInt(signature.substring(128, 130), 16);
                        resolve({r, s, 'v': this.web3.utils.toHex(v)});
                    }
                });
        });
    }

    private _request(method: string = '', options?: any[], isSend?: boolean, cb?: (err: Error, txHash: string)=>void): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.subscriptionsContract) {
                this.subscriptionsContract = this.getContract(subscriptionsContractABI, environment.subscriptionsContractAddress);
            }
            const caller = !options ?
                this.subscriptionsContract.methods[method]() :
                this.subscriptionsContract.methods[method](...options);
            const requestPromise = isSend ?
                caller.send(this._getTxObject(), cb ? cb : () => {}) :
                caller.call();
            requestPromise.then((res) => {
                resolve(res);
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    private _getTxObject(): {from: string | null} {
        return { from: this.web3.eth.defaultAccount }
    }

    private getContract(abi: AbiItem[] | AbiItem, contractAddress: string): Contract {
        return new this.web3.eth.Contract(abi, contractAddress);
    }
}
export enum contractMethods {
    PAUSE_SUBSCRIPTION = 'pauseSubscription'
}
