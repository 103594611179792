<div class="subscriptions__desc">
    <div>
        <img class="loyalty-img" *ngIf="subscription.productLoyalty=='loyalty'" src="assets/images/loyalty.svg"
            (click)="imageClick(subscription)" alt="---" width="20px">

        <div *ngIf="subscription.productLoyalty=='loyalty'" class="subscriptions__image"
            style="position: relative;top: -15px;">

            <img [src]="subscription.productImageUrl ? subscription.productImageUrl : 'assets/images/icon_subscription.png'"
                (click)="imageClick(subscription)" alt="">
        </div>
        <div *ngIf="subscription.productLoyalty=='unloyalty'" class="subscriptions__image">

            <img [src]="subscription.productImageUrl ? subscription.productImageUrl : 'assets/images/icon_subscription.png'"
                (click)="imageClick(subscription)" alt="">
        </div>
    </div>

    <fieldset class="fieldset">
        <legend class="legend">{{subscription.productName}}</legend>
        <div class="subscriptions__row">
            <div class="subscriptions__item">
                <div matTooltip="Price" matTooltipPosition="above"
                    class="subscriptions__button subscriptions__button-width">${{(subscription.billingType ===
                    BillingTypeEnum.single) ? ((subscription.productPrice + subscription.shippingPrice )|number:'.2-2' )
                    : ((subscription.productPrice + subscription.shippingPrice )|number:'.2-2') +' / '+
                    subscription.billingInterval}}</div>

                <ng-container *ngIf="subscription.billingType === BillingTypeEnum.single; else recurringProductInfo">
                    <div class="subscriptions__button subscriptions__button-width">Single Billing Product</div>
                </ng-container>
                <ng-template #recurringProductInfo>
                    <div matTooltip="Billing date" matTooltipPosition="above" class="subscriptions__button">
                        {{(subscription.billingType === BillingTypeEnum.single || subscription.productStatus ===
                        StatusEnum.archive || subscription.status === StatusEnum.unsubscribe ||
                        (subscription.billingType === BillingTypeEnum.recurring && subscription.status ===
                        StatusEnum.pending)) ? 'N/A' : subscription.renewalDate}}</div>
                    <div matTooltip="Number of payments" matTooltipPosition="above" class="subscriptions__button">
                        {{subscription.paymentsLeft ? subscription.status === StatusEnum.pending || subscription.status
                        === StatusEnum.unsubscribe ? 'N/A' : (subscription.paymentsLeft + (subscription.paymentsLeft > 1
                        ? ' payments' : ' payment')) : subscription.billingType === BillingTypeEnum.single ? 'N/A' :
                        subscription.billingCycleType === 'custom' ? 'N/A' : 'ONGOING'}}</div>
                </ng-template>
                <button
                    class="subscriptions__button subscriptions__button-width subscriptions__button-blue action-mobile-hide"
                    (click)="openTransactionsModal(subscription)">VIEW TRANSACTIONS
                </button>
            </div>
            <div class="subscriptions__item subscriptions__item-block">
                <div class="subscriptions__button subscriptions__button-width">{{subscription.category}}</div>
                <div class="subscriptions__button subscriptions__button-width">{{subscription.companyName ?
                    subscription.companyName : 'Anonymous'}}</div>
                <button (click)="onDownloadTransactions()"
                    class="subscriptions__button subscriptions__button-width subscriptions__button-blue action-mobile-hide">Download
                    all transactions</button>
            </div>
            <div class="subscriptions__item subscriptions__item-block subscriptions__item-action"
                *ngIf="subscription.billingType !== BillingTypeEnum.single">
                <div class="subscriptions__button subscriptions__button-width status-btn"><span
                        class="subscription_status"
                        [class]='"subscription_status-" + subscription.status'></span>{{(subscription.billingType ===
                    BillingTypeEnum.single) ? 'PAID' :
                    ((subscription.status === StatusEnum.unsubscribe || subscription.status === StatusEnum.pause) ?
                    subscription.status + 'd' : subscription.status)}}</div>
            </div>
            <div class="subscriptions__item subscriptions__item-block subscriptions__item-action">
                <button
                    class="subscriptions__button subscriptions__button-width subscriptions__button-blue action-mobile-show"
                    (click)="openTransactionsModal(subscription)">VIEW TRANSACTIONS
                </button>
                <button (click)="onDownloadTransactions()"
                    class="subscriptions__button subscriptions__button-width subscriptions__button-blue action-mobile-show">Download
                    all transactions</button>
                <ng-container *ngIf="subscription.billingType !== BillingTypeEnum.single">

                    <button class="subscriptions__button subscriptions__button-blue"
                        [class.subscriptions__button-disabled]="subscription.buttonStates.activeStatuses.activateBlocked || subscription.status === StatusEnum.pending"
                        *ngIf="subscription.buttonStates.activeStatuses.activateShow"
                        (click)="openStatusChangeModal(activeParams)">
                        ACTIVATE
                    </button>
                    <button class="subscriptions__button subscriptions__button-blue"
                        [class.subscriptions__button-disabled]="subscription.buttonStates.pauseStatuses.pauseBlocked || subscription.status === StatusEnum.pending"
                        *ngIf="subscription.buttonStates.pauseStatuses.pauseShow"
                        (click)="openStatusChangeModal(pauseParams)">PAUSE
                    </button>
                    <button class="subscriptions__button subscriptions__button-blue"
                        [class.subscriptions__button-disabled]="subscription.buttonStates.unsubscribeStatuses.unsubscribeBlocked || subscription.status === StatusEnum.pending"
                        *ngIf="subscription.buttonStates.unsubscribeStatuses.unsubscribeShow"
                        (click)="openStatusChangeModal(unsubscribeParams)">UNSUBSCRIBE
                    </button>
                    <button class="subscriptions__button subscriptions__button-blue"
                        [class.subscriptions__button-disabled]="subscription.buttonStates.subscribeStatuses.subscribeBlocked || subscription.status === StatusEnum.pending"
                        *ngIf="subscription.buttonStates.subscribeStatuses.subscribeShow"
                        (click)="openStatusChangeModal(subscribeParams)">SUBSCRIBE
                    </button>
                </ng-container>
            </div>
        </div>
        <!-- <div class="subscriptions__rate">
            <div class="subsctiptions__rate-title">RATE MERCHANT</div>
            <div class="subscriptions__rate-list">
                <div *ngFor="let rate of ratingArray">
                    <svg class="svg-icon icon-star"
                         *ngIf="subscription.rating && subscription.rating >= rate else emptyStar"
                         [class.rating-star]="!subscription.rating"
                         (click)="changeRating(subscription, rate)">
                        <use xlink:href="#star-full"></use>
                    </svg>
                    <ng-template #emptyStar>
                        <svg class="svg-icon icon-star"
                             [class.rating-star]="!subscription.rating"
                             (click)="changeRating(subscription, rate)">
                            <use xlink:href="#star"></use>
                        </svg>
                    </ng-template>
                </div>
            </div>
        </div> -->
    </fieldset>
</div>
