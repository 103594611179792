import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';
import { IUser } from 'src/app/core/services/user.service';
import { AsideService } from '../../services/aside.service';
import { UploadLogoModalComponent } from '../../modals/upload-logo-modal/upload-logo-modal.component';

@Component({
    selector: 'xion-aside-menu',
    templateUrl: './aside-menu.component.html',
    styleUrls: ['./aside-menu.component.scss']
})
export class AsideMenuComponent implements OnInit {
    @Input() user: Observable<IUser>;

    constructor(
        private router: Router,
        public asideService: AsideService,
        private auth: AuthService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {}

    openUploadLogoModal() {
        this.dialog.open(UploadLogoModalComponent, { panelClass: 'popup__holder' });
    }

    logout() {
        this.auth.logout();
        this.router.navigate(['/home']);
    }

    openHelp(): void {
        window.open('https://discord.gg/QfKm5jsq6d', '_blank').focus();
    }
}
