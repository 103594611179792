import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'xion-chart-modal',
  templateUrl: './chart-modal.component.html',
  styleUrls: ['./chart-modal.component.scss']
})
export class ChartModalComponent implements OnInit {

  revenue: Observable<any>;
  users: Observable<any>;
  className : string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private dialogRef:MatDialogRef<ChartModalComponent>) { }

  ngOnInit() {
    this.revenue = this.data.revenue;
    this.users = this.data.users;
    this.users.subscribe(d=>d);
    this.className = this.data.className
  }
  closeDialog(){
    this.dialogRef.close()
  }
}
