import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import Channel from 'pusher-js/types/src/core/channels/channel';
import { environment } from '../../../environments/environment';
import { Web3Service } from './web3.service';
import { AuthService } from '../../auth/services/auth.service';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class PusherService {
    public channel: Channel;
    public pusher: Pusher = new Pusher(environment.pusherKey, environment.pusherConfig);
    public channelName: string = '';

    public presence: any = null;

    constructor(private web3Service: Web3Service, private auth: AuthService) {
        // this.channel = this.getChannel();
        this.auth.currentUser.pipe(first()).subscribe(user => {
            this.channel = this.getUserChannel(user);
        });
        // this.presence = this.getPresence();
    }

    public getUserChannel(user: any) {
        return this.pusher.subscribe(`private-${user.userId}`);
    }

    public navigate(data: any) {
        this.channel.trigger(Events.OPEN_WALLET, data);
    }

    public getPresence(cb: (products: Product[]) => void) {

        const id = this.getWalletIdentifier();
        this.presence = this.pusher.subscribe(`presence-${id}-platform`);


        this.presence.bind('pusher:subscription_succeeded', this.handleConnectionChange.bind(this, cb));
        this.presence.bind('pusher:member_removed', this.handleConnectionChange.bind(this, cb));
        this.presence.bind('pusher:member_added', this.handleConnectionChange.bind(this, cb));

        return this.presence;
    }

    private getWalletIdentifier(): string {
        return this.web3Service.web3.eth.defaultAccount.substr(this.web3Service.web3.eth.defaultAccount.length - 8).toLowerCase();
    }

    private getChannel(): Channel {
        const id = this.getWalletIdentifier();
        this.channelName = `private-${id}`;
        this.channel = this.pusher.subscribe(this.channelName);
        return this.channel;
    }

    private handleConnectionChange(cb: (products: Product[]) => void) {
        if (this.presence.members.count === 1) {

            this.channel.bind('client-transaction', products => cb(products));
        }
    }

    // private createTransactions(products: Product[]): void {
    //     this.web3Service.tokenContract.methods.balanceOf(this.web3Service.web3.eth.defaultAccount).call()
    //         .then(async (bal: string) => {
    //             // if (bal < total) return;
    //             const rsv = await this.web3Service.getMessageForSign(true);
    //             console.log('!!!!!!!!!!!!!!!!rsv', rsv);
    //             // const productsForSend = products.map(product => { return  {productID: product.productID}});
    //             // console.log('productsForSend', productsForSend);
    //             // if (productsForSend && productsForSend.length) {
    //             //     await axios.post(apiUrl + '/subscriptions', {
    //             //         walletAddress: this.currentAccount,
    //             //         products: productsForSend
    //             //     });
    //             //     this.clearOrder()
    //             //
    //             // }
    //         });
    // }
}

export interface Product {
    productID: number
    productName: string
    productPrice: number
    billingType: string;
    billingInterval: string;
    createdAt: string;
}

export enum WalletRoutes {
    TOP_UP = '/top-up',
    SEND = '/send',
    HOME = '/home',
    WITHDRAW = '/withdraw',
    PAUSE = '/pause-subscription',
    CHECKOUT = '/order',
    ORDER_SUMMARY = '/checkout-products',
    LOYALTY_UPGRADE = '/loyalty-upgrade',
}

export enum Events {
    OPEN_WALLET = 'client-checkout',
    ADD_PRODUCT = 'client-add-product',
    REFRESH_ORDER = 'client-refresh-order',
}
