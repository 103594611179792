import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AsideService } from 'src/app/shared/services/aside.service';
import { MetadataService } from '../services/metadata.service';

@Injectable({ providedIn: 'root' })
export class AsideMenuGuard implements CanActivateChild {
    constructor(
        public asideService: AsideService,
        public metadataService: MetadataService
    ) {}
    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>   {
        if (route.data) {
            this.metadataService.pageData$.next(route.data);
        }
        if (this.asideService.isOpen) this.asideService.toggleAside();
        return true;
    }
}
