import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InterestsService {
    INTERESTS = [
        'NFTs',
        'Automotive & Transport',
        'Agriculture',
        'Baby Products',
        'Beauty',
        'Books',
        'Business Products & Services',
        'Camera & Photo',
        'CBD & Hemp',
        'Cell Phones',
        'Clothing & Accessories',
        'Collectible Coins',
        'Conservation',
        'Cooking & Catering',
        'Electronics',
        'Entrepreneur',
        'Events',
        'Fashion Jewelry',
        'Fitness',
        'Fine Jewelry',
        'Fine Art',
        'Grocery & Gourmet Food',
        'Handmade',
        'Health & Personal Care',
        'Historical & Advertising Collectibles',
        'Home & Garden',
        'Industrial & Scientific',
        'Legal Services',
        'Luggage & Travel',
        'Materials',
        'Manufacturing & Construction',
        'Media',
        'Music',
        'Newspapers & Magazines',
        'Office Products',
        'Outdoors',
        'Professional Services',
        'Pet Products & Services',
        'Rental Services',
        'Shoes, Handbags & Sunglasses',
        'Sports',
        'Telecommunications & Computing',
        'Tools & Home Improvement',
        'Toys & Games',
        'Movies',
        'Video Games & Video Game Consoles',
        'Watches'
    ];

    getAreasOfInterest(): Observable<string[]> {
        return of(this.INTERESTS).pipe(take(1));
    }

    getAllAreasOfInterest(): Observable<string[]> {
        let updatedInterest = this.INTERESTS.slice(0);
        updatedInterest.unshift('All');
        return of(updatedInterest).pipe(take(1));
    }
}
