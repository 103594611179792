import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IApiResponse } from '../../shared/models/models';

export enum GenderOptions {
    MALE = 1,
    FEMALE = 0
}

export interface ISignatory {
    iduserkycsign: number | string;
    sign_address: string;
    sign_bday: any;
    sign_email: string;
    sign_image: string;
    sign_name: string;
    sign_sex: GenderOptions;
    sign_telephone: string;
    sign_zip: string;
}

export interface IKyc {
    com_city: number;
    com_country: string;
    com_email: string;
    com_interestareas: string[] | string;
    com_name: string;
    com_state: number;
    com_telephone: string;
    crn: string;
    iec: string;
    pan: string;
    signatory: ISignatory[];
}

const cacheBuster$ = new Subject<void>();
const MAX_CACHE_TIME = environment.MAX_CACHE_TIME;

@Injectable({
    providedIn: 'root'
})
export class KycService {
    private KYC_URL = `${environment.api_url}/users/updateuserkyc`;
    private GET_KYC_DATA = `${environment.api_url}/users/getuserkyc`;

    constructor(private http: HttpClient) {}

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    updateKYC(data: any) {
        return this.http.post(this.KYC_URL, data);
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    getKYC(xionId: string) {
        let params = new HttpParams();
        if (!!xionId) {
            params = params.set('xionId', xionId);
        }
        return this.http
            .get<IApiResponse>(this.GET_KYC_DATA, { params })
            .pipe(map((response) => response.data as IKyc));
    }
}
