import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { EmailValidator } from '../../constants/validators';
import { ISubscription } from '../../interfaces/subscription.interface';

@Component({
    selector: 'xion-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
    loginForm: FormGroup;
    submitted = false;
    error: any = null;
    retailer: boolean;
    user: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {subscription : ISubscription, redirect : any},
        private dialogRef: MatDialogRef<LoginModalComponent>,
        private route: ActivatedRoute,
        private builder: FormBuilder,
        private router: Router,
        private dialog: MatDialog,
        private authService: AuthService
    ) {
    }

    ngOnInit() {

        this.loginForm = this.builder.group({
            email: ['', [Validators.required, EmailValidator.email]],
            password: ['', Validators.required]
        });

    }

    closeDialog() {
        this.dialogRef.close();
    }

    submitForm() {
        this.submitted = true;
        if (this.loginForm.valid) {
            const { email, password } = this.loginForm.value;
            this.authService.login(email, password).subscribe(data => {
                    this.dialogRef.close();
                },
                err => {
                    this.error = err.error.error;
                }
            );
        }
    }

    onSignup() {
        const redirectUrl = this.data.subscription.user_chan ? '/user/signup' : 'merchant/signup';
        this.router.navigate([redirectUrl]);
        this.dialogRef.close();
    }

    get form() {
        return this.loginForm.controls;
    }

}
