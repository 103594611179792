import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';
import { IBillingProduct } from '../models/billing-product.model';
import {
    ISubscriptionsFilter
} from '../models/subscription.model';
@Pipe({
    name: 'billingProductStatusPipe',
    pure: false
})
export class BillingProductStatusPipe implements PipeTransform {

    transform(products: IBillingProduct[], args?: { status: string, searchQuery?: string, filter:ISubscriptionsFilter }): IBillingProduct[] {
    
       
        if (products) {
            if (args.filter?.productType && args.filter?.productType.length)
                products =[... products.filter((product: IBillingProduct) => {
                let isLoyalty = args.filter?.productType.includes("loyalty");
                if (isLoyalty == false){
                    if (args.filter?.productType.includes(product.billing.billingType.toLowerCase()) && product.billing.isLoyalty==false) return true;
                } 
                if (isLoyalty == true) {
                    if (product.billing.isLoyalty == true) return true;
                    if (args.filter?.productType.includes(product.billing.billingType.toLowerCase())) return true;
                } else {
                    if (product.billing.isLoyalty == true) return false;
                    if (args.filter?.productType.includes(product.billing.billingType.toLowerCase())) return true;
                }
                return false;
            })];
            products = [...products.filter((product: IBillingProduct) => {
                if (!args.searchQuery) {
                    return product.product.status === args.status;
                } else {
                    return product.product.status === args.status && product.product.productName.toLowerCase().indexOf(args.searchQuery.toLowerCase()) !== -1;
                }
            })];
            if (!args.filter?.category) return [...products];
            products = [...products.filter((product: IBillingProduct) => product.product.category?.toLowerCase() === args.filter.category.toLowerCase())];
            return products;
        }
       
    }

}
