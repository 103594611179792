<div class="items-retailer__holder">
    <!-- <h3 class="title">{{name}}</h3> -->
    <div class="retailer__slider_holder">
        <div class="swiper-container" [swiper]="config" #usefulSwiper>
            <div class="swiper-wrapper">
                <div *ngFor="let user of users | userFilter:query" class="item-retailer swiper-slide">
                    <div class="item-retailer__picture" *ngIf="user.image">
                        <img [src]="user.image" [alt]="user.fullname" [title]="user.fullname">
                    </div>
                    <div class="item-retailer__picture item-retailer__picture_empty" *ngIf="!user.image">
                        <svg class="svg-icon">
                            <use xlink:href="#icon-retailer"></use>
                        </svg>
                    </div>
                    <h3 class="item-retailer__title text-overflow" [title]="user.fullname">
                        {{user.fullname}}
                    </h3>
                </div>
            </div>
        </div>
        <button class="next-retailer">
            <svg class="svg-icon slide-arrow_bottom">
                <use xlink:href="#slide-arrow"></use>
            </svg>
        </button>
        <button class="prev-retailer">
            <svg class="svg-icon slide-arrow">
                <use xlink:href="#slide-arrow"></use>
            </svg>
        </button>
    </div>

</div>
