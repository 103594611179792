import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ChartModalComponent } from '../chart-modal/chart-modal.component';

@Component({
  selector: 'xion-subscriptions-chart-modal',
  templateUrl: './subscriptions-chart-modal.component.html',
  styleUrls: ['./subscriptions-chart-modal.component.scss']
})
export class SubscriptionsChartModalComponent implements OnInit {

    // showCHart -> to fix conflict between modal plugin and
    // chart plugin. Issue with width of chart
    showCHart = false;
    subscriptions: Observable<any>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,private dialogRef:MatDialogRef<SubscriptionsChartModalComponent>) { }
    ngOnInit() {
        this.subscriptions = this.data.subscriptions;
        setTimeout(() => {
            this.showCHart = true;
        }, 100);
    }

    closeDialog(){
        this.dialogRef.close()
    }
}
