import { Component, Input, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable } from 'rxjs';
import { CurrencyService } from 'src/app/core/services/currency.service';

@Component({
    selector: 'xion-wallet-slider',
    templateUrl: './wallet-slider.component.html',
    styleUrls: ['./wallet-slider.component.scss']
})
export class WalletSliderComponent implements OnInit {
    @Input() data: any[];
    config: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 1,
        keyboard: true,
        scrollbar: false,
        pagination: false,
        navigation: {
            nextEl: '.next',
            prevEl: '.prev',
            disabledClass: 'disable'
        }
    };
    constructor() { }
    ngOnInit() {
    }

}
