import { SubVerifierDetails } from '@toruslabs/customauth';
import SimpleCrypto from 'simple-crypto-js';
import { environment } from 'src/environments/environment';
import { TORUS_ADDRESS, TORUS_PRIVATE_KEY, XION_TXHASH, TICKBOXFORGPDR } from '../constants';

export enum UserRoles {
    SELLAR = 'seller',
    BUYER = 'buyer'
}

export enum Platforms {
    xion = 'xion',
    torus = 'torus'
}

export interface UserSignupResponse {
    id: number;
    status: string;
}

export type Verifier = 'google' | 'reddit' | 'twitch' | 'discord' | 'facebook' | 'email' | 'phone' | 'web3modal';
export const getVerifier = (verifier: Verifier) => `xion-${verifier}`;

export const getTxHash = () => localStorage.getItem(XION_TXHASH);
export const setTxHash = (txHash: string) => localStorage.setItem(XION_TXHASH, txHash);

export const getTorusParams = (verifier: string, hash, queryParameters): SubVerifierDetails => {
    if (verifier === 'google' || verifier === 'xion-google') {
        return {
            typeOfLogin: 'google',
            verifier: getVerifier('google'),
            clientId: environment.googleID,
            hash,
            queryParameters
        };
    }
    if (verifier === 'facebook' || verifier === 'xion-facebook') {
        return {
            typeOfLogin: 'facebook',
            verifier: getVerifier('facebook'),
            clientId: environment.facebookID,
            hash,
            queryParameters
        };
    }

    if (verifier === 'reddit' || verifier === 'xion-reddit') {
        return {
            typeOfLogin: 'reddit',
            verifier: getVerifier('reddit'),
            clientId: environment.redditID,
            hash,
            queryParameters
        };
    }
    if (verifier === 'twitch' || verifier === 'xion-twitch') {
        return {
            typeOfLogin: 'twitch',
            verifier: getVerifier('twitch'),
            clientId: environment.twitchID,
            hash,
            queryParameters
        };
    }
    if (verifier === 'discord' || verifier === 'xion-discord') {
        return {
            typeOfLogin: 'discord',
            verifier: getVerifier('discord'),
            clientId: environment.discordID,
            hash,
            queryParameters
        };
    }
};

export const saveTorusAddress = (address: string) => localStorage.setItem(TORUS_ADDRESS, address);
export const getTorusAddress = () => localStorage.getItem(TORUS_ADDRESS)


export const getTickBoxForGDPR = () => {
    if(localStorage.getItem((TICKBOXFORGPDR+getTorusAddress()))==getTorusAddress()){
      return true;
    } else{
      return false;
    }
  }
  export const saveTickBoxForGDPR = () => localStorage.setItem((TICKBOXFORGPDR+getTorusAddress()), getTorusAddress())
  
const simpleCrypto = new SimpleCrypto(environment.salt);
const encrypt = (input: string) => simpleCrypto.encrypt(input);
const decrypt = (input: string) => simpleCrypto.decrypt(input);

export const getTorusPrivateKey = () => decrypt(localStorage.getItem(TORUS_PRIVATE_KEY)).toString();
export const saveTorusPrivateKey = (key: string) =>
    localStorage.setItem(TORUS_PRIVATE_KEY, encrypt(key));

export type AuthProvider = 'sms' | 'email';
export const TORUS_CHOSEN_VERIFIER = 'XION_CHOSEN_VERIFIER';

export const getCurrentProvider = (): AuthProvider => {
    const verifier = getCurrentVerifier();
    return verifier === environment.emailConfig.verifier ? 'email' : 'sms';
};

export const getCurrentVerifier = () => sessionStorage.getItem(TORUS_CHOSEN_VERIFIER);
export const saveCurrentVerifier = (provider: AuthProvider) => {
    const verifier =
        provider === 'sms' ? environment.phoneConfig.verifier : environment.emailConfig.verifier;
    sessionStorage.setItem(TORUS_CHOSEN_VERIFIER, verifier);
};

export const getURLTorusParams = (input: string) => {
    const url = new URL(input);
    const hash = url.hash.substr(1);
    const queryParams = {};
    // @ts-ignore
    for (const key of url.searchParams.keys()) {
        queryParams[key] = url.searchParams.get(key);
    }
    return { queryParams, hash };
};

export const handleRedirectParameters = (hash, queryParameters): any => {
    const hashParameters = hash.split('&').reduce((result, item) => {
        const [part0, part1] = item.split('=');
        result[part0] = part1;
        return result;
    }, {});
    let instanceParameters = {};
    let error = '';
    if (!queryParameters.preopenInstanceId) {
        if (Object.keys(hashParameters).length > 0 && hashParameters.state) {
            instanceParameters =
                JSON.parse(atob(decodeURIComponent(decodeURIComponent((hashParameters.state))))) ||
                {};
            error = hashParameters.error_description || hashParameters.error || error;
        } else if (Object.keys(queryParameters).length > 0 && queryParameters.state) {
            instanceParameters =
                JSON.parse(atob(decodeURIComponent(decodeURIComponent((queryParameters.state))))) ||
                {};
            if (queryParameters.error) error = queryParameters.error;
        }
    }
    return { error, instanceParameters, hashParameters };
};
