<div class="wrapper" [class.aside-open]="asideService.isOpen">
    <ng-container *ngIf="user$ | async as user;else notLoggedInMenu">
        <!-- <xion-retailer-side-menu
            *ngIf="user.merchant"
            (closeMobileMenu)="closeSideMenu()"
            class="aside-component"></xion-retailer-side-menu> -->
        <!-- <xion-user-side-menu
            *ngIf="!user.merchant"
            (closeMobileMenu)="closeSideMenu()"
            class="aside-component"></xion-user-side-menu> -->
    </ng-container>

    <ng-template #notLoggedInMenu>
        <div class="aside-component">
            <aside class="aside">
                <div class="aside__container">
                    <a routerLink="/" class="main-link">
                        <svg class="svg-icon icon-logo">
                            <use xlink:href="#logo"></use>
                        </svg>
                    </a>
                    <div class="aside-link__bottom-holder">
                        <a href="../../../../assets/Terms-of-Use.pdf" class="aside-link__bottom">
                            T&CS
                            <div class="top-list__icon-holder">
                                <svg class="svg-icon icon-terms">
                                    <use xlink:href="#terms"></use>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </aside>
        </div>
    </ng-template>

    <div class="main__column" *ngIf="offer$ | async">
        <header class="header">
            <div class="show-on__mobile link-holder">
                <a href="/" class="main-link">
                    <svg class="svg-icon icon-logo">
                        <use xlink:href="#logo"></use>
                    </svg>
                </a>
            </div>
            <div class="burger__holder">
                <xion-top-menu
                    *ngIf="user$ | async"
                    class="header__actions-component"
                    (closeMobileMenu)="closeSideMenu()"
                    [user]="user$">
                </xion-top-menu>
            </div>
        </header>

        <main class="main">
            <div class="row_middle-content">
                <section class="preview__section">
                    <div class="title__row">
                        <h1 class="title">
                            {{offer.title}}
                        </h1>
                    </div>
                    <div class="subscription__top-row">

                        <div class="tabs__media"
                             [class.tabs__media_video]="activeTopTab == mediaTabs.VIDEO"
                             [class.tabs__media_fullscreen]="fullscreen">
                            <button class="fullscreen"
                                    (click)="fullscreen = !fullscreen"
                                    *ngIf="offer.image">
                                <svg class="svg-icon icon-fullscreen">
                                    <use xlink:href="#icon-fullscreen"></use>
                                </svg>
                            </button>
                            <div class="subscription__img-holder no-image__holder"
                                 *ngIf="activeTopTab== mediaTabs.PHOTO && !offer.image">
                                <svg class="svg-icon icon-no-image">
                                    <use xlink:href="#no-image"></use>
                                </svg>
                            </div>
                            <div class="subscription__img-holder" *ngIf="activeTopTab==mediaTabs.PHOTO && offer.image">
                                <img [src]="offer.image" [alt]="offer.title" class="subscription__img">
                            </div>
                        </div>

                        <div class="subscription__column">
                            <div class="subscription__aciton-row">
                                <div class="subscription__price">
                                        <span class="subscription__price_value">
                                           {{offer.price | currencyConverter | async}}
                                        </span>
                                </div>
                            </div>
                            <ul class="subscription__details-list">
                                <li class="subscription__details-item" *ngIf="!isOwnerOfOffer">
                                    <span class="subscription__details-title">Merchant:</span>
                                    <span class="subscription__details">
                                            <img *ngIf="offer.user.image;else noUserImage" class="retiler__img"
                                                 [src]="offer.user.image" [alt]="offer.user.companyname">

                                            <ng-template #noUserImage>
                                                 <svg class="svg-icon icon-member">
                                                    <use xlink:href="#icon-user"></use>
                                                </svg>
                                            </ng-template>

                                            <h3 class="retailer__name">
                                                {{offer.user.companyname}}
                                            </h3>
                                        </span>
                                </li>
                                <li class="subscription__details-item" *ngIf="isOwnerOfOffer">
                                    <span class="subscription__details-title">Merchant ID:</span>
                                    <span class="subscription__details">
                                            {{offer.user.idxion}}
                                        </span>
                                </li>
                                <li class="subscription__details-item">
                                    <span class="subscription__details-title">Creation Date:</span>
                                    <span class="subscription__details">
                                          {{offer.created | unixDate}}
                                        </span>
                                </li>
                                <li class="subscription__details-item">
                                    <span class="subscription__details-title">Subscription:</span>
                                    <span class="subscription__details">
                                            <a [routerLink]="['/subscription',offer.subscription.id]">{{offer.subscription.title}}</a>
                                        </span>
                                </li>
                            </ul>
                            <div class="button-holder">
                                <button *ngIf="isOwnerOfOffer" class="button button_small button_blue">
                                    Archive
                                    <span class="button__arrow">
                                            <svg class="svg-icon icon-arrow_button"><use
                                                xlink:href="#icon-archive"></use></svg>
                                        </span>
                                </button>
                                <button class="button button_small button_blue" *ngIf="isOwnerOfOffer">
                                    Activate
                                    <span class="button__arrow">
                                            <svg class="svg-icon icon-arrow_button"><use
                                                xlink:href="#icon-arrow-play"></use></svg>
                                        </span>
                                </button>
                                <button class="button button_small button_blue" *ngIf="isOwnerOfOffer">
                                    Restore
                                    <span class="button__arrow">
                                            <svg class="svg-icon icon-restore"><use
                                                xlink:href="#icon-arrow-reload"></use></svg>
                                        </span>
                                </button>
                                <button class="button button_small button_blue" *ngIf="isOwnerOfOffer">
                                    Pause
                                    <span class="button__arrow">
                                            <svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-pause"></use></svg>
                                        </span>
                                </button>
                                <button *ngIf="!isOwnerOfOffer" class="button button_small button_blue button_subscribe"
                                        (click)="openPrivateKeyModal($event)">
                                    Buy
                                    <span class="button__arrow">
                                            <svg class="svg-icon icon-arrow_button"><use
                                                xlink:href="#icon-bag-blue"></use></svg>
                                        </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <p class="subscription__description">
                        {{offer.description}}
                    </p>
                    <ng-container *ngIf="offer.products.length>0">
                        <h2 class="title__sub">
                            Products
                        </h2>
                        <xion-products-list
                            class="component"
                            [products]="offer.products"
                        ></xion-products-list>
                    </ng-container>

                </section>
            </div>
        </main>
    </div>
</div>


