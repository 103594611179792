import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LoadingService } from '../../../core/services/loading.service';

@Component({
    selector: 'xion-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {

    showSpinner: boolean;
    isLoading$: Observable<boolean> = this.loadingService.isLoading;
    private subscription: Subscription;

    constructor(private loadingService: LoadingService, private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.subscription = this.isLoading$.subscribe(isLoading => {
            this.showSpinner = isLoading;
            this.cd.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
