import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewTransactionModalComponent } from '../../modals/view-transaction-modal/view-transaction-modal.component';
import { Observable } from 'rxjs';
import { DummyDataService } from '../../../core/services/dummy.service';
import { WalletTransaction } from '../../models/models';
import { NotificationsService } from '../../../core/services/notifications.service';
import { ClipboardService } from 'ngx-clipboard';
import { CurrencyService } from '../../../core/services/currency.service';
import { IUser, UserService } from '../../../core/services/user.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'xion-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
    transactions: Observable<WalletTransaction[]>;
    _transactions: WalletTransaction[];
    query: string = '';
    users$: Observable<IUser[]>;
    private users: IUser[] = [];

    constructor(
        private dialog: MatDialog,
        private dummy: DummyDataService,
        private notifications: NotificationsService,
        private clipboard: ClipboardService,
        private usersService: UserService
    ) {}

    ngOnInit() {
        this.users$ = this.usersService.getUsersList().pipe(take(1));
        this.users$.subscribe((users) => (this.users = users));

        this.transactions = this.dummy.getTransactions();
        this.transactions.subscribe((transactions) => {
            this._transactions = transactions;
            // this._transactions.map()
        });
    }

     openModal(transaction?: WalletTransaction) {
        const trans: WalletTransaction = {
            currency: 'USD',
            date: new Date(),
            id: Math.random()
                .toString(36)
                .substring(2, 15),
            price: Math.random() * 100,
            token: Math.random() > 0.5 ? 'ISX' : 'XSC',
            type: 'sent',
            value: Math.random() * 100,
            retailer :  null
        };
        this.dialog.open(ViewTransactionModalComponent, {
            panelClass: 'popup-blue__holder',
            data: trans
        });
    }

    copyAddress(address) {
        this.clipboard.copyFromContent(address);
        this.notifications.showMessage('address copied to clipboard !', 'hide');
    }

    follow(retailerId) {
        this.usersService.addContact(retailerId).subscribe((result) => {
        });
        // this.transaction.user.followed=!this.transaction.user.followed
        // this._transactions.map(item=>{
        //     if (item.retailer.id === retailerId) {
        //         const text = item.retailer.followed ? 'Added Recipient!' : 'Removed Recipient!';
        //         this.notifications.showMessage(text, 'hide');
        //         item.retailer.followed = !item.retailer.followed;
        //     }
        // })
    }
}
