<div class="popup-blue__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title">
            {{data.providerType}} details
        </h3>
        <div class="form__row">
            <div class="form-label__holder">
                <span class="form-label text-overflow" for="name">Name</span>
                <div class="textfield textfield__white-border disable">
                    {{data.provider.name}}
                </div>
            </div>
        </div>
        <div class="form__row" *ngIf="data.provider.interestareas">
            <div class="form-label__holder select-white__border">
                <span class="form-label text-overflow">Areas Of Interest</span>
                <div class="textfield textfield__white-border disable">
                    {{data.provider.interestareas.join(' / ')}}
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="form-label__holder select-white__border">
                <span class="form-label text-overflow">Country</span>
                <div class="textfield textfield__white-border disable">
                    {{data.provider.country}}
                </div>
            </div>
            <div class="form-label__holder select-white__border">
                <span class="form-label text-overflow">State</span>
                <div class="textfield textfield__white-border disable">
                    {{data.provider.state}}
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="form-label__holder select-white__border" >
                <span class="form-label text-overflow">City</span>
                <div class="textfield textfield__white-border disable">
                    {{data.provider.city}}
                </div>
            </div>
            <div class="form-label__holder">
                <span class="form-label text-overflow">Zip Code</span>
                <div class="textfield textfield__white-border disable">
                    {{data.provider.zip}}
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="form-label__holder">
                <label class="form-label text-overflow">Address</label>
                <div class="textfield textfield__white-border disable">
                    {{data.provider.address}}
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="form-label__holder">
                <span class="form-label text-overflow" for="phone">Phone Number</span>
                <div class="textfield textfield__white-border disable">
                    {{data.provider.phone}}
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="form-label__holder">
                <label class="form-label text-overflow" for="website">Website</label>
                <div class="textfield textfield__white-border disable" id="website">
                    {{data.provider.website}}
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="form-label__holder">
                <span class="form-label text-overflow" for="email">Email</span>
                <div class="textfield textfield__white-border disable">
                    {{data.provider.email}}
                </div>
            </div>
        </div>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>
