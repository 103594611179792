import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { getColorScheme } from 'src/app/shared/constants/helpers';
import { ISeries } from '../../../models/models';

@Component({
  selector: 'xion-revenue-chart',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss'],

})
export class RevenueComponent implements OnChanges {
  @Input() revenue: ISeries[];
  @Input() total;
  @Input() colorScheme;
  @Input() styles:string;
  @Input() view: number[];
  @Input() xAxis : boolean;
  @Input() yAxis : boolean;
  @Input() tooltipDisabled: boolean;
  @Input() page: boolean;
  scheme = null;

  ngOnChanges() {
    this.scheme = getColorScheme(this.colorScheme || 'default');
  }
}
