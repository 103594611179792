<div class="popup__content">
  <div class="popup">
    <div class="popup__charts">
       <xion-revenue-chart
       *ngIf="showCHart"
        class="revenue"
        [xAxis]="false"
        [yAxis]="true"
        [revenue]="revenue"
        [page]="false"
        colorScheme="highlight"></xion-revenue-chart>
    </div>
    <span _ngcontent-c29="" class="popup__title">REVENUE AND SALES </span>
  </div>
  <button (click)="closeDialog()" class="popup__close">
    <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
  </button>
</div>

