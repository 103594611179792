<div class="popup__content">
    <div class="popup">
        <div class="form__row">
            <div class="textfield textfield_pink">
                <p>
                    Did you save your data ?
                </p>
                <p>
                    once you navigate away from this page you won't be able to access it again
                </p>
            </div>
        </div>
        <div class="form__row form__row-buttons">
            <button class="button button_blue button_small"(click)="closeDialog(true)">Yes</button>
            <button class="button button_blue button_small" (click)="closeDialog(false)">Cancel</button>
        </div>
        <span class="popup__title">
            CONFIRM
        </span>
    </div>
    <button (click)="closeDialog(false)" class="popup__close">
        <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
    </button>
</div>
