import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { NotificationsService } from '../../../core/services/notifications.service';
import { TokenService } from '../../../core/services/token.service';
import { Tokens, WalletService } from '../../../core/services/wallet.service';
import { CustomNumbersValidator } from '../../constants/validators';
import { IUser, UserService } from '../../../core/services/user.service';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
    selector: 'xion-swap-modal',
    templateUrl: './swap-modal.component.html',
    styleUrls: ['./swap-modal.component.scss']
})
export class SwapModalComponent implements OnInit, OnDestroy {

    tokens = Tokens;
    swapForm: FormGroup;
    currency$: Observable<any>;
    token = Tokens.ISX;
    buyWith = Tokens.XSC;
    tokenValue: number = 0;
    XSC_VALUE: number = null;
    error: string = null;
    user: IUser;

    private submitted: boolean = false;
    private user$: Observable<IUser>;
    private userSub: Subscription;

    constructor(
        private builder: FormBuilder,
        private currencyService: CurrencyService,
        private walletService: WalletService,
        private tokenService: TokenService,
        private authService: AuthService,
        private notifications: NotificationsService,
        private dialogRef: MatDialogRef<SwapModalComponent>
    ) {
    }

    ngOnInit() {
        this.currency$ = this.currencyService.selectedCurrency;
        this.user$ = this.authService.currentUser;
        this.userSub = this.user$.subscribe(user => this.user = user);
        this.XSC_VALUE = this.tokenService.getXSCValue();
        this.swapForm = this.builder.group({
            key: [null, Validators.required],
            method: [Tokens.ISX],
            amount: [null, [Validators.required, CustomNumbersValidator.Negative]]
        });
    }

    closeDialog(swapped: boolean = false) {
        this.dialogRef.close(swapped);
    }

    onSubmit() {
        this.submitted = true;
        const { amount, key, method } = this.swapForm.value;
        if (amount && key) {
            this.walletService.swapTokens(amount, method, key)
                .pipe(
                    take(1),
                    catchError(this.handleError.bind(this))
                )
                .subscribe(() => {
                    this.notifications.showMessage('Tokens swapped successfully !');
                    this.closeDialog(true);
                });
        }
    }

    onTokenChanged() {
        const method = this.form.method.value;
        switch (method) {
            case Tokens.XSC :
                this.buyWith = Tokens.ISX;
                this.tokenValue = this.form.amount.value * this.XSC_VALUE;
                break;
            case Tokens.ISX :
                this.buyWith = Tokens.XSC;
                this.tokenValue = this.form.amount.value / this.XSC_VALUE;
                break;
            default :
                this.buyWith = Tokens.XSC;
        }
    }

    isFieldInvalid(formControlName) {
        const control = this.swapForm.get(formControlName);
        return control.invalid && this.submitted;
    }

    get form() {
        return this.swapForm.controls;
    }


    handleError(response: HttpErrorResponse) {
        this.error = response.error.error;
        if (this.error === 'Invalid salt.') this.error = 'Wrong Private key , please input the correct private key';
        this.form.key.setErrors({ invalid: true });
        return throwError(response);
    }

    ngOnDestroy(): void {
        this.userSub.unsubscribe();
    }


}
