import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteAccountModalComponent } from '../../../shared/modals/delete-account-modal/delete-account-modal.component';

@Component({
  selector: 'xion-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(private httpService: HttpService,
              private authService: AuthService,
              private dialog: MatDialog) { }

  ngOnInit() {
  }

  public onDeleteAccount(): void {
      const currentUser = this.authService.loggedInUser;
      if (currentUser && currentUser.walletAddress) {
          this.dialog.open(DeleteAccountModalComponent, {
              panelClass: 'subscriptions-slider',
          })
              .afterClosed()
              .subscribe((response: boolean) => {
                  if (response) {
                      this.httpService.deleteAccount().subscribe()
                        this.authService.logout;
                  }
              });
      } else {
          this.authService.logout();
      }
  }

}
