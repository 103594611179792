<div class="account-leave-confirm">
    <p class="account-leave-confirm_text">
        To leave the account, please sign the transaction.
    </p>
    <div class="account-leave-confirm_actions">
        <div class="text-center">
            <button class="mr-2" mat-dialog-close>Cancel</button>
            <button class="active">Approve</button>
        </div>
    </div>
</div>
