import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ISeries } from '../../../models/models';
import { getColorScheme } from '../../../constants/helpers';

@Component({
    selector: 'xion-net-income-chart',
    templateUrl: './net-income-chart.component.html',
    styleUrls: ['./net-income-chart.component.scss']
})
export class NetIncomeChartComponent implements OnChanges, OnInit {
    @Input() xAxis: boolean;
    @Input() yAxis: boolean;
    @Input() data: ISeries[];
    @Input() tooltipDisabled: boolean;
    @Input() colorScheme: string;
    scheme = null;

    ngOnInit(): void {
    }

    ngOnChanges() {
        this.scheme = getColorScheme(this.colorScheme || 'default');
    }
}
