import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { ConfirmPasswordValidator } from '../../constants/validators';
import { passwordGenerator } from '../../constants/helpers';

@Component({
    selector: 'xion-confirm-password',
    templateUrl: './confirm-password.component.html',
    styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {


    changeConfirmation: FormGroup;
    submitted: boolean;

    showPassword = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private builder: FormBuilder,
        private clipboard: ClipboardService,
        private notifications: NotificationsService,
        private dialogRef: MatDialogRef<ConfirmPasswordComponent>
    ) {
    }

    ngOnInit() {
        this.changeConfirmation = this.builder.group({
                password: [null, Validators.required],
                key: [null, Validators.required]
            })
    }

    get form() {
        return this.changeConfirmation.controls;
    }

    closeDialog(values = null) {
        this.dialogRef.close(values);
    }

    onSubmit(event) {
        event.preventDefault();
        this.submitted = true;
        if (this.changeConfirmation.valid) {
            this.closeDialog(this.changeConfirmation.value);
        }
    }
}
