import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import { Discounts } from '../../../core/services/wallet.service';

@Component({
    selector: 'xion-discount-calculator-modal',
    templateUrl: './discount-calculator-modal.component.html',
    styleUrls: ['./discount-calculator-modal.component.scss']
})
export class DiscountCalculatorModalComponent implements OnInit {

    get form() {
        return this.discountForm.controls;
    }

    isOneTimePayment: boolean;
    discountForm: FormGroup;
    today = new Date();
    duration = 'days';

    discountLevels = [
        {id: 'Free', rate: 0.005},
        {id: 'level 1', rate: 0.02},
        {id: 'level 2', rate: 0.03},
        {id: 'level 3', rate: 0.04},
        {id: 'level 4', rate: 0.05},
        {id: 'level 5', rate: 0.06},
    ];
    tokenPrice = 0.02;
    rows = [];


    constructor(@Inject(MAT_DIALOG_DATA) public subscription: any, private dialogRef: MatDialogRef<DiscountCalculatorModalComponent>,
                private builder: FormBuilder,) {
    }

    // TODO:change when we have live API

    ngOnInit() {
        this.discountForm = this.builder.group({
            name: [],
            start_date: [this.today],
            end_date: [],
            password: [''],
            plan: ['daily'],
            description: [''],
            type: ['single'],
            duration: [10],
            price: [10],
            discountLevel: [Discounts.FREE],
            discount: [true],
            numberOfSubscriptions: [1],
        });
        this.calculateProjections();
    }


    onProductTypeChange() {
        const plan = this.discountForm.get('plan').value;
        this.isOneTimePayment = false;
        switch (plan) {
            case 'daily' :
                this.duration = 'days';
                break;
            case 'weekly' :
                this.duration = 'weeks';
                break;
            case 'monthly' :
                this.duration = 'months';
                break;
            case 'yearly' :
                this.duration = 'years';
                break;
            default :
                this.duration = 'days';
        }
    }

    calculateDuration() {
        const startDate = moment(this.discountForm.get('start_date').value);
        const endDate = moment(this.discountForm.get('end_date').value);
        const precise = this.duration != 'days';
        // @ts-ignore
        const diff = endDate.diff(startDate, this.duration, precise);
        this.discountForm.get('duration').setValue(diff);
        this.calculateProjections();
    }

    calculateProjections() {
        const startDate = moment(this.discountForm.get('start_date').value);
        const duration = this.discountForm.get('duration').value;
        // @ts-ignore
        const date = startDate.add(duration, this.duration).toDate();
        this.discountForm.get('end_date').setValue(date);
        const price = this.discountForm.get('price').value;
        const discountLevel = this.discountForm.get('discountLevel').value;
        const numberOfSubscriptions = this.discountForm.get('numberOfSubscriptions').value;

        this.rows = Array(duration).fill(null).map((item, index) => {
            const day = index;
            const cost = price * numberOfSubscriptions;



            const payment = cost / Math.pow(Number(1 + discountLevel), Number(day));
            const savings = cost - payment;
            return {
                day,
                payment ,
                savings
            };

        });
    }

    onDiscountLevelChanged(level) {
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
