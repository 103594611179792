<div class="popup-blue__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title">
            Change password
        </h3>
        <form [formGroup]="changePassword">
            <div class="form__row" *ngIf="!data.restore">
                <div class="form-label__holder">
                    <label class="form-label text-overflow" for="old-password">Old Password</label>
                    <input class="textfield textfield__white-border" type="text"
                            id="old-password"
                            formControlName="oldPassword"
                            placeholder="Old Password"
                            [ngClass]="submitted && form.oldPassword.errors ? 'error__field' : ''">
                    <span class="error__text" *ngIf="(submitted||form.oldPassword.touched)   && form.oldPassword.errors">
                        <p *ngIf="form.oldPassword.errors.required">Old password is required</p>
                    </span>
                </div>
            </div>
            <div class="form__row">
                <div class="form-label__holder" [ngClass]="submitted && form.password.errors ? 'error__field' : ''">
                    <label class="form-label text-overflow" for="new-password">New Password</label>
                    <div class="form-label__holder">
                        <input class="textfield textfield__white-border"
                            id="new-password"
                            formControlName="password"
                            [type]="showPassword ? 'text' :'password'"
                            placeholder="New PASSWORD">
                        <div class="form__input-actions">
                            <a (click)="showPassword=!showPassword" class="toggle-password toggle-password_white" [class.active]="showPassword">
                                <div class="toggle-password__icon">
                                <svg class="svg-icon icon-eye_hide">
                                    <use xlink:href="#icon-eye-hide"></use>
                                </svg>
                                <svg class="svg-icon icon-eye">
                                    <use xlink:href="#icon-eye"></use>
                                </svg>
                                </div>
                            </a>
                            <button class="button button_small button_white-border button_password" (click)="generatePassword()">
                                generate
                            </button>
                        </div>
                    </div>
                    <span class="error__text" *ngIf="(submitted || form.password.touched) && form.password.errors">
                        <p *ngIf="form.password.errors.required">password is required</p>
                        <p *ngIf="form.password.errors.weak">please choose a strong password</p>
                    </span>
                </div>
            </div>
            <div class="form__row">
                <div class="form-label__holder"
                    [ngClass]="(submitted || form.confirmPassword.touched) && form.confirmPassword.errors ? 'error__field' : ''">
                    <label class="form-label text-overflow" for="confirm-password">Confirm Password</label>
                    <input class="textfield textfield__white-border"
                        [type]="showPassword ? 'text' :'password'"
                        id="confirm-password"
                        formControlName="confirmPassword"
                        placeholder="CONFIRM PASSWORD">
                    <span class="error__text" *ngIf="form.confirmPassword.errors">
                        passwords didn't match.
                    </span>
                </div>
            </div>
        </form>
        <div class="form__row">
            <div class="row__buttons">
                <button class="button button_small button_white-border" (click)="submitForm()">Save</button>
            </div>
        </div>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>
