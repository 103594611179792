import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AsideService } from '../../services/aside.service';
@Component({
    selector: 'xion-btn-aside-toggle',
    templateUrl: './btn-aside-toggle.component.html',
    styleUrls: ['./btn-aside-toggle.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BtnAsideToggleComponent implements OnInit {
    constructor(public asideService: AsideService) {}

    ngOnInit() {
    }

    toggleMenu() {
        this.asideService.toggleAside();
    }
}
