import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITab, Tabs } from '../../interfaces/tabs.interface';
import { AsideService } from '../../services/aside.service';

@Component({
    selector: 'xion-tabs-header',
    templateUrl: './tabs-header.component.html',
    styleUrls: ['./tabs-header.component.scss']
})
export class TabsHeaderComponent implements OnInit {
    // activeTab: ITab = Tabs[0];
    // @Output() activeTabEmitter: EventEmitter<ITab> = new EventEmitter<ITab>();

    // @Input() tabs: ITab[] = Tabs;

    constructor(
        public asideService: AsideService,
    ) {}

    ngOnInit() {
    }

    // clickOnTab(tab: ITab): void {
    //     this.activeTab = tab;
    //     this.activeTabEmitter.emit(tab);
    // }

}
