// dummy data file
// TODO: Remove when the API is done

export const retailers = [
    {
        name: 'Exxon Mobil',
        domain: 'exxonmobil.com',
        logo: 'https://logo.clearbit.com/exxonmobil.com'
    }, {
        name: 'Chevron',
        domain: 'chevron.com',
        logo: 'https://logo.clearbit.com/chevron.com'
    }, {
        name: 'Berkshire Hathaway',
        domain: 'berkshirehathaway.com',
        logo: 'https://logo.clearbit.com/berkshirehathaway.com'
    }, {
        name: 'Apple',
        domain: 'apple.com',
        logo: 'https://logo.clearbit.com/apple.com'
    }, {
        name: 'Phillips 66',
        domain: 'phillips66.com',
        logo: 'https://logo.clearbit.com/phillips66.com'
    }, {
        name: 'General Motors',
        domain: 'gm.com',
        logo: 'https://logo.clearbit.com/gm.com'
    }, {
        name: 'Ford Motor',
        domain: 'ford.com',
        logo: 'https://logo.clearbit.com/ford.com'
    }, {
        name: 'General Electric',
        domain: 'bitstew.com',
        logo: null
    }, {
        name: 'Valero Energy',
        domain: 'valero.com',
        logo: 'https://logo.clearbit.com/valero.com'
    }, {
        name: 'AT',
        domain: 'portaldasfinancas.gov.pt',
        logo: 'https://logo.clearbit.com/portaldasfinancas.gov.pt'
    }, {
        name: 'CVS Caremark',
        domain: 'caremark.com',
        logo: 'https://logo.clearbit.com/caremark.com'
    }, {
        name: 'Fannie Mae',
        domain: 'fanniemae.com',
        logo: 'https://logo.clearbit.com/fanniemae.com'
    }, {
        name: 'UnitedHealth Group',
        domain: 'unitedhealthgroup.com',
        logo: 'https://logo.clearbit.com/unitedhealthgroup.com'
    }, {
        name: 'McKesson',
        domain: 'mckesson.com',
        logo: 'https://logo.clearbit.com/mckesson.com'
    }, {
        name: 'Costco Wholesale',
        domain: 'costco.com',
        logo: 'https://logo.clearbit.com/costco.com'
    }, {
        name: 'Bank of America',
        domain: 'bankofamerica.com',
        logo: 'https://logo.clearbit.com/bankofamerica.com'
    }, {
        name: 'Cardinal Health',
        domain: 'cardinalhealth.com',
        logo: 'https://logo.clearbit.com/cardinalhealth.com'
    }, {
        name: 'International Business Machines',
        domain: 'lotus.com',
        logo: 'https://logo.clearbit.com/lotus.com'
    }, {
        name: 'Kroger',
        domain: 'kroger.com',
        logo: 'https://logo.clearbit.com/kroger.com'
    }, {
        name: 'Marathon Petroleum',
        domain: 'marathonpetroleum.com',
        logo: 'https://logo.clearbit.com/marathonpetroleum.com'
    }, {
        name: 'Citigroup',
        domain: 'citi-group.co.uk',
        logo: 'https://logo.clearbit.com/citi-group.co.uk'
    }, {
        name: 'Archer Daniels Midland',
        domain: 'adm.com',
        logo: 'https://logo.clearbit.com/adm.com'
    }, {
        name: 'AmerisourceBergen',
        domain: 'amerisourcebergen.com',
        logo: 'https://logo.clearbit.com/amerisourcebergen.com'
    }, {
        name: 'Wells Fargo',
        domain: 'wellsfargo.com',
        logo: null
    }, {
        name: 'Boeing',
        domain: 'boeing.com',
        logo: 'https://logo.clearbit.com/boeing.com'
    }, {
        name: 'Freddie Mac',
        domain: 'freddiemac.com',
        logo: 'https://logo.clearbit.com/freddiemac.com'
    }, {
        name: 'Home Depot',
        domain: 'homedepot-ca.com',
        logo: null
    }, {
        name: 'Microsoft',
        domain: 'microsoft.com',
        logo: 'https://logo.clearbit.com/microsoft.com'
    }, {
        name: 'Amazonギフト券販売.comで格安購',
        domain: 'xn--amazon-8q4emh9dx899ahqpcn0m.com',
        logo: 'https://logo.clearbit.com/xn--amazon-8q4emh9dx899ahqpcn0m.com'
    }, {
        name: 'Target',
        domain: 'target.com',
        logo: 'https://logo.clearbit.com/target.com'
    }, {
        name: 'Walgreen Co.',
        domain: 'walgreen.co',
        logo: null
    }, {
        name: 'Wellpoint - Клиника Лазерной и Эстетической медицины',
        domain: 'mywellpoint.ru',
        logo: null
    }, {
        name: 'Johnson',
        domain: 'johnson.ca',
        logo: null
    }, {
        name: 'MetLife',
        domain: 'metlife.com',
        logo: 'https://logo.clearbit.com/metlife.com'
    }, {
        name: 'PepsiCo',
        domain: 'pepsico.com',
        logo: 'https://logo.clearbit.com/pepsico.com'
    }, {
        name: 'Comcast',
        domain: 'xfinity.com',
        logo: 'https://logo.clearbit.com/xfinity.com'
    }, {
        name: 'United Technologies',
        domain: 'utc.com',
        logo: 'https://logo.clearbit.com/utc.com'
    }, {
        name: 'Google',
        domain: 'google.com',
        logo: 'https://logo.clearbit.com/google.com'
    }, {
        name: 'ConocoPhillips',
        domain: 'conocophillips.com',
        logo: 'https://logo.clearbit.com/conocophillips.com'
    }, {
        name: 'Caterpillar',
        domain: 'caterpillar.com',
        logo: 'https://logo.clearbit.com/caterpillar.com'
    }, {
        name: 'Pfizer',
        domain: 'pfizer.com',
        logo: null
    }, {
        name: 'The Coca-Cola Company',
        domain: 'coca-colacompany.com',
        logo: 'https://logo.clearbit.com/coca-colacompany.com'
    }, {
        name: 'The Walt Disney Company',
        domain: 'go.com',
        logo: 'https://logo.clearbit.com/go.com'
    }, {
        name: 'Merck',
        domain: 'merck.com',
        logo: 'https://logo.clearbit.com/merck.com'
    }, {
        name: 'Morgan Stanley',
        domain: 'morganstanley.com',
        logo: 'https://logo.clearbit.com/morganstanley.com'
    }, {
        name: 'DIRECTV',
        domain: 'directv.com',
        logo: 'https://logo.clearbit.com/directv.com'
    }, {
        name: 'The Southern Company',
        domain: 'thesouthernco.net',
        logo: 'https://logo.clearbit.com/thesouthernco.net'
    }, {
        name: 'The AES Corporation',
        domain: 'aes.com',
        logo: 'https://logo.clearbit.com/aes.com'
    }, {
        name: 'PG',
        domain: 'extrememanual.net',
        logo: 'https://logo.clearbit.com/extrememanual.net'
    }, {
        name: 'Edison International',
        domain: 'edison.com',
        logo: 'https://logo.clearbit.com/edison.com'
    }, {
        name: 'Marsh',
        domain: 'marsh.com',
        logo: 'https://logo.clearbit.com/marsh.com'
    }, {
        name: 'Bed&Bath',
        domain: 'bedandbath.gr',
        logo: 'https://logo.clearbit.com/bedandbath.gr'
    }, {
        name: 'Sempra Energy',
        domain: 'sempra.com',
        logo: 'https://logo.clearbit.com/sempra.com'
    }, {
        name: 'ÖBB',
        domain: 'oebb.at',
        logo: 'https://logo.clearbit.com/oebb.at'
    }, {
        name: 'Air Products',
        domain: 'airproducts.com',
        logo: 'https://logo.clearbit.com/airproducts.com'
    }, {
        name: 'The Mosaic Company',
        domain: 'mosaicco.com',
        logo: 'https://logo.clearbit.com/mosaicco.com'
    }, {
        name: 'MGM Resorts International',
        domain: 'mgmresorts.com',
        logo: 'https://logo.clearbit.com/mgmresorts.com'
    }, {
        name: 'KKRホテル熱海',
        domain: 'kkr-atami.gr.jp',
        logo: null
    }, {
        name: 'Reliance Steel',
        domain: 'reliancesteelofvt.com',
        logo: 'https://logo.clearbit.com/reliancesteelofvt.com'
    }, {
        name: 'Owens',
        domain: 'owensramsbottom.co.uk',
        logo: null
    }, {
        name: 'Pacific Life',
        domain: 'pacificlife.com',
        logo: 'https://logo.clearbit.com/pacificlife.com'
    }, {
        name: 'Corning Incorporated',
        domain: 'corning.com',
        logo: null
    }, {
        name: 'The Hershey Company',
        domain: 'hersheys.com',
        logo: null
    }, {
        name: 'BARNES',
        domain: 'barnes-international.com',
        logo: 'https://logo.clearbit.com/barnes-international.com'
    }, {
        name: 'Erie Insurance Group',
        domain: 'covenantinsurancegroup.com',
        logo: null
    }, {
        name: 'Sanmina',
        domain: 'sanmina.com',
        logo: 'https://logo.clearbit.com/sanmina.com'
    }, {
        name: 'The J.M. Smucker Company',
        domain: 'jmsmucker.com',
        logo: 'https://logo.clearbit.com/jmsmucker.com'
    }, {
        name: 'The Clorox Company',
        domain: 'thecloroxcompany.com',
        logo: 'https://logo.clearbit.com/thecloroxcompany.com'
    }, {
        name: 'Owens Corning',
        domain: 'owenscorning.com',
        logo: 'https://logo.clearbit.com/owenscorning.com'
    }
];
export const currencies = ['USD', 'EUR', 'YEN'];
export const currencies_list = [
    {
        symbol: '$',
        name: 'USD'
    },
    {
        symbol: '€',
        name: 'EUR'
    },
    {
        symbol: '£',
        name: 'GBP'
    },
    {
        symbol: '¥',
        name: 'JPY'
    },
];

export const single = [
    {
        name: 'Germany',
        value: 20
    },
    {
        name: 'United States',
        value: 30
    },
    {
        name: 'France',
        value: 40
    },
    {
        name: 'Japan',
        value: 90
    }
];
export const subscriptions = [
    {
        name: 'Subscription 1',
        value: 20
    },
    {
        name: 'Subscription 2',
        value: 30
    },
    {
        name: 'Subscription 3',
        value: 40
    },
    {
        name: 'Subscription 4',
        value: 89
    },
    {
        name: 'Subscription 5',
        value: 90
    }
];

export const ISX_DATA = {
    name: 'ISX',
    value: 250
};
export const XSX_DATA = {
    name: 'XSC',
    value: 30
};

export const multi = [
    ISX_DATA,
    XSX_DATA
];

export const users = [
    {
        name: 'Users',
        series: [
            {
                name: 'January',
                value: 10
            },
            {
                name: 'February',
                value: 25
            },
            {
                name: 'March',
                value: 40
            },
            {
                name: 'April',
                value: 45
            },
            {
                name: 'May',
                value: 50
            },
            {
                name: 'June',
                value: 40
            },
            {
                name: 'July',
                value: 40
            },
            {
                name: 'August',
                value: 40
            },
            {
                name: 'September',
                value: 40
            },
            {
                name: 'October',
                value: 40
            },
            {
                name: 'November',
                value: 40
            },
            {
                name: 'December',
                value: 40
            }
        ]
    }
];
export const revenue = [
    {
        name: 'Revenue',
        series: [
            {
                name: 'January',
                value: 49737
            },
            {
                name: 'February',
                value: 45986
            },
            {
                name: 'Mars',
                value: 37060
            },
            {
                name: 'April',
                value: 25154
            },
            {
                name: 'May',
                value: 20215
            },
            {
                name: 'June',
                value: 20258
            },
            {
                name: 'July',
                value: 45021
            },
            {
                name: 'August',
                value: 25468
            },
            {
                name: 'October',
                value: 40254
            },
            {
                name: 'September',
                value: 50015
            },
            {
                name: 'November',
                value: 70245
            },
            {
                name: 'December',
                value: 80125
            }
        ]
    }
];

export const multiCountries = [
    {
        name: 'Germany',
        series: [
            {
                name: '2010',
                value: 40632
            },
            {
                name: '2000',
                value: 36953
            },
            {
                name: '1990',
                value: 31476
            }
        ]
    },
    {
        name: 'United States',
        series: [
            {
                name: '2010',
                value: 49737
            },
            {
                name: '2000',
                value: 45986
            },
            {
                name: '1990',
                value: 37060
            }
        ]
    },
    {
        name: 'France',
        series: [
            {
                name: '2010',
                value: 36745
            },
            {
                name: '2000',
                value: 34774
            },
            {
                name: '1990',
                value: 29476
            }
        ]
    },
    {
        name: 'United Kingdom',
        series: [
            {
                name: '2010',
                value: 36240
            },
            {
                name: '2000',
                value: 32543
            },
            {
                name: '1990',
                value: 26424
            }
        ]
    }
];

export const plot = [
    {
        name: 'Disputes',
        series: [
            {
                name: '2001',
                value: 40632
            },
            {
                name: '2002',
                value: 36953
            },
            {
                name: '2003',
                value: 56441
            },
            {
                name: '2004',
                value: 65421
            },
            {
                name: '2005',
                value: 40214
            },
            {
                name: '2006',
                value: 80215
            }
        ]
    },
    {
        name: 'Chargebacks',
        series: [
            {
                name: '2001',
                value: 10000
            },
            {
                name: '2002',
                value: 20145
            },
            {
                name: '2003',
                value: 30245
            },
            {
                name: '2004',
                value: 51025
            },
            {
                name: '2005',
                value: 30215
            },
            {
                name: '2006',
                value: 51202
            }
        ]
    }
];

// List of countries in ISO 3166-1 format (ex : GL or Greenland)

export const countries = [
    ['Country', 'Subscriptions'],
    ['Japan', 500],
    ['Algeria', 36],
    ['Angola', 8],
    ['Benin', 6],
    ['GL', 250],
    ['Botswana', 24],
    ['Burkina Faso', 12],
    ['Burundi', 3],
    ['Cameroon', 3],
    ['Canary Islands', 28],
    ['Cape Verde', 15],
    ['Central African Republic', 4],
    ['Ceuta', 35],
    ['Chad', 12],
    ['Comoros', 12],
    ['Cote d\'Ivoire', 6],
    ['AF', 1000],
    ['Democratic Republic of the Congo', 3],
    ['Djibouti', 12],
    ['Egypt', 26],
    ['Equatorial Guinea', 3],
    ['Eritrea', 15],
    ['Ethiopia', 9],
    ['Gabon', 0],
    ['Gambia', 13],
    ['Ghana', 5],
    ['Guinea', 10],
    ['MA', 2000],
    ['Guinea-Bissau', 12],
    ['Kenya', 1],
    ['Lesotho', 29],
    ['Liberia', 6],
    ['Libya', 32],
    ['Madagascar', 0],
    ['Madeira', 33],
    ['Malawi', 14],
    ['Mali', 12],
    ['Mauritania', 18],
    ['Mauritius', 20],
    ['Mayotte', 13],
    ['Melilla', 35],
    ['Mozambique', 25],
    ['Namibia', 22],
    ['Niger', 14],
    ['Nigeria', 8],
    ['Republic of the Congo', 1],
    ['Réunion', 21],
    ['Ukraine', 2100],
    ['Rwanda', 2],
    ['Saint Helena', 16],
    ['São Tomé and Principe', 0],
    ['Senegal', 15],
    ['Seychelles', 5],
    ['Sierra Leone', 8],
    ['Somalia', 2],
    ['Sudan', 15],
    ['South Africa', 30],
    ['South Sudan', 5],
    ['Swaziland', 26],
    ['Tanzania', 6],
    ['Togo', 6],
    ['Tunisia', 34],
    ['Uganda', 1],
    ['Western Sahara', 25],
    ['Zambia', 15],
    ['Zimbabwe', 18]
];

export const sales = [
    {
        name: 'Revenue',
        series: [
            {
                name: 'January',
                value: 49737
            },
            {
                name: 'February',
                value: 45986
            },
            {
                name: 'Mars',
                value: 37060
            },
            {
                name: 'April',
                value: 25154
            },
            {
                name: 'May',
                value: 20215
            },
            {
                name: 'June',
                value: 20258
            },
            {
                name: 'July',
                value: 45021
            },
            {
                name: 'August',
                value: 25468
            },
            {
                name: 'October',
                value: 40254
            },
            {
                name: 'September',
                value: 50015
            },
            {
                name: 'November',
                value: 70245
            },
            {
                name: 'December',
                value: 80125
            }
        ]
    },
    {
        name: 'Sales',
        series: [
            {
                name: 'January',
                value: 36745
            },
            {
                name: 'February',
                value: 34774
            },
            {
                name: 'Mars',
                value: 29476
            },
            {
                name: 'April',
                value: 29476
            },
            {
                name: 'May',
                value: 30024
            },
            {
                name: 'June',
                value: 24510
            },
            {
                name: 'July',
                value: 21500
            },
            {
                name: 'Aughust',
                value: 37060
            },
            {
                name: 'October',
                value: 21502
            },
            {
                name: 'September',
                value: 20125
            },
            {
                name: 'November',
                value: 42505
            },
            {
                name: 'December',
                value: 15802
            }
        ]
    }
];

export const areasOfInterest = [
    'Apparel & Accessories',
    'Arts & Entertainment',
    'Baby & Toddler',
    'Business & Industrial',
    'Cameras & Optics',
    'Electronics',
    'Food & Beverages',
    'Furniture',
    'Hardware',
    'Health & Beauty',
    'Home & Garden',
    'Luggage & Bags',
    'Office Supplies',
    'Sporting Goods',
    'Toys & Games',
    'Vehicles & Parts'
];


export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const countryCodes = ['ad', 'ae', 'af', 'ag', 'ai', 'al', 'am', 'an', 'ao', 'aq', 'ar', 'as', 'at', 'au', 'aw', 'ax', 'az', 'ba', 'bb', 'bd', 'be', 'bf', 'bg', 'bh', 'bi', 'bj', 'bl', 'bm', 'bn', 'bo', 'bq', 'br', 'bs', 'bt', 'bv', 'bw', 'by', 'bz', 'ca', 'cc', 'cd', 'cf', 'cg', 'ch', 'ci', 'ck', 'cl', 'cm', 'cn', 'co', 'cr', 'cs', 'cu', 'cv', 'cw', 'cx', 'cy', 'cz', 'de', 'dj', 'dk', 'dm', 'do', 'dz', 'ec', 'ee', 'eg', 'eh', 'er', 'es', 'et', 'fi', 'fj', 'fk', 'fm', 'fo', 'fr', 'ga', 'gb', 'gd', 'ge', 'gf', 'gg', 'gh', 'gi', 'gl', 'gm', 'gn', 'gp', 'gq', 'gr', 'gs', 'gt', 'gu', 'gw', 'gy', 'hk', 'hm', 'hn', 'hr', 'ht', 'hu', 'id', 'ie', 'il', 'im', 'in', 'io', 'iq', 'ir', 'is', 'it', 'je', 'jm', 'jo', 'jp', 'ke', 'kg', 'kh', 'ki', 'km', 'kn', 'kp', 'kr', 'kw', 'ky', 'kz', 'la', 'lb', 'lc', 'li', 'lk', 'lr', 'ls', 'lt', 'lu', 'lv', 'ly', 'ma', 'mc', 'md', 'me', 'mf', 'mg', 'mh', 'mk', 'ml', 'mm', 'mn', 'mo', 'mp', 'mq', 'mr', 'ms', 'mt', 'mu', 'mv', 'mw', 'mx', 'my', 'mz', 'na', 'nc', 'ne', 'nf', 'ng', 'ni', 'nl', 'no', 'np', 'nr', 'nu', 'nz', 'om', 'pa', 'pe', 'pf', 'pg', 'ph', 'pk', 'pl', 'pm', 'pn', 'pr', 'ps', 'pt', 'pw', 'py', 'qa', 're', 'ro', 'rs', 'ru', 'rw', 'sa', 'sb', 'sc', 'sd', 'se', 'sg', 'sh', 'si', 'sj', 'sk', 'sl', 'sm', 'sn', 'so', 'sr', 'ss', 'st', 'sv', 'sx', 'sy', 'sz', 'tc', 'td', 'tf', 'tg', 'th', 'tj', 'tk', 'tl', 'tm', 'tn', 'to', 'tr', 'tt', 'tv', 'tw', 'tz', 'ua', 'ug', 'um', 'us', 'uy', 'uz', 'va', 'vc', 've', 'vg', 'vi', 'vn', 'vu', 'wf', 'ws', 'xk', 'ye', 'yt', 'za', 'zm', 'zr', 'zw'];
export const countryNames = [
    'Andorra',
    'United Arab Emirates',
    'Afghanistan',
    'Antigua and Barbuda',
    'Anguilla',
    'Albania',
    'Armenia',
    'Netherlands Antilles',
    'Angola',
    'Antarctica',
    'Argentina',
    'American Samoa',
    'Austria',
    'Australia',
    'Aruba',
    'Aland Islands',
    'Azerbaijan',
    'Bosnia and Herzegovina',
    'Barbados',
    'Bangladesh',
    'Belgium',
    'Burkina Faso',
    'Bulgaria',
    'Bahrain',
    'Burundi',
    'Benin',
    'Saint Barthelemy',
    'Bermuda',
    'Brunei Darussalam',
    'Bolivia',
    'Bonaire, Saint Eustatius and Saba',
    'Brazil',
    'Bahamas',
    'Bhutan',
    'Bouvet Island',
    'Botswana',
    'Belarus',
    'Belize',
    'Canada',
    'Cocos (Keeling) Islands',
    'Democratic Republic of the Congo',
    'Central African Republic',
    'Republic of the Congo',
    'Switzerland',
    'Ivory Coast (Cote D\'Ivoire )',
    'Cook Islands',
    'Chile',
    'Cameroon',
    'China',
    'Colombia',
    'Costa Rica',
    'Serbia and Montenegro',
    'Cuba',
    'Cape Verde',
    'Curacao',
    'Christmas Island',
    'Cyprus',
    'Czech Republic',
    'Germany',
    'Djibouti',
    'Denmark',
    'Dominica',
    'Dominican Republic',
    'Algeria',
    'Ecuador',
    'Estonia',
    'Egypt',
    'Western Sahara',
    'Eritrea',
    'Spain',
    'Ethiopia',
    'Finland',
    'Fiji',
    'Falkland Islands (Malvinas)',
    'Micronesia',
    'Faroe Islands',
    'France',
    'Gabon',
    'Great Britain (UK)',
    'Grenada',
    'Georgia',
    'French Guiana',
    'Guernsey',
    'Ghana',
    'Gibraltar',
    'Greenland',
    'Gambia',
    'Guinea',
    'Guadeloupe',
    'Equatorial Guinea',
    'Greece',
    'South Georgia and the South Sandwich Islands\n',
    'Guatemala',
    'Guam',
    'Guinea-Bissau',
    'Guyana',
    'Hong Kong',
    'Heard Island and McDonald Islands',
    'Honduras',
    'Croatia (Hrvatska)',
    'Haiti',
    'Hungary',
    'Indonesia',
    'Ireland',
    'Israel',
    'Isle of Man',
    'India',
    'British Indian Ocean Territory',
    'Iraq',
    'Iran',
    'Iceland',
    'Italy',
    'Jersey',
    'Jamaica',
    'Jordan',
    'Japan',
    'Kenya',
    'Kyrgyzstan',
    'Cambodia',
    'Kiribati',
    'Comoros',
    'Saint Kitts and Nevis',
    'Korea (North)',
    'Korea (South)',
    'Kuwait',
    'Cayman Islands',
    'Kazakhstan',
    'Laos',
    'Lebanon',
    'Saint Lucia',
    'Liechtenstein',
    'Sri Lanka',
    'Liberia',
    'Lesotho',
    'Lithuania',
    'Luxembourg',
    'Latvia',
    'Libya',
    'Morocco',
    'Monaco',
    'Moldova',
    'Montenegro',
    'Saint Martin',
    'Madagascar',
    'Marshall Islands',
    'Macedonia',
    'Mali',
    'Myanmar',
    'Mongolia',
    'Macao',
    'Northern Mariana Islands',
    'Martinique',
    'Mauritania',
    'Montserrat',
    'Malta',
    'Mauritius',
    'Maldives',
    'Malawi',
    'Mexico',
    'Malaysia',
    'Mozambique',
    'Namibia',
    'New Caledonia',
    'Niger',
    'Norfolk Island',
    'Nigeria',
    'Nicaragua',
    'Netherlands',
    'Norway',
    'Nepal',
    'Nauru',
    'Niue',
    'New Zealand (Aotearoa)',
    'Oman',
    'Panama',
    'Peru',
    'French Polynesia',
    'Papua New Guinea',
    'Philippines',
    'Pakistan',
    'Poland',
    'Saint Pierre and Miquelon',
    'Pitcairn',
    'Puerto Rico',
    'Palestinian Territory',
    'Portugal',
    'Palau',
    'Paraguay',
    'Qatar',
    'Reunion',
    'Romania',
    'Serbia',
    'Russia',
    'Rwanda',
    'Saudi Arabia',
    'Solomon Islands',
    'Seychelles',
    'Sudan',
    'Sweden',
    'Singapore',
    'Saint Helena',
    'Slovenia',
    'Svalbard and Jan Mayen',
    'Slovakia',
    'Sierra Leone',
    'San Marino',
    'Senegal',
    'Somalia',
    'Suriname',
    'South Sudan',
    'Sao Tome and Principe',
    'El Salvador',
    'Sint Maarten',
    'Syria',
    'Swaziland',
    'Turks and Caicos Islands',
    'Chad',
    'French Southern Territories',
    'Togo',
    'Thailand',
    'Tajikistan',
    'Tokelau',
    'East Timor',
    'Turkmenistan',
    'Tunisia',
    'Tonga',
    'Turkey',
    'Trinidad and Tobago',
    'Tuvalu',
    'Taiwan',
    'Tanzania',
    'Ukraine',
    'Uganda',
    'United States Minor Outlying Islands',
    'US',
    'Uruguay',
    'Uzbekistan',
    'Vatican',
    'Saint Vincent and the Grenadines',
    'Venezuela',
    'Virgin Islands (British)',
    'U.S. Virgin Islands',
    'Vietnam',
    'Vanuatu',
    'Wallis and Futuna',
    'Samoa',
    'Kosovo',
    'Yemen',
    'Mayotte',
    'South Africa',
    'Zambia',
    'Zaire (former)',
    'Zimbabwe'
];

export const names = ['Mignon Safhill', 'Suki Neiland', 'Missie Cockerill', 'Isa Rubinfeld', 'Janetta Olver', 'Donavon Albone', 'Felice Winram', 'Lauritz Romi', 'Marsha Darracott', 'Sammy Boteman', 'Drew Langtree', 'Josefa Philcox', 'Kalli Booley', 'Mei Hodgets', 'Vicky Macauley', 'Gottfried Tallet', 'Dilan Plumer', 'Myrilla Sarah', 'Ardene Denk', 'Gertrudis Coburn', 'Julio Hrishchenko', 'Romola McGurn', 'Mareah Ratnege', 'Wilt Kettles', 'Reba Kike', 'Lynette Airlie', 'Algernon Areles', 'Barton Rosie', 'Chic Tanser', 'Emelina Baal', 'Kellyann Kenyam', 'Obadiah Castellaccio', 'Blake Janu', 'Roxie Cholwell', 'Lenee Rackley', 'Camala Whitney', 'Vivianna Minigo', 'Giovanni Cockhill', 'Sibel Bloomer', 'Susana Krolik', 'Mora Simione', 'Pepito Rylstone', 'Kippy Hamm', 'Tyrus Saines', 'Winnifred Borrie', 'Cathlene Stoodley', 'Romain Sowden', 'Korey Hadlington', 'Martino Dust', 'Violet Seamer', 'Norah Pladen', 'Brett Guinery', 'Lavena Goly', 'Joli Divell', 'Kayla Curlis', 'Bethena Rodolf', 'Dulciana Leeder', 'Stevie O\'Feeny', 'Berrie Stallebrass', 'Hyacinth Fearnill', 'Philbert Mansell', 'Abner Dicken', 'Kimberley Tremblay', 'Garrik Kirke', 'Drusy Swainger', 'Hollie Semiraz', 'Denis Dumper', 'Nelli Kenshole', 'Krystle Wilcock', 'Harold Bricham', 'Granny Cade', 'Gail Salerg', 'Goddard Rubinshtein', 'Rahel Pringell', 'Bud Kezourec', 'Sherye Motten', 'Tiffy Winborn', 'Jaye Gelling', 'Liam Hulme', 'Ailis Cantopher', 'Valeria Petre', 'Nicholas Haydon', 'Ev Blankman', 'Karole Malone', 'Devin Tysack', 'Korrie Gorger', 'Nona Hein', 'Catlaina Davenell', 'Clair Vanderson', 'Domingo Iglesia', 'Woodman Chiechio', 'Leilah McVaugh', 'Shadow Genery', 'Darcey McArdell', 'Alma Lilley', 'Jack Camlin', 'Estella Fillis', 'Odell Dowsey', 'Birk Fattore', 'Robinett Bleythin'];
export const issues = [
    'Incorrect Item in my Subscription box',
    'Overcharged',
    'Broken Items',
    'My subscription has not arrived/been delivered',
    'items do not match offer',
    'wrong shipping address',
    'can i get a refund',
    'will my subscription box still be shipped if it\'s paused',
    'will i still have access to my subscription service if it\'s paused',
    'other'
];


export const images = [
    'https://files.slack.com/files-pri/TGQRSHC23-FK2LALMS6/bark_box_subscription.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FK7N5C1TK/subscription_7.jpg',
    'https://files.slack.com/files-pri/TGQRSHC23-FKDJ4U2UC/subscription_box_6.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FKFTZNBRV/digital_subscription.jpg',
    'https://files.slack.com/files-pri/TGQRSHC23-FKDJ4U2UC/subscription_box_6.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FKDJ4UATW/subscription_box.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FKE2ZQ0CV/subscription_box_5.jpg',
    'https://files.slack.com/files-pri/TGQRSHC23-FKE2ZQ8G5/subscription_box.jpg',
    'https://files.slack.com/files-pri/TGQRSHC23-FKE2ZQBAR/subscription_box_3.jpg',
    'https://files.slack.com/files-pri/TGQRSHC23-FK2LALQHG/subscription_box_4.jpg',
    'https://files.slack.com/files-pri/TGQRSHC23-FK7NFD5RP/spotify-logo-png-icon-spotify-4500.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FKE3LJBAL/10-050345-11_ways_to_get_a_cheaper_gym_membership.jpg',
    'https://files.slack.com/files-pri/TGQRSHC23-FK2M8D3EF/screen_shot_2019-06-10_at_11.46.27_am.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FK7NY9MQR/screen_shot_2019-06-10_at_11.46.19_am.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FKFUSLV4P/screen_shot_2019-06-10_at_11.45.58_am.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FK2M3JUHG/screen_shot_2019-06-10_at_11.45.46_am.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FKE3SQEHK/screen_shot_2019-06-10_at_11.45.29_am.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FK2M3K27L/screen_shot_2019-06-10_at_11.44.54_am.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FK2M8TQV9/cinemark-subscription-plan.jpg',
    'https://files.slack.com/files-pri/TGQRSHC23-FK2M92PQB/moviepass-promo-700x323.jpg',
    'https://files.slack.com/files-pri/TGQRSHC23-FK7P01TEV/screen_shot_2019-06-10_at_11.49.00_am.png',
    'https://files.slack.com/files-pri/TGQRSHC23-FK2MAUX8T/playstation_plus.0.jpg',
    'https://files.slack.com/files-pri/TGQRSHC23-FK7P1GT7T/netflix_v2.png'
];


export enum ProductTypes {
    digital = 'digital',
    physical = 'physical',
    preorder = 'preorder',
    nonProfit ='nonprofit'
}

export const products = [
    {
        image: 'https://i.ibb.co/VC67p49/carousel-2.jpg',
        type: ProductTypes.physical
    },
    {
        image: 'https://i.ibb.co/4t8WJrC/bark-box-subscription.png',
        type: ProductTypes.physical
    },
    {
        image: 'https://i.ibb.co/mFpVW7f/porches.png',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/R3bX2jd/digital-subscription.jpg',
        type: ProductTypes.digital
    }, {
        image: 'https://i.ibb.co/YRvCMXy/subscription-box-6.png',
        type: ProductTypes.digital
    }, {
        image: 'https://i.ibb.co/gP2BXhV/Subscription-box.png',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/pjbcVHC/plastic-waste-single-use-worldwide-consumption-animals-2-adapt-1190-1.jpg',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/rM1NHnT/Subscription-box.jpg',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/djNtrs6/Singapore-Airlines-Business-Class2.jpg',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/bzB4HmB/Virgin-Active.png',
        type: ProductTypes.preorder
    }, {
        image: 'https://i.ibb.co/j6Dydbc/Verizon-Galaxy-S10-series.jpg',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/bRbTMMd/10-050345-11-ways-to-get-a-cheaper-gym-membership.jpg',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/zrpS3zN/Screen-Shot-2019-06-10-at-11-46-27-AM.png',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/H2ztmM7/Screen-Shot-2019-06-10-at-11-46-19-AM.png',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/7KMb0YC/Screen-Shot-2019-06-10-at-11-45-58-AM.png',
        type: ProductTypes.preorder
    }, {
        image: 'https://i.ibb.co/rfWkZsT/Screen-Shot-2019-06-10-at-11-45-46-AM.png',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/J5Vkdkm/Screen-Shot-2019-06-10-at-11-45-29-AM.png',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/gvkVgnG/Screen-Shot-2019-06-10-at-11-44-54-AM.png',
        type: ProductTypes.physical
    }, {
        image: 'https://i.ibb.co/cY7kJVm/4-Ocean2018-Clean-Up.jpg',
        type: ProductTypes.digital
    }, {
        image: 'https://i.ibb.co/MSY7T05/moviepass-promo-700x323.jpg',
        type: ProductTypes.digital
    }, {
        image: 'https://i.ibb.co/R2KzdJj/Screen-Shot-2019-06-10-at-11-49-00-AM.png',
        type: ProductTypes.digital
    }, {
        image: 'https://i.ibb.co/cQX79Gn/Aviva-Plus-card-imge.jpg',
        type: ProductTypes.digital
    }, {
        image: 'https://i.ibb.co/Pw98jRk/netflix-v2.png',
        type: ProductTypes.digital
    },
    {
        image: 'https://i.ibb.co/dWtyk9n/carousel-2.jpg',
        type: ProductTypes.digital
    },
    {
        image: 'https://i.ibb.co/wWFmXkK/jlrwhat3wordsisleofmull050618021-770x446.jpg',
        type: ProductTypes.physical
    },
    {
        image: 'https://i.ibb.co/cQX79Gn/Aviva-Plus-card-imge.jpg',
        type: ProductTypes.digital
    },
    {
        image: 'https://i.ibb.co/CbcYxG4/Singapore-Airlines-Business-Class2.jpg',
        type: ProductTypes.physical
    }
    , {
        image: 'https://i.ibb.co/x2P9cv7/Streaming-Cable-TV-Subscription-Services.jpg',
        type: ProductTypes.digital
    },
    {
        image: 'https://i.ibb.co/qn1zrRB/shopify-setup-740x416.jpg',
        type: ProductTypes.digital
    }, {
        image: 'https://i.ibb.co/7bFm9S8/playstation-plus-0.jpg',
        type: ProductTypes.digital
    },
    {
        image: 'https://i.ibb.co/5GnX4GW/Store-PS-Now-Full.jpg',
        type: ProductTypes.digital
    },
    {
        image: 'https://i.ibb.co/SdKwb8D/vive-pro-full-kit.jpg',
        type: ProductTypes.digital
    }
];

export const merchantChannelPosts = [
    {image: 'https://i.ibb.co/tZrCy1Y/boxbanner.png', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/R73n3Fp/How-to-Make-the-Most-of-Linked-In.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/5M6ww81/38a308bfbcbd76ed-2048x1024.png', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/yg2h8vr/100-1.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/TMjQss1/mediafire-banner.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/jMv5z1X/aupin13120009.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/rkbH5x3/Send-Blaster-windows.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/hCgDH9N/2016.png', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/HFYgqQX/onedrivestock.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/n8w2JpL/woo-commerce-eshop.png', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/mCc2bBt/Magento-Logo.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/4Sf0rdG/pcloud-logo-5b911eef46e0fb0050b66963.png', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/crcFSL8/Microsoft-Office.png', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/qn1zrRB/shopify-setup-740x416.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/6N6WQWK/Ship-Station.png', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/1QSk8Nq/dropbox-product-image.png', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/hcxfsRr/Adobe-Banner-1.png', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/Jjtk9pc/4240-universal-display.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/9vpTYBp/201803151259043478.jpg', type: ProductTypes.nonProfit},
    {image: 'https://i.ibb.co/SPH9dff/banner-3.jpg', type: ProductTypes.physical},
    {image: 'https://i.ibb.co/tqYHhhV/Cisco-logo.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/KbZmjxp/donaldson-filterminder-electric-sensor-photo-697x516-a.png', type: ProductTypes.physical},
    {image: 'https://i.ibb.co/PrBNV6k/email-subscription-plugins.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/VwkmHKq/Help-Scout.png', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/X8VKgzN/Java.png', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/80dNyM7/JD-CE-HE-Group-2-1366x768.jpg', type: ProductTypes.physical},
    {image: 'https://i.ibb.co/FnjpsJ6/maxresdefault.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/0mhmSc2/us-steel-flag.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/YDc1PzD/mobility-solutions-photograph-848w477h.jpg', type: ProductTypes.digital},
    {image: 'https://i.ibb.co/pjbcVHC/plastic-waste-single-use-worldwide-consumption-animals-2-adapt-1190-1.jpg', type: ProductTypes.nonProfit},
    {image: 'https://i.ibb.co/S0DLD0G/Sumo-logo1.png', type: ProductTypes.digital},
];
