import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Auth0DecodedHash } from 'auth0-js';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { TORUS_PRIVATE_KEY } from 'src/app/constants';
import { LoadingService } from 'src/app/core/services/loading.service';
import { HttpService } from 'src/app/retailers/services/http.service';
import {
    getCurrentProvider,
    getCurrentVerifier,
    getURLTorusParams,
    handleRedirectParameters,
    saveCurrentVerifier,
    saveTorusPrivateKey,
    Verifier
} from 'src/app/utils';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../auth/services/auth.service';
import { Web3InstanceService } from '../../../auth/services/web3-instance.service';
import { Web3modalService } from '../../../auth/services/web3modal.service';
import { AuthModalComponent } from '../../modals/auth-modal/auth-modal.component';

export type PasswordlessOptions = 'phone' | 'email';

@Component({
    selector: 'xion-home',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy, AfterViewInit {
    user$: Subscription;

    emailForm: FormGroup;
    codeForm: FormGroup;
    emailError: string;
    codeError: string;
    isSubmit: boolean = false;
    audio: any;
    constructor(
        private fb: FormBuilder,
        private router: Router,
        private dialog: MatDialog,
        private auth: AuthService,
        private httpService: HttpService,
        private web3modalService: Web3modalService,
        private web3InstanceService: Web3InstanceService,
        public loadingService: LoadingService
    ) {
        this.user$ = this.auth.currentUser.subscribe((user) => {
            if (user) {
                const walletAddress = this.auth.loggedInUser.walletAddress;
                this.httpService.getUserByWallet(walletAddress).subscribe((res) => {
                    this.auth.setUserPlan(res.xionMerchantType);
                    this.router.navigateByUrl(this.auth.getAuthRedirectLink()).then();
                });
            }
        });

        this.emailForm = this.createForm();
        this.codeForm = this.createCodeForm();
    }

    async ngOnInit() {
        if (this.auth.loggedInUser?.walletAddress) return;
        let auth0: Auth0DecodedHash;
        const provider = getCurrentProvider();
        localStorage.clear();
        try {
            auth0 = await this.auth.parseHash(provider);
        } catch (e) {}

        if (auth0) {
            try {
                await this.handleAuth0Login(auth0);
            } catch (e) {}
        } else {
            const { hash, queryParams } = getURLTorusParams(location.href);
            const { instanceParameters } = handleRedirectParameters(hash, queryParams);

            if (instanceParameters && instanceParameters.verifier) {
                const verifier = instanceParameters.verifier;
                if (verifier === environment.phoneConfig.verifier) {
                    await this.passwordlessLogin('phone', hash, queryParams);
                } else if (verifier === environment.emailConfig.verifier) {
                    await this.passwordlessLogin('email', hash, queryParams);
                } else {
                    await this.login(verifier, hash, queryParams);
                }
            }
        }
    }

    ngAfterViewInit() {
        $('.wrapper.grey-part-bg').attr('style', `min-height: ${$(window).height() - 44}px`);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        $('.wrapper.grey-part-bg').attr('style', `min-height: ${$(window).height() - 44}px`);
    }

    ngOnDestroy(): void {
        this.user$.unsubscribe();
    }

    async login(verifier: Verifier, hash?, queryParams?) {
        try {
            let publicAddress = null;
            if (verifier === 'web3modal') {
                publicAddress = await this.web3modalService.login();
                localStorage.removeItem(TORUS_PRIVATE_KEY);
                this.loadingService.show();
            } else {
                this.loadingService.show();
                const userInfo = await this.auth.torusLogin(verifier, hash, queryParams);
                this.web3modalService.saveConnectorId(null);
                saveTorusPrivateKey(userInfo.privateKey);
                publicAddress = userInfo.publicAddress;
            }
            this.auth.setAddress(publicAddress);
            let exists ;
            try {
                exists = await this.httpService.isUserExists(publicAddress).toPromise();
                if (!exists) {
                    await this.httpService.createUser(publicAddress).toPromise();
                }
            } catch (error) {
                console.log(error);
            }
            await this.auth.setCurrentUser(publicAddress);
            this.web3InstanceService.clearInstance();
            if(!exists){
                setTimeout(() => {
                    this.audio = new Audio();
                    this.audio.src = "../../assets/mixkit-rocket-engine-ignition-rumble-1715.wav";
                    this.audio.load();
                    this.audio.play();
                }, 1500);
            }

            // this.router.navigateByUrl(this.auth.getAuthRedirectLink()).then();
        } catch (error) {
            console.log(error);
        } finally {
            this.loadingService.hide();
        }
    }

    get isEmailValid() {
        return this.emailForm && this.emailForm.valid;
    }

    get isEmailPristine() {
        return this.emailForm.pristine;
    }

    get isCodePristine() {
        return this.codeForm.pristine;
    }

    get isCodeValid() {
        return this.codeForm && this.codeForm.valid;
    }

    get email() {
        return this.emailForm.get('email').value;
    }

    get code() {
        return this.codeForm.get('code').value;
    }

    navigateToHelp(): void {
        window.open('https://discord.gg/QfKm5jsq6d', '_blank').focus();
    }

    onSendCode(): void {
        saveCurrentVerifier('email');
        this.loadingService.show();
        this.auth
            .getEmailCode(this.email)
            .then((res) => {
                this.loadingService.hide();
                this.isSubmit = true;
            })
            .catch((err) => {
                this.loadingService.hide();
                this.emailError = err.description || 'error sending code';
            });
    }

    onAuthenticate(): void {
        this.loadingService.show();
        this.auth
            .authenticateEmailCode(this.email, this.code)
            .then((res) => {
                this.loadingService.hide();
            })
            .catch((err) => {
                this.loadingService.hide();
                this.codeError = err.description || 'error performing verification';
            });
    }

    async handleAuth0Login(response: Auth0DecodedHash) {
        try {
            this.loadingService.show();
            if (!response || !response.idTokenPayload) return;
            const verifierId = response.idTokenPayload.name;
            const verifierParams = { verifier_id: response.idTokenPayload.name };
            const idToken = response.idToken;
            const verifier = getCurrentVerifier();
            const userInfo = await this.auth.torus.getTorusKey(
                verifier,
                verifierId,
                verifierParams,
                idToken
            );

            saveTorusPrivateKey(userInfo.privateKey);

            this.auth.setAddress(userInfo.publicAddress);
            try {
                const exists = await this.httpService
                    .isUserExists(userInfo.publicAddress)
                    .toPromise();
                if (!exists) await this.httpService.createUser(userInfo.publicAddress).toPromise();
            } catch (e) {}
            this.auth.setCurrentUser(userInfo.publicAddress).then(() => {
                this.router.navigateByUrl(this.auth.getAuthRedirectLink());
            });
        } catch (e) {
        } finally {
            this.loadingService.hide();
        }
    }

    async passwordlessLogin(type: PasswordlessOptions, hash?, queryParams?) {
        try {
            this.loadingService.show();
            const userInfo =
                type === 'email'
                    ? await this.auth.emailLogin(hash, queryParams)
                    : await this.auth.phoneLogin(hash, queryParams);
            saveTorusPrivateKey(userInfo.privateKey);
            this.auth.setAddress(userInfo.publicAddress);
            try {
                const exists = await this.httpService
                    .isUserExists(userInfo.publicAddress)
                    .toPromise();
                if (!exists) await this.httpService.createUser(userInfo.publicAddress).toPromise();
            } catch (e) {}
            this.auth.setCurrentUser(userInfo.publicAddress).then(() => {
                this.router.navigateByUrl(this.auth.getAuthRedirectLink());
            });
        } catch (e) {
        } finally {
            this.loadingService.hide();
        }
    }

    private createForm(): FormGroup {
        return this.fb.group({ email: [null, [Validators.required, Validators.email]] });
    }

    private createCodeForm(): FormGroup {
        return this.fb.group({ code: [null, [Validators.required, Validators.minLength(6)]] });
    }

    private openAuthNotificationPopup(): void {
        this.dialog
            .open(AuthModalComponent)
            .afterClosed()
            .subscribe();
    }
}
