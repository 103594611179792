import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';

@Component({
  selector: 'xion-revenu-chart-modal',
  templateUrl: './revenu-chart-modal.component.html',
  styleUrls: ['./revenu-chart-modal.component.scss']
})
export class RevenuChartModalComponent implements OnInit {

    revenue: Observable<any>;
    // showCHart -> to fix conflict between modal plugin and
    // chart plugin. Issue with width of chart
    showCHart = false;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<RevenuChartModalComponent>) { }

    ngOnInit() {
        this.revenue = this.data;
        setTimeout(() => {
            this.showCHart = true;
        }, 100);
    }
    closeDialog() {
        this.dialogRef.close();
    }
}
