<aside class="aside">
    <button class="button__close-aside" (click)="asideService.toggleAside()" *ngIf="user">
        <svg class="svg-icon icon-close_aside">
            <use xlink:href="#close-aside"></use>
        </svg>
    </button>

    <div class="aside__container">
        <a [routerLink]="['/merchant','create']" class="main-link">
            <svg class="svg-icon icon-logo">
                <use xlink:href="#logo"></use>
            </svg>
            <span class="aside__logo-text" *ngIf="user">MENU</span>
        </a>
        <div class="aside__container_menu">
            <ng-content></ng-content>

            <div >
                <ul class="aside-link__bottom-holder mobile">
                    <li class="navigation-aside__item_bottom navigation-aside__item_bottom-help" (click)="openHelp()">
                        <a class="navigation-aside__link" >
                            <span class="link-text__static">
                                &nbsp;&nbsp;Help
                            </span>
                            <span class="link-text__active">
                                &nbsp;&nbsp;Help
                            </span>
                        </a>
                    </li>
                    <li class="navigation-aside__item_bottom navigation-aside__item_bottom-logout" (click)="logout()">
                        <a class="navigation-aside__link" >
                            <span class="link-text__static">
                                &nbsp;
                            </span>
                            <span class="link-text__active">
                                <li class="navigation-aside__item_bottom navigation-aside__item_bottom-settings">
                                    <a class="navigation-aside__link" >
                                        <span class="link-text__static">
                                            &nbsp;
                                        </span>
                                        <span class="link-text__active">
                                            &nbsp;
                                        </span>
                                    </a>
                                </li>
                                &nbsp;
                            </span>
                        </a>
                    </li>
                </ul>
                <ul class="aside-link__bottom-holder">
                    <li class="navigation-aside__item_bottom navigation-aside__item_bottom-logout">
                        <a class="navigation-aside__link" (click)="logout()">
                            <span class="link-text__static">
                                LOGOUT
                            </span>
                            <span class="link-text__active">
                                LOGOUT
                            </span>
                        </a>
                    </li>
                    <li class="navigation-aside__item_bottom navigation-aside__item_bottom-term">
                        <a href="../../../../assets/Terms-of-Use.pdf" target="_blank" class="navigation-aside__link">
                            <span class="link-text__static">
                                T&CS
                            </span>
                            <span class="link-text__active">
                                T&CS
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</aside>
