import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xion-credentials-confirm',
  templateUrl: './credentials-confirm.component.html',
  styleUrls: ['./credentials-confirm.component.scss']
})
export class CredentialsConfirmComponent implements OnInit {


  constructor(private dialogRef: MatDialogRef<CredentialsConfirmComponent>){}


  ngOnInit() {
  }

  closeDialog(answer) {
    this.dialogRef.close(answer);
  }

}
