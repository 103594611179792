import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IPost } from '../../../pages/social/social.component';
import { PostsService } from '../../../../retailers/services/posts.service';
import { IAttachment } from '../../../models/models';
import { find } from 'lodash';
import { IUser, UserService } from '../../../../core/services/user.service';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationsService } from '../../../../core/services/notifications.service';

@Component({
    selector: 'xion-channel-post-item',
    templateUrl: './social-post-item.component.html',
    styleUrls: ['./social-post-item.component.scss']
})
export class SocialPostItemComponent implements OnChanges {

    @Input() post: IPost;
    @Input() tab: string;
    @Input() user: IUser;

    @Output() follow = new EventEmitter<IUser>();
    @Output() like = new EventEmitter<string>();

    activeTabItem: string;
    showFull = false;
    isEditingPost = false;
    showFullLinkPreview = false;

    constructor(
        private notifications: NotificationsService,
        private clipboard: ClipboardService,
        private postsService: PostsService,
    ) {
    }


    toggleShowMore() {
        this.showFull = !this.showFull;
    }

    toggleEditPost() {
        this.isEditingPost = !this.isEditingPost;
        this.postsService.setIsEditingPost(this.isEditingPost);
    }

    likePost() {
        this.like.emit(this.post.id);
        this.post.liked = !this.post.liked;
    }

    followUser(user: IUser) {
        this.follow.emit(user);
        this.post.user.followed = !this.post.user.followed;
    }

    sharePost() {
        const URL = `${document.URL}/${this.post.id}`;
        this.notifications.showMessage('The Post URL has been copied to your clipboard', 'Ok');
        this.clipboard.copyFromContent(URL);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const attachments: IAttachment[] = this.post.attachments as IAttachment[];
        this.post.photo = find(attachments, (item: IAttachment) => item.filetype === 'photo') || null;
        this.post.video = find(attachments, (item: IAttachment) => item.filetype === 'video') || null;
        this.post.document = find(attachments, (item: IAttachment) => item.filetype === 'document') || null;
        this.activeTabItem = this.post.photo ? 'photo' : 'video';
    }


}
