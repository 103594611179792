import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'xion-auth-modal',
    templateUrl: './auth-modal.component.html',
    styleUrls: ['./auth-modal.component.scss']
})
export class AuthModalComponent implements OnInit, OnDestroy {

    countdown: number = 5;
    countdownTimer: NodeJS.Timer;
    public link: string = environment.chromeExtensionLink;

    constructor() {
    }

    ngOnInit() {
        // this.countdownTimer = setInterval(() => {
        //     this.countdown --;
        //     if (!this.countdown) {
        //         this.clearInterval(this.countdownTimer);
        //         window.location.href=environment.chromeExtensionLink
        //     }
        // }, 1000);
    }

    public ngOnDestroy(): void {
        this.clearInterval(this.countdownTimer);
    }

    private clearInterval(targetInterval: NodeJS.Timer): void {
        clearInterval(targetInterval);
    }

}
