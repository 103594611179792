<div class="popup-blue__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title">
            Discount Calculator
        </h3>
        <form [formGroup]="discountForm">
            <fieldset class="fieldset">
                <legend class="legend">Select Plan</legend>
                <mat-radio-group (change)="onProductTypeChange()" formControlName="plan">
                    <mat-radio-button value="daily">Daily</mat-radio-button>
                    <mat-radio-button value="weekly">Weekly</mat-radio-button>
                    <mat-radio-button value="monthly">Monthly</mat-radio-button>
                    <mat-radio-button value="yearly">Yearly</mat-radio-button>
                </mat-radio-group>
            </fieldset>

            <fieldset class="fieldset" *ngIf="!isOneTimePayment">
                <legend class="legend">Select Duration</legend>
                <div class="form__row">
                    <div class="form-label__holder">
                        <label class="form-label text-overflow" for="start">Start date</label>
                        <div class="filter-item">
                            <mat-form-field class="select-white__border">
                                <input matInput [matDatepicker]="start" placeholder="Start" id="start"
                                       formControlName="start_date" [min]="today" (dateChange)="calculateDuration()">
                                <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                                <mat-datepicker #start></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-label__holder">
                        <label class="form-label text-overflow" for="end">End date</label>
                        <div class="filter-item">
                            <mat-form-field class="select-white__border">
                                <input matInput [matDatepicker]="end" id="end" placeholder="End"
                                       formControlName="end_date" [min]="today" (dateChange)="calculateDuration()">
                                <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                                <mat-datepicker #end></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="form__row">
                    <div class="form-label__holder">
                        Or
                    </div>
                </div>
                <div class="form__row">
                    <div class="form-label__holder">
                        <label class="form-label text-overflow" for="duration">Number of {{duration}}</label>
                        <input class="textfield textfield__white-border" id="duration" name="duration"
                               placeholder="Number of {{duration}}" type="number" min="1" formControlName="duration"
                               #durationSelector (input)="calculateProjections(durationSelector.value)">
                    </div>
                </div>
            </fieldset>
            <div class="form__row select-white__border">
                <div class="form-label__holder">
                    <label class="form-label text-overflow" for="discount">Discount</label>
                    <ng-select
                        [multiple]="false"
                        [items]="discountLevels"
                        bindLabel="id"
                        bindValue="rate"
                        formControlName="discountLevel"
                        (change)="calculateProjections()"
                        placeholder="Level">
                    </ng-select>
                </div>
            </div>
            <div class="form__row">
                <div class="form-label__holder">
                    <label class="form-label text-overflow" for="manual">Price</label>
                    <input class="textfield textfield__white-border" id="manual" name="manual_price" placeholder="$10" formControlName="price"
                           min=0
                           (input)="calculateProjections()"
                           type="number">
                </div>
                <div class="form-label__holder">
                    <label class="form-label text-overflow" for="manual">Number of Subscribers</label>
                    <input class="textfield textfield__white-border" id="subscription" name="manual_price"
                           placeholder="1" min=1 max="100" formControlName="numberOfSubscriptions" (input)="calculateProjections()"
                           type="number">
                </div>
            </div>
            <div class="list-transactions__holder textfield textfield__white-border">
                <ul class="list-transactions__head">
                    <span class="head-item list__date">{{duration}}</span>
                    <span class="head-item list__cumulative">Customer Payments </span>
                    <span class="head-item list__cumulative">Customer Savings</span>
                </ul>
                <ul class="list-transactions">
                    <li class="list-transactions__item" *ngFor="let row of rows">
                        <span class="list__date list-transactions__details">
                            <strong class="head-item_mobile">{{duration}}:</strong>
                            {{row.day}}
                        </span>
                        <span class="list__cumulative list-transactions__details">
                            <strong class="head-item_mobile">Customer Payments:</strong>
                             {{row.payment | currency}}
                        </span>
                        <span class="list__cumulative list-transactions__details">
                            <strong class="head-item_mobile">Customer Savings:</strong>
                            {{row.savings | currency}}
                        </span>
                    </li>
                </ul>
            </div>
        </form>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>
