import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, pluck } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { mapResponseToData } from '../../shared/constants/helpers';
import { ISubscription } from '../../shared/interfaces/subscription.interface';
import { Tabs } from '../../shared/pages/marketplace/marketplace.component';

export interface ISubscriptionsFilters {
    username?: string;
    digital?: boolean;
    physical?: boolean;
    preorder?: boolean;
    nonprofit?: boolean;
    tab?: Tabs;
    limit?: number;
    start?: number;
    interestareas?: string[];
}

const cacheBuster$ = new Subject<void>();
const { MAX_CACHE_TIME, MAX_CACHE_COUNT } = environment;

@Injectable({
    providedIn: 'root'
})
export class SubscriptionsService {
    private SUBSCRIPTION = `${environment.api_url}/users/subscription`;
    private ACTIVE_SUBSCRIPTIONS = `${environment.api_url}/users/subscriptions`;
    private SUBSCRIBE = `${environment.api_url}/users/subscribe`;
    private UNSUBSCRIBE = `${environment.api_url}/users/unsubscribe`;
    private MY_SUBSCRIPTIONS = `${environment.api_url}/users/personalsubs`;
    private SUBSCRIBED = `${environment.api_url}/users/substo`;

    private PAUSE_SUBSCRIPTION = `${environment.api_url}/users/subpause`;
    private ACTIVATE_SUBSCRIPTION = `${environment.api_url}/users/subreactivate`;

    private PAUSE_MERCHANT_SUBSCRIPTION = `${environment.api_url}/users/merchsubpause`;
    private ACTIVATE_MERCHANT_SUBSCRIPTION = `${environment.api_url}/users/merchsubactivate`;
    private ARCHIVE_MERCHANT_SUBSCRIPTION = `${environment.api_url}/users/merchsubarchive`;

    private defaultFilters: ISubscriptionsFilters = {
        preorder: false,
        nonprofit: false,
        physical: true,
        digital: true,
        interestareas: [],
        limit: 10,
        start: 0,
        tab: 0,
        username: null
    };

    constructor(private http: HttpClient) {}

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public createSubscription(subscription: ISubscription): Observable<ISubscription> {
        return this.http.post(this.SUBSCRIPTION, subscription).pipe(pluck('data'));
        // return this.http.post(this.SUBSCRIPTION, subscription).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public subscribe(subscription: ISubscription, key: string, xionId?: string) {
        const URL = `${this.SUBSCRIBE}/${subscription.id}`;
        let params = new HttpParams();
        if (!!xionId) {
            params = params.set('aff', xionId);
        }
        return this.http.post(URL, { salt: key }, { params }).pipe(pluck('data'));
        // return this.http.post(URL, { salt: key }, { params }).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public rateSubscription(subscription: ISubscription, rating: number) {
        const URL = `${this.SUBSCRIPTION}/${subscription.id}/rate`;
        return this.http.post(URL, { rating }).pipe(pluck('data'));
        // return this.http.post(URL, { rating }).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public likeSubscription(subscription: ISubscription) {
        const URL = `${this.SUBSCRIPTION}/${subscription.id}/like`;
        return this.http.get(URL).pipe(pluck('data'));
        // return this.http.get(URL).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public pauseSubscription(subscription: ISubscription, key: string): Observable<any> {
        const URL = `${this.PAUSE_SUBSCRIPTION}/${subscription.id}`;
        return this.http.post(URL, { salt: key }).pipe(pluck('data'));
        // return this.http.post(URL, { salt: key }).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public activateSubscription(subscription: ISubscription, key: string): Observable<any> {
        const URL = `${this.ACTIVATE_SUBSCRIPTION}/${subscription.id}`;
        return this.http.post(URL, { salt: key }).pipe(pluck('data'));
        // return this.http.post(URL, { salt: key }).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public unsubscribe(subscription: ISubscription, key: string) {
        const URL = `${this.UNSUBSCRIBE}/${subscription.id}`;
        return this.http.post(URL, { salt: key }).pipe(pluck('data'));
        // return this.http.post(URL, { salt: key }).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public pauseMerchantSubscription(subscription: ISubscription, key: string) {
        const URL = `${this.PAUSE_MERCHANT_SUBSCRIPTION}/${subscription.id}`;
        return this.http.post(URL, { salt: key }).pipe(pluck('data'));
        // return this.http.post(URL, { salt: key }).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public activateMerchantSubscription(subscription: ISubscription, key: string) {
        const URL = `${this.ACTIVATE_MERCHANT_SUBSCRIPTION}/${subscription.id}`;
        return this.http.post(URL, { salt: key }).pipe(pluck('data'));
        // return this.http.post(URL, { salt: key }).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public archiveMerchantSubscription(subscription: ISubscription, key: string) {
        const URL = `${this.ARCHIVE_MERCHANT_SUBSCRIPTION}/${subscription.id}`;
        return this.http.post(URL, { salt: key }).pipe(pluck('data'));
        // return this.http.post(URL, { salt: key }).pipe(map(mapResponseToData));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME,
        maxCacheCount: MAX_CACHE_COUNT
    })
    public getSubscription(id: string): Observable<ISubscription> {
        const URL = `${this.SUBSCRIPTION}/${id}`;
        return this.http.get(URL).pipe(pluck('data'));
        // return this.http.get(URL).pipe(map(mapResponseToData));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    // public getCreatedSubscriptions(): Observable<ISubscription[]> {
    //     return this.http.get(this.MY_SUBSCRIPTIONS).pipe(pluck('data'));
    //     // return this.http.get(this.MY_SUBSCRIPTIONS).pipe(map(mapResponseToData));
    // }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    public getSubscribedSubscriptions(): Observable<ISubscription[]> {
        return this.http.get(this.SUBSCRIBED).pipe(pluck('data'));
        // return this.http.get(this.SUBSCRIBED).pipe(map(mapResponseToData));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME,
        maxCacheCount: MAX_CACHE_COUNT
    })
    public getSubscriptions(filters: ISubscriptionsFilters = this.defaultFilters): Observable<any> {
        const URL = `${this.SUBSCRIPTION}/list`;
        return this.http.post(URL, filters).pipe(pluck('data'));
        // return this.http.post(URL, filters).pipe(map(mapResponseToData));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    public getActiveSubscriptions(): Observable<Array<Partial<ISubscription>>> {
        return this.http.get(this.ACTIVE_SUBSCRIPTIONS).pipe(pluck('data'));
        // return this.http.get(this.ACTIVE_SUBSCRIPTIONS).pipe(map(mapResponseToData));
    }
}
