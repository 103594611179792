import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { Observable, Subject } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { mapResponseToData } from '../../shared/constants/helpers';
import { IOffer } from '../../shared/interfaces/offer.interface';

const cacheBuster$ = new Subject<void>();
const { MAX_CACHE_COUNT, MAX_CACHE_TIME } = environment;

@Injectable({
    providedIn: 'root'
})
export class OfferService {
    private OFFER = `${environment.api_url}/users/offer`;

    constructor(private http: HttpClient) {}

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    createOffer(offer: IOffer): Observable<IOffer> {
        return this.http.post(this.OFFER, offer).pipe(pluck('data'));
        // return this.http.post(this.OFFER, offer).pipe(map(mapResponseToData));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    getOffers(): Observable<IOffer[]> {
        return this.http.get(this.OFFER).pipe(pluck('data'));
        // return this.http.get(this.OFFER).pipe(map(mapResponseToData));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME,
        maxCacheCount: MAX_CACHE_COUNT
    })
    getOffer(id: string): Observable<IOffer> {
        const URL = `${this.OFFER}/${id}`;
        return this.http.get(URL).pipe(pluck('data'));
        // return this.http.get(URL).pipe(map(mapResponseToData));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    getSubscriptionOffers() {
        return this.http.get(this.OFFER).pipe(pluck('data'));
        // return this.http.get(this.OFFER).pipe(map(mapResponseToData));
    }
}
