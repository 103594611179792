<div class="custom-popup">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/eZjHiVsBV8s" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <h3 class="custom-popup-title">LETS GET YOU SIGNED UP!</h3>
    <p>
        <a target="_blank" [href]="link">Click here</a> to be redirected to the Chrome Store to download the Xion Wallet browser extension. You will need it to log into the Xion Platform 🛰️
    </p>

</div>
<!--<div class="custom-popup">-->
<!--    <img class="custom-popup-image" src="/assets/images/lets_signup.png" alt="lets_signup">-->
<!--    <h3 class="custom-popup-title">LETS GET YOU SIGNED UP!</h3>-->
<!--    <p>-->
<!--        You will now be redirected to the Chrome Store to download the Xion Wallet.-->
<!--        You need it to log into the Xion Platform 🛰️-->
<!--    </p>-->
<!--    <p>{{countdown}} s</p>-->
<!--</div>-->
