import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtService } from 'src/app/auth/services/jwt.service';
import { AuthService, UserRoleEnum } from '../../auth/services/auth.service';
import { Web3InstanceService } from '../../auth/services/web3-instance.service';
import { Web3modalService } from '../../auth/services/web3modal.service';
import { ConnectorID } from '../../shared/constants/config';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private web3InstanceService: Web3InstanceService,
        private web3ModalService: Web3modalService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!!this.authService.isLoggedIn) {
            // logged in so return true
            if (this.web3ModalService.getConnectorId() === ConnectorID.MetaMask) {
                this.web3InstanceService.observeMetaMaskEvents();
            }
            return true;
        }
        // not logged in so redirect to home page
        this.router.navigateByUrl('/home');
        return false;
    }
}
