<div class="card__item" [class.description-opened]="showDetails" [class.card__item_discount]="!!subscription.discount">
    <a class="card__picture-holder" [routerLink]="['/subscription',subscription.id]">
        <img class="absolute-img" *ngIf="subscription.image;else noSubscriptionImageSingle" [src]="subscription.image"
             [alt]="subscription.title">

        <ng-container *ngIf="subscription.products.length > 1">
               <img *ngIf="subscription.products[0]" class="card__small-img small-img_first" [src]="subscription.products[0].image" [alt]="subscription.products[0].title">
               <img *ngIf="subscription.products[1]" class="card__small-img small-img_second" [src]="subscription.products[1].image" [alt]="subscription.products[1].title">
               <img *ngIf="subscription.products[2]" class="card__small-img small-img_third" [src]="subscription.products[2].image" [alt]="subscription.products[2].title">
        </ng-container>

        <div class="card__logo" *ngIf="subscription.user.image;else noRetailerLogo" [title]="subscription.user.companyname">
            <img [src]="subscription.user.image" [alt]="subscription.user.companyname">
            <span class="notification-msg__holder notification-msg">
                {{subscription.user.companyname}}
            </span>
        </div>

        <div class="card__bottom">
            <h3 class="card__title">{{subscription.title}}</h3>
            <div class="card__price">
                <div class="notification-msg__holder">
                    {{subscription.price | currencyConverter | async}}
<!--                    <span *ngIf="!!subscription.discount" class="discount__amount">-->
<!--                        / 2 %-->
<!--                    </span>-->
<!--                    <span *ngIf="!!subscription.discount" class="discount__amount">-->
<!--                        / {{subscription.discount}}%-->
<!--                    </span>-->
                        {{subscription.periodicity | subscriptionPlan | slice: 0:1}}
                    <span class="notification-msg">
                        {{subscription.periodicity | subscriptionPlan}}
                    </span>
                </div>
            </div>
        </div>
    </a>
    <div class="card__actions-holder">
        <button class="card__actions" (click)="shareSubscription()">
            <span class="notification-msg__holder notification-msg__holder_share">
                <div class="notification-msg">Share</div>
                <svg class="svg-icon icon-share">
                    <use xlink:href="#icon-share"></use>
                </svg>
            </span>
        </button>

    </div>
    <div class="card__actions-holder card__actions_left-holder">
        <button class="card__actions" (click)="openPrivateKeyModal($event)">
            <span class="notification-msg__holder notification-msg__holder_subscribe">
                <div class="notification-msg">Subscribe</div>
                <svg class="svg-icon icon-subscribe">
                    <use xlink:href="#icon-subscribe_new"></use>
                </svg>
            </span>
        </button>
        <button class="card__actions card__actions_affiliate"  *ngIf="subscription.affiliate" (click)="affiliate()">
            <span class="notification-msg__holder notification-msg__holder_affiliate">
                <div class="notification-msg">Affiliate</div>
                <svg class="svg-icon icon-info">
                    <use xlink:href="#icon-affiliate"></use>
                </svg>
            </span>
        </button>

    </div>
</div>


<ng-template #noSubscriptionImageSingle>
    <svg class="svg-icon icon_no-image">
        <use xlink:href="#no-image"></use>
    </svg>
</ng-template>

<ng-template #noSubscriptionImageMultiple>
    <svg class="svg-icon icon_no-image">
        <use xlink:href="#no-image"></use>
    </svg>
</ng-template>


<ng-template #noRetailerLogo>
    <div class="card__logo card__no-logo">
        <svg class="svg-icon icon__no-retailer">
            <use xlink:href="#icon-retailer"></use>
        </svg>
    </div>
</ng-template>
