import { AbstractControl, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { SubscriptionPlan } from '../interfaces/subscription.interface';

export class ConfirmPasswordValidator {
    static MatchPassword(control: AbstractControl) {
        const password = control.get('password').value;
        const confirmPassword = control.get('confirmPassword').value;
        if (password != confirmPassword) {
            control.get('confirmPassword').setErrors({ ConfirmPassword: true });
        } else {
            return null;
        }
    }
}

export class LocationValidator {
    static Valid(control: AbstractControl) {
        const country = control.get('country')
        const city = control.get('city');
        const state = control.get('state');

        const validators = [Validators.required];
        // state.setValidators(validators);

        if(!country.value){
            city.reset(null,{onlySelf:true})
            state.reset(null,{onlySelf:true})
        }
        if (country.value){
              state.enable({onlySelf:true});
        }

        if (state.value) {
            city.enable({onlySelf:true})
            city.setValidators(validators)
        }

        return null

    }
}

export class PeriodicityValidator {
    static ValidPeriod(control: AbstractControl) {
        const plan: SubscriptionPlan = control.get('plan').value;
        const start: Date = control.get('start').value;
        const end: Date = control.get('end').value;
        const duration: number = control.get('duration').value;
        if (duration % 1 !== 0) {
            control.get('end').setErrors({ invalid: true });
            control.get('duration').setErrors({ invalid: true });
        } else {
            control.get('end').setErrors(null);
            control.get('duration').setErrors(null);
        }
    }
}

export class PasswordValidator {

    public static strong(control: FormControl) {

        const passwordRegex = new RegExp('^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$');
        const valid = passwordRegex.test(control.value);
        if (!valid) {
            // return what´s not valid
            return { strong: true };
        }
        return null;
    }
}

export class EmailValidator {

    public static email(control: FormControl) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const valid = re.test(control.value);
        if (!valid) {
            // return what´s not valid
            return { email: true };
        }
        return null;
    }
}

export class URLValidator {

    public static url(control: FormControl) {
        const urlRegex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
        const valid = urlRegex.test(control.value);
        if (!valid) {
            // return what´s not valid
            return { url: true };
        }
        return null;
    }
}


export class CustomNumbersValidator {
    public static Negative(control: FormControl) {
        if (control.value <= 0) {
            return { min: true };
        }
        return null;
    }
}
