<div class="popup__content">
    <div class="popup">
        <form [formGroup]="privateKeyForm" class="form">
            <div class="form__row" [class.error__field]="form.password.errors && this.submitted">
                <label for="privateKey" class="form-label__holder">Please enter your {{user.usepwd? 'Password' : 'Private Key'}} to confirm transaction</label>
                <input class="textfield" [type]="user.usepwd? 'password':'text'" name="privateKey" id="privateKey" placeholder="{{user.usepwd? 'Password' : 'Private Key'}} " formControlName="password">
                <span class="error__text" *ngIf="submitted && form.password.errors">
                    <p *ngIf="form.password.errors.required">{{user.usepwd? 'Password' : 'Private Key'}}  is required</p>
                </span>
            </div>
            <div class="form__row">
                <button class="button button_blue button_big" (click)="onSubmit()">
                    Confirm
                </button>
            </div>
        </form>
        <span class="popup__title">
			{{user.usepwd? 'Password' : 'Private Key'}}
		</span>
    </div>
    <button (click)="closeDialog()" class="popup__close">
        <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
    </button>
</div>
