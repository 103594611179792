import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WalletTransaction } from '../../models/models';
import { DummyDataService } from '../../../core/services/dummy.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'xion-view-transaction-modal',
    templateUrl: './view-transaction-modal.component.html',
    styleUrls: ['./view-transaction-modal.component.scss']
})
export class ViewTransactionModalComponent implements OnInit {

    retailer: { name: string; logo: string; domain: string; interests: string[] };

    constructor(@Inject(MAT_DIALOG_DATA) public transaction: WalletTransaction, private dialogRef: MatDialogRef<ViewTransactionModalComponent>, private dummy: DummyDataService) {
    }


    ngOnInit() {
        this.dummy.getRetailers().pipe(take(1)).subscribe(retailers => this.retailer = retailers[0]);
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
