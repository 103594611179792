import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { HighlightService } from '../../services/highlight.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'xion-copy-product-modal',
    templateUrl: './copy-product-modal.component.html',
    styleUrls: ['./copy-product-modal.component.scss'],
    animations: [
        trigger('appearAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('1s ease-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('1s ease-in', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class CopyProductModalComponent implements OnInit {
    showCopyResultScript = false;
    showCopyResultProduct = false;
    isMobile = false;
    highlighted: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: number,
        private clipboardService: ClipboardService,
        private highlightService: HighlightService
    ) {}

    ngOnInit() {
        const  ua = navigator.userAgent;
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
            this.isMobile=true
        }
    }

    ngAfterViewChecked() {
        if (!this.highlighted) {
            this.highlightService.highlightAll();
            this.highlighted = true;
        }
    }

    copyButtonCode(value: any) {
        if (this.clipboardService.copyFromContent(value.innerText)) {
            this.showCopyResultProduct = true;
            setTimeout(() => (this.showCopyResultProduct = false), 2000);
        }
    }

    copyScriptCode(value: any) {
        if (this.clipboardService.copyFromContent(value.innerText)) {
            this.showCopyResultScript = true;
            setTimeout(() => (this.showCopyResultScript = false), 2000);
        }
    }
    shareScriptCode(value: any) {
        let newVariable: any;
        newVariable = window.navigator;
        if(newVariable && newVariable.share){
            newVariable.share({
                title: 'Share payment link',
                URL: value?.innerText
                })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }else{
            alert("share not supported.")
        }
    }
}
