<ng-container *ngIf="query;else withoutQuery">
    <ng-container *ngFor="let post of posts| filter:query">
        <xion-channel-post-item [user]="user"
                                [tab]="tab"
                                [post]="post"
                                (like)="like($event)"
                                (follow)="follow($event)">
        </xion-channel-post-item>

        <ng-container *ngIf="!posts  || posts.length === 0">
            <p>no posts found</p>
        </ng-container>

    </ng-container>
</ng-container>

<ng-template #withoutQuery>
    <div
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="300"
        [scrollWindow]="false"
        (scrolled)="onScroll()">
        <ng-container *ngFor="let post of posts">
            <xion-channel-post-item [user]="user"
                                    [tab]="tab"
                                    [post]="post"
                                    (like)="like($event)"
                                    (follow)="follow($event)">
            </xion-channel-post-item>
        </ng-container>

        <ng-container *ngIf="!posts  || posts.length === 0">
            <p>no posts found</p>
        </ng-container>

    </div>
</ng-template>
