import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatMenuModule } from '@angular/material/menu';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LinkyModule } from 'angular-linky';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ClipboardModule } from 'ngx-clipboard';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { MaterialModule } from '../material/material.module';

import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { OutsideClickDirective } from './directives/outside-click.directive';

// import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { ChurnChartComponent } from './components/charts/churn-chart/churn-chart.component';
import { CustomersChartComponent } from './components/charts/customers-chart/customers.component';
import { DisputeChargebacksChartComponent } from './components/charts/dispute-chargebacks-chart/dispute-chargebacks-chart.component';
import { NewUsersChartComponent } from './components/charts/new-users-chart/new-users-chart.component';
import { RevenueComponent } from './components/charts/revenue-chart/revenue.component';
import { SubscriptionsChartComponent } from './components/charts/subscriptions-chart/subscriptions-chart.component';
import { UsersMapChartComponent } from './components/charts/users-map-chart/users-map.component';
import { WalletsChartComponent } from './components/charts/wallets-chart/wallets-chart.component';
import { CurrencySelectorComponent } from './components/currency-selector/currency-selector.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotificationsDropdownComponent } from './components/notifications-dropdown/notifications-dropdown.component';
import { SocialLinksComponent } from './components/social-links/social-links.component';
import { ViewSubscriptionComponent } from './components/subscriptions/view-subscription/view-subscription.component';
import { UserRatingComponent } from './components/user-rating/user-rating.component';
import { TopMenuComponent } from './menus/top-menu/top-menu.component';
import { BankDetailsModalComponent } from './modals/bank-details-modal/bank-details-modal.component';
import { ChartModalComponent } from './modals/charts-modals/chart-modal/chart-modal.component';
import { ChurnChartModalComponent } from './modals/charts-modals/churn-chart-modal/churn-chart-modal.component';
import { CustomersSignupChartModalComponent } from './modals/charts-modals/customers-signup-chart-modal/customers-signup-chart-modal.component';
import { RevenuChartModalComponent } from './modals/charts-modals/revenu-chart-modal/revenu-chart-modal.component';
import { SubscriptionsChartModalComponent } from './modals/charts-modals/subscriptions-chart-modal/subscriptions-chart-modal.component';
import { UsersMapModalComponent } from './modals/charts-modals/users-map-modal/users-map-modal.component';
import { CredentialsConfirmComponent } from './modals/credentials-confirm/credentials-confirm.component';
import { ReviewSubscriptionModalComponent } from './modals/subscriptions/retailer/review-subscription-modal/review-subscription-modal.component';
import { ViewRetailerSubscriptionModalComponent } from './modals/subscriptions/retailer/subscription-modal/view-retailer-subscription-modal.component';
import { EditUserSubscriptionModalComponent } from './modals/subscriptions/user/edit-subscription-modal/edit-user-subscription-modal.component';
import { QuerySubscriptionModalComponent } from './modals/subscriptions/user/query-subscription-modal/query-subscription-modal.component';
import { ViewTransactionsModalComponent } from './modals/subscriptions/user/view-subscription-modal/view-transactions-modal.component';
import { SupportModalComponent } from './modals/support-modal/support-modal.component';
import { TermsModalComponent } from './modals/terms-modal/terms-modal.component';
import { UploadPhotoModalComponent } from './modals/upload-photo-modal/upload-photo-modal.component';
import { UploadLogoModalComponent } from './modals/upload-logo-modal/upload-logo-modal.component';
import { ViewTransactionModalComponent } from './modals/view-transaction-modal/view-transaction-modal.component';
import { CredentialsComponent } from './pages/credentials/credentials.component';
import { MessengerComponent } from './pages/messenger/messenger.component';
import { NotFoundComponent } from './pages/notfound/notfound.component';
import { TermsComponent } from './pages/terms/terms.component';

import { ViewCustomerModalComponent } from './modals/view-customer-modal/view-customer-modal.component';
import { CurrencyConverterPipe } from './pipes/currency-converter.pipe';
import { CustomerFilterPipe } from './pipes/customer-filter.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { PrefixPipe } from './pipes/prefix.pipe';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PostPreviewComponent } from '../retailers/pages/channel-preview/post-preview.component';
import { LinkPreviewComponent } from './components/link-preview/link-preview.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { OneTimeOfferListComponent } from './components/one-time-offer-list/one-time-offer-list.component';
import { ProductsListComponent } from './components/products-list/products-list.component';
import { ProvidersListComponent } from './components/providers-list/providers-list.component';
import { SocialPostItemComponent } from './components/social/channel-post-item/social-post-item.component';
import { SocialPostsListComponent } from './components/social/channel-posts-list/social-posts-list.component';
import { CommunityListComponent } from './components/social/community-list/community-list.component';
import { CreateSocialPostComponent } from './components/social/create-channel-post/create-social-post.component';
import { EditSocialPostComponent } from './components/social/edit-channel-post/edit-social-post.component';
import { SubscriptionListItemComponent } from './components/subscriptions/subscription-list-item/subscription-list-item.component';
import { SubscriptionListComponent } from './components/subscriptions/subscription-list/subscription-list.component';
import { UserChatDetailsComponent } from './components/user-chat-details/user-chat-details.component';
import { UserChatMessagesComponent } from './components/user-chat-messages/user-chat-messages.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { WalletSliderComponent } from './components/wallet-slider/wallet-slider.component';
import { AddWalletModalComponent } from './modals/add-wallet-modal/add-wallet-modal.component';
import { ChangePasswordModalComponent } from './modals/change-password-modal/change-password-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { ConfirmPasswordComponent } from './modals/confirm-password/confirm-password.component';
import { ConnectModalComponent } from './modals/connect-modal/connect-modal.component';
import { CreateProviderModalComponent } from './modals/create-provider-modal/create-provider-modal.component';
import { DiscountCalculatorModalComponent } from './modals/discount-calculator-modal/discount-calculator-modal.component';
import { DiscountInfoModalComponent } from './modals/discount-info-modal/discount-info-modal.component';
import { LoginModalComponent } from './modals/login-modal/login-modal.component';
import { NotificationModalComponent } from './modals/notification-modal/notification-modal.component';
import { PreviewMediaComponent } from './modals/preview-media/preview-media.component';
import { PrivateKeyModalComponent } from './modals/private-key-modal/private-key-modal.component';
import { ProviderDetailsModalComponent } from './modals/provider-details-modal/provider-details-modal.component';
import { RestorePasswordModalComponent } from './modals/restore-password-modal/restore-password-modal.component';
import { SwapModalComponent } from './modals/swap-modal/swap-modal.component';
import { ActivityComponent } from './pages/activity/activity.component';
import { AffiliateComponent } from './pages/affiliate/affiliate.component';
import { MarketplaceComponent } from './pages/marketplace/marketplace.component';
import { OfferPreviewComponent } from './pages/one-time-offer-preview/offer-preview.component';
import { SocialComponent } from './pages/social/social.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { UsersSubscriptionsComponent } from './pages/subscriptions/my-subscriptions.component';
import { RetailerFilterPipe } from './pipes/retailer-filter.pipe';
import { SubscriptionPlanPipe } from './pipes/subscription-plan.pipe';
import { SubscriptionTypePipe } from './pipes/subscription-type.pipe';
import { UnixDatePipe } from './pipes/unix-date.pipe';
import { UserFilterPipe } from './pipes/user-filter.pipe';
import { SubscriptionStatusPipe } from './pipes/subscription-status.pipe';
import { SubscriptionFilterPipe } from './pipes/subscription-filter.pipe';
import { SubscriptionsTableComponent } from './components/subscriptions-table/subscriptions-table.component';
import { SubscribersTableComponent } from './components/subscribers-table/subscribers-table.component';
import { SubscriptionDurationPipe } from './pipes/subscription-duration.pipe';
import { CustomerStatusPipe } from './pipes/customer-status.pipe';
import { BtnAsideToggleComponent } from './components/btn-aside-toggle/btn-aside-toggle.component';
import { AsideMenuComponent } from './components/aside-menu/aside-menu.component';
import { NetIncomeChartComponent } from './components/charts/net-income-chart/net-income-chart.component';
import { HowDoesItWorkComponent } from './modals/how-does-it-work/how-does-it-work.component';
import { DiscountBillingCalculatorModalComponent } from './modals/discount-billing-calculator-modal/discount-billing-calculator-modal.component';
import { BillingProductStatusPipe } from './pipes/billing-product-status.pipe';
import { BillingChangeStatusModalComponent } from './modals/billing-change-status-modal/billing-change-status-modal.component';
import { BillingTransactionsModalComponent } from './modals/billing-transactions-modal/billing-transactions-modal.component';
import { MySubscriptionComponent } from './components/subscriptions/my-subscription/my-subscription.component';
import { SubscriptionTransactionsModalComponent } from './modals/subscription-transactions-modal/subscription-transactions-modal.component';
import { MySubscriptionsFilterPipe } from './pipes/my-subscriptions-filter.pipe';
import { TabsHeaderComponent } from './components/tabs-header/tabs-header.component';
import { CopyScriptModalComponent } from './modals/copy-script-modal/copy-script-modal.component';
import { AuthModalComponent } from './modals/auth-modal/auth-modal.component';
import { AuthUserNotFoundModalComponent } from './modals/auth-user-not-found-modal/auth-user-not-found-modal.component';
import { SettingsComponent } from '../retailers/pages/settings/settings.component';
import { DeleteAccountModalComponent } from './modals/delete-account-modal/delete-account-modal.component';
import {
    VgBufferingModule,
    VgControlsModule,
    VgCoreModule,
    VgOverlayPlayModule
} from 'ngx-videogular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BillingTypePipe } from './pipes/billingType.pipe';
import { HighlightService } from './services/highlight.service';
import { CashbackRewardModalComponent } from './modals/cashback-reward-modal/cashback-reward-modal.component';
import { MaxDiscountInfoModalComponent } from './modals/max-discount-info-modal/max-discount-info-modal.component';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { CustomLoadingComponent } from './modals/custom-loading/custom-loading.component';
import { InvalidChainWarningModalComponent } from './modals/invalid-chain-warning-modal/invalid-chain-warning-modal.component';
import { ChainNamePipe } from './pipes/chain-name.pipe';
import { CheckoutButtonInstructionDialogComponent } from './modals/checkout-button-instruction-dialog/checkout-button-instruction-dialog.component';
import { FloorPipe } from './pipes/floor.pipe';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CopyProductModalComponent } from './modals/copy-product-modal/copy-product-modal.component';
import { HomePageComponent } from './pages/home/home-page.component';
import { QrModalComponent } from './modals/qr-modal/qr-modal.component';
import { LeaveAccountModalComponent } from './modals/leave-account-modal/leave-account-modal.component';
import { CreateAccountModalComponent } from './modals/create-account-modal/create-account-modal.component';
import { PayOutModalComponent } from './modals/pay-out-modal/pay-out-modal.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const CHARTS = [
    RevenueComponent,
    CustomersChartComponent,
    UsersMapChartComponent,
    NewUsersChartComponent,
    WalletsChartComponent,
    DisputeChargebacksChartComponent,
    ChurnChartComponent,
    SubscriptionsChartComponent
];

const MODALS = [
    SupportModalComponent,
    TermsModalComponent,
    UploadPhotoModalComponent,
    ChartModalComponent,
    BankDetailsModalComponent,
    RevenuChartModalComponent,
    UsersMapModalComponent,
    ChurnChartModalComponent,
    SubscriptionsChartModalComponent,
    CustomersSignupChartModalComponent,
    ViewTransactionsModalComponent,
    EditUserSubscriptionModalComponent,
    QuerySubscriptionModalComponent,
    CredentialsConfirmComponent,
    ViewRetailerSubscriptionModalComponent,
    ReviewSubscriptionModalComponent,
    ViewTransactionModalComponent,
    ViewCustomerModalComponent,
    DiscountInfoModalComponent,
    ConnectModalComponent,
    DiscountCalculatorModalComponent,
    ProviderDetailsModalComponent,
    PrivateKeyModalComponent,
    CreateProviderModalComponent,
    AddWalletModalComponent,
    NotificationModalComponent,
    ChangePasswordModalComponent,
    RestorePasswordModalComponent,
    SwapModalComponent,
    PreviewMediaComponent,
    LoginModalComponent,
    ConfirmPasswordComponent,
    ConfirmModalComponent,
    HowDoesItWorkComponent,
    DiscountBillingCalculatorModalComponent,
    ConfirmModalComponent,
    BillingChangeStatusModalComponent,
    BillingTransactionsModalComponent,
    SubscriptionTransactionsModalComponent,
    CopyScriptModalComponent,
    AuthModalComponent,
    AuthUserNotFoundModalComponent,
    DeleteAccountModalComponent,
    UploadLogoModalComponent,
    InvalidChainWarningModalComponent,
    CheckoutButtonInstructionDialogComponent
];

const COMPONENTS = [
    FooterComponent,
    AsideMenuComponent,
    SocialLinksComponent,
    TopMenuComponent,
    UploadPhotoModalComponent,
    UploadLogoModalComponent,
    SupportModalComponent,
    TermsModalComponent,
    NotFoundComponent,
    CurrencySelectorComponent,
    NotificationsDropdownComponent,
    BtnAsideToggleComponent,
    TermsComponent,
    CredentialsComponent,
    ViewSubscriptionComponent,
    UserRatingComponent,
    MessengerComponent,
    UserChatDetailsComponent,
    UserChatMessagesComponent,
    SubscriptionComponent,
    ActivityComponent,
    ProvidersListComponent,
    ProductsListComponent,
    VideoPlayerComponent,
    LoadingSpinnerComponent,
    WalletSliderComponent,
    SubscriptionListItemComponent,
    SubscriptionListComponent,
    LinkPreviewComponent,
    AffiliateComponent,
    SocialComponent,
    CommunityListComponent,
    SocialPostItemComponent,
    SocialPostsListComponent,
    CreateSocialPostComponent,
    PostPreviewComponent,
    EditSocialPostComponent,
    MarketplaceComponent,
    OneTimeOfferListComponent,
    OfferPreviewComponent,
    UsersSubscriptionsComponent,
    SubscriptionsTableComponent,
    SubscribersTableComponent,
    NetIncomeChartComponent,
    MySubscriptionComponent,
    TabsHeaderComponent,
    SettingsComponent,
    CashbackRewardModalComponent,
    MaxDiscountInfoModalComponent,
    ConfirmDialogComponent,
    CustomLoadingComponent,
    HomePageComponent
];

const MODULES = [
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    RouterModule,
    HttpClientModule,
    NgSelectModule,
    // PasswordStrengthBarModule,
    AngularSvgIconModule,
    ImageCropperModule,
    SwiperModule,
    ClipboardModule,
    MaterialModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    ColorPickerModule,
    InfiniteScrollModule,
    LinkyModule,
    RoundProgressModule,
    PerfectScrollbarModule,
    NgxQRCodeModule
];

const PIPES = [
    FilterPipe,
    RetailerFilterPipe,
    TruncatePipe,
    CurrencyConverterPipe,
    CustomerFilterPipe,
    UserFilterPipe,
    UnixDatePipe,
    SubscriptionPlanPipe,
    SubscriptionTypePipe,
    SubscriptionStatusPipe,
    // SubscriptionFilterPipe,
    SubscriptionFilterPipe,
    SubscriptionDurationPipe,
    CustomerStatusPipe,
    BillingProductStatusPipe,
    MySubscriptionsFilterPipe,
    BillingTypePipe,
    ChainNamePipe,
    FloorPipe,
    PrefixPipe
];
const DIRECTIVES = [NumbersOnlyDirective, OutsideClickDirective];

@NgModule({
    declarations: [...COMPONENTS, ...MODALS, ...CHARTS, ...PIPES, ...DIRECTIVES, CheckoutButtonInstructionDialogComponent, CopyProductModalComponent, QrModalComponent, LeaveAccountModalComponent, CreateAccountModalComponent, PayOutModalComponent],
    entryComponents: [...MODALS],

    imports: [MatMenuModule, CommonModule, AngularSvgIconModule.forRoot(), ...MODULES],
    exports: [...MODULES, ...COMPONENTS, ...CHARTS, ...PIPES, ...DIRECTIVES],
    providers: [HighlightService,{
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      }]

})
export class SharedModule {}
