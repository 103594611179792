import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import { IUser } from '../../../core/services/user.service';

@Component({
    selector: 'xion-private-key-modal',
    templateUrl: './private-key-modal.component.html',
    styleUrls: ['./private-key-modal.component.scss']
})
export class PrivateKeyModalComponent implements OnInit, OnDestroy {
    privateKeyForm: FormGroup;
    submitted = false;
    user: IUser;
    private userSub: Subscription;

    constructor(
        private authService: AuthService,
        private builder: FormBuilder,
        private dialogRef: MatDialogRef<PrivateKeyModalComponent>
    ) {}

    ngOnInit() {
        this.userSub = this.authService.currentUser.subscribe((user) => {
            this.user = user;
        });
        this.privateKeyForm = this.builder.group({
            password: [null, Validators.required]
        });
    }

    closeDialog(key = null) {
        this.dialogRef.close(key);
    }

    onSubmit() {
        this.submitted = true;
        if (this.privateKeyForm.valid) {
            const password = this.form.password.value;
            this.closeDialog(password);
        }
    }

    ngOnDestroy(): void {
        this.userSub.unsubscribe();
    }
    get form() {
        return this.privateKeyForm.controls;
    }
}
