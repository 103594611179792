<div class="popup-blue__content popup-billing__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title" mat-dialog-title>
            Discount Calculator
        </h3>
        <form [formGroup]="discountForm" mat-dialog-content>
            <fieldset class="fieldset">
                <legend class="legend">Select Billing Interval</legend>
                <mat-radio-group (change)="onProductTypeChange()" formControlName="plan">
                    <mat-radio-button value="daily">Daily</mat-radio-button>
                    <mat-radio-button value="weekly">Weekly</mat-radio-button>
                    <mat-radio-button value="monthly">Monthly</mat-radio-button>
                    <mat-radio-button value="yearly">Yearly</mat-radio-button>
                </mat-radio-group>
            </fieldset>

            <fieldset class="fieldset" *ngIf="!isOneTimePayment">
                <legend class="legend">Select Billing Cycles</legend>
                <div class="form__row">
                    <div class="form-label__holder">
                        <label class="form-label text-overflow" for="duration">Number of {{duration}}</label>
                        <input class="textfield textfield__white-border" id="duration" name="duration"
                               placeholder="Number of {{duration}}" type="number" min="1" formControlName="duration" max="10000"
                               #durationSelector (input)="calculateProjections(durationSelector.value)">
                    </div>
                </div>
            </fieldset>
            <div class="form__row">
                  <div class="form-label__holder form-label__holder-price">
                      <label class="form-label text-overflow" for="manual">Price</label>
                      <input class="textfield textfield__white-border" id="manual" name="manual_price" placeholder="$10" formControlName="price"
                              min=0
                              (input)="calculateProjections()"
                              type="number">
                  </div>
                  <div class="form-label__holder">
                      <label class="form-label text-overflow" for="manual">Number of Subscribers</label>
                      <input class="textfield textfield__white-border" id="subscription" name="manual_price"
                              placeholder="1" min=1 max="100" formControlName="numberOfSubscriptions" (input)="calculateProjections()"
                              type="number">
                  </div>



                  <div class="form-label__holder">
                      <label class="form-label text-overflow" for="manual">COUPONS</label>
                      <ng-select
                          [multiple]="false"
                          [items]="discountLevels"
                          bindLabel="id"
                          bindValue="rate"
                          formControlName="discountLevel"
                          (change)="calculateProjections()"
                          placeholder="Level">
                      </ng-select>
                  </div>




            </div>
            <div class="list-transactions__holder textfield textfield__white-border">
                <ul class="list-transactions__head">
                    <li class="head-item list__date">{{duration}}</li>
                    <li class="head-item list__cumulative">Customer Payments </li>
                    <li class="head-item list__cumulative">Customer Savings</li>
                </ul>
                <ul class="list-transactions">
                    <li class="list-transactions__item" *ngFor="let row of rows">
                        <span class="list__date list-transactions__details">
                            <strong class="head-item_mobile">{{duration}}:</strong>
                            {{row.day}}
                        </span>
                        <span class="list__cumulative list-transactions__details">
                            <strong class="head-item_mobile">Customer Payments:</strong>
                             ${{row.payment | number:'1.2-2'}}
                        </span>
                        <span class="list__cumulative list-transactions__details">
                            <strong class="head-item_mobile">Customer Savings:</strong>
                            ${{row.cumSavings | number:'1.2-2'}}
                        </span>
                    </li>
                </ul>
            </div>
        </form>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
  </div>
