import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadService, FileUploadTypes} from '../../../core/services/file-upload.service';
import { NotificationsService } from '../../../core/services/notifications.service';
import { ViewChild, ElementRef } from '@angular/core';
import { async } from '@angular/core/testing';

@Component({
  selector: 'xion-upload-logo-modal',
  templateUrl: './upload-logo-modal.component.html',
  styleUrls: ['./upload-logo-modal.component.scss']
})
export class UploadLogoModalComponent implements OnInit {

    logoInput: ElementRef;
    base64: string | ArrayBuffer = null;
    file: File = null;
    fileName: string = null;
    done: boolean = false;
    failed: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
     private dialogRef: MatDialogRef<UploadLogoModalComponent>,
     private uploadService: FileUploadService,
     private notifications: NotificationsService
  ) { 

  }

  ngOnInit(): void {
  }


  async fileUploadLogo(files: FileList) {
    this.fileName = null  
    this.done = false
    this.failed = false
    
    if (files.length == 0){
      return;
    }

    if (!this.uploadService.isFieldSizeValid(files, FileUploadTypes.logo)){
      this.notifications.showMessage(
        `File Size Exceeded maximum of 2 MB`
      );
      return;
    }

    this.file = files[0]
    this.fileName = this.file.name
    this.base64 = await this.getImageBase64(this.file)
    
    this.uploadService
        .uploadLogo(this.base64)
        .subscribe((response) => {
          if (response?.status !== "created") {
            this.failed = true
            this.done = false
            return
          }
          this.done = true
        });
  }

  private async getImageBase64(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve) => {
      var reader = new FileReader();
      reader.readAsDataURL(this.file)
      reader.onload = () => {
          resolve(reader.result);
      };
    });
  }



}
