import { Pipe, PipeTransform } from '@angular/core';
import { ICustomer } from '../../core/services/customers.service';

@Pipe({
    name: 'customerStatus'
})
export class CustomerStatusPipe implements PipeTransform {

    transform(customer: ICustomer, args?: any): any {
        if (customer.subscribed) return 'Active';
        if (!customer.subscribed) return 'Unsubscribed';
        if (customer.paused) return 'Paused';
        return null;
    }

}
