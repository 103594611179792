<a *ngIf="metadata" [href]="metadata.url"
   class="link-preview notification-msg__holder"
   target="_blank"
   [class.link-preview_full]="showFullPreview">
    <img class="link-preview__image" [src]="metadata.image" alt="{{metadata.title}}" *ngIf="metadata.image">
    <div class="link-preview__details">
        <span class="link-preview__url">{{metadata.url}}</span>
        <span class="link-preview__title" *ngIf="metadata.title">{{metadata.title}}</span>
        <p class="link-preview__description" *ngIf="metadata.description">
            {{metadata.description}}
        </p>
    </div>
    <div class="notification-msg">{{metadata.title}}</div>
</a>
