import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheBuster } from 'ngx-cacheable';
import { environment } from '../../../environments/environment';
import { IAuthResponse } from '../../shared/interfaces/auth.interface';
import { ILoyaltyResponse } from '../../shared/interfaces/loyalty.interfase';
import { ITransaction, IUserData } from '../../shared/interfaces/transaction.interface';
import {
    BillingProductStatus,
    IBillingProduct,
    IResponseStatus,
    SubscriptionStatus
} from '../../shared/models/billing-product.model';
import {
    IButtonStates,
    ICreateSubscriptions,
    IMySubscription
} from '../../shared/models/subscription.model';
import { Platforms, UserRoles, UserSignupResponse } from 'src/app/utils';

const cacheBuster$ = new Subject<void>();
@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(private http: HttpClient) {}

    public getBillingProducts(): Observable<IBillingProduct[]> {
        return this.http.get<IBillingProduct[]>(environment.api_url + '/products');
    }

    public createBillingProduct(
        product: IBillingProduct,
        parentProductID?: number
    ): Observable<IResponseStatus> {
        const endpoint = parentProductID ? `/products/${parentProductID}` : '/products';
        return this.http.post<IResponseStatus>(environment.api_url + endpoint, product);
    }

    public updateProductLogo(image64: string | ArrayBuffer, productId): Observable<any> {
        return this.http.post(`${environment.api_url}/products/${productId}/upload-image`, {
            data: image64
        });
    }

    public saveBillingProduct(product: IBillingProduct): Observable<IResponseStatus> {
        return this.http.put<IResponseStatus>(environment.api_url + '/products', product);
    }

    public addPaymentLink(id: string): Observable<any> {
        return this.http.post(environment.api_url + `/products/${id}/create-payment-link`, {
            data: 'create-payment-link'
        });
    }

    public deletePaymentLink(id: string): Observable<any> {
        return this.http.post(environment.api_url + `/products/${id}/delete-payment-link`, {
            data: 'create-payment-link'
        });
    }
    public addFollowUp(parentId: string, followId: string): Observable<any> {
        return this.http.post(environment.api_url + `/products/${parentId}/add-followup-product`, {
            productId: followId
        });
    }

    public addCheckoutButton(id: string): Observable<any> {
        return this.http.post(environment.api_url + `/products/${id}/create-checkout-button`, {
            data: 'create-checkout-button'
        });
    }

    public deleteCheckoutButton(id: string): Observable<any> {
        return this.http.post(environment.api_url + `/products/${id}/delete-checkout-button`, {
            data: 'delete-checkout-button'
        });
    }

    public downloadCustomerDetail() {
        return this.http.get(environment.api_url + '/products/transactions/download', {
            responseType: 'blob' as 'json'
        });
    }

    public changeProductStatus(
        productID: number,
        newStatus: BillingProductStatus,
        prevStatus: BillingProductStatus
    ): Observable<IResponseStatus> {
        return this.http.patch<IResponseStatus>(
            environment.api_url +
                '/products/' +
                productID +
                '?status=' +
                newStatus +
                '&previous=' +
                prevStatus,
            {}
        );
    }

    public changeSubscriptionStatus(
        subscriptionID: number,
        status: SubscriptionStatus,
        currency = 'xdai'
    ): Observable<IButtonStates> {
        return this.http.put<IButtonStates>(
            environment.api_url +
                '/subscriptions/' +
                subscriptionID +
                '?status=' +
                status +
                `&currency=${currency}`,
            null
        );
    }

    public getSubscriptions(): Observable<IMySubscription[]> {
        return this.http.get<IMySubscription[]>(environment.api_url + '/subscriptions');
    }

    public getSingleSubscriptions(): Observable<IMySubscription[]> {
        return this.http.get<IMySubscription[]>(environment.api_url + '/subscriptions/sb');
    }

    public getOutStanding(id: string): Observable<any> {
        return this.http.get(
            `${environment.api_url}/subscriptions/customer-management/${id}/outstanding-payment`
        );
    }

    public createSubscriptions(data: ICreateSubscriptions): Observable<IResponseStatus> {
        return this.http.post<IResponseStatus>(environment.api_url + '/subscriptions', data);
    }

    public changeSubscriptionRating(id: number, rating: number): Observable<IResponseStatus> {
        return this.http.patch<IResponseStatus>(
            environment.api_url + '/subscriptions/' + id + '?rating=' + rating,
            {}
        );
    }

    @CacheBuster({ cacheBusterNotifier: cacheBuster$ })
    public createUser(
        walletAddress: string,
        role: UserRoles = UserRoles.SELLAR,
        platform: Platforms = Platforms.torus
    ): Observable<UserSignupResponse> {
        return this.http.post<UserSignupResponse>(`${environment.api_url}/users`, {
            walletAddress,
            role,
            platform
        });
    }

    @CacheBuster({ cacheBusterNotifier: cacheBuster$ })
    public updateUser(user): Observable<any> {
        return this.http.put(`${environment.api_url}/users`, user);
    }

    @CacheBuster({ cacheBusterNotifier: cacheBuster$ })
    public isUserExists(wallet: string): Observable<boolean> {
        return this.http
            .get<{ exists: boolean }>(`${environment.api_url}/users/${wallet}/exists`)
            .pipe(map((res) => res.exists));
    }

    @CacheBuster({ cacheBusterNotifier: cacheBuster$ })
    public auth(wallet: string): Observable<IAuthResponse> {
        return this.http.post<IAuthResponse>(environment.api_url + '/auth/sign-in', {
            walletAddress: wallet
        });
    }

    public deleteAccount(): Observable<any> {
        return this.http.delete<IAuthResponse>(environment.api_url + '/users');
    }

    public getTransactions(): Observable<any> {
        return this.http.get(environment.api_url + '/products/transactions');
    }

    public getTransactionDetail(id: String): Observable<any> {
        return this.http.get(
            `${environment.api_url}/products/customer-management/${id}/transactions`
        );
    }

    public getCutomerInfo(id: String): Observable<any> {
        return this.http.get(
            `${environment.api_url}/products/customer-management/${id}/get-information`
        );
    }

    public getSubscription(id: String): Observable<any> {
        return this.http.get(`${environment.api_url}/subscriptions/customer-management/${id}`);
    }

    public getManagementTransactionsCSV(productID: number): Observable<any> {
        return this.http.get(
            environment.api_url + '/products/' + productID + '/transactions/download',
            { responseType: 'arraybuffer' }
        );
    }

    public getOrdersTransactionsCSV(subscriptionID: number): Observable<any> {
        return this.http.get(
            environment.api_url + '/subscriptions/' + subscriptionID + '/transactions/download',
            { responseType: 'arraybuffer' }
        );
    }

    public getManagementTransactions(productID: number): Observable<ITransaction[]> {
        return this.http.get<ITransaction[]>(
            environment.api_url + '/products/' + productID + '/transactions',
            {}
        );
    }

    public getOrdersTransactions(subscriptionID: number): Observable<ITransaction[]> {
        return this.http.get<ITransaction[]>(
            environment.api_url + '/subscriptions/' + subscriptionID + '/transactions',
            {}
        );
    }

    public getSingleOrdersTransactions(productID: number): Observable<ITransaction[]> {
        return this.http.get<ITransaction[]>(
            environment.api_url + '/subscriptions/' + productID + '/sb/transactions',
            {}
        );
    }

    public getLoyaltyPrograms(): Observable<ILoyaltyResponse> {
        return this.http.get<ILoyaltyResponse>(environment.api_url + '/loyalty', {}).pipe(
            map((res) => {
                res.loyaltyPackages = res.loyaltyPackages.filter(
                    (x) => x.id !== 0 && x.tier !== 'LEVEL 0' && x.price !== 0
                );
                return res;
            })
        );
    }

    public checkoutProduct(productID: number): Observable<IResponseStatus> {
        return this.http.post<IResponseStatus>(environment.api_url + '/orders ', {
            productid: productID
        });
    }

    public getUserByWallet(walletAddress: string): Observable<IUserData> {
        return this.http.get<IUserData>(environment.api_url + '/users/address/' + walletAddress);
    }

    public getPendingStatus(ids: string): Observable<IMySubscription[]> {
        return this.http.get<IMySubscription[]>(environment.api_url + '/subscriptions?ids=' + ids);
    }

    public getKYCStatus(): Observable<any> {
        return this.http.get(environment.api_url + '/users/get-kyc-status');
    }

    public postAcceptAggrement(): Observable<any> {
        return this.http.post(
            environment.api_url + '/merchant-dashboard/set-merchant-service-agreement',
            {}
        );
    }

    public postCreateAccount(accountType: string, walletAddress: string): Observable<any> {
        return this.http.post(environment.api_url + '/merchant-dashboard', {
            accountType,
            walletAddress
        });
    }

    public getConnectedAccounts(): Observable<any> {
        return this.http.get(environment.api_url + '/merchant-dashboard/get-connected-accounts');
    }

    public getConnectedCompanies(): Observable<any> {
        return this.http.get(environment.api_url + '/merchant-dashboard/get-connected-companies');
    }

    public getXgtPrice(): Observable<any> {
        return this.http.post('https://xion.finance/api/info', {
            user: '0x0'
        });
    }

    public saveProduct(productID: string, token: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Order-Token': token
            })
        };
        return this.http.post(
            environment.api_url + '/orders/public/static',
            {
                productID
            },
            httpOptions
        );
    }

    getUniswapRouterAbi(): Observable<any> {
        return this.http.get('assets/abis/UniswapRouter.json');
    }

    getSubscriptionAbi(): Observable<any> {
        return this.http.get('assets/abis/Subscription.json');
    }

    getXGTContractAbi(): Observable<any> {
        return this.http.get('assets/abis/XGTContract.json');
    }

    getSubscriptionContractAbi(): Observable<any> {
        return this.http.get('assets/abis/subscriptionContractABI.json');
    }

    getXgtClaimContractAbi(): Observable<any> {
        return this.http.get('assets/abis/XgtClaim.json');
    }
}
