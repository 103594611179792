<ng-container class="overlay">
    <div>
        <div class="spinner-wrapper">
            <ng-container *ngIf="transactionStatus === 'loading'">
                <div class="sk-circle">
                    <round-progress [current]="current" [max]="100" color="url(#your-gradient-id)"
                        [background]="'#D8D8D8'" [radius]="65" [stroke]="10" [rounded]="true" [clockwise]="true"
                        [responsive]="false" [duration]="800" [animation]="'easeInOutQuart'" [animationDelay]="0">
                    </round-progress>
                    <svg>
                        <linearGradient id="your-gradient-id" x1="0" x2="0" y1="0" y2="1">
                            <stop offset="5%" stop-color="#35E0FC" />
                            <stop offset="95%" stop-color="#FC6BB9" />
                        </linearGradient>
                    </svg>
                </div>
                <p>VERIFYING PAYMENT</p>
            </ng-container>
            <ng-container *ngIf="transactionStatus === 'success'">
                <img src="assets/images/confirm-success.svg" alt="Success" width="130">
                <p>TRANSACTION SUCCESSFUL</p>
                <a href="{{ blockExplorerURL }}{{ transactionHash }}" target='_blank' *ngIf='transactionHash'>View
                    Transaction</a>
            </ng-container>
            <ng-container *ngIf="transactionStatus === 'failed'">
                <img src="assets/images/confirm-failed.svg" alt="Failed" width="130">
                <p class="failed-topic">TRANSACTION FAILED</p>
                <p class="failed-again" mat-dialog-close>TRY AGAIN</p>
            </ng-container>
        </div>
    </div>
</ng-container>
