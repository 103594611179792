import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AuthService } from '../../../auth/services/auth.service';
import { MetadataService } from '../../../core/services/metadata.service';
import { NotificationsService } from '../../../core/services/notifications.service';
import { IUser } from '../../../core/services/user.service';
import { IAttachment } from '../../../shared/models/models';
import { Post } from '../../../shared/models/Post';
import { IPost } from '../../../shared/pages/social/social.component';
import { PostsService } from '../../services/posts.service';

@Component({
    selector: 'xion-channel-preview',
    templateUrl: './post-preview.component.html',
    styleUrls: ['./post-preview.component.scss']
})
export class PostPreviewComponent implements OnInit {

    activeTopTab = 'photo';
    fullscreen = false;
    user: IUser;
    listPostsDescription = 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non, soluta.';

    configProd: SwiperConfigInterface = {
        slidesPerView: 'auto',
        keyboard: true,
        freeMode: true,
        mousewheel: true,
        observer: true,
        scrollbar: false,
        pagination: false,
        direction: 'horizontal',
        navigation: {
            nextEl: '.next-sub',
            prevEl: '.prev-sub'
        }
    };

    post: IPost = null;

    constructor(private postsService: PostsService,
                private metadata: MetadataService,
                private clipboard: ClipboardService,
                private notifications: NotificationsService,
                private auth: AuthService,
                private route: ActivatedRoute,
                private router: Router) { }

    ngOnInit() {
        this.auth.currentUser.subscribe(user => this.user = user);
        this.route.paramMap.subscribe((params: ParamMap) => {
            const id = params.get('id');
            if (!id) return this.router.navigate(['**']);
            this.postsService.getPost(id)
                .subscribe((post: Post) => {
                        this.post = post;
                        this.getAttachments();
                    }, err => {}
                );
        });
        // this.postsService.getPost()
    }

    sharePost() {
        this.notifications.showMessage('The Post URL has been copied to your clipboard', 'Ok');
        this.clipboard.copyFromContent(document.URL);
    }


    private likePost() {
        this.postsService.likePost(this.post.id)
            .subscribe(liked => {
        });
    }

    private getAttachments() {
        // @ts-ignore
        this.post.photo = this.post.attachments.find((item: IAttachment) => item.filetype === 'photo') || null;
        // @ts-ignore
        this.post.video = this.post.attachments.find((item: IAttachment) => item.filetype === 'video') || null;
        // @ts-ignore
        this.post.document = this.post.attachments.find((item: IAttachment) => item.filetype === 'document') || null;
    }

}
