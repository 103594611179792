import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import Web3 from 'web3';
import { Web3InstanceService } from 'src/app/auth/services/web3-instance.service';
import { Web3modalService } from 'src/app/auth/services/web3modal.service';
import { XgtClaimService } from 'src/app/auth/services/xgt-claim.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { getTorusAddress, getTorusPrivateKey } from 'src/app/utils';
import { environment } from 'src/environments/environment';
import { ConnectorID } from '../../constants/config';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CustomLoadingComponent } from '../custom-loading/custom-loading.component';

@Component({
    selector: 'xion-cashback-reward-modal',
    templateUrl: './cashback-reward-modal.component.html',
    styleUrls: ['./cashback-reward-modal.component.scss']
})
export class CashbackRewardModalComponent implements OnInit {
    claimableAmount = 0;

    web3Instance: any = null;

    constructor(
        private dialog: MatDialog,
        private web3InstanceService: Web3InstanceService,
        private web3ModalService: Web3modalService,
        private xgtClaimService: XgtClaimService,
        private notifications: NotificationsService
    ) {}

    async ngOnInit() {
        await this.web3InstanceService.init();
        this.web3Instance = this.web3InstanceService.web3Instance;

        await this.xgtClaimService.initCashbackXgtClaimContract(this.web3Instance);
        this.claimableAmount = await this.xgtClaimService.getCashbackClaimableAmount(
            getTorusAddress()
        );
    }

    learnMore(): void {
        window
            .open(
                'https://xionwiki.gitbook.io/xion-global/loyalty-cashback-and-discounts/up-to-100-cashback',
                '_blank'
            )
            .focus();
    }

    private confirmTransaction(): Promise<boolean> {
        // signed traction will resolve true and non-signed transaction will resolve false, unapproved will reject promise
        return new Promise(async (resolve, reject) => {
            if (this.web3ModalService.getConnectorId() === ConnectorID.CustomAuth) {
                const approved = await this.dialog
                    .open(ConfirmDialogComponent, { width: '500px' })
                    .afterClosed()
                    .pipe(filter((res) => res))
                    .toPromise();
                if (approved) {
                    resolve(true);
                } else {
                    reject('transaction declined');
                }
            } else {
                resolve(false);
            }
        });
    }

    private async processClaim(sign: boolean) {
        const dialogRef = this.dialog.open(CustomLoadingComponent, {
            disableClose: true
        });
        try {
            let response;
            if (sign) {
                const instance = await this.web3Instance.eth.accounts.privateKeyToAccount(
                    getTorusPrivateKey()
                );
                const res = await instance.signTransaction({
                    from: getTorusAddress(),
                    to: environment.xgtClaimContractAddress,
                    data: this.xgtClaimService.cashbackXgtClaimContractInstance.methods
                        .claim()
                        .encodeABI(),
                    gas: 750000
                });
                response = await this.web3Instance.eth.sendSignedTransaction(res.rawTransaction);
                dialogRef.componentInstance.transactionHash = response.transactionHash;
                dialogRef.componentInstance.transactionStatus = 'success';
            } else {
                this.xgtClaimService.cashbackXgtClaimContractInstance.methods
                    .claim()
                    .send({ from: getTorusAddress() })
                    .on('transactionHash', (hash) => {
                        dialogRef.componentInstance.transactionHash = hash;
                        dialogRef.componentInstance.transactionStatus = 'success';
                    })
                    .on('error', (error) => {
                        dialogRef.componentInstance.transactionStatus = 'failed';
                        this.notifications.showMessage(error);
                    });
            }
        } catch (e) {
            console.log(e);
            dialogRef.componentInstance.transactionStatus = 'failed';
            this.notifications.showMessage(e.message);
        } finally {
            dialogRef.disableClose = false;
        }
    }

    async claimXgtRewards() {
        try {
            await this.web3InstanceService.checkChain();
            const signed = await this.confirmTransaction();
            this.processClaim(signed).then();
        } catch (e) {
            // transaction declined
        }
    }
}
