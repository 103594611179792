import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAffiliateSubscription } from '../../../core/services/affiliate.service';
import { ShareService } from '../../../core/services/share.service';
import { IUser } from '../../../core/services/user.service';

@Component({
    selector: 'xion-subscriptions-table',
    templateUrl: './subscriptions-table.component.html',
    styleUrls: ['./subscriptions-table.component.scss']
})
export class SubscriptionsTableComponent implements OnInit {


    @ViewChild(MatSort) sort: MatSort;
    @Input() subscriptions: IAffiliateSubscription[];
    @Input() user: IUser;


    displayedColumns: string[] = ['id', 'title', 'start', 'percentage', 'amount'];
    dataSource = new MatTableDataSource([]);

    constructor(private shareService: ShareService) {
    }

    ngOnInit() {
        this.dataSource.data = this.subscriptions;
        this.dataSource.sort = this.sort;
    }

    getTotal(field) {
        return this.subscriptions.map(payment => payment[field]).reduce((acc, value) => acc + value, 0);
    }

    getAffiliateLink(subscription: IAffiliateSubscription) {
        this.shareService.shareSubscription(subscription, this.user);
    }
}
