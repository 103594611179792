import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'xion-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent implements OnChanges,OnInit {

  @Input() links : {
    telegram : string;
    twitter: string;
    facebook : string;
    website : string;
  }
  show = false;
  constructor() {}

  ngOnChanges() {}
  ngOnInit(){
    this.links = environment.social;
  }
  toggleSocial() {
      this.show = !this.show;
  }
}
