<!-- <xion-tabs-header>
    MY ORDERS
</xion-tabs-header> -->
<header class="header">
    <div class="burger__holder">
        <xion-top-menu *ngIf="user$ | async" class="header__actions-component" [notifications]="notifications$"
            [user]="user$" (searchEvent)="searchQuery$.next($event)">
            <div class="page-title">
                <p>MY ORDERS</p>
            </div>
        </xion-top-menu>
    </div>
</header>
<div class="subscriptions">
    <div class="subscriptions__top">
        <ng-select id="interests" (change)="categoryChanged($event)" [items]="interests$ | async"
            placeholder="PRODUCT CATEGORY">
        </ng-select>
        <div>
            <mat-checkbox (change)="productTypeFilter($event)" [value]="billingTypeEnum.single">Single
            </mat-checkbox>
            <mat-checkbox (change)="productTypeFilter($event)" [value]="billingTypeEnum.recurring">Recurring
            </mat-checkbox>
            <mat-checkbox (change)="productTypeFilter($event)" [value]="billingTypeEnum.loyalty">Loyalty
            </mat-checkbox>
        </div>
    </div>

    <div class="subscriptions__header hide-on__mobile">
        <div class="subscription__title-column subscription__column">
            <span class="subscription__column-span">
                Products
            </span>
        </div>
        <div class="subscription__type-column subscription__column">
            <span class="subscription__column-span">
                CATEGORY / TYPE
            </span>
        </div>
        <div class="subscription__status-column subscription__column">
            <span class="subscription__column-span">
                Status
            </span>
        </div>
        <div class="subscription__action-column subscription__column">
            <span class="subscription__column-span">
                Action
            </span>
        </div>
    </div>

    <div class="subscriptions__container">

        <xion-my-subscription (pendingStatusEvent)="pendingStatusEvent($event)"
            *ngFor="let subscription of subscriptions$ | async | mySubscriptionsFilterPipe: {filter: filter}"
            [subscription]="subscription"></xion-my-subscription>



        <div class="no-products" *ngIf="!(subscriptions$ | async)?.length && shoppingStart=='display'">
            <!-- <p class="no-purchased">No Purchased Products/Services</p> -->
            <button style="margin: 25px;" class="startShopping startShopping-active" (click)="startShopping()">START
                SHOPPING
            </button>
        </div>


    </div>
</div>
