<div class="popup-blue__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title" *ngIf="notification.title">{{notification.title}}</h3>
        <ng-container *ngIf="notification.text">
            <p *ngFor="let text of notification.text;" class="popup-blue__text">{{text}}</p>
        </ng-container>
        <ng-container *ngIf="notification.autoHide">
            <p class="popup-blue__text">
                {{autoHideText}}
            </p>
        </ng-container>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>
