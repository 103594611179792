<footer class="footer">
    <!-- <nav class="footer__nav">
        <ul class="top-list">
            <li class="top-list__item ">
                <a [routerLink]="['/terms']" class="top-list__link" routerLinkActive="link-active">
                    T&CS
                    <div class="top-list__icon-holder">
                        <svg class="svg-icon icon-terms"><use xlink:href="#terms"></use></svg>
                    </div>
                </a>
            </li>
            <li class="top-list__item">
                <a (click)="openSupportModal()" class="top-list__link" routerLinkActive="link-active">
                    Support
                    <div class="top-list__icon-holder">
                        <svg class="svg-icon icon-mail"><use xlink:href="#icon-mail"></use></svg>
                    </div>
                </a>
            </li>
        </ul>
    </nav> -->
    <!-- <a  class="general-chat">
        <svg class="svg-icon icon-chat-robot">
        <use xlink:href="#icon-chat-robot"></use>
        </svg>
        <span class="general-chat__notification">
        HOW CAN WE HELP?
        </span>
    </a> -->
    
</footer>
