<div class="wrapper">
    <aside class="aside hide-on__mobile">
        <div class="aside__container">
            <a (click)="redirectTo('')" class="main-link">
                <svg class="svg-icon icon-logo"><use xlink:href="#logo"></use></svg>
            </a>
            <ul class="aside-buttons">
                <li class="aside-buttons__list">
                    <span class="button link-active button_small button_blue-border">
                        NEW ACCOUNT SETUP
                    </span>
                </li>
            </ul>
            <div class="aside-link__bottom-holder">
                <a href="../../../../assets/Terms-of-Use.pdf" class="aside-link__bottom">
                    T&CS
                    <div class="top-list__icon-holder">
                        <svg class="svg-icon icon-terms"><use xlink:href="#terms"></use></svg>
                    </div>
                </a>
            </div>
        </div>
    </aside>
    <div class="main__column">

        <header class="header">
            <div class="show-on__mobile link-holder">
                <a href="/" class="main-link">
                    <svg class="svg-icon icon-logo"><use xlink:href="#logo"></use></svg>
                </a>
            </div>
        </header>
        <main class="main">
            <ul class="action-list__top-holder show-on__mobile">
                <li class="action-list__item">
                    <a href="#" class="button button_blue link-active button_small">
                        NEW ACCOUNT setup
                    </a>
                </li>

            </ul>
            <div class="row_middle-content form"  *ngIf="credentials$ | async as credentials">
                <div class="main__section">
                    <div class="form__content">
                        <div class="form__row">
                            <div class="textfield textfield_pink">
                            <div>this is important!</div>
                            <div>You Should copy this information below.</div>
                            <div>KEEP YOUR DETAILS BELOW IN A SAFE PLACE</div>
                            </div>
                        </div>
                        <div class="form__row credential__text">
                            CONGRATULATIONS YOU ARE NOW SET
                        </div>
                        <div class="form__row" id="credentials">
                        <dl class="textfield">
                            <dt>USER NAME:</dt><dd>{{credentials.fullname}}</dd>
                            <dt>PROFILE/WALLET IDENTIFICATION:</dt><dd>{{credentials.xionid}}</dd>
                            <dt>PRIVATE ISX KEY:</dt><dd>{{credentials.priv_isx}}</dd>
                            <dt>PRIVATE XSC KEY:</dt><dd>{{credentials.priv_xsc}}</dd>
                        </dl>

                        </div>
                        <div  class="form__row form__row-links">
                            <a  class="button button_big button_blue-border" (click)="download()">
                                DOWNLOAD
                                <span class="button__arrow"><svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-download"></use></svg></span>
                            </a>
                            <a  class="button button_big button_blue-border" (click)="printPage()">
                                PRINT
                                <span class="button__arrow"><svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-print"></use></svg></span>
                            </a>
                            <a  class="button button_big button_blue-border" (click)="redirectTo('')">
                                Proceed
                                <span class="button__arrow"><svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-arrow-play"></use></svg></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
