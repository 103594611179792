import { ICustomer } from '../../core/services/customers.service';
import { IProduct } from '../../core/services/product.service';
import { Distributor, Supplier } from '../../core/services/provider.service';
import { IUser } from '../../core/services/user.service';
import { IOffer } from './offer.interface';

type Periodicity = 0 | 1 | 2 | 3;

export enum CreateSubscriptionStatus {
    active = 'active',
    paused = 'paused',
    preorder = 'preorder',
}

export enum SubscriptionPlan {
    daily = 0,
    weekly = 1,
    monthly = 2,
    yearly = 3
}

export interface Irsv {
    s: string;
    r: string;
    v: string;
}

export type SubscriptionType = 'Single' | 'Multiple';

export interface ISubscription {
    id?: number
    title: string;
    price: number;
    description: string;
    products: IProduct[] | number[],
    periodicity: Periodicity
    image: string;
    photo: string;
    video: string;
    discount: boolean;
    affiliate: boolean;
    user_chan: boolean;
    merch_chan: boolean;
    private: boolean;
    addressed: number;
    aff_perc: number;
    start: number;
    rating : number,
    end: number;
    suppliers: Supplier[]
    distributors: Distributor[];
    interestareas: string[];
    offers: IOffer [],
    ongoing: boolean; // if subscription is active by customer
    active: boolean;  // if subscription is active by merchant
    paused: boolean;
    preorder: boolean;
    subscribed: boolean;
    user?: IUser
    upaused: boolean;
    type: SubscriptionType
    customers? : ICustomer []
    liked: boolean;
    created?: number
}

