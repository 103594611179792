<div class="popup-blue__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title">
            CUSTOMER
        </h3>
        <div class="form__row">
            <div class="textfield__holder">
                <span class="form-label text-overflow"> NAME</span>
                <div class="textfield textfield__white-border disable">{{customer.fullname}}</div>
            </div>
        </div>
        <div class="form__row">
            <div class="textfield__holder">
                <span class="form-label text-overflow">Wallet ID</span>
                <div class="textfield textfield__white-border disable">
                    {{customer.xsc}}
                </div>
            </div>
        </div>
        <div class="tabs" >
            <a class="tab__item"
                [class.link-active]="activeTab === 'subscription'"
                (click)="activeTab = 'subscription'"
            >Subscription</a>
            <a class="tab__item"
                [class.link-active]="activeTab === 'offer'"
                (click)="activeTab = 'offer'"
            >One Time Offer</a>
        </div>
        <div class="form__row">
            <span class="form-label text-overflow">Creation date</span>
            <div class="textfield textfield__white-border disable">
                {{customer.created | unixDate}}
            </div>
        </div>
        <div class="list-transactions__holder textfield textfield__white-border">
            <ul class="list-transactions__head">
                <span class="list__date">Date</span>
                <span class="list__price">Price</span>
                <span class="list__id hide-on__mobile">ID</span>
                <span class="list__token hide-on__mobile">Token</span>
            </ul>
            <ul class="list-transactions">
                <li class="list-transactions__item" *ngFor="let transaction of transactions$ | async">
                <span class="list__date list-transactions__details">
                    <strong class="show-on__mobile">Date:</strong>{{transaction.date | date:'shortDate'}}
                </span>
                <span class="list__price list-transactions__details">
                    <strong class="show-on__mobile">Price:</strong> {{transaction.price | currencyConverter | async}}
                </span>
                <span class="list__id list-transactions__details notification-msg__holder">
                    <strong class="show-on__mobile">ID:</strong> {{transaction.id}}
                    <span class="notification-msg">
                        {{transaction.id}}
                    </span>
                </span>
                <span class="list__token list-transactions__details">
                    <strong class="show-on__mobile">Token:</strong> {{transaction.token}}
                </span>
                </li>
            </ul>
        </div>
        <div class="form__row">
            <form [formGroup]="contactForm">
                <div class="form__row">
                    <div class="textfield__holder">
                        <span class="form-label text-overflow">Message customer</span>
                        <textarea name="" class="textfield textfield__white-border" id="" cols="30" rows="5"
                                  placeholder="Message" formControlName="message"></textarea>
                    </div>
                </div>
            </form>
        </div>
        <div class="row__buttons">
            <button class="button button_small button_white-border" (click)="closeDialog()">Send</button>
        </div>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>
