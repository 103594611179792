<div class="wrapper">
  <div class="error__holder">
    <div class="error">
      4
      <svg class="svg-icon">
        <use xlink:href="#logo-part"></use>
      </svg>
      4
    </div>
    <div class="text__big">Oops, This page could not be found!</div>
    <div class="text__small">We`re sorry, but page you were looking for does not exist</div>
    <a routerLink="/" class="button button_blue button_big">
      Go to homepage
      <span class="button__arrow">
          <svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-arrow-play"></use></svg>
      </span>
    </a>
  </div>
</div>
