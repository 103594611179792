<div class="popup__content">
    <div class="popup">
        <form [formGroup]="loginForm" class="popup__body">
            <div class="error__field form__row" *ngIf="error">
                <span class="error__text">{{error}}</span>
            </div>
            <div class="form__row" [ngClass]="submitted && form.email.errors ? 'error__field' : ''">
                <input class="textfield" type="email" formControlName="email" id="login-email" placeholder="EMAIL">
                <span class="error__text" *ngIf="submitted && form.email.errors">
                    <p *ngIf="form.email.errors.required">email is required</p>
                    <p *ngIf="form.email.errors.email && !form.email.errors.required">please enter a valid email</p>
                </span>
            </div>
            <div class="form__row" [ngClass]="submitted && form.password.errors ? 'error__field' : ''">
                <input class="textfield" type="password" formControlName="password" id="pass" placeholder="PASSWORD">
                <span class="error__text" *ngIf="submitted && form.password.errors">
                    <span *ngIf="form.password.errors.required">password is required</span>
                </span>
            </div>
            <div class="row__buttons">
                <button class="button button_small button_blue" (click)="submitForm()">
                    LOGIN
                    <span class="button__arrow">
                        <svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-arrow-play"></use></svg>
                    </span>
                </button>
            </div>
            <div class="row__buttons">
                <button (click)="onSignup()" class="button button_small button_blue">
                    Signup
                    <span class="button__arrow">
                        <svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-arrow-play"></use></svg>
                    </span>
                </button>
            </div>
        </form>
        <span class="popup__title">
            Login
        </span>
    </div>
    <button (click)="closeDialog()" class="popup__close">
        <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
    </button>
</div>
