import { Pipe, PipeTransform } from '@angular/core';
import { ITransaction } from '../interfaces/transaction.interface';

@Pipe({
    name: 'billingType'
})
export class BillingTypePipe implements PipeTransform {

    transform(products: ITransaction[], args?: {billingType: string}): ITransaction[] {
        if (!products) return [];
        return products.filter(item => item.transaction_info.billingType === args.billingType);
    }

}
