import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xion-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private builder: FormBuilder,
        private dialogRef: MatDialogRef<ConfirmModalComponent>
    ) { }

    ngOnInit() {
    }
    closeDialog( confirm = false ) {
        this.dialogRef.close( confirm );
    }
    confirm() {
        this.closeDialog(true);
    }
}
