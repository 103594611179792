<h1 class="title">
    Affiliate
</h1>
<div class="columns" *ngIf="statistics$ | async as statistics">
    <div class="column__item column__item_details">
        <!-- <div class="top__row">
            <div class="date">
                <mat-form-field>
                    <input matInput [matDatepicker]="picker1" placeholder="" >
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="date">
                <mat-form-field>
                    <input matInput [matDatepicker]="picker" placeholder="" >
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

        </div> -->
        <div class="details__holder">
            <div class="details__row">
                <strong class="item-title">Lifetime Earnings:</strong>
                <span class="amount amount__blue">
                    <strong>{{statistics.lifetime | currencyConverter | async}}</strong>
                </span>
            </div>
            <!-- <div class="details__row">
                <div class="details__item">
                    <strong>$0.00</strong>
                    pending
                </div>
                <div class="details__item">
                    <strong>$0.00</strong>
                    paid
                </div>
            </div> -->
            <!-- <div class="details__row details__row_last">
                <div class="pending-item"><strong class="item-title">Pending Breakdown:</strong></div>
                <div class="pending-item">
                    Ready for Payout Today:
                    <span class="amount">
                        <strong>$0.00</strong>
                    </span>
                </div>
                <div class="pending-item">
                    Ready for Payout Next Monday:
                    <span class="amount">
                        <strong>$0.00</strong>
                    </span>
                </div>
                <div class="pending-item">
                    In Holding Past Monday:
                    <span class="amount">
                        <strong>$0.00</strong>
                    </span>
                </div>
            </div> -->
        </div>
        <div class="earnings__row">
            <strong class="item-title">Earnings Snapshot</strong>
            <span class="amount">As of {{today | date }}</span>
        </div>
        <div class="earning-details">
            <div class="earning-details__item">
                <span>Today so far</span>
                <strong class="earning-details_blue">{{statistics.today | currencyConverter | async}}</strong>
            </div>
            <div class="earning-details__item">
                <span>Last 7 days</span>
                <strong>{{statistics.seven | currencyConverter | async}}</strong>
            </div>
            <div class="earning-details__item">
                <span>Last 30 days</span>
                <strong>{{statistics.thirty | currencyConverter | async}}</strong>

            </div>
        </div>
    </div>
    <div class="column__item column__item_table">
        <ul class="days-list">
            <li class="days-list__item" *ngFor="let payment of statistics.lastdays">
                <span class="days-list__title">{{payment.date | unixDate}}</span>
                <span class="amount">
                    <span class="days-list__amount">
                        {{payment.amount | currencyConverter | async}}
                    </span>
                </span>
            </li>
        </ul>
    </div>
</div>

<div class="table__holder" *ngIf="statistics$ | async as stats">
    <xion-subscriptions-table
        [user]="user$ | async"
        [subscriptions]="stats.subscriptions"
    ></xion-subscriptions-table>
</div>
