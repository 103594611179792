import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as getUrls from 'get-urls';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { FileUploadService, IUploadResponse } from '../../../../core/services/file-upload.service';
import {
    IMetadataResponse,
    LinksMetadataService
} from '../../../../core/services/links-metadata.service';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { IUser } from '../../../../core/services/user.service';
import { PostsService } from '../../../../retailers/services/posts.service';
import { IAttachment } from '../../../models/models';
import { IPost } from '../../../pages/social/social.component';

export class Post implements IPost {
    attachments: IAttachment[] | number[];
    id: string;
    metadata: IMetadataResponse;
    text: string;
    user: IUser;
    liked: boolean;
}

@Component({
    selector: 'xion-create-channel-post',
    templateUrl: './create-social-post.component.html',
    styleUrls: ['./create-social-post.component.scss']
})
export class CreateSocialPostComponent implements OnInit {
    @Input() user: IUser;
    @Output() post = new EventEmitter<IPost>();

    createPostForm: FormGroup;
    attachments: number[] = [];

    _post: Post = new Post();
    photoLabel = 'Upload Photo';
    videoLabel = 'Upload Video';
    documentLabel = 'Upload Document (max. size 25mb)';
    uploadImageObj = { label: this.photoLabel, done: false };
    uploadDocumentObj = { label: this.documentLabel, done: false };
    uploadVideoObj = { label: this.videoLabel, done: false };
    uploadProgress: number = 0;
    uploadInProgress: boolean = false;

    constructor(
        private builder: FormBuilder,
        private notifications: NotificationsService,
        private http: HttpClient,
        private channelService: PostsService,
        private uploadService: FileUploadService,
        private linksMetadataService: LinksMetadataService
    ) {}

    ngOnInit() {
        this.createPostForm = this.builder.group({
            text: ['', [Validators.required]],
            metadata: [null]
        });
    }

    onSubmit() {
        if (this.createPostForm.valid) {
            const text = this.createPostForm.get('text').value;
            this.getURLMetadata(text)
                .pipe(
                    catchError(() => of(null))
                )
                .pipe(switchMap(metadata=> this.createPost(metadata)))
                .subscribe((post: IPost) => {
                    this.post.emit(post);
                    this.createPostForm.reset();
                });
            this.uploadImageObj.label = this.photoLabel;
            this.uploadImageObj.done = false;
            this.uploadDocumentObj.label = this.documentLabel;
            this.uploadDocumentObj.done = false;
            this.uploadVideoObj.label = this.videoLabel;
            this.uploadVideoObj.done = false;
            // this.createPostForm.reset();
        }
    }

    createPost(metadata: IMetadataResponse): Observable<IPost> {
        this._post = this.createPostForm.value;
        this._post.attachments = this.attachments;
        this._post.metadata = metadata;
        return this.channelService.createPost(this._post);
    }

    uploadPhoto(photo: FileList) {
        this.uploadInProgress = true;
        this.uploadService
            .uploadPhoto(photo)
            .subscribe((upload) => this.handleFileUpload(upload, 'image'));
    }

    uploadDocument(documents: FileList) {
        this.uploadInProgress = true;
        this.uploadService
            .uploadDocument(documents)
            .subscribe((upload) => this.handleFileUpload(upload, 'document'));
    }

    uploadVideo(video: FileList) {
        this.uploadInProgress = true;
        this.uploadService
            .uploadVideo(video)
            .subscribe((upload) => this.handleFileUpload(upload, 'video'));
    }

    private handleFileUpload(upload: IUploadResponse, typeUpload) {
        this.uploadProgress = upload.progress;
        switch (typeUpload) {
            case 'image':
                this.uploadImageObj.done = false;
                break;
            case 'document':
                this.uploadDocumentObj.done = false;
                break;
            case 'video':
                this.uploadVideoObj.done = false;
                break;

            default:
                break;
        }
        if (upload.status === 'success') {
            this.attachments.push(...upload.data.map((attachment) => attachment.id));
            this.uploadProgress = 0;
            this.uploadInProgress = false;
            switch (typeUpload) {
                case 'image':
                    this.uploadImageObj.label = upload.data[0].filename;
                    this.uploadImageObj.done = true;
                    break;
                case 'document':
                    this.uploadDocumentObj.label = upload.data[0].filename;
                    this.uploadDocumentObj.done = true;
                    break;
                case 'video':
                    this.uploadVideoObj.label = upload.data[0].filename;
                    this.uploadVideoObj.done = true;
                    break;

                default:
                    break;
            }
        }
    }

    private getURLMetadata(text: string): Observable<IMetadataResponse | null> {
        const urls: string[] = Array.from(getUrls(text)) || [];
        const url = urls[0] || null;
        // const match = new RegExp(
        //     '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?'
        // ).exec(text);
        if (url) {
            // const link = match[0];
            return this.linksMetadataService.preview(url);
        }
        return of(null);
    }

    get form() {
        return this.createPostForm.controls;
    }
}
