

    <div class="chart-double">
        <div class="chart-title" *ngIf="chartTitle">
            Customers
        </div>
        <div class="chart-part">
            <span class="yAxis-title">Total</span>
            <span class="xAxis-title">Years</span>
            <ng-container *ngIf="users">
                <ngx-charts-line-chart
                    style="display: block;"

                    class="chart-container fit-container"
                    [scheme]="scheme"
                    [results]="users"
                    [xAxis]="xAxis"
                    [yAxis]="yAxis"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [showGridLines]="true"
                    [rangeFillOpacity]="rangeFillOpacity"
                    [showRefLines]="true"
                    [referenceLines]="refLines"
                    [roundDomains]="true"
                    (select)="select($event)">
                </ngx-charts-line-chart>
            </ng-container>
        </div>
        <div class="chart-title" *ngIf="chartTitle">
            Revenue
        </div>
        <div class="chart-part">
            <span class="yAxis-title">Total</span>
            <span class="xAxis-title">Month</span>
            <ng-container *ngIf="revenue">
                <ngx-charts-line-chart
                    style="display: block;"
                    class="chart-container fit-container"
                    [scheme]="scheme"
                    [results]="revenue"
                    [xAxis]="xAxis"
                    [yAxis]="yAxis"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [showGridLines]="true"
                    [showRefLines]="true"
                    [roundDomains]="true"
                    (select)="select($event)">
                </ngx-charts-line-chart>
                </ng-container>
        </div>
    </div>
