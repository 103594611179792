import { Pipe, PipeTransform } from '@angular/core';
import { IMySubscription, ISubscriptionsFilter } from '../models/subscription.model';

@Pipe({
    name: 'mySubscriptionsFilterPipe',
    pure: false
})
export class MySubscriptionsFilterPipe implements PipeTransform {

    transform(subscriptions: IMySubscription[], args?: { filter: ISubscriptionsFilter }): IMySubscription[] {
        if (!subscriptions) return [];
        args.filter.searchQuery = args.filter.searchQuery.toLowerCase();
        subscriptions = [...subscriptions.filter((subscription: IMySubscription) => subscription.productName.toLowerCase().includes(args.filter.searchQuery))];

        if (args.filter.productType && args.filter.productType.length)
            subscriptions = [...subscriptions.filter((subscription: IMySubscription) => args.filter.productType.includes(subscription.billingType.toLowerCase()) ||args.filter.productType.includes(subscription.productLoyalty.toLowerCase()) )];
      
        if (!args.filter.category) return subscriptions;
        subscriptions = [...subscriptions.filter((subscription: IMySubscription) => subscription.category.toLowerCase() === args.filter.category.toLowerCase())];
        return subscriptions;
    }

}
