<div class="activity__top-row">
    <div class="search-row">
        <input type="text" name="search" id="search" class="search" placeholder="search" [(ngModel)]="query">
    </div>
</div>
<div class="activity-item__holder">
    <div class="activity-item" *ngFor="let user of users$ | async | userFilter:query" >
        <div class="activity-item__action">
            <svg class="svg-icon icon-send">
                <use xlink:href="#icon-send"></use>
            </svg>
<!--            <svg class="svg-icon icon-send" *ngIf="transaction.type =='sent'">-->
<!--                <use xlink:href="#icon-send"></use>-->
<!--            </svg>-->
<!--            <svg class="svg-icon icon-exchange" *ngIf="transaction.type =='swapped'">-->
<!--                <use xlink:href="#icon-exchange"></use>-->
<!--            </svg>-->
<!--            <svg class="svg-icon icon-receive" *ngIf="transaction.type =='received'">-->
<!--                <use xlink:href="#icon-receive"></use>-->
<!--            </svg>-->
        </div>
        <div class="activity-item__date">
            {{user.created| unixDate}}
        </div>
        <div class="activity-item__currency activity-item__details">
<!--            {{transaction.currency}}-->
            USD
        </div>
        <div class="activity-item__details">
            10 ISX
<!--            {{transaction.value}} {{transaction.token}}-->
        </div>
        <div class="activity-item__details">
            <ng-container>
<!--                {{transaction.value | currencyConverter |async }}-->
                20 $
            </ng-container>
        </div>
        <div class="activity-item__wallet">
            <span class="activiti-item__wallet-id">
               2kkg4p0k8x5g3968uva7dn
            </span>
            <button class="activity-item__button" (click)="copyAddress(user.isx)">
                <svg class="svg-icon icon-copy">
                    <use xlink:href="#icon-copy"></use>
                </svg>
            </button>
<!--            <button class="activity-item__button" (click)="copyAddress(transaction.id)">-->
<!--                <svg class="svg-icon icon-copy">-->
<!--                    <use xlink:href="#icon-copy"></use>-->
<!--                </svg>-->
<!--            </button>-->
        </div>
        <div class="activity-item__add">
            <img class="activity-picture" *ngIf="user.image" [src]="user.image"
                 [alt]="user.fullname">
            <ng-container *ngIf="!user.image">
                <ng-container *ngIf="user.merchant">
                    <svg class="svg-icon icon-member">
                        <use xlink:href="#icon-retailer"></use>
                    </svg>
                </ng-container>
                <ng-container *ngIf="!user.merchant">
                    <svg class="svg-icon icon-member">
                        <use xlink:href="#icon-user"></use>
                    </svg>
                </ng-container>
            </ng-container>


            <span class="activity-item__name text-overflow">
                {{user.fullname}}
            </span>

        </div>
        <div class="activity-item__view">
            <button class="activity-item__button"
                    (click)="follow(user.id)">
                <svg class="svg-icon icon-follow">
                    <use xlink:href="#icon-follow"></use>
                </svg>
<!--                <svg class="svg-icon icon-follow" *ngIf="transaction.retailer.followed;else unfollowRetailer">-->
<!--                    <use xlink:href="#icon-follow"></use>-->
<!--                </svg>-->
                <ng-template #unfollowRetailer>
                    <svg class="svg-icon icon-unfollow">
                        <use xlink:href="#icon-unfollow"></use>
                    </svg>
                </ng-template>
            </button>
            <button class="activity-item__view-button" (click)="openModal()">
<!--            <button class="activity-item__view-button" (click)="openModal(transaction)">-->
                <!-- view  -->
                <svg class="svg-icon icon-info">
                    <use xlink:href="#icon-info"></use>
                </svg>
            </button>
        </div>
    </div>
</div>
