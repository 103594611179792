
<div class="fit-container wallet">
    <ng-container *ngIf="wallets; else noData">
        <div class="wallet__item">
            <ngx-charts-pie-grid
                style="flex: auto"
                class="chart-container"
                [view]="view"
                [scheme]="schemes[0]"
                [results]="[wallets[0]]"
                [label]="'ISX'"
                (select)="select($event)"
                [designatedTotal]="total"
                >
            </ngx-charts-pie-grid>
        </div>
        <div class="wallet__item wallet__item_second">
            <ngx-charts-pie-grid
                style="flex: auto"
                class="chart-container"
                [view]="view"
                [scheme]="schemes[1]"
                [designatedTotal]="total"
                [label]="'XSC'"
                [results]="[wallets[1]]"
                (select)="select($event)"
                >
            </ngx-charts-pie-grid>
        </div>
    </ng-container>
    <ng-template #noData>
        <span class="no-data">There is no data yet</span>
    </ng-template>
</div>

