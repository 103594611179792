import { Injectable } from '@angular/core';
import Big from 'big.js';
import { HttpService } from '../../retailers/services/http.service';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class XgtClaimService {

    xgtClaimContractInstance;
    cashbackXgtClaimContractInstance

    constructor(
        private http: HttpService,
        private authService: AuthService
    ) {
    }

    async initXgtClaimContract(web3Instance): Promise<void> {
        try {
            const abiRouter = await this.http.getXgtClaimContractAbi().toPromise();
            this.xgtClaimContractInstance = new web3Instance.eth.Contract(abiRouter, environment.xgtClaimContractAddress, null);
        } catch (e) {
            console.log(e);
        }
    }

    getClaimableAmount(address: string): Promise<number> {
        return new Promise(async (resolve) => {
            try {
                const amount = await this.xgtClaimContractInstance.methods.getClaimableBalance(address).call();
                resolve(parseFloat(new Big(amount.toString()).div(new Big(10 ** 18)).toFixed(2).toString()));
            } catch (e) {
                resolve(0);
            }
        });
    }

    async initCashbackXgtClaimContract(web3Instance): Promise<void> {
        try {
            const abiRouter = await this.http.getXgtClaimContractAbi().toPromise();
            this.cashbackXgtClaimContractInstance = new web3Instance.eth.Contract(abiRouter, environment.xgtClaimContractAddress, null);
        } catch (e) {
            console.log(e);
        }
    }

    getCashbackClaimableAmount(address: string): Promise<number> {
        return new Promise(async (resolve) => {
            try {
                const amount = await this.cashbackXgtClaimContractInstance.methods.getClaimableBalance(address).call();
                resolve(parseFloat(new Big(amount.toString()).div(new Big(10 ** 18)).toFixed(2).toString()));
            } catch (e) {
                resolve(0);
            }
        });
    }
}
