<div class="search-bar" [class.is-expanded]="isExpanded">
    <img class="search-bar-back" src="assets/images/header/back.svg" width="15px" alt="Back" (click)="isExpanded = !isExpanded">
    <input class="search-input" type="text" placeholder="Search" (keyup)="onSearch($event)" [(ngModel)]="searchValue">
</div>
<div class="top-menu-bar" [class.is-expanded]="isExpanded">
    <button class="burger" (click)="toggleMenu()" [class.is-open]="asideService.isOpen">
        <svg class="svg-icon icon-burger"><use xlink:href="#icon-burger"></use></svg>
    </button>
    <div class="mobile-logo" [class.is-open]="asideService.isOpen">
        <img src="assets/images/header/xion-logo.svg" width="26" alt="XION">
    </div>
    <div class="mobile-header-logo" [class.is-open]="!asideService.isOpen">
        <img src="assets/images/header/text-logo.svg" width="120" alt="XION">
    </div>
    <nav class="main-menu" aria-label="Desktop Menu" id="mobile-menu">
        <ng-content *ngIf="!asideService.isOpen"></ng-content>
        <ul class="top-list">
            <li class="top-list__item search-control" *ngIf="user | async as currentUser">
                <input class="search-input" type="text" placeholder="Search" (keyup)="onSearch($event)" [(ngModel)]="searchValue">
                <img  class="top-list__item_icon"  src="assets/images/header/search.svg" width="20px" alt="Search">
            </li>
            <li class="top-list__item" *ngIf="user | async as currentUser ">
                <button mat-button class="top-list__link top-list__link_rewards" (click)="onOpenRewards()">
                    Cashback Rewards
                </button>
            </li>
            <li class="top-list__item" *ngIf="user | async as currentUser " (xionOutsideClick)="closeList()">
                <button mat-button class="top-list__link top-list__link_create" (click)="showOption = !showOption">
                    Create &nbsp;&nbsp;&nbsp;
                </button>
                <img class="top-list__item_icon" src="assets/images/header/dropdown.svg">
                <div class="top-list__item-options" *ngIf="showOption" >
                    <div class="menu_title"><p>ONLINE PAYMENTS</p></div>
                    <div class="menu_content"><div class="image-icon products-icon"></div><p><a [routerLink]="['/merchant', 'create', 'products']" routerLinkActive="router-link-active">Products</a></p></div>
                    <div class="menu_content"><div class="image-icon checkout-icon"></div><p><a [routerLink]="['/merchant', 'create', 'checkout']" routerLinkActive="router-link-active">Checkout buttons</a></p></div>
                    <div class="menu_content"><div class="image-icon link-icon"></div><p><a [routerLink]="['/merchant', 'create', 'payment']" routerLinkActive="router-link-active">Payment links</a></p></div>
                </div>
            </li>
            <li class="top-list__item help-control" *ngIf="user | async as currentUser " (click)="openHelp()">
                <img src="assets/images/header/help.svg" width="20px" alt="Help">
                <span>Help</span>
            </li>
            <li class="top-list__item" *ngIf="user | async as currentUser ">
                <div class="mobile-search-control image-icon" (click)="isExpanded = !isExpanded"></div>
                <div class="notification-control image-icon"></div>
                <div class="profile-control image-icon" (click)="navigateProfile()"></div>
            </li>
        </ul>
    </nav>
</div>
