import { Directive, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[xionOutsideClick]'
})
export class OutsideClickDirective {

    @Output() xionOutsideClick = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) { }

    @HostListener('document:click', ['$event.target'])
    public onClick(target) {
      const clickedInside = this.elementRef.nativeElement.contains(target);
      //  console.log("clickedInside-->>", clickedInside,"target==>>", target, target.id)
      // console.log('===========')
      if (!clickedInside && target.id!='copy'&&target.id!='swapCoin'&&target.id!='loyalty') {
        // console.log("id==>>",target.id, target)
        this.xionOutsideClick.emit(target);
      }
    }

}
