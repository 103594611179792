import { Injectable } from '@angular/core';
import { HttpService } from '../../retailers/services/http.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CommonService {
    pauseFeePercent: number = 12.5;
    pauseFeePlus: number = 0.3;  // formula: 12.5% + $0.3;
    constructor(private httpService: HttpService) {

    }

    public onDownloadManagementTransactions(productID: number): Observable<any> {
        return this.httpService.getManagementTransactionsCSV(productID);
    }

    public onDownloadOrdersTransactions(subscriptionID: number): Observable<any> {
        return this.httpService.getOrdersTransactionsCSV(subscriptionID);
    }

    public downloadTransactionsFile(res, productName: string): void {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = productName + 'Transactions.xlsx';
        element.click();
        element.remove();
    }
}
