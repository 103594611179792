<div class="wrapper" [class.aside-open]="asideMenuOpen">
    <ng-container *ngIf="user$ | async as user;else notLoggedInMenu">
        <!-- <xion-retailer-side-menu
            *ngIf="user.merchant"
            (closeMobileMenu)="closeSideMenu()"
            class="aside-component"></xion-retailer-side-menu>
        <xion-user-side-menu
            *ngIf="!user.merchant"
            (closeMobileMenu)="closeSideMenu()"
            class="aside-component"></xion-user-side-menu> -->
    </ng-container>
    <ng-template  #notLoggedInMenu>
        <div class="aside-component">
            <aside class="aside">
                <div class="aside__container">
                    <a routerLink="/" class="main-link">
                        <svg class="svg-icon icon-logo">
                            <use xlink:href="#logo"></use>
                        </svg>
                    </a>
                    <div class="aside-link__bottom-holder">
                        <a href="../../../../assets/Terms-of-Use.pdf" class="aside-link__bottom">
                            T&CS
                            <div class="top-list__icon-holder">
                                <svg class="svg-icon icon-terms">
                                    <use xlink:href="#terms"></use>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </aside>
        </div>
    </ng-template>

    <div class="main__column">
        <header class="header">
            <div class="show-on__mobile link-holder">
                <a href="/" class="main-link">
                    <svg class="svg-icon icon-logo">
                        <use xlink:href="#logo"></use>
                    </svg>
                </a>
            </div>
            <div class="burger__holder">
                <xion-top-menu *ngIf="user$ | async" class="header__actions-component"
                               (closeMobileMenu)="closeSideMenu()"
                               [notifications]="notifications$"
                               [user]="user$">
                </xion-top-menu>
            </div>

        </header>

        <main class="main" *ngIf="subscription">
            <div class="row_middle-content">
                <section class="preview__section">
                    <div class="title__row">
                        <h1 class="title">
                            {{subscription.title}}
                        </h1>

                        <div class="status status_active" *ngIf="subscription.active && !subscription.paused">
                            Active
                        </div>
                        <div class="status status_paused" *ngIf="subscription.paused">
                            Paused
                        </div>
                        <div class="status status_archived" *ngIf="!subscription.active && !subscription.paused">
                            Archived
                        </div>
                    </div>
                    <div class="subscription__top-row">

                        <div class="tabs__media" [class.tabs__media_video]="activeTopTab == tabs.VIDEO"
                             [class.tabs__media_fullscreen]="fullscreen">
                            <button class="fullscreen" *ngIf="subscription.image" (click)="fullscreen = !fullscreen">
                                <svg class="svg-icon icon-fullscreen">
                                    <use xlink:href="#icon-fullscreen"></use>
                                </svg>
                            </button>
                            <button class="tab__item link-active"
                                    [class.link-active]="activeTopTab == tabs.PHOTO"
                                    (click)="activeTopTab = tabs.PHOTO">
                                Photo
                            </button>
                            <button class="tab__item"
                                    *ngIf="subscription.video"
                                    [class.link-active]="activeTopTab == tabs.VIDEO"
                                    (click)="activeTopTab = tabs.VIDEO">
                                Video
                            </button>

                            <ng-container *ngIf="subscription.image else noSubscriptionImage">
                                <div class="subscription__img-holder" *ngIf="activeTopTab==tabs.PHOTO">
                                    <img [src]="subscription.image" [alt]="subscription.title" class="subscription__img">
                                </div>
                            </ng-container>

                            <ng-template #noSubscriptionImage>
                                <div class="subscription__img-holder no-image__holder" *ngIf="activeTopTab==tabs.PHOTO">
                                    <svg class="svg-icon icon-no-image">
                                        <use xlink:href="#no-image"></use>
                                    </svg>
                                </div>
                            </ng-template>

                            <ng-container *ngIf="subscription.video && activeTopTab == tabs.VIDEO">
                                <xion-video-player class="player-component" [video]="subscription.video"></xion-video-player>
                            </ng-container>

                        </div>

                        <div class="subscription__column">
                            <div class="subscription__aciton-row">
                                <div class="subscription__price">
                                        <span class="subscription__price_value" *ngIf="!user">
                                            {{subscription.price  | currency }}
                                        </span> <span class="subscription__price_value" *ngIf="user">
                                            {{subscription.price  | currencyConverter | async }}
                                        </span>

                                    <ng-container *ngIf="(user$  | async) && (wallet$ | async)">
                                        <ng-container *ngIf="subscription.discount">
                                                <span class="discount notification-msg__holder" *ngIf="walletService.discountLevel | async as level"> / {{ level }}
                                                        <span class="notification-msg"> You Qualify for Discount </span>
                                                    </span>
                                        </ng-container>
                                    </ng-container>

                                    <a class="info notification-msg__holder" (click)="openDiscountInfoModal()" *ngIf="subscription.discount">
                                        <svg class="svg-icon icon-info">
                                            <use xlink:href="#icon-discount"></use>
                                        </svg>
                                        <div class="notification-msg">
                                            Discount DETAILS
                                        </div>
                                    </a>
                                    <a class="info notification-msg__holder" *ngIf="isOwnerOfSubscription && subscription.discount"
                                       (click)="openDiscountCalculatorModal()">
                                        <svg class="svg-icon icon-info">
                                            <use xlink:href="#icon-calc"></use>
                                        </svg>
                                        <div class="notification-msg">
                                            Calculate Discount
                                        </div>
                                    </a>
                                </div>
                                <ng-container *ngIf="!isOwnerOfSubscription && user; else loginModalActions">
                                    <button class="subscription__like notification-msg__holder" [class.unlike]="subscription.liked"
                                            (click)="likeSubscription()">
                                        <svg class="svg-icon icon-like">
                                            <use xlink:href="#icon-like"></use>
                                        </svg>
                                        <span class="notification-msg">
                                                Like
                                            </span>
                                    </button>
                                    <button class="subscription__share notification-msg__holder"
                                            (click)="shareSubscription()">
                                        <svg class="svg-icon icon-share">
                                            <use xlink:href="#icon-share"></use>
                                        </svg>
                                        <span class="notification-msg">
                                                Share
                                            </span>
                                    </button>
                                    <star-rating
                                        [hoverEnabled]="true"
                                        [starType]="'svg'"
                                        (ratingChange)="rateSubscription($event)"></star-rating>

                                </ng-container>
                                <ng-template #loginModalActions>
                                    <button class="subscription__like notification-msg__holder"
                                            (click)="openLoginModal()">
                                        <svg class="svg-icon icon-like">
                                            <use xlink:href="#icon-like"></use>
                                        </svg>
                                        <span class="notification-msg">
                                                    Like
                                                </span>
                                    </button>
                                    <button class="subscription__share notification-msg__holder"
                                            (click)="openLoginModal()">
                                        <svg class="svg-icon icon-share">
                                            <use xlink:href="#icon-share"></use>
                                        </svg>
                                        <span class="notification-msg">
                                                    Share
                                                </span>
                                    </button>
                                    <star-rating
                                        [starType]="'svg'"
                                        class="disable-rating"
                                        [hoverEnabled]="true"
                                        (click)="openLoginModal()"></star-rating>
                                </ng-template>

                            </div>
                            <ul class="subscription__details-list">
                                <li class="subscription__details-item" *ngIf="!isOwnerOfSubscription">
                                    <span class="subscription__details-title">Merchant:</span>
                                    <span class="subscription__details">
                                        <img class="retiler__img"
                                             *ngIf="subscription.user.image;else noProfileImage"
                                             [src]="subscription.user.image" [alt]="subscription.user.companyname">
                                        <ng-template #noProfileImage>
                                            <svg class="svg-icon icon-member">
                                                <use xlink:href="#icon-user"></use>
                                            </svg>
                                        </ng-template>
                                        <h3 class="retailer__name">
                                            {{subscription.user.companyname}}
                                        </h3>
                                        <xion-user-rating class="merchant-rating"
                                                          [rating]="subscription.user.rating"></xion-user-rating>
                                    </span>
                                </li>
                                <li class="subscription__details-item">
                                    <span class="subscription__details-title">Merchant ID:</span>
                                    <span class="subscription__details">
                                            {{subscription.user.idxion}}
                                        </span>
                                </li>
                                <li class="subscription__details-item">
                                    <span class="subscription__details-title">Plan:</span>
                                    <span class="subscription__details">
                                            {{subscription.periodicity | subscriptionPlan}}
                                        </span>
                                </li>
                                <li class="subscription__details-item">
                                    <span class="subscription__details-title">Type:</span>
                                    <span class="subscription__details">
                                            {{subscription.products | subscriptionType}}
                                        </span>
                                </li>

                                <ng-container *ngIf="isOwnerOfSubscription">
                                    <li class="subscription__details-item">
                                        <span class="subscription__details-title">Duration:</span>
                                        <span class="subscription__details">
                                            {{subscription | subscriptionDuration}}
                                        </span>
                                    </li>
                                    <li class="subscription__details-item">
                                        <span class="subscription__details-title">Creation Date:</span>
                                        <span class="subscription__details">
                                           {{ subscription.start | unixDate}}
                                        </span>
                                    </li>
                                    <li class="subscription__details-item" *ngIf="activeCustomers">
                                        <span class="subscription__details-title">Active Customers:</span>
                                        <span class="subscription__details">
                                            {{ activeCustomers  }}
                                        </span>
                                    </li>
                                    <li class="subscription__details-item" *ngIf="cancelledCustomers">
                                        <span class="subscription__details-title">Cancelled Customers:</span>
                                        <span class="subscription__details">
                                            {{cancelledCustomers}}
                                        </span>
                                    </li>
<!--                                    <li class="subscription__details-item">-->
<!--                                        <span class="subscription__details-title">Net Revenue:</span>-->
<!--                                        <span class="subscription__details">-->
<!--                                            863-->
<!--                                        </span>-->
<!--                                    </li>-->
                                </ng-container>
                            </ul>
                            <div class="button-holder">
                                <ng-container *ngIf="isOwnerOfSubscription; else userActions">
<!--                                    <a href="" class="button button_small button_blue">-->
<!--                                        Edit-->
<!--                                        <span class="button__arrow">-->
<!--                                            <svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-edit"></use></svg>-->
<!--                                        </span>-->
<!--                                    </a>-->
                                    <button class="button button_small button_blue" (click)="openConnectModal()">
                                        Connect
                                        <span class="button__arrow">
                                            <svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-link"></use></svg>
                                        </span>
                                    </button>
                                    <button class="button button_small button_blue" *ngIf="subscription.upaused && !isOwnerOfSubscription" (click)="activateSubscription()">
                                        Activate
                                        <span class="button__arrow">
                                            <svg class="svg-icon icon-arrow_button"><use
                                                xlink:href="#icon-arrow-play"></use></svg>
                                        </span>
                                    </button>
                                    <button class="button button_small button_blue" *ngIf="isOwnerOfSubscription && this.subscription.paused" (click)="activateMerchantSubscription()">
                                        Activate Subscription
                                        <span class="button__arrow">
                                            <svg class="svg-icon icon-arrow_button"><use
                                                xlink:href="#icon-arrow-play"></use></svg>
                                        </span>
                                    </button>
<!--                                    <button class="button button_small button_blue" *ngIf="!subscription.active" (click)="activateMerchantSubscription()">-->
<!--                                        Restore-->
<!--                                        <span class="button__arrow">-->
<!--                                            <svg class="svg-icon icon-restore"><use-->
<!--                                                xlink:href="#icon-arrow-reload"></use></svg>-->
<!--                                        </span>-->
<!--                                    </button>-->
                                    <button class="button button_small button_blue" *ngIf="!subscription.upaused && !isOwnerOfSubscription"  (click)="pauseSubscription()">
                                        Pause
                                        <span class="button__arrow">
                                            <svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-pause"></use></svg>
                                        </span>
                                    </button>

                                    <button class="button button_small button_blue" *ngIf="!subscription.paused && subscription.active" (click)="pauseMerchantSubscription()">
                                        Pause Subscription
                                        <span class="button__arrow">
                                            <svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-pause"></use></svg>
                                        </span>
                                    </button>
                                    <button class="button button_small button_blue" *ngIf="subscription.paused && isOwnerOfSubscription" (click)="archiveMerchantSubscription()">
                                        Archive
                                        <span class="button__arrow">
                                            <svg class="svg-icon icon-arrow_button"><use
                                                xlink:href="#icon-archive"></use></svg>
                                        </span>
                                    </button>
                                </ng-container>
                                <ng-template #userActions>
                                    <button *ngIf="subscription.affiliate"
                                        class="button button_small button_blue button_subscribe "
                                        (click)="affiliate()"> Affiliate
                                        <span class="button__arrow icon-affiliate">
                                            <svg class="svg-icon icon-arrow_button">
                                                <use xlink:href="#icon-affiliate"></use>
                                            </svg>
                                        </span>
                                    </button>
                                    <ng-container *ngIf="subscription.subscribed;else subscribeTemplate">
                                        <button class="button button_small button_blue button_subscribe"  (click)="unsubscribe()">
                                            Unsubscribe
                                            <span class="button__arrow">
                                                <svg class="svg-icon icon-arrow_button"><use xlink:href="#logo-part"></use></svg>
                                            </span>
                                        </button>
                                    </ng-container>
                                    <ng-template #subscribeTemplate>
                                        <button class="button button_small button_blue button_subscribe"   (click)="subscribe()">
                                            Subscribe
                                            <span class="button__arrow">
                                                <svg class="svg-icon icon-arrow_button"><use xlink:href="#logo-part"></use></svg>
                                            </span>
                                        </button>
                                    </ng-template>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <p class="subscription__description">
                        {{subscription.description}}
                    </p>

                    <h2 class="title__sub" *ngIf="!isOwnerOfSubscription || hasOnetimeOffers">
                        Products
                    </h2>
                    <div class="tabs" *ngIf="isOwnerOfSubscription || hasOnetimeOffers">
                        <button class="tab__item" [class.link-active]="activeTab == 'products'"
                                (click)="activeTab = 'products'">
                            <h2>
                                Products
                            </h2>
                        </button>
                        <button class="tab__item" [class.link-active]="activeTab == 'oneTime'" *ngIf="hasOnetimeOffers"
                                (click)="activeTab = 'oneTime'">
                            <h2>
                                One Time Offers
                            </h2>
                        </button>
                        <ng-container *ngIf="isOwnerOfSubscription">

                            <button class="tab__item" [class.link-active]="activeTab == 'supplier'"
                                    (click)="activeTab = 'supplier'">
                                <h2>
                                    Supplier
                                </h2>
                            </button>
                            <button class="tab__item" [class.link-active]="activeTab == 'distributor'"
                                    (click)="activeTab = 'distributor'">
                                <h2>
                                    Distributor
                                </h2>
                            </button>
                            <button class="tab__item" [class.link-active]="activeTab == 'statistic'"
                                    (click)="activeTab = 'statistic'">
                                <h2>
                                    Subscribers
                                </h2>
                            </button>
                        </ng-container>
                    </div>
                    <xion-providers-list
                        class="component"
                        *ngIf="activeTab==='supplier' && isOwnerOfSubscription"
                        [providerType]="'supplier'"
                        [providers]="subscription.suppliers"
                    ></xion-providers-list>
                    <xion-providers-list
                        class="component"
                        *ngIf="activeTab==='distributor' && isOwnerOfSubscription"
                        [providerType]="'distributor'"
                        [providers]="subscription.distributors"
                    ></xion-providers-list>
                    <xion-products-list
                        class="component"
                        [user]="user"
                        [products]="subscription.products"
                        [isSubscriptionOwner]="isOwnerOfSubscription"
                        *ngIf="activeTab==='products'"
                    ></xion-products-list>

                    <xion-one-time-offer-list
                        class="component"
                        *ngIf="activeTab==='oneTime' && hasOnetimeOffers"
                        [canBuy]="!isOwnerOfSubscription"
                        [subscription]="subscription"
                        [user]="user"
                    >
                    </xion-one-time-offer-list>
                    <div class="table__holder" [class.tab-active]="activeTab == 'statistic'"  *ngIf="customers" >
                        <xion-subscribers-table [customers]="customers"></xion-subscribers-table>
                    </div>
                </section>
            </div>
        </main>
    </div>
</div>


