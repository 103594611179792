import { Component, Input, OnInit } from '@angular/core';
import { ISubscription } from '../../../interfaces/subscription.interface';
import { IUser } from 'src/app/core/services/user.service';

@Component({
    selector: 'xion-subscription-list',
    templateUrl: './subscription-list.component.html',
    styleUrls: ['./subscription-list.component.scss']
})
export class SubscriptionListComponent implements OnInit {

    @Input() subscriptions: ISubscription[]
    @Input() query: string;
    @Input() user: IUser;

    constructor() {
    }

    ngOnInit() {
    }

}
