<div class="add-post__row retiler-post__item">
    <div class="border-right"></div>
    <div class="border-left"></div>
    <div class="white-background">
        <div class="post">
            <div class="post__picture" *ngIf="post.user.image">
                <img [src]="post.user.image" [alt]="post.user.fullname">
            </div>

            <div class="post__picture post__picture-empty" *ngIf="!post.user.image">
                <svg class="svg-icon icon__no-retailer">
                    <use xlink:href="#icon-retailer"></use>
                </svg>
            </div>
            <div class="form__row">
                <textarea name="" class="textfield" id="" cols="30" rows="5" [(ngModel)]="post.text" placeholder="Type text here"></textarea>
            </div>
        </div>
        <!-- <div class="retiler-post__picture-holder" *ngIf="photo">
            <img [src]="photo.url" [alt]="photo.filename" class="retiler-post__picture">
            <a (click)="removePostImage()" class="clear-picture">
                <svg class="svg-icon clear-icon">
                    <use xlink:href="#close-simple"></use>
                </svg>
            </a>
        </div> -->

        <div class="form__row form__row_multiple progress__holder" [class.progress_active]="isUploadInProgress()">
            <div class="row__form_file">
                <label class="textfield file__label file__label_multiple" for="photo" [class.done]="uploadPhotoObj.done">
                    <span class="file__title file__title_multiple">
                        {{uploadPhotoObj.label}}
                    </span>
                    <input type="file" class="file" id="photo" aria-label="File browser example"
                           accept="image/*" #photoUpload
                           (change)="uploadPhoto(photoUpload.files)">
                    <svg class="svg-icon icon-check_file">
                        <use xlink:href="#check"></use>
                    </svg>
                </label>
            </div>
            <div class="row__form_file">
                <label class="textfield file__label file__label_multiple" for="video" [class.done]="uploadVideoObj.done">
                    <span class="file__title file__title_multiple">
                        {{uploadVideoObj.label}}
                    </span>
                    <input type="file" class="file" id="video" aria-label="File browser example"
                           accept="video/*" #video
                           (change)="uploadVideo(video.files)">
                    <svg class="svg-icon icon-check_file">
                        <use xlink:href="#check"></use>
                    </svg>
                </label>
            </div>
            <div class="row__form_file">
                <label class="textfield file__label file__label_multiple" for="document" [class.done]="uploadDocumentObj.done">
                    <span class="file__title file__title_multiple">
                        {{uploadDocumentObj.label}}
                    </span>
                    <input type="file" class="file" id="document" aria-label="File browser example"
                           accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt,.pdf"
                           #documents
                           (change)="uploadDocument(documents.files)">
                    <svg class="svg-icon icon-check_file">
                        <use xlink:href="#check"></use>
                    </svg>
                </label>
            </div>
            <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
        </div>


    </div>
    <button class="popup__close">
        <span class="button-text" (click)="savePost()">Save</span>
    </button>

</div>
