import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable } from 'ngx-cacheable';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    share,
    skip,
    switchMap,
    take
} from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';
import { Currencies, supportedCurrencies } from '../../shared/constants/config';
import { UserService } from './user.service';

export interface IRatesResponse {
    base: string;
    rates: {
        [key: string]: number;
    };
    date: string;
}

const MAX_CACHE_TIME = 300000;

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {
    public selectedCurrency: Observable<any>;
    public defaultCurrency = {
        name: 'USD',
        index: 0
    };
    public selectedCurrencySubject = new BehaviorSubject<string>(null);
    private supportedCurrencies = supportedCurrencies;
    private currencies: IRatesResponse;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private userService: UserService
    ) {
        this.auth.currentUser.subscribe((user) => {
            if (user) {
                this.defaultCurrency = this.getCurrencyFromList(user.currency);
                this.selectedCurrencySubject.next(this.defaultCurrency.name);
                this.selectedCurrency = this.selectedCurrencySubject.asObservable();
                this.getExchangeRates().subscribe((currencies: any) => {
                    this.currencies = currencies;
                });
            }
        });
    }

    public getCurrencies(): string[] {
        return this.supportedCurrencies;
    }

    getCurrencyRate(currency: string = 'USD'): number | null {
        if (!this.currencies || !this.currencies.rates) return null;
        const { rates } = this.currencies;
        return rates[currency] || null;
    }

    convert(from: Currencies) {
        return this.getCurrencyRate(from) / this.getCurrencyRate(Currencies.USD);
    }

    updateCurrency() {
        return this.selectedCurrency.pipe(
            skip(1),
            share(),
            debounceTime(1000),
            distinctUntilChanged(),
            filter((currency) => !!currency),
            switchMap((currency) => this.userService.updateUserCurrency(currency))
        );
    }

    @Cacheable({
        maxAge: MAX_CACHE_TIME
    })
    private getExchangeRates() {
        return this.http
            .get(
                `https://api.exchangeratesapi.io/latest?base=USD&symbols=${this.supportedCurrencies.join(
                    ','
                )}`
            )
            .pipe(take(1));
    }

    private getCurrencyFromList(name: string) {
        return { name, index: this.supportedCurrencies.indexOf(name) };
    }
}
