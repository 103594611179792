<div class="popup-blue__content discount-details">
    <div class="popup-blue">
        <div *ngIf="data.isMerchant; else elseTempl">

            <div class="details-loyalty">

                <h3 class="popup-blue__title">MAX DISCOUNT ALLOWED</h3>
                <p class="details">
                    This is to set the maximum amount of discount you are willing to offer your customers per recurring billing renewal. Based on the loyalty program
                </p>
            </div>

        </div>
        <ng-template #elseTempl>
            <h3 class="popup-blue__title">MAX DISCOUNT ALLOWED</h3>
            <p class="details">
                This is to set the maximum amount of discount you are willing to offer your customers per recurring billing renewal. Based on the loyalty program
            </p>
        </ng-template>


    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>
