import * as moment from 'moment';
import {
    BillingCycles,
    BillingCyclesStatusType, BillingInterval, BillingIntervalType,
    BillingProductStatus,
    BillingProductType, BillingType, BillingTypeEnum,
    IBillingCycleBase, IBillingTypeBase,
    ISubscriptionStatusBase,
    ProductType,
    StatusEnum, SubscriptionStatus
} from './billing-product.model';
import { Irsv } from '../interfaces/subscription.interface';

export type SubscriptionRating = 0 | 1 | 2 | 3 | 4 | 5;
export interface ISubscriptionBase {
    productID: number;
    productName: string;
    productPrice: any;
    shippingPrice?: any;
    productImageUrl: string,
    category: string;
    productType?: any;
    productUrl: string;
}

interface ISubscribeStatuses {
    subscribeShow: boolean,
    subscribeBlocked: boolean
}

interface IUnsubscribeStatuses {
    unsubscribeShow: boolean;
    unsubscribeBlocked: boolean;
}

interface IActiveStatuses {
    activateShow: boolean;
    activateBlocked: boolean;
}

interface IPauseStatuses {
    pauseShow: boolean;
    pauseBlocked: boolean;
}

export interface IButtonStates {
    subscribeStatuses: ISubscribeStatuses;
    unsubscribeStatuses: IUnsubscribeStatuses;
    activeStatuses: IActiveStatuses;
    pauseStatuses: IPauseStatuses;
}

export interface IMySubscription extends ISubscriptionBase, IBillingCycleBase, ISubscriptionStatusBase, IBillingTypeBase {
    id: number;
    renewalDate: string;
    paymentsLeft: number;
    productLoyalty: string;
    billingCycleType: string;
    billingInterval: BillingIntervalType;
    rating: SubscriptionRating;
    productStatus: BillingProductStatus;
    buttonStates: IButtonStates;
    companyName: string;
}

export interface ISubscriptionsFilter {
    category: string,
    searchQuery: string,
    productType: string[],
}

export const subscriptionsFilter: ISubscriptionsFilter = {
    category: '',
    searchQuery: '',
    productType: []
};

export interface ICreateSubscriptions {
    walletAddress: string;
    productsIDs: number[];
    signature: Irsv;
}

export class SubscriptionModel implements IMySubscription {
    id: number;
    productID: number;
    productName: string;
    productPrice: string;
    shippingPrice: number;
    category: string;
    productType: any;
    productUrl: string;
    productImageUrl: string;
    productLoyalty: string;
    productStatus: BillingProductStatus;
    renewalDate: string;
    paymentsLeft: number;
    billingType: BillingType;
    billingCycle: BillingCyclesStatusType | null;
    billingCycleType: string;
    billingInterval: BillingIntervalType;
    rating: SubscriptionRating;
    status: SubscriptionStatus;
    buttonStates: IButtonStates;
    companyName: string;
    constructor(data: IMySubscription) {
        if (data) {
            this.id = data.id || null;
            this.productID = Number(data.productID);
            this.productName = data.productName || '';
            this.productPrice = data.productPrice || 0;
            this.shippingPrice = data.shippingPrice || 0;
            this.category = data.category || '';
            this.companyName = data.companyName || '';
            this.productType = data.productType || BillingProductType.digital;
            this.productImageUrl = data.productImageUrl || '';
            this.renewalDate = moment(new Date(data.renewalDate)).format('MM/DD/YYYY');
            this.paymentsLeft = data.paymentsLeft;
            this.billingType = (data.billingType=='recurring'&& data.productLoyalty)? 'unknown' : data.billingType || BillingTypeEnum.single;
            this.productUrl = (data.productUrl.match(/^https?:/)) ? data.productUrl : '//' + data.productUrl || '';
            this.productStatus = data.productStatus || null;
            this.billingCycle = data.billingCycle || BillingCycles.ongoing;
            this.billingCycleType = data.billingCycleType
            this.billingInterval = data.billingInterval || BillingInterval.daily;
            this.rating = data.rating || 0;
            this.status = data.status || StatusEnum.active;
            this.buttonStates = data.buttonStates || DefaultButtonStates;
            this.productLoyalty = data.productLoyalty ? "loyalty" : 'unloyalty';
        }
    }
}

const DefaultButtonStates: IButtonStates = {
    subscribeStatuses: {
        subscribeShow: true,
        subscribeBlocked: true
    },
    unsubscribeStatuses: {
        unsubscribeShow: true,
        unsubscribeBlocked: true
    },
    activeStatuses: {
        activateShow: true,
        activateBlocked: true
    },
    pauseStatuses: {
        pauseShow: true,
        pauseBlocked: true
    }
};
