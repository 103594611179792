import {Component, Input, OnInit} from '@angular/core';
import { RetailerSubscription } from '../../../models/models';

@Component({
    selector: 'xion-view-subscription',
    templateUrl: './view-subscription.component.html',
    styleUrls: ['./view-subscription.component.scss']
})
export class ViewSubscriptionComponent implements OnInit {

    @Input() subscription: RetailerSubscription;

    constructor() {
    }

    ngOnInit() {
    }

}
