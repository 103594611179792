import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prefix'
})
export class PrefixPipe implements PipeTransform {

  transform(value: number): unknown {
    return value === 1 ? value + ' PAYMENT' : value + ' PAYMENTS'
  }

}
