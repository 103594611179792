import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
    // UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, UserRoleEnum } from '../../auth/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class UserGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authService.loggedInUser;
        if (user && user.role !== UserRoleEnum.buyer) {
            this.authService.logout();
            return false;
        }
        return true;
    }
}
