import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xion-leave-account-modal',
  templateUrl: './leave-account-modal.component.html',
  styleUrls: ['./leave-account-modal.component.scss']
})
export class LeaveAccountModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
