import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, pluck, take, tap } from 'rxjs/operators';
import TorusSdk from '@toruslabs/customauth';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { Auth0DecodedHash, WebAuth } from 'auth0-js';
import { ICredentials, ISignupResponse, IUser } from '../../core/services/user.service';
import { WalletService } from '../../core/services/wallet.service';
import { HttpService } from '../../retailers/services/http.service';
import { mapResponseToData, updateTokens } from '../../shared/constants/helpers';
import { WEB3 } from '../../shared/services/web3.injector';
import { JwtService } from './jwt.service';
import { AuthErrorTypeEnum, IGetAuthTokenError } from '../../shared/interfaces/auth.interface';
import { MatDialog } from '@angular/material/dialog';
import { AuthUserNotFoundModalComponent } from '../../shared/modals/auth-user-not-found-modal/auth-user-not-found-modal.component';
import { Web3Service } from '../../shared/services/web3.service';
import { AuthProvider, getTorusParams, saveTorusAddress, Verifier } from 'src/app/utils';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public redirectUrl: UrlSegment[] = null;
    public currentUser: Observable<IUser>;
    private currentUserSubject: BehaviorSubject<IUser>;

    public currentUserPlan: Observable<string>;
    public currentUserPlanSubject: BehaviorSubject<string>;

    private LOGIN_ROUTE = `${environment.api_url}/users/authenticate`;
    private REFRESH_TOKEN = `${environment.api_url}/users/token/refresh`;
    private SIGNUP_ROUTE = `${environment.api_url}/users/create`;
    private RESET_PASSWORD = `${environment.api_url}/users/reset`;
    // used to update the payment confirmation method from password to private key or vise versa
    private CONFIRMATION_TYPE = `${environment.api_url}/users/usepwd`;

    public torus: TorusSdk;
    private torusInitialized: boolean = false;

    public webAuth: WebAuth;

    constructor(
        private http: HttpClient,
        private jwtService: JwtService,
        private router: Router,
        private walletService: WalletService,
        private web3Service: Web3Service,
        private ngZone: NgZone,
        private httpService: HttpService,
        private dialog: MatDialog
    ) {
        this.currentUserSubject = new BehaviorSubject<IUser>(this.jwtService.getUser());
        this.currentUser = this.currentUserSubject.asObservable();

        this.currentUserPlanSubject = new BehaviorSubject<string>(this.getUserPlan());
        this.currentUserPlan = this.currentUserPlanSubject.asObservable();
        // this.web3Service._init().then(this.init.bind(this));

        // this.torus = new DirectWebSdk({
        //     baseUrl: environment.url,
        //     proxyContractAddress: environment.proxyContractAddress, // details for test net
        //     network: 'testnet', // details for test net
        //     enableLogging: !environment.production,
        //     redirectPathName: 'redirect.html'
        // });
        this.torus = new TorusSdk({
            baseUrl: environment.url,
            network: 'testnet', // details for test net
            networkUrl: 'https://spring-ancient-spring.ropsten.discover.quiknode.pro/8819e67dfdcd0ff11be9f1647e8718be5a4985fd/', //custom quicknode rpc fix for ropsten breaking change
            enableLogging: !environment.production,
            redirectPathName: 'redirect.html'
        });
        this.initTorus().then(() => console.log('[torus initialized]'));
    }

    public get isLoggedIn(): boolean {
        return !!this.loggedInUser;
    }

    public get loggedInUser(): IUser {
        // console.log(this.currentUserSubject.value);
        return this.currentUserSubject.value;
    }

    signup(user: IUser): Observable<ICredentials> {
        return this.http.post<ISignupResponse>(this.SIGNUP_ROUTE, { ...user }).pipe(
            take(1),
            pluck('data')
            // map((response) => response.data)
        );
    }

    refreshToken() {
        return this.http
            .post(this.REFRESH_TOKEN, {
                refreshToken: this.jwtService.getRefreshToken()
            })
            .pipe(tap(updateTokens));
    }

    resetPassword(email: string) {
        return this.http.post(this.RESET_PASSWORD, { email }).pipe(pluck('data'));
        // return this.http.post(this.RESET_PASSWORD, { email }).pipe(map(mapResponseToData));
    }

    private async initTorus() {
        await this.torus.init({ skipSw: true });
        this.torusInitialized = true;
    }

    public setAddress(address: string) {
        saveTorusAddress(address);
    }

    login(email: string, password: string) {
        return this.http
            .post<any>(this.LOGIN_ROUTE, {
                email,
                password
            })
            .pipe(
                map((response) => {
                    const { token, refreshToken, image } = response.data;
                    // login successful if there's a jwt token in the response
                    if (token && refreshToken) {
                        this.jwtService.setToken(token);
                        this.jwtService.setRefreshToken(refreshToken);
                        this.jwtService.setUserImage(image);
                        const user = this.jwtService.getUser();
                        this.currentUserSubject.next(user);
                    }
                    return response;
                }, tap(this.walletService.refreshBalance.bind(this)))
            );
    }

    public async torusLogin(verifier: Verifier, hash, queryParameters) {
        if (!this.torusInitialized) await this.initTorus();
        const params = getTorusParams(verifier, hash, queryParameters);
        return this.torus.triggerLogin(params);
    }

    logout() {
        // remove user from local storage to log user out
        this.jwtService.deleteToken();
        localStorage.removeItem('image');
        this.redirectUrl = null;
        this.currentUserSubject.next(null);
        this.router.navigate(['']);
    }

    setUserImage(image) {
        localStorage.setItem('image', image);
    }

    updateUserImage(image: string) {
        const user = {
            ...this.currentUserSubject.value,
            image
        };
        this.setUserImage(image);
        this.currentUserSubject.next(user);
    }

    setUserPlan(plan: string) {
        localStorage.setItem('user_plan', plan);
        this.currentUserPlanSubject.next(plan);
    }

    getUserPlan() {
        return localStorage.getItem('user_plan');
    }

    refreshUser() {
        this.currentUserSubject.next(this.jwtService.getUser());
    }

    getUserImage() {
        return localStorage.getItem('image') || null;
    }

    updateConfirmationMethod(password: string, key: string) {
        return this.http
            .post(this.CONFIRMATION_TYPE, {
                salt: key,
                password
            })
            .pipe(
                pluck('data'),
                // map(mapResponseToData),
                tap(updateTokens),
                tap(() => this.refreshUser())
            );
    }

    public getAuthRedirectLink(user: IUser = this.loggedInUser): string {
        let link = '/merchant/dashboard';        
        return link;
    }

    private getAuthToken(account: string) {
        return this.httpService.auth(account);
    }

    private authTokenErrorHandler(error: HttpErrorResponse): void {
        if (error) {
            switch (error.error.error_type) {
                case AuthErrorTypeEnum.NotFound:
                    this.dialog.closeAll();
                    this.dialog
                        .open(AuthUserNotFoundModalComponent, {
                            panelClass: 'subscriptions-slider'
                        })
                        .afterClosed()
                        .subscribe();
                    break;
                case AuthErrorTypeEnum.InternalServerError:
                    break;
                default:
                    break;
            }
        }
    }

    public async setCurrentUser(account: string) {
        if (account) {
            const token = await this.getAuthToken(account)
                .toPromise()
                .catch((err: HttpErrorResponse) => {
                    this.authTokenErrorHandler(err);
                    this.logout();
                });
            if (token && token.access_token) {
                this.jwtService.setToken(token.access_token);
                this.currentUserSubject.next(this.jwtService.getUser());
            }
        }
    }

    private init() {
        if (
            this.web3Service.web3.currentProvider &&
            this.web3Service.web3.currentProvider['isMetaMask']
        ) {
            if ('enable' in (this.web3Service.web3.currentProvider as object)) {
                this.setCurrentUser(this.web3Service.web3.eth.defaultAccount);
                window.ethereum.on('accountsChanged', (accounts) => {
                    this.ngZone.run(() => {
                        if (accounts && accounts[0]) {
                            this.web3Service.web3.eth.defaultAccount = accounts[0];
                            this.setCurrentUser(this.web3Service.web3.eth.defaultAccount);
                        } else {
                            this.web3Service.web3.currentProvider['enable']();
                            this.logout();
                        }
                    });
                });
            }
            // 0x9a7a7dA3286c796D33B91ba9dDB9d8082623baaa
        } else {
            this.logout();
        }
    }

    getEmailCode(email: string) {
        return new Promise((resolve, reject) => {
            const webAuth = this.getWebAuth('email');
            webAuth.passwordlessStart(
                {
                    connection: 'email',
                    send: 'code',
                    email
                },
                (err, res) => {
                    // handle errors or continue
                    if (err) reject(err);
                    resolve(res);
                }
            );
        });
    }

    public getWebAuth(type: AuthProvider) {
        const config = type === 'email' ? environment.emailConfig : environment.phoneConfig;
        const webAuth = new WebAuth({
            redirectUri: location.origin,
            domain: environment.auth.customDomain,
            clientID: config.id,
            responseType: 'token id_token'
        });
        this.webAuth = webAuth;
        return webAuth;
    }

    authenticateEmailCode(email: string, verificationCode: string) {
        const webAuth = this.getWebAuth('email');
        return new Promise((resolve, reject) => {
            webAuth.passwordlessLogin(
                {
                    connection: 'email',
                    email,
                    verificationCode
                },
                (err, res) => {
                    if (err) reject(err);
                    resolve(res);
                }
            );
        });
    }

    public parseHash(provider: AuthProvider): Promise<Auth0DecodedHash> {
        const email = this.getWebAuth(provider);
        return new Promise((resolve, reject) => {
            email.parseHash((err, res) => {
                if (err) reject(err);
                resolve(res || null);
            });
        });
    }

    public async emailLogin(hash?, queryParameters?) {
        try {
            if (!this.torusInitialized) await this.initTorus();
            return this.torus.triggerLogin({
                typeOfLogin: 'jwt',
                clientId: environment.emailConfig.id,
                verifier: environment.emailConfig.verifier,
                hash,
                queryParameters,
                jwtParams: {
                    verifierIdField: 'name',
                    connection: '',
                    isVerifierIdCaseSensitive: false,
                    client_id: environment.emailConfig.id,
                    domain: environment.auth.domain
                }
            });
        } catch (e) {}
    }

    public async phoneLogin(hash?, queryParameters?) {
        try {
            if (!this.torusInitialized) await this.initTorus();
            return this.torus.triggerLogin({
                typeOfLogin: 'jwt',
                clientId: environment.phoneConfig.id,
                verifier: environment.phoneConfig.verifier,
                hash,
                queryParameters,
                jwtParams: {
                    verifierIdField: 'name',
                    connection: '',
                    isVerifierIdCaseSensitive: false,
                    client_id: environment.phoneConfig.id,
                    domain: environment.auth.domain
                }
            });
        } catch (e) {}
    }

    async getSMSCode(phoneNumber: string) {
        const webAuth = await this.getWebAuth('sms');
        return new Promise((resolve, reject) => {
            webAuth.passwordlessStart(
                {
                    connection: 'sms',
                    send: 'code',
                    phoneNumber
                },
                (err, res) => {
                    // handle errors or continue
                    if (err) reject(err);
                    resolve(res);
                }
            );
        });
    }

    async authenticatePhoneCode(phoneNumber: string, verificationCode: string) {
        const webAuth = await this.getWebAuth('sms');
        return new Promise((resolve, reject) => {
            webAuth.passwordlessLogin(
                {
                    connection: 'sms',
                    phoneNumber,
                    verificationCode
                },
                (err, res) => {
                    if (err) reject(err);
                    resolve(res);
                }
            );
        });
    }
}

export enum UserRoleEnum {
    seller = 'seller',
    buyer = 'buyer'
}
