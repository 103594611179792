<section class="main__section">
    <div class="columns">
        <div class="community-list__holder">
            <div class="search-row">
                <input type="text" name="search" id="search" class="search" (keyup)="onQueryChange()"
                        [placeholder]="isMerchant? 'Search Merchant' : 'Search User'"
                        [(ngModel)]="query">
            </div>
            <div class="channel__select-holder">
                <ng-select
                    [items]="interests$ | async"
                    placeholder="Interests"
                    (change)="interestsChanged($event)"
                    [multiple]="true">
                </ng-select>
            </div>
            <div class="checkbox__holder">
                <input type="checkbox" id="non-profit" class="checkbox" [(ngModel)]="charity" (change)="filterPosts()">
                <label for="non-profit" class="checkbox__label">
                    <svg class="svg-icon icon-check">
                        <use xlink:href="#check"></use>
                    </svg>
                    <span class="checkbox__text">non profit organizations</span>
                </label>
            </div>
            <div class="tabs" [class.empty-social]="community.followers.length === 0 && community.following.length === 0">
                <a class="tab__item"
                    [class.link-active]="activeCommunityTab == 'following'"
                    [class.empty-social]="community.following.length === 0"
                    (click)="activeCommunityTab = 'following'">
                    Following</a>
                <a class="tab__item"
                    [class.link-active]="activeCommunityTab == 'followers'"
                    [class.empty-social]="community.followers.length === 0"
                    (click)="activeCommunityTab = 'followers'">
                    Followers</a>
            </div>
            <xion-community-list [name]="'followers'"
                                 [users]="community.followers"
                                 [query]="query"
                                 [class.empty-social]="community.followers.length === 0"
                                 *ngIf="activeCommunityTab == 'followers'">
            </xion-community-list>
            <xion-community-list [name]="'following'"
                                 [users]="community.following"
                                 [query]="query"
                                 [class.empty-social]="community.following.length === 0"
                                 *ngIf="activeCommunityTab == 'following'">
            </xion-community-list>
        </div>

        <div class="column">
            <div class="retiler-post__holder">

                <div class="tabs" [class.disable]="isEditingPost | async">
                    <a class="tab__item"
                        [class.link-active]="activeTab == 'feed'"
                        (click)="switchTab('feed')"
                    >Feed</a>
                    <a class="tab__item"
                        [class.link-active]="activeTab == 'posts'"
                        (click)="switchTab('posts')"
                    >My Posts</a>
                </div>

                <div class="add-post__row" *ngIf="activeTab == 'posts'&& !(isEditingPost | async)">
                    <xion-create-channel-post
                        [user]="currentUser$ | async"
                        (post)="postCreated($event)">
                    </xion-create-channel-post>
                </div>

                <xion-channel-posts-list
                    *ngIf="activeTab == 'feed'"
                    class="channels"
                    [user]="currentUser$ | async"
                    (followUser)="followUser($event)"
                    (likePost)="likePost($event)"
                    [posts]="filteredPosts"
                    (userScrolled)="uploadPosts($event)"
                ></xion-channel-posts-list>
                <xion-channel-posts-list
                    *ngIf="activeTab == 'posts'"
                    class="channels channels_my"
                    [user]="currentUser$ | async"
                    [tab]="activeTab"
                    (followUser)="followUser($event)"
                    (likePost)="likePost($event)"
                    [posts]="myPosts"
                    (userScrolled)="uploadPosts($event)"
                ></xion-channel-posts-list>
            </div>
        </div>
    </div>
</section>
