<div class="custom-popup">
    <div class="custom-popup--header">
        <p>Upload your company logo that will be used in your Xion hosted checkout</p>
    </div>

    <div class="custom-popup--row">
        <div class="custom-popup--row-text">
            <span>Logo file must be:</span>
            <i>JPG or PNG format   •   Max file size of 2MB</i>
            <p>PNG files with transparent backgrounds look best <br>Recommended logo size of 390x110</p>
        </div>

            <label for="logoFile" class="custom-popup--label">
                <svg class="svg-icon icon-logo">
                    <use xlink:href="#uploadPopup"></use>
                </svg>
                UPLOAD YOUR LOGO
            </label>

            <input id="logoFile" type="file" 
                   accept="image/*" #logo
                   (change)="fileUploadLogo(logo.files)">

            <div class="custom-popup--info">
                <div *ngIf="fileName">
                    <span class="custom-popup--name">{{fileName}}</span>
                </div>

                <div *ngIf="done">
                    <span class="custom-popup_span" x>
                        <svg class="svg-icon icon-logo">
                            <use xlink:href="#donePopup"></use>
                        </svg>
                        File has been uploaded
                    </span>
                </div>

                <div *ngIf="failed" class="custom-popup_error">
                     <span class="custom-popup_span" x>
                        <svg class="svg-icon icon-logo">
                            <use xlink:href="#errorPopupImage"></use>
                        </svg>
                        File upload has failed
                    </span>
                </div>
                
            </div>
            <p class="text-bottom">Check it out by testing your Xion checkout buttons on your website or app</p>
    </div>

    

</div>
