<div class="popup-blue__content">
        <div class="popup-blue">
            <h3 class="popup-blue__title">
                Restore password
            </h3>
            <form [formGroup]="restorePassword">
                <div class="form__row" [ngClass]="submitted && form.email.errors ? 'error__field' : ''">
                    <div class="form-label__holder">
                        <label class="form-label" for="login-email">Enter your email address. We’ll email instructions on how to reset your password</label>
                        <input class="textfield textfield__white-border" type="email" formControlName="email" id="login-email" placeholder="EMAIL">
                        <span class="error__text" *ngIf="submitted && form.email.errors">
                            <p *ngIf="form.email.errors.required">email is required</p>
                            <p *ngIf="form.email.errors.email && !form.email.errors.required">please enter a valid email</p>
                        </span>
                    </div>
                </div>
            </form>
            <div class="form__row">
                <div class="row__buttons">
                    <button class="button button_small button_white-border" (click)="sendRestore()">Send</button>
                </div>
            </div>
        </div>
        <button (click)="closeDialog()" class="close">
            <svg class="svg-icon">
                <use xlink:href="#close-simple"></use>
            </svg>
        </button>
    </div>
