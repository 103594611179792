<div class="fit-container">
  <ng-container>
    <ngx-charts-bar-vertical
    class="chart-container"
    [scheme]="scheme"
    [results]="subscriptions"
    [xAxis]="xAxis"
    [xAxis]="xAxis"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [showGridLines]="true"
    [roundEdges]="roundEdges"
    [showDataLabel]="showDataLabel"
    [roundDomains]="true"
    [tooltipDisabled]="tooltipDisabled"
    >
  </ngx-charts-bar-vertical>

  </ng-container>
</div>
