import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[xionNumbersOnly]'
})
export class NumbersOnlyDirective {

    private el: NgControl;

    constructor(private ngControl: NgControl) {
        this.el = ngControl;
    }

    // Listen for the input event to also handle copy and paste.
    @HostListener('input', ['$event.target.value'])
    onInput(value: string) {
        // Use NgControl patchValue to prevent the issue on validation
        // const v = Number(value.replace(/[^0-9]/g, ''));
        const inputValue = Math.round(Number(value));
        this.el.control.patchValue(inputValue);
    }

}
