import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ICustomer } from '../../../core/services/customers.service';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationsService } from '../../../core/services/notifications.service';

@Component({
    selector: 'xion-subscribers-table',
    templateUrl: './subscribers-table.component.html',
    styleUrls: ['./subscribers-table.component.scss']
})
export class SubscribersTableComponent implements OnInit {

    @Input() customers: ICustomer[];
    displayedColumns: string[] = ['id', 'fullname', 'email', 'created', 'status'];
    dataSource = new MatTableDataSource([]);
    @ViewChild(MatSort) sort: MatSort;

    constructor(private clipboard: ClipboardService, private notifications: NotificationsService) {
    }


    ngOnInit() {
        this.dataSource.data = this.customers;
        this.dataSource.sort = this.sort;
    }

    copyAddress(customer: ICustomer) {
        this.notifications.showMessage('User ID copied to your clipboard');
        this.clipboard.copyFromContent(customer.idxion);
    }

    copyEmail(customer: ICustomer) {
        this.notifications.showMessage('User Email copied to your clipboard');
        this.clipboard.copyFromContent(customer.email);
    }
}
