<div class="chart__holder fit-container">
  <ng-container *ngIf="disputes">
    <ngx-charts-line-chart
        style="position: static; display: inline-block; transform: none;"
        class="chart-container"
        [scheme]="scheme"
        [results]="disputes"
        [legend]="false"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
        [showGridLines]="true"
        [roundDomains]="true"
        [trimXAxisTicks]="false"
        [trimYAxisTicks]="false"
        [tooltipDisabled]="tooltipDisabled"
    >
  </ngx-charts-line-chart>
  </ng-container>
</div>
