<div class="custom-popup">
    <!-- <h3 class="custom-popup-title">Product URL</h3> -->
    <h3 class="custom-popup-title">Payment link</h3>

    <!-- <p>
        Copy and Paste product URL to navigate checkout page:
    </p> -->
    <p>
        No code, just share a payment link and pay
    </p>
    <div class="code-box-copy">
        <button class="code-box-copy__btn" (click)="copyScriptCode(srcScriptCode)" title="Copy"></button>
        <pre style="text-align: center"><code class="language-html" style="word-break: break-word;" #srcScriptCode>{{data}}</code></pre>
        <div *ngIf="showCopyResultScript" [@appearAnimation]>
            <span>Copied</span>
        </div>
    </div>
    <!-- <p style="text-align: center;">
        <img id='share' src="assets/images/share_icon1.svg" alt="Done" class="active" width="20"> Share your payment link
    </p> -->
    <div *ngIf="isMobile" style="display:flex;justify-content: center">
        <img id='share' src="assets/images/share_icon1.svg" alt="Done" class="active" width="20" (click)="shareScriptCode(srcScriptCode)">
        <p style="margin-left: 3px;">
            Share your payment link
        </p> 
    </div>
    <div *ngIf="!isMobile">
        <a style="display:flex;justify-content: center" [href]="'mailto:' +'?subject=You can pay here&body=Payment link: ' + srcScriptCode.innerText">
            <img id='share' src="assets/images/share_icon1.svg" alt="Done" class="active" width="20">
            <p style="margin-left: 3px;">
                Share your payment link
            </p> 
        </a>
    </div>
            
       
</div>
