import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { getColorScheme } from 'src/app/shared/constants/helpers';
import { INewUsers } from '../../../../retailers/services/analytics.service';
import { ISeriesDetails } from '../../../models/models';

@Component({
    selector: 'xion-subscriptions-chart',
    templateUrl: './subscriptions-chart.component.html',
    styleUrls: ['./subscriptions-chart.component.scss']
})
export class SubscriptionsChartComponent implements OnChanges {

    @Input() subscriptions: ISeriesDetails[];

    @Input() total: number;
    @Input() colorScheme: string = 'default';
    @Input() styles: string = '';
    @Input() xAxis: boolean;
    @Input() yAxis: boolean;
    @Input() showXAxisLabel;
    @Input() showYAxisLabel;
    @Input() showGridLines;
    @Input() roundEdges;
    @Input() showDataLabel;
    @Input() tooltipDisabled: boolean;
    @Output() selected = new EventEmitter();

    scheme = null;

    ngOnChanges() {
        this.scheme = getColorScheme(this.colorScheme);
    }

    select($event) {
        this.selected.emit($event);
    }
}
