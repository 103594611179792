import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, pluck, switchMap, take, tap } from 'rxjs/operators';
import { mapResponseToData } from 'src/app/shared/constants/helpers';
import { environment } from 'src/environments/environment';

export enum Tokens {
    ISX = 'ISX',
    XSC = 'XSC'
}

export enum Discounts {
    FREE = 0.005,
    LEVEL1 = 0.02,
    LEVEL2 = 0.03,
    LEVEL3 = 0.04,
    LEVEL4 = 0.05,
    LEVEL5 = 0.06
}

export enum DiscountRange {
    FREE   = 500,
    LEVEL1 = 1000,
    LEVEL2 = 2000,
    LEVEL3 = 4000,
    LEVEL4 = 8000,
    LEVEL5 = 16000
}

export interface IWallets {
    XSC: IWallet;
    ISX: IWallet;
}

export interface IWallet {
    name?: string;
    address: string;
    balance: number;
}

export interface ITokensParams {
    address: string;
    amount: number;
    currency: Tokens;
    salt: string;
}

export enum Rates {
    ISX = 2,
    XSC = 0.5
}

const cacheBuster$ = new Subject<void>();
const MAX_CACHE_TIME = environment.MAX_CACHE_TIME;

@Injectable({
    providedIn: 'root'
})
export class WalletService {
    public wallet$: Observable<IWallets>;
    public discountLevel: Observable<string>;

    private discountSubject = new BehaviorSubject<string>(null);

    private WALLET = `${environment.api_url}/users/getbalance`;
    private SEND_TOKENS = `${environment.api_url}/users/sendtokens`;
    private SWAP_TOKENS = `${environment.api_url}/users/swaptokens`;

    private walletSubject: BehaviorSubject<IWallets>;
    private walletUpdateSubject = new BehaviorSubject(true);
    private walletUpdate = this.walletUpdateSubject.asObservable();
    private defaultWallet = {
        XSC: {
            name: 'XSC',
            address: null,
            balance: null
        },
        ISX: {
            balance: null,
            name: 'ISX',
            address: null
        }
    };

    constructor(private http: HttpClient) {
        this.walletSubject = new BehaviorSubject(this.defaultWallet);
        this.wallet$ = this.walletSubject.asObservable();
        // this.wallet$ = this.walletUpdate.pipe(switchMap(this.getBalance.bind(this)));
        this.discountLevel = this.discountSubject.asObservable();
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public sendTokens(params: ITokensParams) {
        return this.http.post(this.SEND_TOKENS, params).pipe(pluck('data'));
        // return this.http.post(this.SEND_TOKENS, params).pipe(map(mapResponseToData));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public swapTokens(amount: number, token: Tokens, key: string) {
        const rate = token === Tokens.ISX ? Rates.ISX : Rates.XSC;
        const data = { rate, amount, currency: token, salt: key };
        return this.http.post(this.SWAP_TOKENS, data).pipe(pluck('data'));
        // return this.http.post(this.SWAP_TOKENS, data).pipe(map(mapResponseToData));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    public getWallets(): Observable<IWallet[]> {
        return this.wallet$.pipe(
            take(1),
            map((wallets) => [{ ...wallets.XSC }, { ...wallets.ISX }])
        );
    }

    public getTokens(): string[] {
        return [Tokens.ISX, Tokens.XSC];
    }

    public refreshBalance() {
        this.walletUpdateSubject.next(true);
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    private getBalance(): Observable<IWallets> {
        return this.http.get(this.WALLET).pipe(
            map(mapResponseToData),
            tap(this.setDiscountLevel.bind(this))
        );
    }

    private setDiscountLevel(wallets: IWallets) {
        const balance = wallets.XSC.balance;
        let level = null;
        if (balance <= DiscountRange.FREE) level = 'Free';
        if (balance <= DiscountRange.LEVEL1) level = 'Level 1';
        if (balance >= DiscountRange.LEVEL2) level = 'Level 2';
        if (balance >= DiscountRange.LEVEL3) level = 'Level 3';
        if (balance >= DiscountRange.LEVEL4) level = 'Level 4';
        if (balance >= DiscountRange.LEVEL5) level = 'Level 5';
        this.discountSubject.next(level);
    }
}
