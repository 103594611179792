<div class="popup__content">
    <div class="popup">
        <div class="popup__charts">
            <div id="geocharts" class="geochart "></div>
        </div>
      <span _ngcontent-c29="" class="popup__title">CUSTOMERS BY COUNTRY</span>
    </div>
    <button (click)="closeDialog()" class="popup__close">
      <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
    </button>
  </div>

