import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'xion-add-wallet-modal',
  templateUrl: './add-wallet-modal.component.html',
  styleUrls: ['./add-wallet-modal.component.scss']
})
export class AddWalletModalComponent implements OnInit {
    addWalletForm: FormGroup;


    constructor(
        private builder: FormBuilder,
        private dialogRef: MatDialogRef<AddWalletModalComponent>
        ) { }

    ngOnInit() {
        this.addWalletForm = this.builder.group({
            xsc: ['']
        });
    }
    closeDialog() { this.dialogRef.close(); }

}
