import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable } from 'rxjs';
import { IProvider } from '../../../core/services/provider.service';
import { ProviderDetailsModalComponent } from '../../modals/provider-details-modal/provider-details-modal.component';
import { Provider } from '../../models/models';

@Component({
    selector: 'xion-providers-list',
    templateUrl: './providers-list.component.html',
    styleUrls: ['./providers-list.component.scss']
})
export class ProvidersListComponent implements OnInit {

    @Input() providers: IProvider[];
    @Input() providerType: string;
    configSub: SwiperConfigInterface = {
        slidesPerView: 'auto',
        keyboard: true,
        freeMode: true,
        mousewheel: true,
        observer: true,
        scrollbar: false,
        pagination: false,
        direction: 'horizontal',
        navigation: {
            nextEl: '.next-sub',
            prevEl: '.prev-sub'
        }
    };

    constructor(private dialog: MatDialog) {
    }

    ngOnInit() {
    }

    openProviderDetails(provider : IProvider) {
        this.dialog
            .open(ProviderDetailsModalComponent, {
                panelClass: 'popup-blue__holder',
                data: {
                    providerType: this.providerType,
                    provider
                }
            });
    }
}
