<div class="create-account">
    <p class="create-account_header">Create a connected account</p>
    <div class="create-account-setting">
        <div class="create-account-setting_form">
            <div class="step-1" *ngIf="formStep === 'step_1'">
                <div class="row mb-4">
                    <div class="col-md-12">
                        <p>Select preferred merchant account type</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 d-flex align-items-center">
                        <p>Account type</p>
                    </div>
                    <div class="col-md-9">
                        <ng-select [multiple]='false' class="create-account-dropdown" [(ngModel)]="selectedMerchant">
                            <ng-option *ngFor="let merchant of merchantList" [value]="merchant.value">
                                {{ merchant.text }}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 d-flex align-items-center">
                        <p>Wallet Address</p>
                    </div>
                    <div class="col-md-9">
                        <input type="text" placeholder="Paste merchant wallet address" [(ngModel)]="walletAddress">
                    </div>
                </div>
            </div>
            <div class="step-2" *ngIf="formStep === 'step_2'">
                <p>This link can only be used once. If you’d like to onboard more users, request more links.</p>
            </div>
        </div>
        <div class="create-account-setting_actions text-right">
            <div class="step-1" *ngIf="formStep === 'step_1'">
                <button class="mr-2" mat-dialog-close>CANCEL</button>
                <button class="active" (click)="creatAccount()">CREATE ACCOUNT</button>
            </div>
            <div class="form-step_2" *ngIf="formStep === 'step_2'">
                <p>Send this one-time-use onboarding link to your user. It will expire in seven days, but you can create
                    another one if needed. Once they complete their profle amd KYB, their account will be created and
                    will show up in your accounts list. </p>
                <div class="d-flex w-full align-items-center">
                    <textarea rows="3"></textarea>
                    <img src="assets/images/share-copy.svg" alt="Copy">
                </div>
            </div>
            <div class="step-2" *ngIf="formStep === 'step_2'">
                <button class="active" mat-dialog-close="true">DONE</button>
            </div>
        </div>
    </div>
</div>
