import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import { DummyDataService } from '../../../core/services/dummy.service';
import { IMetadataResponse } from '../../../core/services/links-metadata.service';
import { IUser, UserService } from '../../../core/services/user.service';
import { InterestsService } from '../../../core/shared/interests.service';
import { ICommunityList, PostsService } from '../../../retailers/services/posts.service';
import { IAttachment } from '../../models/models';

export interface IPost {
    id: string;
    text: string;
    metadata: IMetadataResponse;
    attachments: IAttachment[] | number[];
    user: IUser;
    liked: boolean;
    photo?: IAttachment;
    video?: IAttachment;
    document?: IAttachment;
}

@Component({
    selector: 'xion-social',
    templateUrl: './social.component.html',
    styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {
    public retailers$: Observable<Array<{ name: string; logo: string; domain: string }>>;
    public currentUser$: Observable<IUser>;
    public interests$: Observable<string[]>;
    public query: string;
    public activeTab = 'feed';
    public activeCommunityTab = 'following';
    public posts: IPost[] = [];
    public community$: Observable<ICommunityList>;
    public community: ICommunityList = {
        following: [],
        followers: []
    };
    public filteredPosts: IPost[];

    isMerchant: boolean;
    charity: boolean = false;
    isEditingPost: Observable<boolean>;
    myPosts: IPost[];

    private amountOfPosts: number;
    private interests: string[];
    private user: IUser;

    constructor(
        private auth: AuthService,
        private interestsService: InterestsService,
        private dummy: DummyDataService,
        private route: ActivatedRoute,
        private postsService: PostsService,
        private userService: UserService
    ) {}

    public ngOnInit(): void {
        this.route.data.pipe(take(1)).subscribe((data) => {
            this.isMerchant = data.merchant;
        });

        this.filterPosts();

        this.interests$ = this.interestsService.getAreasOfInterest();
        this.retailers$ = this.dummy.getRetailers();
        this.currentUser$ = this.auth.currentUser;
        this.community$ = this.postsService.getCommunityList();
        this.isEditingPost = this.postsService.isEditingPost;

        this.getMyPosts();
        this.getFeed();

        this.community$.subscribe((community) => {
            // @ts-ignore
            this.community = community || [];
            this.setActiveTab();
        });
    }

    public uploadPosts(scrolledTimes: number): void {
        const startIndex: number = this.amountOfPosts - scrolledTimes * 3;
        if (startIndex > 0) {
            this.postsService
                .getPosts(this.amountOfPosts - scrolledTimes * 3 + 1, 3, this.interests, this.query)
                .subscribe((data) => {
                    this.posts = this.posts.concat(data.posts);
                    this.filteredPosts = this.posts;
                });
        }
    }

    interestsChanged(interests: string[]) {
        this.interests = interests;
        this.updatePosts();
    }

    filterPosts(): void {
        this.filteredPosts = !this.charity
            ? this.posts
            : this.posts.filter((post) => !!post.user.charity);
        // if (!this.charity) {
        //     this.filteredPosts = this.posts;
        // } else {
        //     this.filteredPosts = this.posts.filter(post => !!post.user.charity);
        // }
    }

    onQueryChange() {
        // TODO:change query to behavior subject and throttle search requests
        this.updatePosts();
    }

    switchTab(tabItem) {
        this.activeTab = tabItem;
        if (tabItem === 'feed') {
            this.getFeed();
        } else {
            this.getMyPosts();
        }
    }

    private likePost(postId: string) {
        this.postsService.likePost(postId).subscribe((liked) => {
        });
    }

    private followUser(user: IUser) {
        this.userService.followUser(user.idxion).subscribe((followed: IUser) => {
            this.community.following = this.community.following.filter(
                (u) => u.idxion !== user.idxion
            );
            this.posts.forEach((post) => {
                if (post.user.idxion === user.idxion) {
                    post.user = user;
                }
            });
            if (user.followed) this.community.following.push(user);
        });
    }

    private updatePosts() {
        this.postsService.getPosts(null, 5, this.interests, this.query).subscribe((data) => {
            this.posts = data.posts;
            this.filteredPosts = data.posts;
        });
    }

    private getMyPosts() {
        this.currentUser$
            .pipe(
                take(1),
                switchMap((user) => {
                    this.user = user;
                    return this.postsService.getPostsByUsername(user.fullname);
                })
            )
            .subscribe((data) => (this.myPosts = data.posts));
    }

    private getFeed() {
        this.postsService
            .getPosts()
            .pipe(take(1))
            .subscribe((data) => {
                this.posts = data.posts;
                this.filteredPosts = this.posts;
                this.amountOfPosts = data.total;
            });
    }

    private setActiveTab() {
        this.activeCommunityTab =
            this.community.following.length > 0
                ? 'following'
                : this.community.followers.length > 0 && this.community.following.length < 1
                ? 'followers'
                : 'following';
    }

    private postCreated(post: IPost) {
        this.filteredPosts.unshift(post);
        this.myPosts.unshift(post);
    }
}
