import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';

@Component({
  selector: 'xion-customers-signup-chart-modal',
  templateUrl: './customers-signup-chart-modal.component.html',
  styleUrls: ['./customers-signup-chart-modal.component.scss']
})
export class CustomersSignupChartModalComponent implements OnInit {
    // showCHart -> to fix conflict between modal plugin and
    // chart plugin. Issue with width of chart
    showCHart = false;
    users: Observable<any>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CustomersSignupChartModalComponent>) { }

    ngOnInit() {
        this.users = this.data.users;
        setTimeout(() => {
            this.showCHart = true;
        }, 100);
    }
    closeDialog() {
        this.dialogRef.close();
    }
}
