import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { getColorScheme } from 'src/app/shared/constants/helpers';
import { DummyDataService } from '../../../../core/services/dummy.service';
import { ISeries } from '../../../models/models';

@Component({
  selector: "xion-new-users-chart",
  templateUrl: "./new-users-chart.component.html",
  styleUrls: ["./new-users-chart.component.scss"]
})
export class NewUsersChartComponent implements OnChanges , OnInit{

  @Input() users: ISeries[];
  @Input() showXAxisLabel: boolean;
  @Input() showYAxisLabel: boolean;
  @Input() xAxis: boolean;
  @Input() yAxis: boolean;
  @Input() total: number;
  @Input() colorScheme: string;
  @Input() view : number[];
  @Input() tooltipDisabled: boolean;
  @Input() refLines: Array<{ value: number; name: string }> = [
    { value: 30, name: "" },
    { value: 20, name: "" },
    { value: 1, name: "" }
  ];

  scheme = null;

  ngOnChanges() {
    this.scheme = getColorScheme(this.colorScheme || 'default');
  }

    ngOnInit(): void {
    }
}
