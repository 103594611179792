<div class="cashback-popup">
    <div class="cashback-popup-close">
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <div class="cashback-popup-logo">
        <img src="../../../../assets/images/cashback/title.svg">
    </div>
    <div class="cashback-popup-title">
        CASHBACK REWARDS ON PURCHASES
    </div>
    <div class="cashback-popup-card" mat-dialog-content>
        <div class="cashback-popup-card-item">
            <img src="../../../../assets/images/cashback/shop.svg" class="cashback-popup-card-logo" height="100">
            <div class="cashback-popup-card-item-title">SHOP ONLINE</div>
            <div class="cashback-popup-card-item-content">
                Your customers can purchase products with <b>crypto</b>
            </div>
        </div>
        <div class="cashback-popup-card-item">
            <img src="../../../../assets/images/cashback/get.svg" class="cashback-popup-card-logo" height="100">
            <div class="cashback-popup-card-item-title">GET CASHBACK</div>
            <div class="cashback-popup-card-item-content">
                They get up to 100% cashback in <b>XGT rewards</b> from the XGT Chest (Distributed over 12 months)
            </div>
        </div>
        <div class="cashback-popup-card-item">
            <img src="../../../../assets/images/cashback/spend.svg" class="cashback-popup-card-logo" height="100">
            <div class="cashback-popup-card-item-title">SPEND REWARDS</div>
            <div class="cashback-popup-card-item-content">
                Returning\new customers can purchase your products with their <b>XGT rewards</b>
            </div>
        </div>
    </div>
    <div class="cashback-popup-button">
        <button (click)="learnMore()">LEARN MORE</button>
        <button (click)="claimXgtRewards()" class="claim-reward">CLAIM {{claimableAmount}} XGT REWARDS</button>
    </div>
    <div class="cashback-popup-footer">
        Note: XGT CASHBACK REWARDS ARE ONLY SENT FROM THE XGT CHEST WHEN CUSTOMERS MAKE A PURCHASE WITH CRYPTO.
        CUSTOMERS WILL NOT GET XGT CASHBACK REWARDS IF IT’S LOCKED DUE TO THE AMOUNT OF LIQUIDITY IN THE POOLS OR THEY
        PURCHASED THE PRODUCT WITH XGT.
    </div>
</div>
