import { Component, Input, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { IProduct } from '../../../core/services/product.service';
import { IAttachment } from '../../models/models';
import { FileUploadTypes } from '../../../core/services/file-upload.service';
import { IUser } from '../../../core/services/user.service';

@Component({
    selector: 'xion-products-list',
    templateUrl: './products-list.component.html',
    styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {

    @Input() products: IProduct[] | number[];
    @Input() user: IUser | null;
    @Input() productType: string;
    @Input() canBuy: boolean;
    @Input() isSubscriptionOwner: boolean;


    configProd: SwiperConfigInterface = {
        slidesPerView: 'auto',
        keyboard: true,
        freeMode: true,
        mousewheel: true,
        observer: true,
        scrollbar: false,
        pagination: false,
        direction: 'horizontal',
        navigation: {
            nextEl: '.next-sub',
            prevEl: '.prev-sub'
        }
    };

    constructor() {
    }

    ngOnInit() {
    }

    hasAttachment(product: IProduct): IAttachment | null {
        if (!product.attachments) return null;
        // @ts-ignore
        return product.attachments.find((item: IAttachment) => item.filetype === FileUploadTypes.document) || null;
    }
}
