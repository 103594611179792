import { Component, Inject, OnInit, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef,  } from '@angular/material/dialog';

@Component({
  selector: 'xion-preview-media',
  templateUrl: './preview-media.component.html',
  styleUrls: ['./preview-media.component.scss']
})
export class PreviewMediaComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private builder: FormBuilder,
  private dialogRef: MatDialogRef<PreviewMediaComponent>) { }

// tslint:disable-next-line: no-empty
    ngOnInit() {
    }
    closeDialog() {
        this.dialogRef.close()
    }
}
