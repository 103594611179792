<div class="popup__content">
    <div class="popup">
        <div class="popup__body">
            <div class="card__item" *ngIf="data.previewType === 'image'">
                <div class="card__picture-holder">
                    <img class="absolute-img" [src]="data.image" [alt]="data.previewType">
                </div>
            </div>
            <xion-video-player
                *ngIf="data.previewType === 'video'"
                class="player-component"
                [video]="data.video"></xion-video-player>
        </div>
        <h3 class="popup__title">{{data.previewType}} Preview</h3>
    </div>
    <button (click)="closeDialog()" class="popup__close">
        <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
    </button>
</div>
