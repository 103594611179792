import {
    AfterViewInit,
    Component,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

declare var google: any;

@Component({
    selector: 'xion-users-map',
    templateUrl: './users-map.component.html',
    styleUrls: ['./users-map.component.scss']
})
export class UsersMapChartComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {
    @Input() users: any[];

    windowSize = new BehaviorSubject(null);
    windowSizeSub: Subscription;

    // tslint:disable-next-line: no-empty
    constructor() {}

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        const size = event.target.innerWidth;
        this.windowSize.next(size);
    }

    ngAfterViewInit() {
        this.drawChartMap(this.getUsers());
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.drawChartMap(this.getUsers());
    }

    ngOnInit() {
        this.windowSizeSub = this.windowSize
            .asObservable()
            .pipe(throttleTime(500))
            .subscribe((i) => {
                this.drawChartMap(this.getUsers());
            });
    }

    ngOnDestroy() {
        this.windowSizeSub.unsubscribe();
    }

    private drawChartMap(users) {
        google.charts.load('current', {
            packages: ['geochart'],
            // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
            mapsApiKey: 'AIzaSyC4C3lx9qF2ktxipapvFukqvKcc59TlDgg'
            // mapsApiKey: 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY'
        });

        google.charts.setOnLoadCallback(drawRegionsMap.bind(this));

        function drawRegionsMap() {
            const data = google.visualization.arrayToDataTable(users);
            const options = {
                // region: 'US',
                // displayMode: 'regions',
                // resolution: 'provinces',
                colorAxis: { colors: ['#f7fdfd', '#86e5e7', '#94e8ea', '#31d3d6'] },
                backgroundColor: '#f7f7f7',
                datalessRegionColor: '#e6e6e6'
            };
            const chart = new google.visualization.GeoChart(
                document.getElementById('geochart-colors')
            );
            chart.draw(data, options);
        }
    }

    private getUsers() {
        return [
            ['Country', 'Subscriptions'],
            ...this.users.map((item) => [item.country, item.subscriptions])
        ];
    }
}
