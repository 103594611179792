import * as moment from 'moment';
import { ITransaction, ITransactionInfo, ITransactionUserInfo } from '../interfaces/transaction.interface';

export class TransactionModel {
    transaction_info: ITransactionInfo | any = {};
    user_info?: ITransactionUserInfo | any = {};

    constructor(data: ITransaction) {
        if (data) {
            if (data?.transaction_info) {
                this.transaction_info.id = data.transaction_info.id || null;
                this.transaction_info.title = data.transaction_info.title || '';
                this.transaction_info.companyName = data.transaction_info.companyName || '';
                this.transaction_info.date = moment(data.transaction_info.date).format('MM/DD/YYYY') || null;
                this.transaction_info.billingType = data.transaction_info.billingType || null;
                this.transaction_info.price = data.transaction_info.price || null;
                this.transaction_info.cryptoAmount = data.transaction_info.cryptoAmount || null;
                this.transaction_info.status = data.transaction_info.status || '';
                this.transaction_info.invoice = data.transaction_info.invoice || '';
                this.transaction_info.currency = data.transaction_info.currency || '';
                this.transaction_info.orderCode = data.transaction_info.orderCode || '';
                this.transaction_info.txType = data.transaction_info.txType || '';
                this.transaction_info.phoneNumber = data.transaction_info.phoneNumber || '';
                this.transaction_info.goalAddress = this.formatAddress(data.transaction_info.address?.address)  + " "
                   + this.formatAddress(data.transaction_info.address?.country?.name) + ' '
                   + this.formatAddress(data.transaction_info.address?.state)  + ' '
                   + this.formatAddress(data.transaction_info.address?.city) + ' '
                   + this.formatAddress(data.transaction_info.address?.zip);
                this.transaction_info.email = data.transaction_info.email || '';
                this.transaction_info.address = data.transaction_info.address || {};
                this.transaction_info.companyWebsiteURL = data.transaction_info.companyWebsiteURL || '';
            }
            if (data?.user_info) {
                if (data?.user_info?.address) {
                    this.user_info.address = {};
                    this.user_info.address.address = this.formatAddress(data?.user_info?.address?.address) || '';
                    this.user_info.address.city = this.formatAddress(data?.user_info?.address?.city) || '';
                    this.user_info.address.country = this.formatAddress(data?.user_info?.address?.country) || '';
                    this.user_info.address.state = this.formatAddress(data?.user_info?.address?.state) || '';
                    this.user_info.address.zip = this.formatAddress(data?.user_info?.address?.zip) || '';
                }
                this.user_info.phoneNumber = data?.user_info?.phoneNumber || '';
                this.user_info.address = data?.user_info?.address || '';
                this.user_info.email = data?.user_info?.email || '';
                this.user_info.name = data?.user_info?.name || '';
                this.user_info.signUpDate = data?.user_info?.signUpDate || '';
                this.user_info.deliveryAddress = this.formatAddress(this.user_info?.address?.address) + ' '
                + this.formatAddress(this.user_info?.address?.country?.name) + ' '
                + this.formatAddress(this.user_info?.address?.state) + ' '
                + this.formatAddress(this.user_info?.address?.city) + ' '
                + this.formatAddress(this.user_info?.address?.zip);
            }
        }
    }

    formatAddress(address: any = 'address') {
        return address === null ? '' : address
    }
}
