import { Component, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import BigNumber from 'bignumber.js';
import Big from 'big.js';
import Web3 from 'web3';
import { Transaction } from '@ethereumjs/tx';
import Common from '@ethereumjs/common';
import { AbiItem } from 'web3-utils';
import { IMySubscription, SubscriptionRating } from '../../../models/subscription.model';
import {
    BillingStatusModalType,
    BillingTypeEnum,
    IBillingStatusChangeParam,
    IResponseStatus,
    StatusEnum,
    SubscriptionStatus
} from '../../../models/billing-product.model';
import { BillingChangeStatusModalComponent } from '../../../modals/billing-change-status-modal/billing-change-status-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from '../../../../retailers/services/http.service';
import { SubscriptionTransactionsModalComponent } from '../../../modals/subscription-transactions-modal/subscription-transactions-modal.component';
import { Web3Service } from '../../../services/web3.service';
import { ITransaction } from '../../../interfaces/transaction.interface';
import { CommonService } from '../../../services/common.service';
import { Product, PusherService } from '../../../services/pusher.service';
import { SubscriptionService } from '../../../../auth/services/subscription.service';
import { Web3InstanceService } from '../../../../auth/services/web3-instance.service';
import { Web3modalService } from '../../../../auth/services/web3modal.service';
import { ConnectorID } from '../../../constants/config';
import { getTorusPrivateKey, setTxHash } from '../../../../utils';
import { AuthService } from '../../../../auth/services/auth.service';
import { CustomLoadingComponent } from '../../../modals/custom-loading/custom-loading.component';
import { environment } from 'src/environments/environment';
import { tokenABI } from 'src/app/shared/services/token.abi';

@Component({
    selector: 'xion-my-subscription',
    templateUrl: './my-subscription.component.html',
    styleUrls: ['./my-subscription.component.scss']
})
export class MySubscriptionComponent implements OnInit, OnDestroy {
    @Input() subscription: IMySubscription;
    @Output() pendingStatusEvent: EventEmitter<IMySubscription> = new EventEmitter<IMySubscription>();
    BillingStatusModalType = BillingStatusModalType;
    StatusEnum = StatusEnum;
    activeParams: IBillingStatusChangeParam = {
        title: 'activate',
        description: 'Are you ready to start receiving this product/service again? You will be billed on your next due billing date and your discount progress will resume(if applicable).',
        status: this.StatusEnum.active
    };
    pauseParams: IBillingStatusChangeParam = {
        title: 'pause',
        description: 'Are you sure you want to Pause? You will be charged 12.5% of your total subscription price to stay subscribed and to save your discount progress(if applicable).',
        status: this.StatusEnum.pause,
        type: BillingStatusModalType.Pause
    };
    unsubscribeParams: IBillingStatusChangeParam = {
        title: 'unsubscribe',
        description: 'Are you sure you want to unsubscribe? Your discount progress will be reset(if applicable) and you will not be billed again.',
        status: this.StatusEnum.unsubscribe
    };
    subscribeParams: IBillingStatusChangeParam = {
        title: 'subscribe',
        description: 'DO YOU WANT TO SUBSCRIBE TO THIS PRODUCT/SERVICE AGAIN? Make sure you have enough xDAI or XGT to complete this purchase.',
        status: this.StatusEnum.subscribe,
        type: BillingStatusModalType.Subscribe
    };
    ratingArray: number[] = [1, 2, 3, 4, 5];
    public BillingTypeEnum = BillingTypeEnum;
    private subscriptionStatus: SubscriptionStatus;
    private tokenContract: any;

    constructor(private dialog: MatDialog,
                private httpService: HttpService,
                private authService: AuthService,
                private web3Service: Web3Service,
                private commonService: CommonService,
                private pusherService: PusherService,
                private subscriptionService: SubscriptionService,
                private web3InstanceService: Web3InstanceService,
                private zone: NgZone,
                private web3ModalService: Web3modalService,
                ) {
    }

    ngOnInit() {
    }

    onDownloadTransactions(): void {
        this.commonService.onDownloadOrdersTransactions(this.subscription.id)
            .subscribe((res) => {
                this.commonService.downloadTransactionsFile(res, this.subscription.productName);
            });
    }

    openStatusChangeModal(params: IBillingStatusChangeParam): void {
        this.dialog.open(BillingChangeStatusModalComponent, {
            panelClass: 'subscriptions-slider',
            width: '400px',
            data: { ...params, price: Number(this.subscription.productPrice) + Number(this.subscription.shippingPrice) }
        })
            .afterClosed()
            .subscribe(async (res: { currency: string, status: SubscriptionStatus }) => {
                if (res && res.status) {
                    const totalPrice = Number(this.subscription.productPrice) + Number(this.subscription.shippingPrice);
                    this.subscriptionStatus = res.status;
                    switch (res.status) {
                        // Might be different case, but currently will be only one call to change
                        case this.StatusEnum.pause:
                            res.currency === "xdai" ? await this.checkSubscriptionContractForXdai(totalPrice * 12.5 / 100) : await this.payWithRewardsXGT();
                            this.eventHandler(true, res.currency);
                            break;
                        case this.StatusEnum.active:
                            this.eventHandler(true, res.currency);
                            break;
                        case this.StatusEnum.unsubscribe:
                            this.eventHandler(true, res.currency);
                            break;
                        case this.StatusEnum.subscribe:
                            res.currency === "xdai" ? await this.checkSubscriptionContractForXdai(totalPrice): await this.payWithRewardsXGT();
                            this.eventHandler(true, res.currency);
                            break;
                    }
                }
            });

    }

    public openTransactionsModal(subscription: IMySubscription): void {
        if(subscription.billingType === BillingTypeEnum.single) {
            this.httpService.getSingleOrdersTransactions(subscription.productID).subscribe((transactions: ITransaction[]) => {

                this.dialog.open(SubscriptionTransactionsModalComponent, {
                    width: '800px',
                    data: transactions,
                    panelClass: 'wide-dialog'
                })
                    .afterClosed()
                    .subscribe();
            });
        } else if(subscription.billingType === BillingTypeEnum.recurring || subscription.billingType === "unknown") {
            this.httpService.getOrdersTransactions(subscription.id).subscribe((transactions: ITransaction[]) => {

                this.dialog.open(SubscriptionTransactionsModalComponent, {
                    width: '800px',
                    data: transactions,
                    panelClass: 'wide-dialog'
                })
                    .afterClosed()
                    .subscribe();
            });
        }
    }

    public changeRating(subscription: IMySubscription, rating: number): void {
        if (!subscription.rating) {
            this.httpService.changeSubscriptionRating(subscription.id, rating)
                .subscribe((res) => {
                    subscription.rating = rating as SubscriptionRating;
                });
        }
    }

    public imageClick(subscription: IMySubscription): void {
        open(subscription.productUrl, '_blank');
    }

    ngOnDestroy(): void {
        if (this.pusherService?.channel) {
            this.pusherService.channel.unbind('client-subscription-paused', this.eventHandler.bind(this));
            // this.pusherService.channel.unbind_all();
        }
    }

    private checkout(productID: number): Observable<IResponseStatus> {
        return this.httpService.checkoutProduct(productID);
    }

    private async eventHandler(userResponse: boolean | Product, currency = 'xdai') {
        if (userResponse) {
            const buttonStates = await this.httpService.changeSubscriptionStatus(this.subscription.id, this.subscriptionStatus, currency).toPromise();
            this.zone.run(() => {
                let status = this.subscriptionStatus;
                if (this.subscriptionStatus === StatusEnum.subscribe || this.subscriptionStatus === StatusEnum.pause || this.subscriptionStatus === StatusEnum.unsubscribe || this.subscriptionStatus === StatusEnum.active) {
                    status = StatusEnum.pending;
                    this.pendingStatusEvent.emit(this.subscription);
                }
                this.subscription.buttonStates = buttonStates;
                this.subscription.status = status;
            });
        }
    }

    private getFeePercent(price: string): number {
        return new BigNumber(new BigNumber(price).multipliedBy(new BigNumber(this.commonService.pauseFeePercent)))
            .dividedBy(100)
            .plus(this.commonService.pauseFeePlus)
            .precision(4, BigNumber.ROUND_UP)
            .toNumber();
    }

    private async checkSubscriptionContractForXdai(price: number) {
        const dialogRef = this.dialog.open(CustomLoadingComponent, {
            disableClose: true
        });
        await Promise.all([
            this.waitForSeconds(15000),
            new Promise(async (resolve) => {
                await this.web3InstanceService.init();
                const web3Instance = this.web3InstanceService.web3Instance;
                await this.subscriptionService.initSubscriptionContract(web3Instance);
                const balance = await this.subscriptionService.getSubscriptionContractBalance();
                if (balance < price) {
                    if (this.web3ModalService.getConnectorId() === ConnectorID.CustomAuth) {
                        const instance = await web3Instance.eth.accounts.privateKeyToAccount(getTorusPrivateKey());
                        const res = await instance.signTransaction({
                            from: this.authService.loggedInUser.walletAddress,
                            to: environment.subscriptionsContractAddress,
                            value: web3Instance.utils.toWei(String((price - balance).toFixed(18)), 'ether'),
                            gas: 200000
                        });
                        await web3Instance.eth.sendSignedTransaction(res.rawTransaction);
                    } else {
                        await web3Instance.eth.sendTransaction({
                            from: this.authService.loggedInUser.walletAddress,
                            to: environment.subscriptionsContractAddress,
                            value: web3Instance.utils.toWei(String((price - balance).toFixed(18)), 'ether'),
                            gas: 200000
                        });
                    }
                }
                resolve(true);
            })
        ]);
        dialogRef.componentInstance.transactionStatus = 'success';
        dialogRef.close();
    }

    private async payWithRewardsXGT() {
        const dialogRef = this.dialog.open(CustomLoadingComponent, {
            disableClose: true
        });
        await Promise.all([
            this.waitForSeconds(15000),
            new Promise(async (resolve) => {
                await this.web3InstanceService.init();
                const web3Instance = this.web3InstanceService.web3Instance;

                const addressFrom = this.authService.loggedInUser.walletAddress;
                const addressTo = environment.subscriptionsContractAddress;
                const nonce = await web3Instance.eth.getTransactionCount(addressFrom, 'pending');
                const gasPrice = await web3Instance.eth.getGasPrice();
                const privateKey = Buffer.from(getTorusPrivateKey(), 'hex');
                const tokenContract = new web3Instance.eth.Contract(tokenABI, environment.tokenAddress);

                const txData: any = {
                    nonce: web3Instance.utils.toHex(nonce),
                    gasLimit: web3Instance.utils.toHex(500000),
                    gasPrice: web3Instance.utils.toHex(gasPrice), // 10 Gwei
                    from: addressFrom,
                    to: '0x0',
                    value: '0x0'
                };

                let common;
                if (environment.mainChainId === 'sokol') {
                    common = Common.custom({ name: 'Sokol', chainId: 77 });
                } else if (environment.mainChainId === 'xdai') {
                    common = Common.custom({ name: 'xDAI', chainId: 100 });
                } else {
                    common = new Common({ chain: environment.mainChainId });
                }

                const allowance = await tokenContract.methods.allowance(addressFrom, addressTo).call();
                if (Big(allowance).toNumber() == 0) {
                    txData.to = environment.tokenAddress;
                    txData.data = tokenContract.methods.approve(addressTo, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').encodeABI();
                    const tx = Transaction.fromTxData(txData, { common });
                    const signedTx = tx.sign(privateKey);
                    const serializedTx = signedTx.serialize().toString('hex');
                    await web3Instance.eth.sendSignedTransaction('0x' + serializedTx)
                        .on('transactionHash', txHash => {
                            setTxHash(txHash);
                            console.log(txData)
                        })
                        .on('receipt', console.log)
                        .on('error', error => {
                            console.error(error)
                        });
                }
                resolve(true);
            })
        ]);

        dialogRef.componentInstance.transactionStatus = 'success';
        dialogRef.close();
    }

    private waitForSeconds(sec: number): Promise<boolean> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, sec);
        });
    }
}
