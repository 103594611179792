import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'xion-review-subscription-modal',
    templateUrl: './review-subscription-modal.component.html',
    styleUrls: ['./review-subscription-modal.component.scss']
})
export class ReviewSubscriptionModalComponent implements OnInit {
    contactForm: FormGroup;

    constructor(
                @Inject(MAT_DIALOG_DATA) public query: any,
                private dialogRef: MatDialogRef<ReviewSubscriptionModalComponent>,
                private builder: FormBuilder) {
    }


    ngOnInit() {
        this.contactForm = this.builder.group({
            message : [null]
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
