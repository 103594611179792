<div class="popup__content">
    <div class="popup">
        <form [formGroup]="bankForm" class="form">
            <div class="form__row" [ngClass]="submitted && form.name.errors ? 'error__field' : ''">
                <input class="textfield" type="text" name="name" id="name" placeholder="NAME" formControlName="name">
                <span class="error__text" *ngIf="submitted && form.name.errors">
                    <p *ngIf="form.name.errors.required">name is required</p> 
                </span>
            </div>

            <div class="form__row" [ngClass]="submitted && form.recipient_name.errors ? 'error__field' : ''">
                <input class="textfield" type="text" name="recipient_name" id="recipient_name" placeholder="RECIPIENT NAME"
                    formControlName="recipient_name">
                <span class="error__text" *ngIf="submitted && form.recipient_name.errors">
                    <p *ngIf="form.recipient_name.errors.required">recipient name is required</p> 
                </span>
            </div>
            <div class="form__row" [ngClass]="submitted && form.account_number.errors ? 'error__field' : ''">
                <input class="textfield" type="text" name="account_number" id="account_number" placeholder="ACCOUNT NUMBER"
                    formControlName="account_number">
                <span class="error__text" *ngIf="submitted && form.account_number.errors">
                    <p *ngIf="form.account_number.errors.required">account number is required</p> 
                </span>
            </div>
            <div class="form__row" [ngClass]="submitted && form.bank.errors ? 'error__field' : ''">
                <input class="textfield" type="text" name="bank" id="bank" placeholder="BANK" formControlName="bank">
                <span class="error__text" *ngIf="submitted && form.bank.errors">
                    <p *ngIf="form.bank.errors.required">bank is required</p> 
                </span>
            </div>
            
            <div class="form__row" [ngClass]="submitted && form.com_country.errors ? 'error__field' : ''">
                <ng-select
                    [items]="(countries$ | async)?.data"
                    formControlName="country"
                    bindLabel="name"
                    bindValue="code"
                    placeholder="Country"
                >
                </ng-select>
                <span class="error__text" *ngIf="submitted && form.com_country.errors">
                    <p *ngIf="form.com_country.errors.required">country is required</p>
                </span>
            </div>
            
            <div class="form__row">
                <button class="button button_blue button_big" (click)="submitForm()">
                    SEND
                    <span class="button__arrow">
                        <svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-arrow-play"></use></svg>
                    </span>
                </button>
            </div>
        </form>
        <span class="popup__title">
            Bank Details
        </span>
    </div>
    <button (click)="closeDialog()" class="popup__close">
        <svg class="svg-icon icon-close">
        <use xlink:href="#close"></use>
        </svg>
    </button>
</div>
