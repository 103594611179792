<div class="popup-blue__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title">
            Transaction
        </h3>
        <span class="name">
            <ng-container *ngIf="retailer.logo;else noLogoTemplate">
                <img class="svg-icon" [src]="retailer.logo" [alt]="retailer.name">
            </ng-container>
            <ng-template #noLogoTemplate>
<!--                <svg class="svg-icon" *ngIf="retailer.merchant == 1;else userIcon"><use-->
                <!--                    xlink:href="#icon-retailer"></use>-->
                <!--                </svg>-->
                     <svg class="svg-icon"><use xlink:href="#icon-retailer"></use>
                </svg>
            </ng-template>
            {{retailer.name}}
        </span>
        <div class="form__row">
            <div class="textfield__holder">
                <span class="form-label text-overflow">Date / Time</span>
                <div class="textfield textfield__white-border disable">
                    {{transaction.date | date}}
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="textfield__holder">
                <span class="form-label text-overflow">Swap</span>
                <div class="textfield textfield__white-border disable">
                    Swap ISX->XSC
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="textfield__holder">
                <span class="form-label text-overflow">Currency</span>
                <div class="textfield textfield__white-border disable">
                    {{transaction.currency}}
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="textfield__holder">
                <span class="form-label text-overflow">Address</span>
                <div class="textfield textfield__white-border disable">
                    Address
                </div>
            </div>
        </div>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>


<ng-template #userIcon>
    <svg class="svg-icon">
        <use xlink:href="#icon-user"></use>
    </svg>
</ng-template>
