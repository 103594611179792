<div class="cards__holder">
    <div class="swiper-container" [swiper]="configProd">
        <div class="swiper-wrapper">
            <div class="card__item swiper-slide" *ngFor="let product of products">
                <div class="card__picture-holder">
                    <img *ngIf="product.image;else noProductImage" [src]="product.image" [alt]="product.title" class="absolute-img__full-image">
                    <ng-container *ngIf="hasAttachment(product)">
                        <a class="download card__picture_item" [href]="hasAttachment(product).url">
                            <div class="notification-msg__holder">
                                <svg class="svg-icon icon-download">
                                    <use xlink:href="#icon-download"></use>
                                </svg>
                                <div class="notification-msg">Download document</div>
                            </div>
                        </a>
                    </ng-container>
                    <ng-template #noProductImage>
                        <svg class="svg-icon icon_no-image">
                            <use xlink:href="#no-image"></use>
                        </svg>
                    </ng-template>
                    <button class="buy card__picture_item" *ngIf="productType==='oneTime' && canBuy">
                        <div class="notification-msg__holder">
                            <svg class="svg-icon icon-download">
                                <use xlink:href="#icon-bag"></use>
                            </svg>
                            <div class="notification-msg">Buy</div>
                        </div>
                    </button>
                    <h3 class="card__title card__picture_item">{{product.title}}</h3>
                    <div class="card__price card__picture_item" *ngIf="isSubscriptionOwner">{{product.price | currencyConverter | async}}</div>
                </div>
            </div>
        </div>
    </div>
    <button class="next-sub slider-next">
        <svg class="svg-icon slide-arrow_next">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
    <button class="prev-sub slider-prev">
        <svg class="svg-icon slide-arrow">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
</div>




<!-- <div class="cards__holder">
    <div class="card__item">
        <div class="card__picture-holder">
            <img src="../../../../assets/subscription/sub1.jpg" alt="title" class="absolute-img__full-image">
            <button class="download card__picture_item">
                <svg class="svg-icon icon-download">
                    <use xlink:href="#icon-download"></use>
                </svg>
            </button>
            <h3 class="card__title card__picture_item">Product</h3>
            <div class="card__price card__picture_item">$120</div>
        </div>
    </div>
    <div class="card__item">
        <div class="card__picture-holder">
            <img src="../../../../assets/subscription/sub1.jpg" alt="title" class="absolute-img__full-image">
            <button class="download card__picture_item">
                <svg class="svg-icon icon-download">
                    <use xlink:href="#icon-download"></use>
                </svg>
            </button>
            <h3 class="card__title card__picture_item">Product</h3>
            <div class="card__price card__picture_item">$120</div>
        </div>
    </div>
    <div class="card__item">
        <div class="card__picture-holder">
            <img src="../../../../assets/subscription/sub1.jpg" alt="title" class="absolute-img__full-image">
            <button class="download card__picture_item">
                <svg class="svg-icon icon-download">
                    <use xlink:href="#icon-download"></use>
                </svg>
            </button>
            <h3 class="card__title card__picture_item">Product</h3>
            <div class="card__price card__picture_item">$120</div>
        </div>
    </div>
    <div class="card__item">
        <div class="card__picture-holder">
            <img src="../../../../assets/subscription/sub1.jpg" alt="title" class="absolute-img__full-image">
            <button class="download card__picture_item">
                <svg class="svg-icon icon-download">
                    <use xlink:href="#icon-download"></use>
                </svg>
            </button>
            <h3 class="card__title card__picture_item">Product</h3>
            <div class="card__price card__picture_item">$120</div>
        </div>
    </div>
</div> -->
