import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { IMetadataResponse, LinksMetadataService } from '../../../../core/services/links-metadata.service';
import { IUser } from '../../../../core/services/user.service';
import { PostsService } from '../../../../retailers/services/posts.service';
import { containsURLRegex } from '../../../constants';
import { IAttachment } from '../../../models/models';
import { IPost } from '../../../pages/social/social.component';
import { FileUploadService, IUploadResponse } from '../../../../core/services/file-upload.service';

@Component({
    selector: 'xion-edit-channel-post',
    templateUrl: './edit-social-post.component.html',
    styleUrls: ['./edit-social-post.component.scss']
})
export class EditSocialPostComponent implements OnInit, OnChanges {

    @Output() closeEditForm = new EventEmitter();
    @Output() formSaved = new EventEmitter();
    @Input() video: IAttachment;
    @Input() photo: IAttachment;
    @Input() post: IPost;
    @Input() user: IUser;
    @Input() tab: string;

    uploadProgress: number = 0;
    attachments: IAttachment[] = [];
    document: IAttachment;
    uploadPhotoObj = {label: 'Upload Photo', done: false};
    uploadDocumentObj = {label: 'Upload Document (max. size 25mb)', done: false};
    uploadVideoObj = {label: 'Upload Video', done: false};

    constructor(
        private postsService: PostsService,
        private uploadService: FileUploadService,
        private linksMetadataService: LinksMetadataService) {
    }


    ngOnInit() {
        if (this.post.attachments && this.post.attachments.length > 0) {
            this.getAttachments();
            this.updateUploadFields();
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.post.attachments && this.post.attachments.length > 0) {
            this.getAttachments();
        }
    }
    updateUploadFields() {
        if (this.post.photo) {
            this.uploadPhotoObj.label = this.post.photo.filename;
            this.uploadPhotoObj.done = true;
        }
        if(this.post.document) {
            this.uploadDocumentObj.label = this.post.document.filename;
            this.uploadDocumentObj.done = true;
        }
        if(this.post.video) {
            this.uploadVideoObj.label = this.post.video.filename;
            this.uploadVideoObj.done = true;
        }
    }
    savePost() {
        const post = this.post;
        post.attachments = [this.video || this.post.video, this.photo || this.post.photo, this.document || this.post.document].filter(item => !!item).map(item => item.id);

        this.getURLMetadata(post.text)
            .pipe(switchMap( metadata=> {
                post.metadata = metadata;
                return this.postsService.updatePost(this.post.id,post)
            }), take(1)).subscribe(update => {});
        this.closeEditForm.emit(this.post);
    }

    uploadPhoto(photo) {
        this.uploadPhotoObj.done = false;
        this.uploadService.uploadPhoto(photo).subscribe(response => {
            const file = this.handleFileUpload(response);
            if (file) {
                this.uploadPhotoObj.done = true;
                this.uploadPhotoObj.label = file.filename;
                this.photo = file;

            }
        });
    }

    uploadDocument(documents) {
        this.uploadDocumentObj.done = false;
        this.uploadService.uploadDocument(documents).subscribe(response => {
            const file = this.handleFileUpload(response);
            if (file) {
                this.uploadDocumentObj.done = true;
                this.uploadDocumentObj.label = file.filename;
                this.document = file;
            }
        });
    }

    uploadVideo(video) {
        this.uploadVideoObj.done = false;
        this.uploadService.uploadVideo(video).subscribe(response => {
            const file = this.handleFileUpload(response);
            if (file) {
                this.uploadVideoObj.label = file.filename;
                this.uploadVideoObj.done = true;
                this.video = file;
            }
        });
    }


    isUploadInProgress() {
        return this.uploadProgress > 0 && this.uploadProgress < 100;
    }

    removePostImage() {
        this.photo = null;
    }

    private handleFileUpload(response: IUploadResponse) {
        this.uploadProgress = response.progress;
        if (response.status === 'success') {
            this.uploadProgress = 0;
            return response.data[0];
        }
    }


    private getAttachments() {
        // @ts-ignore
        this.post.photo = this.post.attachments.find((item: IAttachment) => item.filetype === 'photo') || null;
        // @ts-ignore
        this.post.video = this.post.attachments.find((item: IAttachment) => item.filetype === 'video') || null;
        // @ts-ignore
        this.post.document = this.post.attachments.find((item: IAttachment) => item.filetype === 'document') || null;
    }


    private getURLMetadata(text: string): Observable<IMetadataResponse> {
        const match = containsURLRegex.exec(text);
        if (match) {
            const link = match[0];
            return this.linksMetadataService.preview(link);
        }
        return of(null);
    }


}
