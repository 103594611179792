<div class="popup-blue__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title">
            REVIEW SUBSCRIPTION
        </h3>
        <div class="form__row">
            <div class="textfield__holder">
                <span class="form-label text-overflow"> NAME</span>
                <div class="textfield textfield__white-border disable">{{query.user$.name}}</div>
            </div>
        </div>
        <div class="form__row">
            <div class="textfield__holder">
                <span class="form-label text-overflow">Wallet ID</span>
                <div class="textfield textfield__white-border disable">
                    {{query.user$.walletId}}
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="textfield__holder">
                <span class="form-label text-overflow">Problem</span>
                <div class="textfield textfield__white-border disable">
                    <span *ngIf="query.problem.message">{{query.problem.message}}</span>
                    <span *ngIf="!query.problem.message">{{query.problem.issue}}</span>
                </div>
            </div>
        </div>
        
        <div class="form__row">
            <span class="form-label text-overflow">Creation date</span>
            <div class="textfield textfield__white-border disable">
               {{query.date | date }}
            </div>
        </div>
        <div class="form__row">
            <div class="textfield__holder">
                <span class="form-label text-overflow">Price</span>
                <div class="textfield textfield__white-border disable" >
                    {{query.subscription.price| currencyConverter |async}}
                </div>
            </div>
        </div>
        <div class="form__row">
        <form [formGroup]="contactForm">
            <div class="form__row">
                <div class="textfield__holder">
                    <span class="form-label text-overflow">Message customer</span>
                    <textarea name="" class="textfield textfield__white-border" id="" cols="30" rows="5" placeholder="Message" formControlName="message"></textarea>
                </div>
            </div>
        </form>
        </div>

        <!--<div class="row__form_file">-->
            <!--<label class="textfield file__label file__label_multiple" for="file">-->
                <!--<span class="file__title file__title_multiple">-->
                    <!--UPLOAD File-->
                <!--</span>-->
                <!--<input class="file" type="file" id="file">-->
            <!--</label>-->
        <!--</div>-->
        <div class="row__buttons">
            <button class="button button_small button_white-border" (click)="closeDialog()" >Send</button>
            <button class="button button_small button_white-border" (click)="closeDialog()" >Refund</button>
            <button class="button button_small button_white-border" (click)="closeDialog()" >Resolved</button>
        </div>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div> 
