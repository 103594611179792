<h3 class="popup-blue__title">View Subscription</h3>
    <div class="form__row">
        <div class="textfield__holder">
            <label class="form-label text-overflow" for="title">Title</label>
            <input class="textfield textfield__white-border disable" disabled type="text" name="title" id="title" [(ngModel)]="subscription.name">
        </div>
    </div>
    <div class="form__row form__row_details">
        <div class="textfield__holder">
            <label class="form-label text-overflow" for="plan">Plan</label>
            <input type="text" name="type" id="type" class="textfield textfield__white-border disable"
                    [(ngModel)]="subscription.type" disabled>
        </div>
        <div class="textfield__holder">
            <label class="form-label text-overflow" for="price">Price</label>
            <input type="text" name="price" id="price" class="textfield textfield__white-border disable"
                [(ngModel)]="subscription.price" disabled>
        </div>
        <div class="textfield__holder">
            <label class="form-label text-overflow" for="duration">Duration</label>
            <input type="text" name="duration" id="duration" class="textfield textfield__white-border disable "
                [(ngModel)]="subscription.duration" disabled>
        </div>
    </div>
    <div class="form__row form__row_details">
        <div class="textfield__holder">
            <span class="form-label text-overflow">Type</span>
            <div class="textfield textfield__white-border disable">
                <span class="link-active selection-link">Single</span><span
                class="separation-text">/</span>
                <span class="selection-link" >Multiple</span><span class="separation-text">/</span>
                <span class="selection-link" >Digital product type</span>
            </div>
        </div>
    </div>
    <div class="form__row form__row_details select-white__border popup-row">
        <label class="form-label text-overflow" for="supplier">Supplier</label>
        <ng-select
            class="disable"
            [ngModel]="['Supplier1', 'Supplier2', 'Supplier3', 'Supplier4', 'Supplier5']"
            [searchable]="false"
            [clearable]="false"
            [multiple]="true"
            placeholder="Supplier">
        </ng-select>
    </div>
    <div class="form__row form__row_details select-white__border popup-row">
        <label class="form-label text-overflow" for="distributor">Distributor</label>
        <ng-select
            class="disable"
            [searchable]="false"
            [clearable]="false"
            [multiple]="true"
            [ngModel]="['Distributor1', 'Distributor2', 'Distributor3', 'Distributor4', 'Distributor5']"
            placeholder="Distributor">
        </ng-select>
    </div>
    <div class="form__row form__row_details popup-row">
        <div class="textfield__holder">
            <label class="form-label text-overflow" for="sub-creation-date">Sub creation date</label>
            <mat-form-field class="select-white__border disable">
                <input matInput disabled [matDatepicker]="picker" placeholder="Sub creation date"
                    [(ngModel)]="subscription.date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <!-- <div class="textfield__holder">
            <label class="form-label text-overflow" for="discount">Discount Allocated % XSC</label>
            <input type="text" name="discount" id="discount" class="textfield textfield__white-border disable"
               placeholder="Discount Allocated % XSC" [(ngModel)]="subscription.discount" disabled>
        </div> -->
    </div>
    <div class="form__row form__row_details">
        <div class="textfield__holder">
            <label class="form-label text-overflow" for="active-customers">Active Customers</label>
            <input type="text" name="active-customers" id="active-customers"
                class="textfield textfield__white-border disable" value="Active Customers 200"
                [(ngModel)]="subscription.activeCustomers" disabled>
        </div>
        <div class="textfield__holder">
            <label class="form-label text-overflow" for="active-customers">Canceled Customers</label>
            <input type="text" name="canceled-customers" id="canceled-customers"
                class="textfield textfield__white-border disable" placeholder="Canceled Customers 20"
                [(ngModel)]="subscription.canceledCustomers" disabled>
        </div>
    </div>

    <div class="form__row form__row_details">
        <div class="textfield__holder">
            <label class="form-label text-overflow" for="mrr">MRR</label>
            <input type="text" name="mrr" id="mrr" class="textfield textfield__white-border disable" [(ngModel)]="subscription.mrr" disabled>
        </div>
        <div class="textfield__holder">
            <label class="form-label text-overflow" for="net-revenue">Net Revenue</label>
            <input type="text" name="net-revenue" id="net-revenue" class="textfield textfield__white-border disable"
                placeholder="Net Revenue" [(ngModel)]="subscription.netRevenue" disabled>
        </div>
    </div>
