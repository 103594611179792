import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import { ChatService } from '../../../core/services/chat.service';
import { DummyDataService } from '../../../core/services/dummy.service';
import { IUser } from '../../../core/services/user.service';
import { Message } from '../../models/models';
import { ApplozicService } from '../../services/applozic.service';

@Component({
    selector: 'xion-messenger',
    templateUrl: './messenger.component.html',
    styleUrls: ['./messenger.component.scss']
})
export class MessengerComponent implements OnInit {
    constructor(
        private messengerService: ApplozicService) {
    }

    ngOnInit() {
        this.messengerService.init();
    }
}
