import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IProvider } from '../../../core/services/provider.service';

@Component({
  selector: 'xion-provider-details-modal',
  templateUrl: './provider-details-modal.component.html',
  styleUrls: ['./provider-details-modal.component.scss']
})
export class ProviderDetailsModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data : {provider : IProvider, providerType : string},private dialogRef: MatDialogRef<ProviderDetailsModalComponent>) { }

  ngOnInit() {}
  closeDialog() {
    this.dialogRef.close();
  }
}
