import { Component } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import initHelpHero from 'helphero';
import { AuthService } from './auth/services/auth.service';

@Component({
    selector: 'xion-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(private ccService: NgcCookieConsentService, private auth: AuthService) {
        const hlp = initHelpHero('TLf5sfEQIA');

        this.auth.currentUser.subscribe((user) => {
            if (user) {
                hlp.identify(user.walletAddress);
            }
        });
    }
}
