<div class="chart__holder fit-container">
    <ng-container *ngIf="users">
    <ngx-charts-bar-vertical
        style="position: static; display: inline-block; transform: none;"
        class="chart-container"
        [scheme]="scheme"
        [results]="users"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
        [showGridLines]="true"
        [roundDomains]="true"
        [barPadding]="15"
        [roundEdges]="false"
        [tooltipDisabled]="tooltipDisabled">
    </ngx-charts-bar-vertical>
    </ng-container>
</div>
