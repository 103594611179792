import { CountryISO } from 'ngx-intl-tel-input';

export const TORUS_PRIVATE_KEY = 'torus_private_key';
export const TORUS_ADDRESS = 'torus_address';
export const XION_TXHASH = 'xion_txhash';
export const TICKBOXFORGPDR: string = 'xion_tickbox_for_GPDR'
export const allowCountries = [
        CountryISO.Albania,
        CountryISO.Algeria,
        CountryISO.AmericanSamoa,
        CountryISO.Andorra,
        CountryISO.Angola,
        CountryISO.Anguilla,
        CountryISO.AntiguaAndBarbuda,
        CountryISO.Argentina,
        CountryISO.Armenia,
        CountryISO.Aruba,
        CountryISO.Australia,
        CountryISO.Austria,
        CountryISO.Azerbaijan,
        CountryISO.Bahamas,
        CountryISO.Bahrain,
        CountryISO.Bangladesh,
        CountryISO.Barbados,
        CountryISO.Belarus,
        CountryISO.Belgium,
        CountryISO.Belize,
        CountryISO.Benin,
        CountryISO.Bermuda,
        CountryISO.Bhutan,
        CountryISO.Bolivia,
        CountryISO.BosniaAndHerzegovina,
        CountryISO.Botswana,
        CountryISO.Brazil,
        CountryISO.BritishIndianOceanTerritory,
        CountryISO.BritishVirginIslands,
        CountryISO.Brunei,
        CountryISO.Bulgaria,
        CountryISO.BurkinaFaso,
        CountryISO.Burundi,
        CountryISO.Cambodia,
        CountryISO.Cameroon,
        CountryISO.Canada,
        CountryISO.CapeVerde,
        CountryISO.CaribbeanNetherlands,
        CountryISO.CaymanIslands,
        CountryISO.CentralAfricanRepublic,
        CountryISO.Chad,
        CountryISO.Chile,
        CountryISO.China,
        CountryISO.ChristmasIsland,
        CountryISO.Cocos,
        CountryISO.Colombia,
        CountryISO.Comoros,
        CountryISO.CongoDRCJamhuriYaKidemokrasiaYaKongo,
        CountryISO.CongoRepublicCongoBrazzaville,
        CountryISO.CookIslands,
        CountryISO.CostaRica,
        CountryISO.CôteDIvoire,
        CountryISO.Croatia,
        CountryISO.Cuba,
        CountryISO.Curaçao,
        CountryISO.Cyprus,
        CountryISO.CzechRepublic,
        CountryISO.Denmark,
        CountryISO.Djibouti,
        CountryISO.Dominica,
        CountryISO.DominicanRepublic,
        CountryISO.Ecuador,
        CountryISO.Egypt,
        CountryISO.ElSalvador,
        CountryISO.EquatorialGuinea,
        CountryISO.Eritrea,
        CountryISO.Estonia,
        CountryISO.Ethiopia,
        CountryISO.FalklandIslands,
        CountryISO.FaroeIslands,
        CountryISO.Fiji,
        CountryISO.Finland,
        CountryISO.France,
        CountryISO.FrenchGuiana,
        CountryISO.FrenchPolynesia,
        CountryISO.Gabon,
        CountryISO.Gambia,
        CountryISO.Georgia,
        CountryISO.Germany,
        CountryISO.Ghana,
        CountryISO.Gibraltar,
        CountryISO.Greece,
        CountryISO.Greenland,
        CountryISO.Grenada,
        CountryISO.Guadeloupe,
        CountryISO.Guam,
        CountryISO.Guatemala,
        CountryISO.Guernsey,
        CountryISO.Guinea,
        CountryISO.GuineaBissau,
        CountryISO.Guyana,
        CountryISO.Haiti,
        CountryISO.Honduras,
        CountryISO.HongKong,
        CountryISO.Hungary,
        CountryISO.Iceland,
        CountryISO.India,
        CountryISO.Indonesia,
        CountryISO.Ireland,
        CountryISO.IsleOfMan,
        CountryISO.Israel,
        CountryISO.Italy,
        CountryISO.Jamaica,
        CountryISO.Japan,
        CountryISO.Jersey,
        CountryISO.Jordan,
        CountryISO.Kazakhstan,
        CountryISO.Kenya,
        CountryISO.Kiribati,
        CountryISO.Kosovo,
        CountryISO.Kuwait,
        CountryISO.Kyrgyzstan,
        CountryISO.Laos,
        CountryISO.Latvia,
        CountryISO.Lebanon,
        CountryISO.Lesotho,
        CountryISO.Liberia,
        CountryISO.Libya,
        CountryISO.Liechtenstein,
        CountryISO.Lithuania,
        CountryISO.Luxembourg,
        CountryISO.Macau,
        CountryISO.Macedonia,
        CountryISO.Madagascar,
        CountryISO.Malawi,
        CountryISO.Malaysia,
        CountryISO.Maldives,
        CountryISO.Mali,
        CountryISO.Malta,
        CountryISO.MarshallIslands,
        CountryISO.Martinique,
        CountryISO.Mauritania,
        CountryISO.Mauritius,
        CountryISO.Mayotte,
        CountryISO.Mexico,
        CountryISO.Micronesia,
        CountryISO.Moldova,
        CountryISO.Monaco,
        CountryISO.Mongolia,
        CountryISO.Montenegro,
        CountryISO.Montserrat,
        CountryISO.Morocco,
        CountryISO.Mozambique,
        CountryISO.Myanmar,
        CountryISO.Namibia,
        CountryISO.Nauru,
        CountryISO.Nepal,
        CountryISO.Netherlands,
        CountryISO.NewCaledonia,
        CountryISO.NewZealand,
        CountryISO.Nicaragua,
        CountryISO.Niger,
        CountryISO.Nigeria,
        CountryISO.Niue,
        CountryISO.NorfolkIsland,
        CountryISO.NorthKorea,
        CountryISO.NorthernMarianaIslands,
        CountryISO.Norway,
        CountryISO.Oman,
        CountryISO.Pakistan,
        CountryISO.Palau,
        CountryISO.Palestine,
        CountryISO.Panama,
        CountryISO.PapuaNewGuinea,
        CountryISO.Paraguay,
        CountryISO.Peru,
        CountryISO.Philippines,
        CountryISO.Poland,
        CountryISO.Portugal,
        CountryISO.PuertoRico,
        CountryISO.Qatar,
        CountryISO.Réunion,
        CountryISO.Romania,
        CountryISO.Russia,
        CountryISO.Rwanda,
        CountryISO.SaintBarthélemy,
        CountryISO.SaintHelena,
        CountryISO.SaintKittsAndNevis,
        CountryISO.SaintLucia,
        CountryISO.SaintMartin,
        CountryISO.SaintPierreAndMiquelon,
        CountryISO.SaintVincentAndTheGrenadines,
        CountryISO.Samoa,
        CountryISO.SanMarino,
        CountryISO.SãoToméAndPríncipe,
        CountryISO.SaudiArabia,
        CountryISO.Senegal,
        CountryISO.Serbia,
        CountryISO.Seychelles,
        CountryISO.SierraLeone,
        CountryISO.Singapore,
        CountryISO.SintMaarten,
        CountryISO.Slovakia,
        CountryISO.Slovenia,
        CountryISO.SolomonIslands,
        CountryISO.Somalia,
        CountryISO.SouthAfrica,
        CountryISO.SouthKorea,
        CountryISO.SouthSudan,
        CountryISO.Spain,
        CountryISO.SriLanka,
        CountryISO.Sudan,
        CountryISO.Suriname,
        CountryISO.SvalbardAndJanMayen,
        CountryISO.Swaziland,
        CountryISO.Sweden,
        CountryISO.Switzerland,
        CountryISO.Taiwan,
        CountryISO.Tajikistan,
        CountryISO.Tanzania,
        CountryISO.Thailand,
        CountryISO.TimorLeste,
        CountryISO.Togo,
        CountryISO.Tokelau,
        CountryISO.Tonga,
        CountryISO.TrinidadAndTobago,
        CountryISO.Tunisia,
        CountryISO.Turkey,
        CountryISO.Turkmenistan,
        CountryISO.TurksAndCaicosIslands,
        CountryISO.Tuvalu,
        CountryISO.USVirginIslands,
        CountryISO.Uganda,
        CountryISO.Ukraine,
        CountryISO.UnitedArabEmirates,
        CountryISO.UnitedKingdom,
        CountryISO.UnitedStates,
        CountryISO.Uruguay,
        CountryISO.Uzbekistan,
        CountryISO.Vanuatu,
        CountryISO.VaticanCity,
        CountryISO.Venezuela,
        CountryISO.Vietnam,
        CountryISO.WallisAndFutuna,
        CountryISO.WesternSahara,
        CountryISO.Yemen,
        CountryISO.Zambia,
        CountryISO.Zimbabwe,
        CountryISO.ÅlandIslands
]
