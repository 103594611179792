import { Pipe, PipeTransform } from '@angular/core';
import { IProduct } from '../../core/services/product.service';
import { SubscriptionType } from '../interfaces/subscription.interface';

@Pipe({
    name: 'subscriptionType'
})
export class SubscriptionTypePipe implements PipeTransform {

    transform(products: IProduct[], args?: any): SubscriptionType {
        if (!products) return null;
        return products.length > 1 ? 'Multiple' : 'Single';
    }

}
