import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'xion-upload-photo',
    templateUrl: './upload-photo-modal.component.html',
    styleUrls: ['./upload-photo-modal.component.scss']
})
export class UploadPhotoModalComponent {


    imageChangedEvent: Event;
    base64: string = null;
    file: File;
    filename: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<UploadPhotoModalComponent>) {
        const { $event } = this.data;
        const file: File = <File>Array.from($event.target.files)[0];
        this.filename = file.name;
        this.imageChangedEvent = $event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.base64 = event.base64;
        this.urlToFile(this.base64)
            .then(file => this.file = file);
    }

    closeDialog() {
        this.dialogRef.close({
            base64: null,
            file: null
        });
    }

    saveImage() {
        this.dialogRef.close({
            base64: this.base64,
            file: this.file
        });
    }

    urlToFile(url) {
        return fetch(url)
            .then(res => res.blob())
            .then(blob => new File([blob], this.filename));
    }


}
