<aside class="social" [class.social_show]="show">
    <a [href]="links.telegram" target="_blank" class="social__item">
        <svg class="svg-icon icon-telegramm">
            <use xlink:href="#icon-telegramm"></use>
        </svg>
    </a>
    <a [href]="links.twitter" target="_blank" class="social__item">
        <svg class="svg-icon icon-twitter">
            <use xlink:href="#icon-twitter"></use>
        </svg>
    </a>
    <a [href]="links.facebook" target="_blank" class="social__item icon-facebook">
        <svg class="svg-icon">
            <use xlink:href="#icon-facebook"></use>
        </svg>
    </a>
    <a [href]="links.website" target="_blank" class="social__item icon-www">
        <svg class="svg-icon">
            <use xlink:href="#www"></use>
        </svg>
    </a>
</aside>
<button class="social__item toggle-social" (click)="toggleSocial()" [class.toggle-social_active]="show">
    <svg class="svg-icon">
        <use xlink:href="#icon-subscribe"></use>
    </svg>
</button>
