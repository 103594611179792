<div class="popup-blue__content">
    <div class="popup-blue">
<!--        <h3 class="popup-blue__title">QUERY Merchant</h3>-->
        <h3 class="popup-blue__title">QUERY {{subscription.user.fullname}}</h3>
        <div class="form__row">
            <div class="textfield textfield__white-border disable">
                {{subscription.title}}
            </div>
        </div>
        <div class="form__row">
            <div class="textfield textfield__white-border disable">
                {{subscription.id}}
            </div>
        </div>
        <form [formGroup]="queryForm">
            <div class="form__row form__row_details select-white__border popup-row">
                <ng-select
                    [items]="issues"
                    (change)="selectedIssue($event)"
                    (clear)="clearedIssueSelection($event)"
                    formControlName="issue"
                    [placeholder]="'please select issue'"
                >
                </ng-select>
            </div>
            <div class="form__row">
                <a href="javascript:void(0)" class="emotion-action link-active">
                    <svg class="svg-icon icon-emotion icon-happy">
                        <use xlink:href="#icon-happy"></use>
                    </svg>
                </a>
                <a href="javascript:void(0)" class="emotion-action">
                    <svg class="svg-icon icon-emotion icon-unhappy">
                        <use xlink:href="#icon-unhappy"></use>
                    </svg>
                </a>
            </div>
            <div class="form__row details">
                <div class="textfield textfield__white-border disable" *ngIf="user$|async as currentUser">
                    <div class="details__item">
                        {{currentUser.fullname}}
                    </div>
                    <div class="details__item">
                        {{subscription.price | currencyConverter | async}}
                    </div>
                    <div class="details__item">
                        {{subscription.periodicity | subscriptionPlan}}
                    </div>
                </div>
            </div>
            <ng-container *ngIf="showMessageBox">
                <div class="form__row" [ngClass]="form.message.errors && form.message.touched ? 'error__field' : ''">
                    <textarea name="" class="textfield textfield__white-border" id="" cols="30" rows="5" placeholder="Message" formControlName="message"></textarea>
                    <span class="error__text" *ngIf="form.message.errors && form.message.touched">
                        <p *ngIf="form.message.errors.required">message is required</p>
                    </span>
                </div>
                <div class="row__form_file">
                    <label class="textfield file__label file__label_multiple" for="file">
                        <span class="file__title file__title_multiple">
                            UPLOAD File
                        </span>
                        <input class="file" type="file" id="file" multiple #file (change)="selectedFile(file.files)">
                        <svg class="svg-icon icon-check_file">
                            <use xlink:href="#check"></use>
                        </svg>
                    </label>
                </div>
            </ng-container>

            <div class="row__buttons">
                <button class="button button_small button_white-border" [class.disable]="queryForm.invalid && queryForm.touched" (click)="submitForm()">Submit</button>
                <button class="button button_small button_white-border" (click)="closeDialog()">Cancel</button>
            </div>

        </form>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>

