import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'subscriptionPlan'
})
export class SubscriptionPlanPipe implements PipeTransform {

    transform(plan: number, args?: any): string {
        switch (plan) {
            case 0 :
                return 'Daily';
            case 1 :
                return 'Weekly';
            case 2 :
                return 'Monthly';
            case 3 :
                return 'Yearly';
            default :
                return '';
        }
    }

}
