import * as lodash from 'lodash';

export const pageCount = (itemsCount, pageSize) => {
    const pageCount = Math.ceil(itemsCount / pageSize);
    if (pageCount === 1) return [1];
    const pageNumber = lodash.range(1, pageCount + 1);
    return pageNumber;
};

export const paginate = (items, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize;
    return lodash(items).slice(startIndex).take(pageSize).value();
}
