<div class="HowDoesItWorkComponent">
  <h2 class="HowDoesItWorkComponent__title">HOW DOES IT WORK?</h2>
  <p>Got a website where you sell products/services!? Then this is just for you!</p>
  <p>Watch this video if you don’t feel like reading >>  </p>

  <br>
  <br>
  <br>
  <br>
    <video controls src="https://xion-static.s3.amazonaws.com/HOW+TO+1.mp4"></video>
  <br>
  <br>
  <br>
  <br>

  <a class="HowDoesItWorkComponent-link" href="../../../../assets/HOW_DOES_IT_WORK.pdf" target="_blank">Click on this link for step by step instructions >></a>

</div>
