<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> User ID</th>
        <td mat-cell *matCellDef="let customer">
            {{customer.idxion}}
            <button class="copy-button" (click)="copyAddress(customer)">
                <svg class="svg-icon icon-copy">
                    <use xlink:href="#icon-copy"></use>
                </svg>
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fullname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let customer"> {{customer.fullname}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email</th>
        <td mat-cell *matCellDef="let customer">
            {{customer.email}}
            <button class="copy-button" (click)="copyEmail(customer)">
                <svg class="svg-icon icon-copy">
                    <use xlink:href="#icon-copy"></use>
                </svg>
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let customer"> {{customer.created | unixDate}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
        <td mat-cell *matCellDef="let customer"> {{customer | customerStatus}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <!--                            &lt;!&ndash; Cost Column &ndash;&gt;-->
    <!--                            <ng-container matColumnDef="cost">-->
    <!--                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount of Payments</th>-->
    <!--                                <td mat-cell *matCellDef="let transaction"> {{transaction.cost | currency}}</td>-->
    <!--                                <td mat-footer-cell *matFooterCellDef> {{getTotal('cost') | currency}}</td>-->
    <!--                            </ng-container>-->

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
</table>
