import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocationService } from 'src/app/core/services/location.service';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xion-bank-details-modal',
  templateUrl: './bank-details-modal.component.html',
  styleUrls: ['./bank-details-modal.component.scss']
})
export class BankDetailsModalComponent implements OnInit {

  bankForm: FormGroup;
  countries$: Observable<any>;
  submitted = false;

  constructor(private builder: FormBuilder, private location: LocationService, private dialogRef: MatDialogRef<BankDetailsModalComponent>) { }


  ngOnInit() {
    this.countries$ = this.location.getCountries();
    this.bankForm = this.builder.group({
      name: ['', Validators.required],
      com_country: [null, [Validators.required]],
      recipient_name: ['', [Validators.required]],
      account_number: ['', [Validators.required]],
      bank: ['', [Validators.required]],
      account_type: ['', [Validators.required]],
    });
  }

  submitForm() {
    this.submitted = true;
  }

  closeDialog() { this.dialogRef.close(); }
  get form()  { return this.bankForm.controls; }
}
