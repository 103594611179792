import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { Observable, Subject } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IApiResponse } from '../../shared/models/models';

export enum ProviderTypes {
    Supplier = 'Supplier',
    Distributor = 'Distributor'
}

export interface IProvider {
    id?: number;
    name: string;
    email: string;
    zip: string;
    address: string;
    city: string;
    state: string;
    country: string;
    phone: string;
    website: string;
    interestareas: string[];
    distributor?: boolean;
    supplier?: boolean;
}

export class Supplier implements IProvider {
    id?: number;
    name: string;
    email: string;
    city: string;
    address: string;
    country: string;
    interestareas: string[];
    phone: string;
    state: string;
    website: string;
    zip: string;
    supplier?: boolean;
    distributor?: boolean;
}

export class Distributor implements IProvider {
    id?: number;
    address: string;
    city: string;
    country: string;
    email: string;
    interestareas: string[];
    name: string;
    phone: string;
    state: string;
    supplier?: boolean;
    distributor?: boolean;
    website: string;
    zip: string;
}

const cacheBuster$ = new Subject<void>();
const MAX_CACHE_TIME = environment.MAX_CACHE_TIME;

@Injectable({
    providedIn: 'root'
})
export class ProviderService {
    private DISTRIBUTORS = `${environment.api_url}/users/distributors`;
    private SUPPLIERS = `${environment.api_url}/users/suppliers`;
    private PROVIDERS = `${environment.api_url}/users/provider`;

    constructor(private http: HttpClient) {}

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    public getSuppliers(): Observable<Supplier[]> {
        return this.http.get(this.SUPPLIERS).pipe(pluck('data'));
        // return this.http.get(this.SUPPLIERS).pipe(map((response: IApiResponse) => response.data));
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxAge: MAX_CACHE_TIME
    })
    public getDistributors(): Observable<Distributor[]> {
        return this.http
            .get(this.DISTRIBUTORS)
            .pipe(pluck('data'));
            // .pipe(map((response: IApiResponse) => response.data));
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$
    })
    public createProvider(provider: IProvider): Observable<IProvider> {
        return this.http
            .post(this.PROVIDERS, provider)
            .pipe(pluck('data'));
            // .pipe(map((response: IApiResponse) => response.data));
    }
}
