import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ISubscription } from '../../../interfaces/subscription.interface';
import { PrivateKeyModalComponent } from '../../../modals/private-key-modal/private-key-modal.component';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { ClipboardService } from 'ngx-clipboard';
import { IProduct } from '../../../../core/services/product.service';
import { IUser } from 'src/app/core/services/user.service';
import { ConfirmModalComponent } from 'src/app/shared/modals/confirm-modal/confirm-modal.component';
import { SubscriptionsService } from '../../../../core/services/subscriptions.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { mapToErrorMessage } from '../../../constants/helpers';
import { AffiliateService } from '../../../../core/services/affiliate.service';
import { pipe } from 'rxjs';

@Component({
    selector: 'xion-subscription-list-item',
    templateUrl: './subscription-list-item.component.html',
    styleUrls: ['./subscription-list-item.component.scss']
})
export class SubscriptionListItemComponent implements OnInit {

    @Input() subscription: ISubscription;
    @Input() user: IUser;
    showDetails: boolean = false;

    constructor(private dialog: MatDialog,
                private notifications: NotificationsService,
                private affiliateService: AffiliateService,
                private clipboard: ClipboardService,
                private subscriptionService: SubscriptionsService) {
    }

    ngOnInit() {
        // @ts-ignore
        this.subscription.products = this.subscription.products.sort((a: IProduct, b: IProduct) => a.image > b.image);
    }

    toggleShowDetails() {
        this.showDetails = !this.showDetails;
    }

    openPrivateKeyModal(event: any) {
        event.stopPropagation();
        this.dialog
            .open(PrivateKeyModalComponent, {
                panelClass: 'popup__holder', data: this.user
            })
            .afterClosed()
            .pipe(filter(key => !!key),
                switchMap(key => {
                    return this.subscriptionService.subscribe(this.subscription, key);
                }))
            .subscribe(
                () => this.notifications.showMessage('You successfully subscribed to this subscription'),
                err => this.notifications.showMessage(mapToErrorMessage(err)));
    }

    shareSubscription() {
        const URL = `http://${window.location.host}/subscription/${this.subscription.id}`;
        this.clipboard.copyFromContent(URL);
        this.notifications.showMessage('Subscription Link copied to your clipboard', 'Ok');
    }

    openConfirmAffiliateModal() {
        return this.dialog
            .open(ConfirmModalComponent, {
                panelClass: 'popup-blue__holder',
                data: {
                    title: 'Confirmation',
                    description: 'Add To My Affiliate Dashboard?',
                    confirm: 'Yes',
                    cancel: 'No'
                }
            }).afterClosed()
            .pipe(filter(confirm => confirm));
    }

    affiliate() {
        const URL= `http://${window.location.host}/subscription/${this.subscription.id}?aff=${this.user.idxion}`;
        this.clipboard.copyFromContent(URL);
        this.openConfirmAffiliateModal()
            .pipe(switchMap(() => this.affiliateService.affiliate(this.subscription)), pipe(take(1)))
            .subscribe(aff => {
            }, err => this.notifications.showMessage(mapToErrorMessage(err)), () => {
                this.notifications.showMessage('affiliate link copied to the clipboard');

            });
    }
}
