import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { IPageData } from 'src/app/shared/models/models';
// import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MetadataService {
    default = {
        description:
            'Xion offers a freemium interface for any and all subscription models to purchase, sell, scale, manage and edit with a click of a button. Powered by Blockchain Technology...',
        title: 'Xion | Recurring Billing & Subscription Management Ecosystem'
    };
    pageData$ = new BehaviorSubject<IPageData>({});
    constructor(private meta: Meta) {
        // this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
        // this.meta.addTag({ name: 'twitter:site', content: environment.social.twitter });
        // this.meta.addTag({ name: 'twitter:title', content: this.default.title });
        // this.meta.addTag({ name: 'twitter:description', content: this.default.description });
        // this.meta.addTag({ name: 'twitter:image', content: 'https://xion.global/media/images/logo-small.svg' });
    }

    updateTags({ site, title, description, image }) {
        image = image.length > 0 ? image : 'https://xion.global/media/images/logo-small.svg';
        const tags = [
            { name: 'twitter:title', content: title },
            { name: 'twitter:image', content: image },
            { name: 'twitter:site', content: site },
            { name: 'twitter:description', content: description },
            { content: site, property: 'og:url' },
            { content: description, property: 'og:description' },
            { content: image, property: 'og:image' },
            { content: title, property: 'og:title' }
        ];

        for (const tag of tags) {
            this.meta.updateTag(tag);
        }
    }
}
