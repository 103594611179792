import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable } from 'ngx-cacheable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IApiResponse } from '../../shared/models/models';

export interface ICountry {
    id: string;
    name: string;
    zip_regexp: string;
}

export interface IState {
    id: number;
    name: string;
    country: string;
}

export interface ICity {
    id: string;
    name: string;
    region: number;
    country: string;
}

const { MAX_CACHE_TIME, MAX_CACHE_COUNT } = environment;

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    private COUNTRIES_URL = '/techs/countries';
    private CITIES_URL = '/techs/cities';
    private REGIONS_URL = '/techs/regions';

    constructor(private http: HttpClient) {}

    @Cacheable({ maxAge: MAX_CACHE_TIME })
    getCountries(): Observable<ICountry[]> {
        return this.http
            .get<IApiResponse>(`${environment.api_url}${this.COUNTRIES_URL}`)
            .pipe(map((response) => response.data as ICountry[]));
    }

    @Cacheable({ maxAge: MAX_CACHE_TIME, maxCacheCount: MAX_CACHE_COUNT })
    getStates(countryCode?: string): Observable<IState[]> {
        let params = new HttpParams();
        if (!!countryCode) {
            params = params.set('countryCode', countryCode);
        }
        return this.http
            .get<IApiResponse>(`${environment.api_url}${this.REGIONS_URL}`, { params })
            .pipe(map((response) => response.data as IState[]));
    }

    @Cacheable({ maxAge: MAX_CACHE_TIME, maxCacheCount: MAX_CACHE_COUNT })
    getCities(countryCode?: string, stateId?: number): Observable<ICity[]> {
        let params = new HttpParams();
        if (!!countryCode) {
            params = params.set('countryCode', countryCode);
        }
        if (!!stateId) {
            params = params.set('stateId', String(stateId));
        }
        return this.http
            .get<IApiResponse>(`${environment.api_url}${this.CITIES_URL}`, { params })
            .pipe(map((response) => response.data as ICity[]));
    }

    //   getCity(cityId: number) : Observable<ICity[]>{

    //   }
}
