import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    public isLoading: Observable<boolean>;
    private isLoadingSubject = new BehaviorSubject<boolean>(true);

    public showLoading$: BehaviorSubject<any> = new BehaviorSubject(true);

    constructor() {
        this.isLoading = this.isLoadingSubject.asObservable();
    }

    show() {
        this.isLoadingSubject.next(true);
    }

    hide() {
        this.isLoadingSubject.next(false);
    }

    setShowLoading(val: boolean) {
        this.showLoading$.next(val);
    }

}
