import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

    constructor(public loadingService: LoadingService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            req.url !== 'https://xion.finance/api/info' &&
            req.url !== `${environment.api_url}/products` &&
            !req.url.includes(`${environment.api_url}/subscriptions?ids=`)
        ) {
            if(this.loadingService.showLoading$.value) {
                this.loadingService.show();
            }
        }
        return next.handle(req).pipe(finalize(() => {
            this.loadingService.hide();
        }));
    }
}
