<div class="popup__content">
    <div class="popup">
        <form [formGroup]="changeConfirmation" class="modal-form">
            <div class="form__row">
                <label class="form-label__holder" for="password">Type your password</label>
                <input class="textfield"
                       type="password"
                       id="password"
                       formControlName="password"
                       placeholder="Password"
                       [class.error__field]="submitted && form.password.errors">
                <span class="error__text" *ngIf="(submitted||form.password.touched)   && form.password.errors">
                    <p *ngIf="form.password.errors.required">Password</p>
                </span>

            </div>
            <div class="form__row">
                <label for="key" class="form-label__holder">Please enter your Private Key</label>
                <input class="textfield" type="text" name="key" id="key" placeholder="Private Key"
                       formControlName="key"
                       [class.error__field]="submitted && form.key.errors">

                <span class="error__text" *ngIf="submitted && form.key.errors">
                    <p *ngIf="form.key.errors.required">Private Key is required</p>
                </span>
            </div>
            <div class="form__row">
                <button class="button button_blue button_big" (click)="onSubmit($event)">
                    Confirm
                </button>
            </div>
        </form>
        <span class="popup__title">
            Confirm password
        </span>
    </div>
    <button (click)="closeDialog()" class="popup__close">
        <svg class="svg-icon icon-close">
            <use xlink:href="#close"></use>
        </svg>
    </button>
</div>
