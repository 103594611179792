import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { async } from 'q';
import { IMessage } from 'src/app/core/services/chat.service';
import { IUser } from '../../../core/services/user.service';
import { Message } from '../../models/models';

@Component({
    selector: 'xion-user-chat-messages',
    templateUrl: './user-chat-messages.component.html',
    styleUrls: ['./user-chat-messages.component.scss']
})
export class UserChatMessagesComponent implements OnInit {

    @Input() messages: IMessage [];
    @Input() user : IUser;
    @Output() showUserDetails = new EventEmitter(false);

    // tslint:disable-next-line: no-empty
    constructor() {}

    // tslint:disable-next-line: no-empty
    ngOnInit() {}

    toggleUserDetails() {
        this.showUserDetails.emit(false);
    }

}
