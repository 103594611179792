import { Component, OnInit, Inject, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ISubscription } from '../../interfaces/subscription.interface';
import { ConnectModalComponent } from '../connect-modal/connect-modal.component';

@Component({
  selector: 'xion-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {
    autoHideText = 'This notification will hide in few seconds';
    constructor(
        @Inject(MAT_DIALOG_DATA) public notification: any,
        private builder: FormBuilder,
        private dialog: MatDialog,
        private router: Router,
        private dialogRef: MatDialogRef<NotificationModalComponent>) { }

    ngOnInit() {
        if (this.notification.autoHide) {
            setTimeout(() => {
                this.closeDialog();
                if (this.notification.redirect) {
                    this.router.navigateByUrl(this.notification.redirect.url,this.notification.redirect.dataRedirect);
                }
                if (this.notification.response) {
                    this.openConnectModal(this.notification.response);
                }
            }, 3000);
        }
        if (this.notification.response && !this.notification.autoHide) {
            this.openConnectModal(this.notification.response);
        }
    }
    closeDialog() {
        this.dialogRef.close();
    }
    openConnectModal(subscription: ISubscription) {
        this.dialog
            .open(ConnectModalComponent, { panelClass: 'popup-blue__holder', data: subscription });
    }
}
