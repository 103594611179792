import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'xion-custom-loading',
    templateUrl: './custom-loading.component.html',
    styleUrls: ['./custom-loading.component.scss']
})
export class CustomLoadingComponent implements OnInit {
    current: number = 0;
    interval;
    transactionStatus = 'loading';
    transactionHash = null;
    blockExplorerURL = '';

    constructor() {}

    ngOnInit(): void {
        this.blockExplorerURL = environment.blockExplorerURL;
        this.setLoader();
    }

    setLoader() {
        this.interval = setInterval(() => {
            if (this.current === 100) {
                this.current = 0;
            }
            this.current++;
        }, 10);
    }

    setTransactionStatus(): void {
        setTimeout(() => {
            this.transactionStatus = 'success';
        }, 2000);
    }
}
