<div class="popup__content">
  <div class="popup">
    <div class="popup__charts">
      <xion-churn-chart
        *ngIf="showCHart"
        [xAxis]="false"
        [yAxis]="true"
        [users]="users"
        [revenue]="revenue"
        [chartTitle]="true"
        colorScheme="all"></xion-churn-chart>
    </div>
    <span _ngcontent-c29="" class="popup__title">CHURN RATE </span>
  </div>
  <button (click)="closeDialog()" class="popup__close">
    <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
  </button>
</div>

