import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'xion-terms',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss']
})
export class TermsModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<TermsModalComponent>){}

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close()
  }

}
