import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'floor' })
export class FloorPipe implements PipeTransform {
    /**
     *
     * @param value
     * @returns {string}
     */
    transform(value: number): string {
        const num1 = Math.floor(value * 100) / 100;
        return num1.toFixed(2);
    }
}
