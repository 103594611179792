import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'xion-max-discount-info-modal',
    templateUrl: './max-discount-info-modal.component.html',
    styleUrls: ['./max-discount-info-modal.component.scss']
})
export class MaxDiscountInfoModalComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<MaxDiscountInfoModalComponent>
    ) {}
    ngOnInit() {}
    closeDialog() {
        this.dialogRef.close();
    }
}
