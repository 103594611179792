import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerFilter'
})
export class CustomerFilterPipe implements PipeTransform {

    transform(list: any[], query: string): any {
        return list ? list.filter(item => item.user$.name.search(new RegExp(query, 'i')) > -1) : [];
    }

}
