<div class="cards__holder">
    <div class="swiper-container" [swiper]="configProd">
        <div class="swiper-wrapper">
            <div class="card__item swiper-slide" *ngFor="let offer of subscription.offers" >
                <a [routerLink]="['/one-time-offer', offer.id]" class="card__picture-holder">
                    <img *ngIf="offer.image;else noOfferImage" [src]="offer.image" [alt]="offer.title" class="absolute-img__full-image">
                     <p class="card__description card__picture_item">{{offer.description}}</p>
                    <div class="card__price card__picture_item">{{offer.price | currencyConverter |async}}</div>
                    <h3 class="card__title card__picture_item">{{offer.title}}</h3>
                    <ng-template #noOfferImage>
                        <svg class="svg-icon icon_no-image">
                            <use xlink:href="#no-image"></use>
                        </svg>
                    </ng-template>
                </a>
                <button
                    *ngIf="canBuy && user; else loginModalSubscribe"
                    class="buy card__picture_item"
                    (click)="openPrivateKeyModal($event)">
                    <div class="notification-msg__holder">
                        <svg class="svg-icon icon-download">
                            <use xlink:href="#icon-bag"></use>
                        </svg>
                        <div class="notification-msg">Buy</div>
                    </div>
                </button>
                <ng-template #loginModalSubscribe>
                    <button
                        class="buy card__picture_item"
                        (click)="openLoginModal()">
                        <div class="notification-msg__holder">
                            <svg class="svg-icon icon-download">
                                <use xlink:href="#icon-bag"></use>
                            </svg>
                            <div class="notification-msg">Buy</div>
                        </div>
                    </button>
                </ng-template>
            </div>
        </div>
    </div>
    <button class="next-sub slider-next">
        <svg class="svg-icon slide-arrow_next">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
    <button class="prev-sub slider-prev">
        <svg class="svg-icon slide-arrow">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
</div>
