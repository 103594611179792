<div class="popup__content">
    <div class="popup">
        <div class="popup__charts">
            <xion-churn-chart [xAxis]="true" [yAxis]="true" [users]="users" [revenue]="revenue" colorScheme="all"></xion-churn-chart>
            <!-- <xion-revenue-chart class="revenue" [revenue]="revenue"  colorScheme="highlight"></xion-revenue-chart> -->
            <!-- <div class="map-chart">
                <xion-users-map [users]="users"></xion-users-map>
            </div> -->
            <!-- <div class="chart-double ">
                <div class="chart-part">
                    <xion-new-users-chart class="fit-container" [xAxis]="false" [yAxis]="false" [users]="newUsers"></xion-new-users-chart>
                </div>
                <div class="chart-part">
                    <xion-dispute-chargebacks-chart class="fit-container" [xAxis]="false" [yAxis]="false" [disputes]="disputesData"></xion-dispute-chargebacks-chart>
                </div>
            </div> -->
        </div> 
        <span _ngcontent-c29="" class="popup__title">Chart </span>
     </div>
     <button (click)="closeDialog()" class="popup__close">
        <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
    </button>
</div>

