import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable } from 'ngx-cacheable';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { mapResponseToData } from '../../shared/constants/helpers';
import { ISubscription } from '../../shared/interfaces/subscription.interface';
import { IUser, KYCStatus } from './user.service';

export interface ICustomer extends IUser {
    address: string;
    charity: boolean;
    city: number;
    companyname: string;
    country: string;
    crn: string;
    currency: string;
    email: string;
    exp: number;
    followed: boolean;
    fullname: string;
    gender: boolean;
    id: number;
    idxion: string;
    iec: string;
    image: string;
    interestareas: string[];
    isx: string;
    kycstatus: KYCStatus;
    merchant: boolean;
    notif: boolean;
    pan: string;
    rating: number | null;
    roomId: string;
    state: number;
    usepwd: boolean;
    website: string;
    xsc: string;
    zip: string;
    paused: boolean;
    subscribed: boolean;
    created: number;
}

const { MAX_CACHE_TIME, MAX_CACHE_COUNT } = environment;

@Injectable({
    providedIn: 'root'
})
export class CustomersService {
    private GET_CUSTOMERS = `${environment.api_url}/users/sub`;

    constructor(private http: HttpClient) {}

    @Cacheable({
        maxAge: MAX_CACHE_TIME,
        maxCacheCount: MAX_CACHE_COUNT
    })
    public getSubscribers(subscription: Partial<ISubscription>): Observable<ICustomer[]> {
        const URL = `${this.GET_CUSTOMERS}/${subscription.id}/customers`;
        return this.http.get(URL).pipe(pluck('data'));
        // return this.http.get(URL).pipe(map(mapResponseToData));
    }
}
