import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { DEFAULT_SWIPER_CONFIG } from '../shared/constants/config';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { CustomersService } from './services/customers.service';
import { DummyDataService } from './services/dummy.service';
import { LoadingService } from './services/loading.service';
import { WalletService } from './services/wallet.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        WalletService,
        CustomersService,
        DummyDataService,
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        },
        LoadingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        }
    ]
})
export class CoreModule {}
