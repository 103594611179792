<button class="back back__top" (click)="toggleUserDetails()">
    <svg class="svg-icon icon-back icon-back__top">
        <use xlink:href="#icon-arrow-back"></use>
    </svg>
</button>
<div class="user__picture_holder">
    <svg class="svg-icon no-picture" *ngIf="!user.image">
        <use xlink:href="#icon-retailer"></use>
    </svg>
    <img
        class="absolute-img__full-image"
        *ngIf="user.image"
        [src]="user.image"
        [alt]="user.fullname | truncate : 10">
    <!-- <span class="status"></span> -->
</div>

<h3 class="user-details__name">
    {{user.fullname}}
</h3>
<ul class="user-details__list">
    <li class="user-details__list-item user-details__list_id text-overflow">
        <svg class="svg-icon icon-user-list">
            <use xlink:href="#logo-part"></use>
        </svg>
        {{user.idxion}}
        <button class="user-details__link copy" (click)="copyAddress(user.idxion)">
            <svg class="svg-icon icon-copy">
                <use xlink:href="#icon-copy"></use>
            </svg>
        </button>
    </li>
    <li class="user-details__list-item user-details__list_id text-overflow">
        <div class="user-details__wallet-title">
            ISX
        </div>
        {{user.isx}}
        <button class="user-details__link copy" (click)="copyAddress(user.isx)">
            <svg class="svg-icon icon-copy">
                <use xlink:href="#icon-copy"></use>
            </svg>
        </button>
    </li>
    <li class="user-details__list-item user-details__list_id text-overflow">
        <div class="user-details__wallet-title">
            XSC
        </div>
        {{user.xsc}}
        <button class="user-details__link copy" (click)="copyAddress(user.xsc)">
            <svg class="svg-icon icon-copy">
                <use xlink:href="#icon-copy"></use>
            </svg>
        </button>
    </li>
    <li class="user-details__list-item">
        <svg class="svg-icon icon-user-list">
            <use xlink:href="#icon-mail"></use>
        </svg>
        <a class="user-details__link" href="mailto:{{user.email}}">{{user.email}}</a>
    </li>
    <li class="user-details__list-item">
        <svg class="svg-icon icon-user-list">
            <use xlink:href="#icon-location"></use>
        </svg>
        <div>
            {{user.country}}
        </div>
    </li>
    <li class="user-details__list-item" *ngIf="user.website">
        <svg class="svg-icon icon-user-list">
            <use xlink:href="#www"></use>
        </svg>
        <a href="{{user.website}}" target="_blank" class="user-details__link">{{user.website}}</a>
    </li>
    <li class="user-details__list-item">
        <svg class="svg-icon icon-user-list">
            <use xlink:href="#icon-clock"></use>
        </svg>
        {{user.merchant ? 'Merchant' : 'User'}} since {{user.created | unixDate}}
    </li>
</ul>

