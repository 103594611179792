<div class="cards__holder">
    <ng-container *ngFor="let subscription of subscriptions ">
        <xion-subscription-list-item
            [user]="user"
            [subscription]="subscription"
            class="card__item-component"></xion-subscription-list-item>
    </ng-container>
</div>

<ng-container *ngIf="!subscriptions || subscriptions.length ===0">
    There are no subscriptions matching this filter
</ng-container>
