import { Component, OnInit, Input } from '@angular/core';
import { IMetadataResponse } from 'src/app/core/services/links-metadata.service';

@Component({
  selector: 'xion-link-preview',
  templateUrl: './link-preview.component.html',
  styleUrls: ['./link-preview.component.scss']
})
export class LinkPreviewComponent implements OnInit {

  constructor() { }
    @Input() metadata: IMetadataResponse;
    @Input() showFullPreview: boolean;
    ngOnInit() {
    }

}
