import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xion-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss']
})
export class DeleteAccountModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DeleteAccountModalComponent>) { }

  ngOnInit() {
  }

  public confirm(): void {
      this.dialogRef.close(true);
  }

}
