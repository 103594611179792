import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { DummyDataService } from '../../../core/services/dummy.service';
import { OfferService } from '../../../core/services/offer.service';
import { IUser } from '../../../core/services/user.service';
import { IOffer } from '../../interfaces/offer.interface';
import { PrivateKeyModalComponent } from '../../modals/private-key-modal/private-key-modal.component';
import { AsideService } from '../../services/aside.service';

enum MediaTabs {
    PHOTO = 'photo',
    VIDEO = 'video',
}

@Component({
    selector: 'xion-offer-preview',
    templateUrl: './offer-preview.component.html',
    styleUrls: ['./offer-preview.component.scss']
})
export class OfferPreviewComponent implements OnInit {

    asideMenuOpen = false;
    user$: Observable<IUser>;
    mediaTabs = MediaTabs;
    activeTopTab = MediaTabs.PHOTO;
    fullscreen = false;

    offer$: Observable<IOffer>;

    isOwnerOfOffer: boolean = false;
    offer: IOffer;
    user: IUser;

    constructor(private dummy: DummyDataService,
                private auth: AuthService,
                private dialog: MatDialog,
                private route: ActivatedRoute,
                private offerService: OfferService,
                public asideService: AsideService
    ) {}

    ngOnInit() {
        this.user$ = this.auth.currentUser;
        this.offer$ = this.route.paramMap.pipe(switchMap(params => this.offerService.getOffer(params.get('id'))));

        this.user$.pipe(take(1)).subscribe(user => this.user = user);
        this.offer$.pipe(take(1)).subscribe(offer => {
            this.offer = offer;
            this.isOwnerOfOffer = this.user && (offer.user.idxion === this.user.idxion)
        });
    }

    closeSideMenu() {
        this.asideMenuOpen = !this.asideMenuOpen;
    }

    openPrivateKeyModal(event: any) {
        event.stopPropagation();
        this.dialog
            .open(PrivateKeyModalComponent, {
                panelClass: 'popup__holder',
                data: this.user
            })
            .afterClosed()
            .subscribe(key => key);
    }


}
