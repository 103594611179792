
<div class="revenue-chart__content chart__holder"
    [class.revenue-chart__content_page]="page"
    [class.revenue-chart__content_popup]="!page">
  <ng-container *ngIf="revenue">
    <ngx-charts-line-chart
        style="position: static; display: inline-block; transform: none; color: #4988a6;"
        [view]="view || null"
        class="chart-container"
        [scheme]="scheme"
        [results]="revenue"
        [legend]="false"
        [xAxis]="xAxis || false"
        [yAxis]="yAxis || true"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
        [xAxisLabel]="'angle'"
        [yAxisLabel]="'radius'"
        [showGridLines]="true"
        [trimXAxisTicks]="true"
        [trimYAxisTicks]="true"
        [tooltipDisabled]="tooltipDisabled"
    >
  </ngx-charts-line-chart>
  </ng-container>
</div>
