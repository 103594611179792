import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { ITransaction } from '../../interfaces/transaction.interface';
import { BillingTypeEnum } from '../../models/billing-product.model';
import { TransactionModel } from '../../models/transaction.model';

@Component({
    selector: 'xion-billing-transactions-modal',
    templateUrl: './billing-transactions-modal.component.html',
    styleUrls: ['./billing-transactions-modal.component.scss']
})
export class BillingTransactionsModalComponent implements OnInit {
    transactions: TransactionModel[];
    billingType = BillingTypeEnum;
    billingTabs: Array<{ tabLabel: string, billingType: string }> = [];
    selectedTransaction: TransactionModel;
    copied = null;
    filteredTransactions: TransactionModel[];

    retryStrings = ['renew', '1st Retry', '2nd Retry', '3rd Retry', '4th Retry'];
    count: any = {};

    constructor(
        private dialogRef: MatDialogRef<BillingTransactionsModalComponent>,
        private notifications: NotificationsService,
        @Inject(MAT_DIALOG_DATA) public data: ITransaction[],
    ) {
        this.billingTabs = [
            {
                tabLabel: 'SINGLE BILLING',
                billingType: this.billingType.single
            },
            {
                tabLabel: 'Recurring BILLING',
                billingType: this.billingType.recurring
            }];
    }

    ngOnInit() {
        if (this.data) {
            this.transactions = this.data.reverse().map((item) => {
                if(item.transaction_info.billingType === this.billingType.recurring) {
                    const userInfo = item.user_info.userId;
                    if(item.transaction_info.txType === 'renew' && item.transaction_info.status === 'failed') {
                        if(this.count.hasOwnProperty(userInfo)) {
                            if(this.count[userInfo] < 4) {
                                this.count[userInfo] = this.count[userInfo] + 1;
                                item.transaction_info.txType = this.retryStrings[this.count[userInfo]];
                            }
                        } else {
                            this.count[userInfo] = 0
                            item.transaction_info.txType = this.retryStrings[this.count[userInfo]];
                        }
                    } else {
                        if(this.count.hasOwnProperty(userInfo)) {
                            delete this.count[userInfo]
                        }
                    }
                }
                return new TransactionModel(item);
            });
            this.filteredTransactions = this.transactions;
        }
    }

    transactionSelected(transaction: HTMLElement, trans: TransactionModel): void {
        const className = 'selected';
        this.selectedTransaction = trans;
        transaction.parentNode.childNodes.forEach((item: HTMLElement) => item?.classList?.remove(className));
        transaction.classList.add(className);
    }

    selectedTabChange(): void {
        this.selectedTransaction = null;
    }

    public close(): void {
        this.dialogRef.close();
    }

    copyAddress(params: string): void {
        this.notifications.showMessage('Copied!');
        this.copied = params;
        setTimeout(() => {
            this.copied = null;
        }, 1000);
    }

    onSearch(event: any): void {
        const searchKey = event.target.value.toLowerCase();
        this.filteredTransactions = [];
        for(let transaction of this.transactions) {
            if(transaction.user_info.name.toLowerCase().includes(searchKey) || transaction.transaction_info.orderCode.toLowerCase().includes(searchKey)) {
                this.filteredTransactions.push(transaction);
            }
        }
    }

}
