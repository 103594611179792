import { Pipe, PipeTransform } from '@angular/core';
import { ISubscription } from '../interfaces/subscription.interface';

@Pipe({
    name: 'subscriptionFilter'
})
export class SubscriptionFilterPipe implements PipeTransform {

    transform(subscriptions: ISubscription[], query: string): ISubscription[] {
        if (!query) return subscriptions;
        return subscriptions ? subscriptions.filter(item => (item.title.search(new RegExp(query, 'i')) > -1)) : [];
    }

}
