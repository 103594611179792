import { AbiItem } from 'web3-utils';

export const subscriptionsContractABI: AbiItem[] | AbiItem = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'user',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'string',
                name: 'subscriptionID',
                type: 'string'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            }
        ],
        name: 'ActivateSubscription',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'user',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'string',
                name: 'subscriptionID',
                type: 'string'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            }
        ],
        name: 'CancelSubscription',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'OwnershipTransferred',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'user',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'string',
                name: 'subscriptionID',
                type: 'string'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'string',
                name: 'currency',
                type: 'string'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenPrice',
                type: 'uint256'
            }
        ],
        name: 'PauseSubscriptionByCustomer',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'string',
                name: 'productID',
                type: 'string'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            }
        ],
        name: 'PauseSubscriptionsByMerchant',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'user',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'merchant',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'string',
                name: 'subscriptionID',
                type: 'string'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'rebillID',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'string',
                name: 'currency',
                type: 'string'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'basePayment',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenPayment',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenPrice',
                type: 'uint256'
            }
        ],
        name: 'RecurringBillingPaymentPaid',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'user',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'merchant',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'string',
                name: 'subscriptionID',
                type: 'string'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'string',
                name: 'currency',
                type: 'string'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'basePayment',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenPayment',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenPrice',
                type: 'uint256'
            }
        ],
        name: 'SingleBillingPaymentPaid',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'user',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'merchant',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'string',
                name: 'subscriptionId',
                type: 'string'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'string',
                name: 'productId',
                type: 'string'
            }
        ],
        name: 'SubscriptionCreated',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'string',
                name: 'productID',
                type: 'string'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            }
        ],
        name: 'UnpauseSubscriptionsByMerchant',
        type: 'event'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: 'subscriptionId',
                type: 'string'
            },
            {
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            }
        ],
        name: 'activateSubscription',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: 'productId',
                type: 'string'
            },
            {
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            }
        ],
        name: 'activateSubscriptionsByMerchant',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: 'subscriptionId',
                type: 'string'
            },
            {
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            }
        ],
        name: 'cancelSubscription',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'deposit',
        outputs: [],
        stateMutability: 'payable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address'
            }
        ],
        name: 'depositForUser',
        outputs: [],
        stateMutability: 'payable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256'
            }
        ],
        name: 'depositXGT',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256'
            }
        ],
        name: 'depositXGTForUser',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_xgt',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_freezer',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_feeWallet',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_backend',
                type: 'address'
            }
        ],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: 'subscriptionId',
                type: 'string'
            },
            {
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            },
            {
                internalType: 'bool',
                name: 'payWithXGT',
                type: 'bool'
            },
            {
                internalType: 'uint256',
                name: 'tokenPrice',
                type: 'uint256'
            }
        ],
        name: 'pauseSubscription',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: 'productId',
                type: 'string'
            },
            {
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            }
        ],
        name: 'pauseSubscriptionsByMerchant',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: 'purchaseId',
                type: 'string'
            },
            {
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'basePayment',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'tokenPayment',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'tokenPrice',
                type: 'uint256'
            }
        ],
        name: 'processOneTimePayment',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'user',
                type: 'address'
            },
            {
                internalType: 'address',
                name: 'merchant',
                type: 'address'
            },
            {
                internalType: 'string',
                name: 'purchaseId',
                type: 'string'
            },
            {
                internalType: 'string',
                name: 'productId',
                type: 'string'
            },
            {
                internalType: 'string',
                name: 'parentProductId',
                type: 'string'
            },
            {
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'basePayment',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'tokenPayment',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'tokenPrice',
                type: 'uint256'
            }
        ],
        name: 'processOneTimePurchase',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: 'subscriptionId',
                type: 'string'
            },
            {
                internalType: 'uint256',
                name: 'rebillID',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'basePayment',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'tokenPayment',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'tokenPrice',
                type: 'uint256'
            },
            {
                internalType: 'bool',
                name: 'useFallback',
                type: 'bool'
            }
        ],
        name: 'processSubscriptionPayment',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_address',
                type: 'address'
            },
            {
                internalType: 'bool',
                name: '_authorized',
                type: 'bool'
            }
        ],
        name: 'setAuthorizedAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'user',
                type: 'address'
            },
            {
                internalType: 'address',
                name: 'merchant',
                type: 'address'
            },
            {
                internalType: 'string',
                name: 'subscriptionId',
                type: 'string'
            },
            {
                internalType: 'string',
                name: 'productId',
                type: 'string'
            },
            {
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'billingDay',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'billingCycle',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'cycles',
                type: 'uint256'
            },
            {
                internalType: 'uint256[]',
                name: 'priceInfo',
                type: 'uint256[]'
            },
            {
                internalType: 'bool',
                name: 'unlimited',
                type: 'bool'
            },
            {
                internalType: 'string',
                name: 'parentProductId',
                type: 'string'
            }
        ],
        name: 'subscribeUser',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address'
            },
            {
                internalType: 'string',
                name: '_featureDescriptor',
                type: 'string'
            },
            {
                internalType: 'bool',
                name: '_active',
                type: 'bool'
            }
        ],
        name: 'toggleFeatureUnlock',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string[]',
                name: 'subscriptionIds',
                type: 'string[]'
            },
            {
                internalType: 'uint256',
                name: 'processID',
                type: 'uint256'
            }
        ],
        name: 'unsubscribeBatchByMerchant',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_feeBP',
                type: 'uint256'
            }
        ],
        name: 'updateDepositFee',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_feeWallet',
                type: 'address'
            }
        ],
        name: 'updateFeeWallet',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_freezer',
                type: 'address'
            }
        ],
        name: 'updateFreezerContract',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_freezeBP',
                type: 'uint256'
            }
        ],
        name: 'updateFrozenAmountMerchant',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_feeBP',
                type: 'uint256'
            }
        ],
        name: 'updateWithdrawFee',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256'
            }
        ],
        name: 'withdraw',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256'
            }
        ],
        name: 'withdrawForUser',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256'
            }
        ],
        name: 'withdrawXGT',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256'
            }
        ],
        name: 'withdrawXGTForUser',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        stateMutability: 'payable',
        type: 'fallback'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        name: 'activeSubscriptionsOfUser',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        name: 'authorized',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        name: 'customerBalancesBase',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        name: 'customerBalancesXGT',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'DEPOSIT_FEE_IN_BP',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'feeWallet',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'FREEZE_PERCENT_OF_MERCHANT_PAYMENT_IN_BP',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'freezer',
        outputs: [
            {
                internalType: 'contract IXGTFreezer',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address'
            },
            {
                internalType: 'string',
                name: '_featureDescriptor',
                type: 'string'
            }
        ],
        name: 'getFeatureUnlockStatus',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: 'subscriptionId',
                type: 'string'
            }
        ],
        name: 'getSubscriptionDetails',
        outputs: [
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'user',
                        type: 'address'
                    },
                    {
                        internalType: 'address',
                        name: 'merchant',
                        type: 'address'
                    },
                    {
                        internalType: 'string',
                        name: 'productId',
                        type: 'string'
                    },
                    {
                        internalType: 'string',
                        name: 'parentProductId',
                        type: 'string'
                    },
                    {
                        internalType: 'enum SubscriptionsContract.Status',
                        name: 'status',
                        type: 'uint8'
                    },
                    {
                        internalType: 'bool',
                        name: 'unlimited',
                        type: 'bool'
                    },
                    {
                        internalType: 'uint256',
                        name: 'billingDay',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'nextBillingDay',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'billingCycle',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'cycles',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'price',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'successPaymentsAmount',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'lastPaymentDate',
                        type: 'uint256'
                    }
                ],
                internalType: 'struct SubscriptionsContract.Subscription',
                name: '',
                type: 'tuple'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: 'subscriptionId',
                type: 'string'
            }
        ],
        name: 'getSubscriptionStatus',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address'
            }
        ],
        name: 'getUnlockedFeaturesOfUser',
        outputs: [
            {
                internalType: 'bytes32[]',
                name: '',
                type: 'bytes32[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: '',
                type: 'string'
            }
        ],
        name: 'productPaused',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: '',
                type: 'string'
            }
        ],
        name: 'purchases',
        outputs: [
            {
                internalType: 'address',
                name: 'user',
                type: 'address'
            },
            {
                internalType: 'address',
                name: 'merchant',
                type: 'address'
            },
            {
                internalType: 'string',
                name: 'productId',
                type: 'string'
            },
            {
                internalType: 'string',
                name: 'parentProductId',
                type: 'string'
            },
            {
                internalType: 'uint256',
                name: 'date',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256'
            },
            {
                internalType: 'bool',
                name: 'paid',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: '',
                type: 'string'
            }
        ],
        name: 'subscriptions',
        outputs: [
            {
                internalType: 'address',
                name: 'user',
                type: 'address'
            },
            {
                internalType: 'address',
                name: 'merchant',
                type: 'address'
            },
            {
                internalType: 'string',
                name: 'productId',
                type: 'string'
            },
            {
                internalType: 'string',
                name: 'parentProductId',
                type: 'string'
            },
            {
                internalType: 'enum SubscriptionsContract.Status',
                name: 'status',
                type: 'uint8'
            },
            {
                internalType: 'bool',
                name: 'unlimited',
                type: 'bool'
            },
            {
                internalType: 'uint256',
                name: 'billingDay',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'nextBillingDay',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'billingCycle',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'cycles',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'successPaymentsAmount',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'lastPaymentDate',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            },
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32'
            }
        ],
        name: 'unlockedFeatures',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'unlockedFeaturesOfUser',
        outputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'WITHDRAW_FEE_IN_BP',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'xgt',
        outputs: [
            {
                internalType: 'contract IERC20',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    }
];
export const tokenContractABI: AbiItem[] | AbiItem = [
    {
        inputs: [{ internalType: 'uint256', name: 'chainId_', type: 'uint256' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'constructor'
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'src', type: 'address' },
            { indexed: true, internalType: 'address', name: 'guy', type: 'address' },
            { indexed: false, internalType: 'uint256', name: 'wad', type: 'uint256' }
        ],
        name: 'Approval',
        type: 'event'
    },
    {
        anonymous: true,
        inputs: [
            { indexed: true, internalType: 'bytes4', name: 'sig', type: 'bytes4' },
            { indexed: true, internalType: 'address', name: 'usr', type: 'address' },
            { indexed: true, internalType: 'bytes32', name: 'arg1', type: 'bytes32' },
            { indexed: true, internalType: 'bytes32', name: 'arg2', type: 'bytes32' },
            { indexed: false, internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        name: 'LogNote',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'src', type: 'address' },
            { indexed: true, internalType: 'address', name: 'dst', type: 'address' },
            { indexed: false, internalType: 'uint256', name: 'wad', type: 'uint256' }
        ],
        name: 'Transfer',
        type: 'event'
    },
    {
        constant: true,
        inputs: [],
        name: 'DOMAIN_SEPARATOR',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: true,
        inputs: [],
        name: 'PERMIT_TYPEHASH',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: true,
        inputs: [
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'address', name: '', type: 'address' }
        ],
        name: 'allowance',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: false,
        inputs: [
            { internalType: 'address', name: 'usr', type: 'address' },
            { internalType: 'uint256', name: 'wad', type: 'uint256' }
        ],
        name: 'approve',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: false,
        inputs: [
            { internalType: 'address', name: 'usr', type: 'address' },
            { internalType: 'uint256', name: 'wad', type: 'uint256' }
        ],
        name: 'burn',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: false,
        inputs: [{ internalType: 'address', name: 'guy', type: 'address' }],
        name: 'deny',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        constant: false,
        inputs: [
            { internalType: 'address', name: 'usr', type: 'address' },
            { internalType: 'uint256', name: 'wad', type: 'uint256' }
        ],
        name: 'mint',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        constant: false,
        inputs: [
            { internalType: 'address', name: 'src', type: 'address' },
            { internalType: 'address', name: 'dst', type: 'address' },
            { internalType: 'uint256', name: 'wad', type: 'uint256' }
        ],
        name: 'move',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        constant: true,
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'nonces',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: false,
        inputs: [
            { internalType: 'address', name: 'holder', type: 'address' },
            { internalType: 'address', name: 'spender', type: 'address' },
            { internalType: 'uint256', name: 'nonce', type: 'uint256' },
            { internalType: 'uint256', name: 'expiry', type: 'uint256' },
            { internalType: 'bool', name: 'allowed', type: 'bool' },
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' }
        ],
        name: 'permit',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        constant: false,
        inputs: [
            { internalType: 'address', name: 'usr', type: 'address' },
            { internalType: 'uint256', name: 'wad', type: 'uint256' }
        ],
        name: 'pull',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        constant: false,
        inputs: [
            { internalType: 'address', name: 'usr', type: 'address' },
            { internalType: 'uint256', name: 'wad', type: 'uint256' }
        ],
        name: 'push',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        constant: false,
        inputs: [{ internalType: 'address', name: 'guy', type: 'address' }],
        name: 'rely',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: false,
        inputs: [
            { internalType: 'address', name: 'dst', type: 'address' },
            { internalType: 'uint256', name: 'wad', type: 'uint256' }
        ],
        name: 'transfer',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        constant: false,
        inputs: [
            { internalType: 'address', name: 'src', type: 'address' },
            { internalType: 'address', name: 'dst', type: 'address' },
            { internalType: 'uint256', name: 'wad', type: 'uint256' }
        ],
        name: 'transferFrom',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        constant: true,
        inputs: [],
        name: 'version',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'wards',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    }
];
