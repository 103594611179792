<div class="popup-blue__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title">
            Connect
        </h3>
        <div class="tabs form__row">
            <button class="tab__item" [class.link-active]="activeTab == 'copy'" (click)="activeTab = 'copy'">
                Copy link
            </button>
            <button class="tab__item" [class.link-active]="activeTab == 'generate'" (click)="activeTab = 'generate'">
                Generate button
            </button>

        </div>
        <form [formGroup]="connectForm">
            <div class="form__row" *ngIf="activeTab=='copy'">
                <div class="form-label__holder">
                    <input class="textfield textfield__white-border disable" type="text" [readOnly]="true" name="url"
                           id="url" formControlName="url">
                    <button class="copy" (click)="copySubscriptionLink()">
                        <svg class="svg-icon icon-copy">
                            <use xlink:href="#icon-copy"></use>
                        </svg>
                    </button>
                </div>
            </div>
            <div *ngIf="activeTab=='generate'">
                <div class="form__row">
                    <div class="form-label__holder">
                        <label class="form-label text-overflow" for="title">Button text</label>
                        <input class="textfield textfield__white-border" type="text" name="title" id="title"
                               formControlName="title">
                    </div>
                </div>
                <div class="form__row">
                    <div class="checkbox__holder">
                        <input type="checkbox" id="logo-show" class="checkbox" formControlName="logo">
                        <label for="logo-show" class="checkbox__label">
                            <svg class="svg-icon icon-check">
                                <use xlink:href="#check"></use>
                            </svg>
                            <span class="checkbox__text">Enable Logo</span>
                        </label>
                    </div>
                </div>
                <div class="form__row form__row_color-picker" *ngIf="showColorPicker">
                    <div class="form-label__holder">
                        <span [style.background]="styles[selectedColor]"
                              [cpToggle]="true"
                              [cpDialogDisplay]="'inline'"
                              [cpOutputFormat]="'hex'"
                              (colorPickerChange)="setColor($event)"
                              [(colorPicker)]="styles[selectedColor]"
                        ></span>
                        <div class="colors__column">
                            <div class="color-item__holder">
                                <div class="color-item__title">Background color</div>
                                <div class="color-item" [style.background]="styles['background-color']"
                                     (click)="selectedColor='background-color'"></div>
                            </div>
                            <div class="color-item__holder">
                                <div class="color-item__title">Font color</div>
                                <div class="color-item" [style.color]="styles['color']"
                                     (click)="selectedColor='color'">Font color
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row__buttons">
                    <button class="button button_small button_white-border" (click)="showColorPicker = !showColorPicker">
                        {{showColorPicker ? 'Hide color section' : 'Change colors'}}
                    </button>
                </div>
                <div class="form__row">
                    <div class="form-label__holder">
                        <span class="form-label text-overflow">Preview</span>
                        <div class="form__row_preview">
                            <a class="button button_small" [ngStyle]="styles">
                                {{form.title.value}}
                                <span class="button__arrow" *ngIf="form.logo.value">
                                    <svg class="svg-icon icon-logo-part" version="1.1" xmlns="http://www.w3.org/2000/svg" width="26" height="32"
                                         viewBox="0 0 26 32">
                                        <path fill="#29a7de"
                                              d="M16.7 14.1l6.7-12.8c0.1-0.3 0.1-0.5-0.1-0.7s-0.4-0.3-0.7-0.3h-2.6c-0.4 0-0.7 0.2-0.8 0.6l-6.9 13.4 8.8 17.1c0.1 0.4 0.3 0.5 0.8 0.5h2.6c0.2 0 0.4-0.1 0.6-0.2s0.2-0.4 0.2-0.6c0.1-0.2-8.6-17-8.6-17v0z"></path>
                                        <path fill="#29a7de"
                                              d="M4.3 14.2l-3.9 7.4c-0.1 0.3-0.1 0.5 0.1 0.7s0.4 0.3 0.6 0.3h2.6c0.4 0 0.7-0.2 0.8-0.6l4.1-7.9-3.8-7.2c-0.1-0.4-0.3-0.5-0.7-0.5h-2.6c-0.3 0-0.5 0.1-0.6 0.2-0.2 0.2-0.2 0.4-0.2 0.6 0 0.1 3.6 7 3.6 7v0z"></path>
                                        <path fill="#f0f"
                                              d="M12.3 5.8l-2.6-5c0-0.4-0.3-0.6-0.7-0.6h-2.6c-0.2 0-0.4 0.1-0.6 0.3-0.1 0.1-0.2 0.3-0.2 0.5v0 0c0 0.1 2.6 5.1 4.6 9l2.1-4.2z"></path>
                                        <path fill="#f0f"
                                              d="M10.2 18.3l-4.6 8.7c-0.1 0.3-0.1 0.5 0.1 0.7s0.4 0.3 0.7 0.3h2.6c0.4 0 0.7-0.2 0.8-0.6l2.6-5-2.2-4.1z"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row__buttons">
                    <button class="button button_small button_white-border" (click)="generateButtonCode()">
                        Generate code
                    </button>
                    <div class="info__holder" *ngIf="activeTab!='copy'">
                        <button class="info notification-msg__holder" [class.link-active]="activeTab == 'info'"
                                (click)="activeTab = 'info'">
                            <svg class="svg-icon icon-info">
                                <use xlink:href="#icon-info"></use>
                            </svg>
                            <div class="notification-msg">
                                How to use script
                            </div>
                        </button>
                    </div>
                </div>
                <div class="form__row" *ngIf="showCodeArea">
                    <div class="form-label__holder">
                        <textarea class="textfield textfield__white-border disable"  id="code-field"
                                  cols="30" rows="5" formControlName="codeField"></textarea>
                        <button class="copy" (click)="copyScript()">
                            <svg class="svg-icon icon-copy">
                                <use xlink:href="#icon-copy"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="activeTab=='info'">
                <div class="title__info">How to use script</div>
                <ul class="info__list">
                    <li>
                        Go through the steps of Generate Button section
                    </li>
                    <li>
                        Press button Generate Code
                    </li>
                    <li>
                        Copy HTML code from the textarea which will appears under Generate Code button
                    </li>
                    <li>
                        Paste this code inside HTML code of your page. It should be place between open and close HTML
                        'body' tag like in an example above
                        <p>
                            &lt;body&gt;
                        </p>
                        <p>
                            ...Some place
                        </p>
                        <p>
                            &lt;/body&gt;
                        </p>
                    </li>
                </ul>
                <p>

                </p>
            </div>
        </form>

    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>
