import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xion-edit-user-subscription-modal',
  templateUrl: './edit-user-subscription-modal.component.html',
  styleUrls: ['./edit-user-subscription-modal.component.scss']
})
export class EditUserSubscriptionModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
