import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResponseStatus } from 'src/app/shared/models/billing-product.model';
import { environment } from '../../../environments/environment';
import { IAttachment } from '../../shared/models/models';
import { ILogoResponse } from '../../shared/interfaces/file.interface';
import { NotificationsService } from './notifications.service';

export interface IUploadResponse {
    data: IAttachment[];
    progress: number;
    status: 'success' | 'progress' | 'fail';
}

export enum FileUploadTypes {
    document = 'document',
    kyc = 'kyc',
    video = 'video',
    photo = 'photo',
    logo = 'logo'
}

// In MB
export enum FileSizeLimits {
    document = 25,
    kyc = 25,
    video = 1500,
    photo = 15,
    logo = 2
}

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {
    private UPLOAD_PROFILE_PHOTO = `${environment.api_url}/uploadphoto`;
    private UPLOAD_KYC = `${environment.api_url}/users/uploadkyc`;
    private UPLOAD_VIDEO = `${environment.api_url}/users/uploadvideo`;
    private UPLOAD_DOC = `${environment.api_url}/users/uploaddoc`;
    private UPLOAD_PHOTO = `${environment.api_url}/users/uploadphoto`;
    private UPLOAD_LOGO = `${environment.api_url}/users/logo`;

    constructor(private http: HttpClient, private notifications: NotificationsService) {}

    uploadProfilePhoto(files: any[] | FileList) {
        return this.upload(files, this.UPLOAD_PROFILE_PHOTO, FileUploadTypes.photo);
    }

    uploadDocument(files: any[] | FileList): Observable<IUploadResponse> {
        return this.upload(files, this.UPLOAD_DOC, FileUploadTypes.document);
    }
    uploadPhoto(files: any[] | FileList): Observable<IUploadResponse> {
        return this.upload(files, this.UPLOAD_PHOTO, FileUploadTypes.photo);
    }

    uploadVideo(files: any[] | FileList): Observable<IUploadResponse> {
        return this.upload(files, this.UPLOAD_VIDEO, FileUploadTypes.video);
    }

    uploadKyc(files: any[] | FileList): Observable<IUploadResponse> {
        return this.upload(files, this.UPLOAD_KYC, FileUploadTypes.kyc);
    }

    uploadLogo(image64: string | ArrayBuffer) : Observable<ILogoResponse>{
        return this.http.patch<any>(this.UPLOAD_LOGO, {data: image64})
    }

    private upload(
        files: any[] | FileList,
        URL: string,
        uploadType: FileUploadTypes
    ): Observable<IUploadResponse> {
        const form = new FormData();
        files = Array.from(files);
        if (!this.isFieldSizeValid(files, uploadType)) {
            this.notifications.showMessage(
                `File Size Exceeded maximum of ${FileSizeLimits[uploadType]} MB`
            );
            return;
        }

        files.forEach((file: File) => {
            form.append('filename', file);
        });
        return this.http
            .post<any>(URL, form, {
                reportProgress: true,
                observe: 'events'
            })
            .pipe(
                map(this.handleEvent.bind(this))
                // catchError(this.handleError)
            );
    }

    isFieldSizeValid(files: any[] | FileList, uploadType: FileUploadTypes): boolean {
        const size = files[0].size / 1024 / 1024; // in MB
        return size < FileSizeLimits[uploadType];
    }

    private handleEvent(event: HttpEvent<any>): IUploadResponse {
        switch (event.type) {
            case HttpEventType.UploadProgress:
                return { status: 'progress', progress: this.fileUploadProgress(event), data: null };

            case HttpEventType.Response:
                return { data: this.apiResponse(event), status: 'success', progress: 100 };
            default:
                return { data: null, progress: 0, status: 'fail' };
        }
    }

    private fileUploadProgress(event) {
        return Math.round((100 * event.loaded) / event.total);
    }

    private apiResponse({ body }) {
        return body.data;
    }

    private handleError(error: HttpErrorResponse) {
        // return { data: null, status: 'fail', progress: null };
        return throwError('Something bad happened. Please try again later.');
    }
}
