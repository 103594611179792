import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ICity, ICountry, IState, LocationService } from 'src/app/core/services/location.service';
import { InterestsService } from 'src/app/core/shared/interests.service';
import { EmailValidator, URLValidator } from 'src/app/shared/constants/validators';
import { IProvider, ProviderService, ProviderTypes } from '../../../core/services/provider.service';

@Component({
    selector: 'xion-create-provider-modal',
    templateUrl: './create-provider-modal.component.html',
    styleUrls: ['./create-provider-modal.component.scss']
})
export class CreateProviderModalComponent implements OnInit {
    interests: Observable<string[]>;
    states$: Observable<IState[]>;
    cities$: Observable<ICity[]>;
    countries$: Observable<ICountry[]>;
    submitted = false;
    supplier: boolean;
    providerForm: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public modalType: ProviderTypes,
        private dialogRef: MatDialogRef<CreateProviderModalComponent>,
        private builder: FormBuilder,
        private location: LocationService,
        private interestsService: InterestsService,
        private providersService: ProviderService
    ) {
        this.supplier = modalType === ProviderTypes.Supplier;
    }

    ngOnInit() {
        this.interests = this.interestsService.getAreasOfInterest();
        this.countries$ = this.location.getCountries();
        this.providerForm = this.builder.group({
            name: ['', Validators.required],
            phone: ['', Validators.required],
            address: ['', Validators.required],
            email: [''],
            website: [''],
            interestareas: [null, Validators.required],
            country: [null, Validators.required],
            state: [{ value: null, disabled: true }],
            city: [{ value: null, disabled: true }],
            zip: ['', Validators.required]
        });
    }

    closeDialog(provider: IProvider = null) {
        this.dialogRef.close(provider);
    }

    onSubmit() {
        this.submitted = true;
        if (this.providerForm.valid) {
            const provider: IProvider = this.providerForm.value;
            provider.distributor = !this.supplier;
            provider.supplier = this.supplier;
            this.providersService.createProvider(provider).subscribe((provider) => {
                this.closeDialog(provider);
            });
        }
    }

    selectCountry(country: ICountry) {
        if (country) {
            this.form.state.setValue(null);
            this.form.city.setValue(null);
            const pattern = new RegExp(country.zip_regexp || '.*');
            this.providerForm
                .get('zip')
                .setValidators([Validators.required, Validators.pattern(pattern)]);
            this.states$ = this.location.getStates(country.id);
            this.states$.subscribe((res) => {
                if (res.length === 0) {
                    this.cities$ = this.location.getCities(country.id);
                    this.cities$.subscribe((citiesRes) => {
                        if (citiesRes.length > 0) {
                            this.form.city.enable();
                        } else {
                            this.form.city.setValue(null);
                            this.form.city.disable();
                        }
                    });
                    this.form.state.setValue(null);
                    this.form.state.disable();
                } else {
                    this.form.state.enable();
                }
            });
        } else {
            this.form.state.disable();
            this.form.state.setValue(null);
            this.form.city.disable();
            this.form.city.setValue(null);
        }
    }

    selectState(state: IState) {
        if (state) {
            this.cities$ = this.location.getCities(state.country, state.id);
            this.form.city.enable();
        } else {
            this.form.city.setValue(null);
            this.form.city.disable();
        }
    }

    isFieldInvalid(formControlName) {
        const control = this.providerForm.get(formControlName);
        return (this.submitted && control.invalid) || (control.touched && control.invalid);
    }

    get form() {
        return this.providerForm.controls;
    }
}
