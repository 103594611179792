import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'xion-checkout-button-instruction-dialog',
    templateUrl: './checkout-button-instruction-dialog.component.html',
    styleUrls: ['./checkout-button-instruction-dialog.component.scss']
})
export class CheckoutButtonInstructionDialogComponent implements OnInit {

    buttonName = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            buttonName: string
        }
    ) {
    }

    ngOnInit(): void {
        this.buttonName = this.data.buttonName;
    }

}
