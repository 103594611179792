import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DummyDataService } from 'src/app/core/services/dummy.service';
import { ICustomer } from '../../../core/services/customers.service';

@Component({
  selector: 'xion-view-customer-modal',
  templateUrl: './view-customer-modal.component.html',
  styleUrls: ['./view-customer-modal.component.scss']
})
export class ViewCustomerModalComponent implements OnInit {
    transactions$: any;
    contactForm: FormGroup;
    activeTab = 'subscription';
    constructor(
        @Inject(MAT_DIALOG_DATA) public customer: ICustomer,
        private dialogRef: MatDialogRef<ViewCustomerModalComponent>,
        private builder: FormBuilder,
        private dummy: DummyDataService) {
    }


    ngOnInit() {
        this.contactForm = this.builder.group({
            message : [null]
        });
        this.transactions$ = this.dummy.getTransactions();
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
