import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, UserRoleEnum } from 'src/app/auth/services/auth.service';
import { MetadataService } from 'src/app/core/services/metadata.service';
import { IUser, KYCStatus } from '../../../core/services/user.service';
import { AsideService } from '../../services/aside.service';
import { UploadLogoModalComponent } from '../../modals/upload-logo-modal/upload-logo-modal.component';
import { Events, PusherService, WalletRoutes } from '../../services/pusher.service';
import { CashbackRewardModalComponent } from '../../modals/cashback-reward-modal/cashback-reward-modal.component';

@Component({
    selector: 'xion-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
    KYC = KYCStatus;
    title = '';
    role = UserRoleEnum;
    isExpanded = false;
    showOption = false;
    searchValue = '';
    @Input() user: Observable<IUser>;
    @Input() notifications: Observable<any>;
    @Output() closeMobileMenu = new EventEmitter();
    @Output() searchEvent = new EventEmitter();

    constructor(
        private authService: AuthService,
        private router: Router,
        private dialog: MatDialog,
        private metadataService: MetadataService,
        public asideService: AsideService,
        private pusherService: PusherService
    ) {
        this.metadataService.pageData$.subscribe((data) => {
            if (data) this.title = data.title || '';
        });
    }

    ngOnInit() {}

    logout() {
        this.authService.logout();
        this.router.navigate(['/home']);
    }

    onCurrencyChanged(currency) {}

    closeMobileSideMenu() {
        this.closeMobileMenu.emit(true);
    }

    toggleMenu() {
        this.asideService.toggleAside();
    }

    openExtensionWallet(): void {
        this.pusherService.channel.trigger(Events.OPEN_WALLET, {});
    }

    openUploadLogoModal() {
        this.dialog.open(UploadLogoModalComponent, { panelClass: 'popup__holder' });
    }

    onOpenRewards() {
        this.dialog.open(CashbackRewardModalComponent, { panelClass: 'popup__holder' });
    }

    closeList(): void {
        if (this.showOption) this.showOption = false;
    }

    navigateProfile(): void {
        this.router.navigate(['/merchant', 'profile']);
    }

    openHelp(): void {
        window.open('https://discord.gg/QfKm5jsq6d', '_blank').focus();
    }

    onSearch(event: any): void {
        this.searchEvent.emit(event.target.value);
    }
}
