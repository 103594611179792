import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { combineLatest, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { InterestsService } from 'src/app/core/shared/interests.service';
import { Web3InstanceService } from '../../../auth/services/web3-instance.service';
import { NotificationsService } from '../../../core/services/notifications.service';
import { SubscriptionsService } from '../../../core/services/subscriptions.service';
import { HttpService } from '../../../retailers/services/http.service';
import { mapToErrorMessage } from '../../constants/helpers';
import { ISubscription } from '../../interfaces/subscription.interface';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { PrivateKeyModalComponent } from '../../modals/private-key-modal/private-key-modal.component';
import { ViewTransactionsModalComponent } from '../../modals/subscriptions/user/view-subscription-modal/view-transactions-modal.component';
import {
    BillingProductType,
    StatusEnum,
    BillingTypeEnum
} from '../../models/billing-product.model';
import {
    IMySubscription,
    SubscriptionModel,
    subscriptionsFilter
} from '../../models/subscription.model';
import { IUser } from 'src/app/core/services/user.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { DummyDataService } from 'src/app/core/services/dummy.service';

@Component({
    selector: 'xion-subscriptions',
    templateUrl: './my-subscriptions.component.html',
    styleUrls: ['./my-subscriptions.component.scss']
})
export class UsersSubscriptionsComponent implements OnInit, OnDestroy {
    config: SwiperConfigInterface = {
        direction: 'vertical',
        slidesPerView: 'auto',
        keyboard: true,
        mousewheel: true,
        scrollbar: false,
        navigation: true,
        freeMode: true
    };

    query: string;
    subscriptions: ISubscription[] = null;
    subscriptions$: Observable<IMySubscription[]>;
    pendingSubscriptionsList: number[] = [];
    shoppingStart: string = 'unknown';
    pendingIntervalInMS: number = 3000;
    pendingInterval$: Observable<number> = null;
    pendingStop$: Subject<boolean> = new Subject();

    interests$: Observable<string[]>;
    searchQuerySubscription: Subscription;
    public filter = subscriptionsFilter;
    public billingProductType = BillingProductType;
    public billingTypeEnum = BillingTypeEnum;
    public searchQuery$: Subject<string> = new Subject<string>();

    user$: Observable<IUser>;
    notifications$: Observable<any>;

    constructor(
        private dialog: MatDialog,
        private subscriptionsService: SubscriptionsService,
        private interestsService: InterestsService,
        private notifications: NotificationsService,
        private httpService: HttpService,
        private web3InstanceService: Web3InstanceService,
        private formBuilder: FormBuilder,
        private router: Router,
        public authService: AuthService,
        private dummyService: DummyDataService,
    ) {}

    async ngOnInit() {
        this.user$ = this.authService.currentUser;
        this.notifications$ = this.dummyService.getNotifications();

        this.interests$ = this.interestsService.getAllAreasOfInterest();

        combineLatest([
            this.httpService.getSubscriptions(),
            this.httpService.getSingleSubscriptions()
        ]).subscribe(([res, resSingle]) => {
            const subscriptions = [];
            if (res) {
                res.forEach((data: IMySubscription) => {
                    const model = new SubscriptionModel(data);
                    if (model.status === StatusEnum.pending || model.status === StatusEnum.active || model.status === StatusEnum.subscribe) {
                        this.pendingStatusEvent(model);
                    }
                    subscriptions.push(model);
                });
            }
            if (resSingle) {
                resSingle.forEach((data: IMySubscription) => {
                    const model = new SubscriptionModel(data);
                    if (model.status === StatusEnum.pending || model.status === StatusEnum.active || model.status === StatusEnum.subscribe) {
                        this.pendingStatusEvent(model);
                    }
                    subscriptions.push(model);
                });
            }
            this.subscriptions$ = of(subscriptions);
        });

        this.searchQuerySubscription = this.searchQuery$
            .pipe(debounceTime(300))
            .subscribe((value: string) => {
                this.filter.searchQuery = value;
            });
        await this.web3InstanceService.init();
        await this.web3InstanceService.checkChain(false);
        setTimeout(() => {
            this.shoppingStart='display'
        }, 1000);
    }

    ngOnDestroy(): void {
        if (this.searchQuerySubscription) this.searchQuerySubscription.unsubscribe();
        if (this.pendingStop$) this.pendingStop$.next(true);
    }

    openViewTransactionsModal(subscription) {
        this.dialog.open(ViewTransactionsModalComponent, {
            panelClass: 'popup-blue__holder',
            data: subscription
        });
    }

    unsubscribe(subscription: ISubscription) {
        this.openConfirmUnsubscriptionModal()
            .pipe(switchMap(this.openPrivateKeyModal.bind(this)))
            .pipe(
                switchMap((key: string) => this.subscriptionsService.unsubscribe(subscription, key))
            )
            .subscribe((unsub) => {
                subscription.subscribed = false;
                subscription.paused = false;
                this.notifications.showMessage(
                    'You successfully unsubscribed from this subscription'
                );
            });
    }

    openPrivateKeyModal(): Observable<string> {
        return this.dialog
            .open(PrivateKeyModalComponent, {
                panelClass: 'popup__holder'
            })
            .afterClosed()
            .pipe(filter((key) => !!key));
    }

    public categoryChanged(value): void {
        if (value === 'All') {
            this.filter.category = '';
        } else {
            this.filter.category = value ? value : '';
        }
    }

    public productTypeFilter(value: MatCheckboxChange) {
        if (this.filter.productType.includes(value.source.value)) {
            this.filter.productType.splice(this.filter.productType.indexOf(value.source.value), 1);
        } else {
            this.filter.productType.push(value.source.value);
        }
    }

    public pendingStatusEvent(subscription: IMySubscription): void {
        this.pendingSubscriptionsList.push(subscription.id);
        this.startIntervalIfNeeded();
    }

    private startIntervalIfNeeded(): void {
        if (!this.pendingInterval$) {
            this.pendingInterval$ = interval(this.pendingIntervalInMS);
            this.pendingInterval$
                .pipe(takeUntil(this.pendingStop$))
                .subscribe(this.pendingHandler.bind(this));
        }
    }

    private pendingHandler(val: IMySubscription[]) {
        this.httpService
            .getPendingStatus(this.pendingSubscriptionsList.join(','))
            .subscribe((response: IMySubscription[]) => {
                response.map((data) => {
                    const model = new SubscriptionModel(data);
                    if (model.status !== StatusEnum.pending) {
                        this.subscriptions$
                            .pipe(map((subs) => subs.filter((sub) => sub.id === model.id)))
                            .subscribe((result) => {
                                result[0].status = model.status;
                                result[0].buttonStates = model.buttonStates;
                                this.pendingSubscriptionsList.splice(
                                    this.pendingSubscriptionsList.indexOf(model.id),
                                    1
                                );
                                if (!this.pendingSubscriptionsList.length) {
                                    this.pendingStop$.next(true);
                                    this.pendingInterval$ = null;
                                }
                            });
                    }
                });
            });
    }

    private openConfirmModal(config) {
        return this.dialog
            .open(ConfirmModalComponent, {
                panelClass: 'popup-blue__holder',
                data: config
            })
            .afterClosed()
            .pipe(
                take(1),
                filter((confirm) => confirm)
            );
    }

    private openConfirmUnsubscriptionModal() {
        return this.dialog
            .open(ConfirmModalComponent, {
                panelClass: 'popup-blue__holder',
                data: {
                    title: 'Unsubscribe Confirmation',
                    description: 'Are you sure you want to Unsubscribe?',
                    confirm: 'Confirm',
                    cancel: 'Cancel'
                }
            })
            .afterClosed()
            .pipe(filter((unsubscribe) => unsubscribe));
    }

    private handleError(err: HttpErrorResponse) {
        const message = mapToErrorMessage(err);
        this.notifications.showMessage(message);
        throw err;
    }
    startShopping() {
        window.open('https://xion.finance/shop', '_blank').focus();
    }
}
