
<div class="subscription-slider__holder">
    <div class="swiper-container" [swiper]="configSub">
        <div class="swiper-wrapper">
            <div class="subscription-item swiper-slide" *ngFor="let provider of providers" (click)="openProviderDetails(provider)">
                <div class="subscription-item__content" >
                    <div class="subscription-item__row">
                        <div class="textfield subscription-item__title text-overflow">
                            {{provider.name}}
                        </div>
                    </div>
                    <div class="subscription-item__row subscription-item__row_details">
                        <div class="textfield textfield__subscription text-overflow">{{provider.website}}</div>
                        <div class="textfield textfield__blue">{{provider.email}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="next-sub slider-next">
        <svg class="svg-icon slide-arrow_next">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
    <button class="prev-sub slider-prev">
        <svg class="svg-icon slide-arrow">
            <use xlink:href="#slide-arrow"></use>
        </svg>
    </button>
</div>
