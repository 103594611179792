<div class="popup-blue__content">
    <div class="popup-blue">
        <h3 class="popup-blue__title">{{data.title}}</h3>
        <p class="popup-blue__text">{{data.description}}</p>
        <div class="row__buttons">
            <button *ngIf="data.confirm" class="button button_small button_white-border" (click)="confirm()">{{data.confirm}}</button>
            <button class="button button_small button_white-border" (click)="closeDialog()">{{data.cancel}}</button>
        </div>
    </div>
    <button (click)="closeDialog()" class="close">
        <svg class="svg-icon">
            <use xlink:href="#close-simple"></use>
        </svg>
    </button>
</div>
