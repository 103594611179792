<div class="popup__content">
    <div class="popup">
        <div class="form__row">
            <!--<label class="textfield file__label" for="file" (change)="fileChangeEvent($event)">-->
                <!--<span class="file__title">-->
                    <!--UPLOAD A PROFILE PHOTO-->
                <!--</span>-->
                <!--<input type="file" class="file" id="file" aria-label="File browser example">    -->
            <!--</label>-->
            <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [roundCropper]="true"
                (imageCropped)="imageCropped($event)"
            ></image-cropper>
            
        </div>
        <div class="form__row">
            <a (click)="saveImage()" mat-dialog-close  class="button button_blue button_small">
                Done
                <span class="button__arrow">
                    <svg class="svg-icon icon-arrow_button"><use xlink:href="#icon-arrow-play"></use></svg>
                </span>
            </a>
        </div>
        <span class="popup__title">
            CROP PHOTO
        </span>
    </div>
    <button (click)="closeDialog()" class="popup__close">
        <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
    </button>
</div>
