import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EmailValidator } from '../../constants/validators';

@Component({
    selector: 'xion-restore-password-modal',
    templateUrl: './restore-password-modal.component.html',
    styleUrls: ['./restore-password-modal.component.scss']
})
export class RestorePasswordModalComponent implements OnInit {
    restorePassword: FormGroup;
    submitted: boolean;

    constructor(
        private builder: FormBuilder,
        private dialogRef: MatDialogRef<RestorePasswordModalComponent>
    ) {
    }

    ngOnInit() {
        this.restorePassword = this.builder.group({
            email: [null, [Validators.required, EmailValidator.email]]
        });
    }

    closeDialog(email = null) {
        this.dialogRef.close(email);
    }

    sendRestore() {
        this.submitted = true;
        if (this.restorePassword.valid) {
            const email = this.restorePassword.get('email').value;
            this.closeDialog(email);
        }
    }

    get form() {
        return this.restorePassword.controls;
    }
}
