import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUser } from '../../../../core/services/user.service';
import { IPost } from '../../../pages/social/social.component';

@Component({
    selector: 'xion-channel-posts-list',
    templateUrl: './social-posts-list.component.html',
    styleUrls: ['./social-posts-list.component.scss']
})
export class SocialPostsListComponent {

    @Output() public userScrolled: EventEmitter<number> = new EventEmitter<number>();
    @Input() public posts: IPost[];
    @Input() public tab: string;
    @Input() public query: string;
    @Input() public user: IUser;

    @Output() public likePost = new EventEmitter<string>();
    @Output() public followUser = new EventEmitter<IUser>();


    private scrolledTimes = 0;

    onScroll() {
        this.scrolledTimes++;
        this.userScrolled.emit(this.scrolledTimes);
    }

    follow(user: IUser) {
        this.followUser.emit(user);
    }

    like(postId: string) {
        this.likePost.emit(postId);
    }

}
