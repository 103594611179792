<section class="main__section">
    <div class="channel__top-row">
        <div class="search-row">
            <input
                type="text"
                name="search"
                id="search"
                #input
                class="search"
                placeholder="search"
                [(ngModel)]="filters.username"
                (keyup)="querySubject.next(input.value)"
            >
        </div>
        <div class="channel__select-holder">
            <ng-select
                placeholder="Areas Of Interest"
                [items]="interests$ | async"
                [(ngModel)]="filters.interestareas"
                (change)="filterSubscriptions()"
                [multiple]="true">
            </ng-select>
        </div>
    </div>
    <div class="form__row ">
        <div class="checkbox__holder">
            <input type="checkbox" id="digital" class="checkbox" value="digital" (change)="filterSubscriptions()" [(ngModel)]="filters.digital">
            <label for="digital" class="checkbox__label">
                <svg class="svg-icon icon-check"><use xlink:href="#check"></use></svg>
                <span class="checkbox__text">Digital</span>
            </label>
        </div>
        <div class="checkbox__holder">
            <input type="checkbox" id="physical" class="checkbox" value="physical" (change)="filterSubscriptions()" [(ngModel)]="filters.physical">
            <label for="physical" class="checkbox__label">
                <svg class="svg-icon icon-check"><use xlink:href="#check"></use></svg>
                <span class="checkbox__text">physical</span>
            </label>
        </div>
<!--        <div class="checkbox__holder">-->
<!--            <input type="checkbox" id="preorder" class="checkbox" value="preorder" (change)="filterSubscriptions()" [(ngModel)]="filters.preorder">-->
<!--            <label for="preorder" class="checkbox__label">-->
<!--                <svg class="svg-icon icon-check"><use xlink:href="#check"></use></svg>-->
<!--                <span class="checkbox__text">Pre-Order</span>-->
<!--            </label>-->
<!--        </div>-->
        <div class="checkbox__holder">
            <input type="checkbox" id="non-profit" class="checkbox" (change)="filterSubscriptions()" [(ngModel)]="filters.nonprofit">
            <label for="non-profit" class="checkbox__label">
                <svg class="svg-icon icon-check"><use xlink:href="#check"></use></svg>
                <span class="checkbox__text">non profit organizations</span>
            </label>
        </div>
    </div>
    <div class="tabs">
        <button class="tab__item" [class.link-active]="activeTab === tabs.DISCOVER" (click)="setActiveTab(tabs.DISCOVER)">Discover</button>
        <button class="tab__item" [class.link-active]="activeTab === tabs.TRENDING" (click)="setActiveTab(tabs.TRENDING)">Trending</button>
        <button class="tab__item" [class.link-active]="activeTab === tabs.FEED"  (click)="setActiveTab(tabs.FEED)" >Feed</button>
        <button class="tab__item" [class.link-active]="activeTab === tabs.OFFERS" (click)="setActiveTab(tabs.OFFERS)">One Time Offer</button>
        <button class="tab__item" [class.link-active]="activeTab === tabs.PRIVATE" (click)="setActiveTab(tabs.PRIVATE)">Private</button>
    </div>
    <xion-subscription-list
        class="channels"
        *ngIf="activeTab === tabs.FEED"
        [user]="user"
        [subscriptions]="feed$ | async" ></xion-subscription-list>
    <xion-subscription-list
        class="channels"
        *ngIf="activeTab === tabs.TRENDING"
        [user]="user"
        [subscriptions]="trending$ | async"></xion-subscription-list>
    <xion-subscription-list
        class="channels"
        *ngIf="activeTab === tabs.DISCOVER"
        [user]="user"
        [subscriptions]="discover$| async" ></xion-subscription-list>
    <xion-subscription-list
        class="channels"
        *ngIf="activeTab === tabs.OFFERS"
        [user]="user"
        [subscriptions]="onetime$| async" ></xion-subscription-list>
    <xion-subscription-list
        class="channels"
        *ngIf="activeTab === tabs.PRIVATE"
        [user]="user"
        [subscriptions]="private$| async" ></xion-subscription-list>
</section>

