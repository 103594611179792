import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { HighlightService } from '../../services/highlight.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'xion-copy-script-modal',
    templateUrl: './copy-script-modal.component.html',
    styleUrls: ['./copy-script-modal.component.scss'],
    animations: [
        trigger('appearAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('1s ease-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('1s ease-in', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class CopyScriptModalComponent implements OnInit {
    showCopyResultScript = false;
    showCopyResultProduct = false;

    highlighted: boolean = false;
    public scriptUrl: string = environment.checkoutButtonScript;

    constructor(
        @Inject(MAT_DIALOG_DATA) public productId: number,
        private clipboardService: ClipboardService,
        private highlightService: HighlightService
    ) {}

    ngOnInit() {}

    ngAfterViewChecked() {
        if (!this.highlighted) {
            this.highlightService.highlightAll();
            this.highlighted = true;
        }
    }

    copyButtonCode(value: any) {
        if (this.clipboardService.copyFromContent(value.innerText)) {
            this.showCopyResultProduct = true;
            setTimeout(() => (this.showCopyResultProduct = false), 2000);
        }
    }

    copyScriptCode(value: any) {
        if (this.clipboardService.copyFromContent(value.innerText)) {
            this.showCopyResultScript = true;
            setTimeout(() => (this.showCopyResultScript = false), 2000);
        }
    }
}
