import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrencyService } from '../../core/services/currency.service';

@Pipe({
    name: 'currencyConverter'
})
export class CurrencyConverterPipe extends CurrencyPipe implements PipeTransform {

    private readonly currency: Observable<any>;

    constructor(private currencyService: CurrencyService) {
        super('en-US');
        this.currency = this.currencyService.selectedCurrency || null
    }

    // @ts-ignore
    transform(value: any, display = 'symbol-narrow'): Observable<any> {
        if (!this.currency) {
            const transformed = super.transform(Number(value), 'USD', display);
            return of(transformed);
        }
        return this.currencyService.selectedCurrency
            .pipe(map(currency => this.transformCurrencyRate(value, currency, display)));
    }

    private transformCurrencyRate(value: any, currency: string, display: string) {
        const rate = this.currencyService.getCurrencyRate(currency) || value;
        return super.transform(Number(value) * rate, currency, display);
    }
}
