<div class="border-right"></div>
<div class="border-left"></div>
<div class="white-background">
    <div class="post">
        <div class="post__picture" *ngIf="user.image;else noProfileImage">
            <img [src]="user.image" [alt]="user.fullname">
        </div>
        <ng-template #noProfileImage>
            <div class="post__picture post__picture-empty">
                <svg class="svg-icon icon__no-retailer">
                    <use xlink:href="#icon-retailer"></use>
                </svg>
            </div>
        </ng-template>

        <form [formGroup]="createPostForm" class="post-form">
            <div class="form__row" [ngClass]="form.text.errors && form.text.touched? 'error__field' : ''">
                <textarea
                    class="textfield"
                    cols="30"
                    rows="5"
                    formControlName="text"
                    placeholder="Type text here"></textarea>
                <span class="error__text" *ngIf="form.text.errors && form.text.touched">
                    <p *ngIf="form.text.errors.required">post text is required</p>
                </span>
            </div>
        </form>
        <div class="form__row form__row_multiple progress__holder" [class.progress_active]="uploadInProgress">
            <div class="row__form_file">
                <label class="textfield file__label file__label_multiple" for="photo" [class.done]="uploadImageObj.done">
                    <span class="file__title file__title_multiple">
                        {{uploadImageObj.label}}
                    </span>
                    <input type="file" class="file" id="photo" aria-label="File browser example"
                           accept="image/*"
                           #photoInput
                           (change)="uploadPhoto(photoInput.files)">
                    <svg class="svg-icon icon-check_file">
                        <use xlink:href="#check"></use>
                    </svg>
                </label>
            </div>
            <div class="row__form_file">
                <label class="textfield file__label file__label_multiple" for="video" [class.done]="uploadVideoObj.done">
                    <span class="file__title file__title_multiple">
                        {{uploadVideoObj.label}}
                    </span>
                    <input type="file" class="file" id="video" aria-label="File browser example"
                           accept="video/*" #videoInput
                           (change)="uploadVideo(videoInput.files)">
                    <svg class="svg-icon icon-check_file">
                        <use xlink:href="#check"></use>
                    </svg>
                </label>
            </div>
            <div class="row__form_file">
                <label class="textfield file__label file__label_multiple" for="document" [class.done]="uploadDocumentObj.done">
                    <span class="file__title file__title_multiple">
                        {{uploadDocumentObj.label}}
                    </span>
                    <input type="file" class="file" id="document" aria-label="File browser example"
                           accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt,.pdf"
                           #documentInput
                           (change)="uploadDocument(documentInput.files)">
                    <svg class="svg-icon icon-check_file">
                        <use xlink:href="#check"></use>
                    </svg>
                </label>
            </div>
            <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
        </div>
    </div>
</div>
<button class="popup__close" (click)="onSubmit()" [class.disable]="uploadInProgress || form.text.invalid">
    <span class="button-text">post</span>
</button>
