import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import { Observable } from 'rxjs';
import { mapTo, take, tap } from 'rxjs/operators';
import { ICredentials } from '../../../core/services/user.service';
import { CredentialsConfirmComponent } from '../../modals/credentials-confirm/credentials-confirm.component';

@Component({
    selector: 'xion-credentials',
    templateUrl: './credentials.component.html',
    styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {

    canRedirect: boolean = false;

    credentials$: Observable<ICredentials>;
    constructor(private router : Router, private dialog: MatDialog,private route : ActivatedRoute) {
        this.credentials$ = this.route.paramMap.pipe(take(1),mapTo(window.history.state),tap(nav=>nav.navigationId === 1? router.navigate(['/']): nav));
    }

    ngOnInit() {
    }

    printPage() {
        window.print();
        this.canRedirect = true;
    }

    download() {
        const doc = new jsPDF();
        doc.fromHTML(document.querySelector('#credentials'));
        doc.save('credentials.pdf');
        this.canRedirect = true;
    }

    redirectTo(path: string) {
        if (this.canRedirect){
            return this.router.navigate([path])
        }else{
            this.openModal()
        }
    }
    openModal() {
        this.dialog
        .open(CredentialsConfirmComponent, {panelClass: 'popup__holder'})
        .afterClosed()
        .subscribe(answer => {
            if(answer) {
                return this.router.navigate([''])
            }
        });
      }


}
