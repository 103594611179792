<div class="popup__content">
  <div class="popup">
    <div class="popup__charts new-user__popup">
        <xion-new-users-chart
        *ngIf="showCHart"
          [xAxis]="false"
          [yAxis]="true"
          [users]="users"
          [showXAxisLabel]="false"
          [showYAxisLabel]="false"></xion-new-users-chart>
    </div>
    <span _ngcontent-c29="" class="popup__title">NEW CUSTOMER SIGNUPS</span>
  </div>
  <button (click)="closeDialog()" class="popup__close">
    <svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg>
  </button>
</div>

