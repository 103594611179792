import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { SharedModule } from './shared/shared.module';
import { AuthModalComponent } from './shared/modals/auth-modal/auth-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.cookieDomain
    },
    palette: {
        popup: {
            background: '#ffffff',
            text: '#000000',
        },
        button: {
            background: '#299cd3',
            text: '#ffffff',
            border: 'transparent'
        }
    },
    theme: 'classic',
    type: 'info',
    content: {
        message: 'THIS WEBSITE USES COOKIES TO IMPROVE YOUR EXPERIENCE',
        "dismiss": "Accept",
        link: ''
    }
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        RouterModule,
        CoreModule,
        SharedModule,
        MatCheckboxModule,
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
