import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordGenerator } from '../../constants/helpers';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { ConfirmPasswordValidator } from '../../constants/validators';

@Component({
  selector: 'xion-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
    changePassword: FormGroup;
    showPassword = false;
    submitted: boolean;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private builder: FormBuilder,
        private clipboard: ClipboardService,
        private notifications: NotificationsService,
        private dialogRef: MatDialogRef<ChangePasswordModalComponent>
    ) { }

    ngOnInit() {
        this.changePassword = this.builder.group({
            oldPassword: ['', Validators.required],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        },
        {
            validator: ConfirmPasswordValidator.MatchPassword
        });
    }
    get form() {
        return this.changePassword.controls;
    }
    closeDialog() { this.dialogRef.close(); }
    generatePassword() {
        const pass = passwordGenerator();
        this.form.password.setValue(pass);
        this.form.confirmPassword.setValue(pass);
        this.copyPassword(pass);
    }
    copyPassword(text: string) {
        this.clipboard.copyFromContent(text);
        this.notifications.showMessage('password copied to clipboard', 'Hide');
    }
    submitForm() {
        this.submitted = true;
        if (this.changePassword.valid) {

        }
    }
    // submitForm() {
    //     this.submitted = true;
    //     if (this.merchantForm.valid) {
    //         const values = this.merchantForm.value;
    //         const { confirmPassword, acceptTerms, ...data } = values;
    //         const user: IUser = data;
    //         user.merchant = true;
    //         this.authService.signup(user).subscribe((data => {
    //             console.log('merchant sign up', data);
    //             this.router.navigate(['merchant', 'login']);
    //         }), err => console.log(err));
    //     }
    //     console.log('errors', this.merchantForm.controls);
    // }
}
